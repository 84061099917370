import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transport-condition-popup',
  templateUrl: './transport-condition-popup.component.html',
  styleUrls: ['./transport-condition-popup.component.css']
})
export class TransportConditionPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
