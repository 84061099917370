import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatOption } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { List } from 'linqts';
import { FilterOptions } from 'src/core/models/filter-options';
import { HttpClientService } from 'src/core/services/http-client.service';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { QueryBuilder } from 'odata-query-builder';
import UserPermission from 'src/core/models/UserPermission';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { FilterPermission } from 'src/core/models/filter-permission';
import { RoleTypeEnum } from 'src/core/models/role.model';
import { Roles } from 'src/core/enum/roles';
import { PeoplePickerComponent } from 'src/app/shared/people-picker/people-picker.component';
import { Router } from '@angular/router';

interface group {
  id: number;
  roleName: string;
}

@Component({
  selector: 'app-gestion-permissions',
  templateUrl: './gestion-permissions.component.html',
  styleUrls: ['./gestion-permissions.component.css'],
})
export class GestionPermissionsComponent implements OnInit {
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  elementToDelete!: any;
  userPermission: any[] = [];
  displayedColumnsUser!: any;
  groups: Array<group> = [];
  allRoles: Array<group> = [];
  permissionForm!: FormGroup;
  selectedRoles = new FormControl();
  selectedGroupIds!: number[];
  permissionToUpdate!: any;
  requestor: any;
  filterOptions = new FilterPermission();
  @ViewChild('allSelectedpermission')
  private allSelectedpermission!: MatOption;
  listRole: any;
  rolesForm!: FormGroup;
  user: any;

  @ViewChild('openDeleteConfirm')
  openDeleteConfirm!: ElementRef;
  @ViewChild('closeButton')
  closeButton!: ElementRef;

  @ViewChild('openUpdatePopup')
  openUpdatePopup!: ElementRef;

  @ViewChild('closeUpdatePopup')
  closeUpdatePopup!: ElementRef;

  @ViewChild('openAddUser')
  openAddUser!: ElementRef;
  @ViewChild('closeAddButton')
  closeAddButton!: ElementRef;

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('paginatorUserPermission') paginatorUserPermission!: MatPaginator;

  // @ViewChild('allSelectedBike') allSelectedBike!: MatOption;

  constructor(
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  get environement(): typeof environment {
    return environment;
  }
  async ngOnInit(): Promise<void> {
    this.rolesForm = this.fb.group({
      rolesFieldTransport: new FormControl(''),
    });
    this.buildForm();
    await this.initializeFilter();
    await this.loadListPermission();
  }
  getRoles(): void {
    this.listRole = Object.getOwnPropertyNames(Roles)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => Roles[key]);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadListPermission();
  }

  openAddModal() {
    this.openAddUser.nativeElement.click();
  }

  openPopUpDelete(element: any) {
    this.openDeleteConfirm.nativeElement.click();
    this.elementToDelete = element;
  }
  deletePermission() {
    this.httpService
      .delete('UserPermissions', this.elementToDelete.email)
      .subscribe((res) => {
        this.loadListPermission();
        this.closeButton.nativeElement.click();
      });
  }

  resetRequester() {
    this.requestor = null;
  }

  selectRequester(user: any): void {
    this.requestor = user;
  }

  async loadListPermission(fromFilter?: boolean): Promise<void> {
    this.httpService
      .findAll('UserPermissions', {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        User: this.filterOptions.User,
        RoleType: this.filterOptions.RoleType,
      })
      .subscribe((users) => {
        this.userPermission = users.items;
        this.totalRows = users.totalCount;
      });
    this.displayedColumnsUser = [
      'displayName',
      'email',
      'rankDesc',
      'sslDesc',
      'actions',
    ];
  }

  async resetSearchPermission(): Promise<void> {
    this.resetUser();
    window.location.reload();
  }

  resetUser() {
    this.user = null;
    this.filterOptions.User = '';
  }
  selectUser(user: any): void {
    this.user = user;
    this.filterOptions.User = user.email;
  }

  async initializeFilter(): Promise<void> {
    await this.getRoles();
    this.filterOptions.User = '';
    this.filterOptions.RoleType = [];
  }

  toggleAllSelectionPermission(): void {
    if (this.allSelectedpermission.selected) {
      this.rolesForm.controls['rolesFieldTransport'].setValue([
        ...this.listRole.map((item: any) => item),
        0,
      ]);
      this.filterOptions.RoleType = [...this.listRole.map((item: any) => item)];
    } else {
      this.rolesForm.controls['rolesFieldTransport'].setValue([]);

      this.filterOptions.RoleType = [];
    }
  }

  selectPerOneTransport(all: any) {
    this.filterOptions.RoleType =
      this.rolesForm.controls['rolesFieldTransport'].value;
    if (this.allSelectedpermission.selected) {
      this.allSelectedpermission.deselect();

      return false;
    }
    if (
      this.rolesForm.controls['rolesFieldTransport'].value.length ==
      this.listRole.length
    )
      this.allSelectedpermission.select();
    return true;
  }
  getRoleNameById(roleId: number): string {
    const role = this.allRoles.find((role) => role.id === roleId);
    return role ? role.roleName : '';
  }

  async updatePermission(permission: any) {
    this.permissionToUpdate = permission;
    await this.getAllGroups();
  }

  buildForm() {
    this.permissionForm = this.formBuilder.group({
      selectedRoles: [],
    });
  }

  async getAllGroups() {
    await this.httpService
      .findAll('UserPermissions/all-roles', {
        pageSize: 1,
        pageNumber: 30,
      })
      .subscribe((roles) => {
        this.allRoles = roles.items;
        this.getUserGroups();
      });
  }

  async getUserGroups() {
    await this.httpService
      .findOneById('UserPermissions', this.permissionToUpdate.email)
      .subscribe((res) => {
        this.groups = res.roles;
        const selectedRoleIds = this.groups.map((group) => group.id);
        this.selectedGroupIds = selectedRoleIds;
        this.permissionForm.patchValue({
          selectedRoles: this.selectedGroupIds,
        });

        this.openUpdatePopup.nativeElement.click();
      });
  }

  removeChip(event: any) {}

  onRolesSelectionChange(event: MatSelectChange): void {
    const selectedValues = event.value;
    this.selectedRoles = selectedValues;
    this.selectedGroupIds = selectedValues;
  }

  async submitUpdate() {
    let roleIds = this.permissionForm.value.selectedRoles as Array<number>;
    await this.httpService
      .update('UserPermissions', this.permissionToUpdate.email, roleIds)
      .subscribe((res) => {
        this.closeUpdatePopup.nativeElement.click();
        this.loadListPermission();
      });
  }

  async addUser() {
    let requesterEmail = this.requestor.email;
    await this.httpService
      .update('UserPermissions/SetPermission', requesterEmail, [])
      .subscribe((res) => {
        this.resetRequester();
        this.closeAddButton.nativeElement.click();
        this.loadListPermission();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/super-admin/permission']);
          });
      });
  }
}
