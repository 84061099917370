<div class="row">
    <div class="col-md-12">
        <div class="banner-admin">
            <div class="col-md-12 padding-left">
                <span class="ArrowVelo"><a routerLink="/super-admin/formulas"><img alt="" [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span><span class="bold TitleVelo">Espace Admin - Remboursements </span>
                <div>
                    <img alt="" class="DividerVelo" [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="spacer-box">
    <br />



    <div class="row box-header">
        <div class="col-md-6">
            <strong> Titre de la formule : <span class="subscription-title">{{titleSubscription}}</span></strong>
        </div>
        <div class="col-md-2">
            <button class="btn btn-ajouter blod mt-4 px-1 mr-3 mb-3" (click)="openAddModal()">
                Ajouter un remboursement
            </button>
        </div>
    </div>

    <table mat-table [dataSource]="SubscriptionTypeRefundsList" *ngIf="SubscriptionTypeRefundsList.length > 0">
        <ng-container matColumnDef="valueDate">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Value Date</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.valueDate | date : "dd/MM/yyyy" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="percent">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Percent</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{element.percent ? 'Oui' : 'Non'}}
            </td>
        </ng-container>


        <ng-container matColumnDef="monthlyRefundRate">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Monthly Refund Rate</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.monthlyRefundRate? element.monthlyRefundRate : 'Non Applicable' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="unitRefundRate">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Unit Refund Rate</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.unitRefundRate? element.unitRefundRate : 'Non Applicable' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="annualFees">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Annual Fees</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.annualFees ? element.annualFees : 'Non Applicable'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="monthlyFees">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Monthly Fees</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">

                {{ element.monthlyFees? element.monthlyFees : 'Non Applicable' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="otherFees">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Other Fees</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.otherFees? element.otherFees : 'Non Applicable' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Actions</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                <div class="semi-bold d-flex">
                    <button (click)="openUpdateModal(element.id)" class="btn btnVoirModifier link">
                        Modifier
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator *ngIf="SubscriptionTypeRefundsList.length>0 " class="request-paginator" #paginatorRequestTransport
        (page)="pageChanged($event)" [length]="totalRows" [pageIndex]="currentPage-1" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20, 30]">
    </mat-paginator>


</div>

<span #openAddRefund data-target="#open-add-refund" data-toggle="modal"></span>
<div class="modal fade" tabindex="-1" role="dialog" id="open-add-refund" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title bold" id="exampleModalLabel">
                    Ajouter un remboursement
                </h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="refundForm">
                    <div class="row form-container">
                        <div class="col-md-6">
                            <div class="form-item">
                                <label>
                                    Value Date:
                                </label>
                                <input
                                matInput
                                class="form-control date-input ml-3"
                                [matDatepicker]="picker1"
                                (dateChange)="changePurchaseDate($event)"
                                formControlName="valueDate"
                                />
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1></mat-datepicker>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-item">
                                <label>
                                    Percent:
                                </label>
                                <select class="form-control" formControlName="percent">
                                    <option [value]="0">
                                        Non
                                    </option>
                                    <option [value]="1">
                                        Oui
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==1 || SubscriptionTypeFormulaType==0 || SubscriptionTypeFormulaType==3 ">
                            <div class="form-item">

                                <label>
                                    Monthly Refund Rate:
                                </label>
                                <input (change)="testFormat($event,'monthlyRefundRate')" type="text" class="form-control" matInput formControlName="monthlyRefundRate">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==2">
                            <div class="form-item">
                                <label>
                                    Unit Refund Rate:
                                </label>
                                <input (change)="testFormat($event,'unitRefundRate')" type="text" class="form-control" matInput formControlName="unitRefundRate">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==0">
                            <div class="form-item">
                                <label>
                                    Annual Fees:
                                </label>
                                <input (change)="testFormat($event,'annualFees')" type="text" class="form-control" matInput formControlName="annualFees">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==1">
                            <div class="form-item">
                                <label>
                                    Monthly Fees:
                                </label>
                                <input (change)="testFormat($event,'monthlyFees')" type="text" class="form-control" matInput formControlName="monthlyFees">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==2" >
                            <div class="form-item">
                                <label>
                                    Other Fees:
                                </label>
                                <input (change)="testFormat($event,'otherFees')" type="text" class="form-control" matInput formControlName="otherFees">
                            </div>
                        </div>
                    </div>
                </form>
                <div class="modal-footer d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-primary"
                    (click)="addRefund()">
                        Ajouter
                    </button>
                    <button type="button" #closeAddButton class="btn btn-secondary" data-dismiss="modal"
                    (click)="cancelAddFormula()">
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<span #openUpdateRefund data-target="#open-update-refund" data-toggle="modal"></span>
<div class="modal fade" tabindex="-1" role="dialog" id="open-update-refund" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title bold" id="exampleModalLabel">
                    modifier un remboursement
                </h5>
            </div>
            <div class="modal-body">
                <form [formGroup]="updateRefundForm">
                    <div class="row form-container">
                        <div class="col-md-6">
                            <div class="form-item">
                                <label>
                                    Value Date:
                                </label>
                                <input
                                matInput
                                class="form-control date-input ml-3"
                                [matDatepicker]="picker"
                                (dateChange)="changePurchaseDate($event)"
                                formControlName="valueDate"
                                />
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-item">
                                <label>
                                    Percent:
                                </label>
                                <select class="form-control" formControlName="percent">
                                    <option [value]="0">
                                        Non
                                    </option>
                                    <option [value]="1">
                                        Oui
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==1 || SubscriptionTypeFormulaType==0 || SubscriptionTypeFormulaType==3 ">
                            <div class="form-item">

                                <label>
                                    Monthly Refund Rate:
                                </label>
                                <input (change)="testFormatUpdate($event,'monthlyRefundRate')" type="text" class="form-control" matInput formControlName="monthlyRefundRate">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==2">
                            <div class="form-item">
                                <label>
                                    Unit Refund Rate:
                                </label>
                                <input (change)="testFormatUpdate($event,'unitRefundRate')" type="text" class="form-control" matInput formControlName="unitRefundRate">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==0">
                            <div class="form-item">
                                <label>
                                    Annual Fees:
                                </label>
                                <input (change)="testFormatUpdate($event,'annualFees')" type="text" class="form-control" matInput formControlName="annualFees">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==1">
                            <div class="form-item">
                                <label>
                                    Monthly Fees:
                                </label>
                                <input (change)="testFormatUpdate($event,'monthlyFees')" type="text" class="form-control" matInput formControlName="monthlyFees">
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="SubscriptionTypeFormulaType==2" >
                            <div class="form-item">
                                <label>
                                    Other Fees:
                                </label>
                                <input (change)="testFormatUpdate($event,'otherFees')" type="text" class="form-control" matInput formControlName="otherFees">
                            </div>
                        </div>
                    </div>
                </form>
                <div class="modal-footer d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-primary"
                    (click)="UpdateRefund()"
                    >
                        Mettre à jour
                    </button>
                    <button type="button" #closeUpdate class="btn btn-secondary" data-dismiss="modal"
                    >
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


    <span
    #openModalNegativeNumber
    data-target="#open-negative-number"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-negative-number"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Valeur incorrecte
          </h5>
        </div>
        <div class="modal-body">
          Dans "tarif réglé pour le mois", vous ne pouvez saisir qu'un nombre
          positive. Pour ajouter des décimales, vous devez utiliser une ",".
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>