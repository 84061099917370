import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  openFile(base64: string) {
    switch (base64.charAt(0)) {
      case '/':
      case 'i':
        {
          let pdfWindow = window.open('');
          pdfWindow?.document.write(
            "<img style='max-width: 100%;' src='data:image/png;base64, " +
            encodeURI(base64) +
            "'></img>"
          );
          break;
        }
    
      default:
        {
          let pdfWindow = window.open('');
          if (pdfWindow) {
            try {
              pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(base64) +
                "'></iframe>"
              );
            } catch (error) {
              console.error('Error writing to the document:', error);
            }
          } else {
            console.error('Error opening the window.');
          }
          break;
        }
    }
  }
}
