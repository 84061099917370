<div class="container-formule">
    <div class="bold">
      {{request.description }}
    </div>
    <span class="label-Transports bold mt-3" *ngIf="request.type==constants.transportAllowance">{{request.type}}</span>
    <span class="label-Velo bold mt-3" *ngIf="request.type==constants.bicycleSubsidy">{{request.type}}</span>
    <span class="label-Parking bold mt-3" *ngIf="request.type==constants.parkingCard">{{request.type}}</span>
    <span class="semi-gray bold mt-3" *ngIf="!request.type">Pas de formule active </span>
  
    <!-- <span class="bold mt-3" *ngIf="request.forfait">{{request.forfait}}</span> -->
    
  
    <div *ngIf="request.type && request.type == constants.bicycleSubsidy" class="semi-bold position-absolute">
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day >= businessParamDay"
              [routerLink]="['/bike/view', request.value]"
              (click)="goUpdateRequest($event,request.description)">Voir
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day < businessParamDay"
              [routerLink]="['/bike/reject', request.value]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month < request.month"
              [routerLink]="['/bike/reject', request.value]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link" *ngIf="actuelMonth.year < request.year"
              [routerLink]="['/bike/reject', request.value]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year && actuelMonth.month > request.month "
              [routerLink]="['/bike/view', request.value]"
              (click)="goUpdateRequest($event,request.description)">Voir
      </button>
  
  
    </div>
    <div *ngIf="request.type && request.type == constants.transportAllowance" class="semi-bold position-absolute">
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day >= businessParamDay"
              [routerLink]="['/transport/view/', request.value,request.requestDate]"
              (click)="goUpdateRequest($event,request.description)">Voir
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day < businessParamDay"
              [routerLink]="['/transport/reject', request.value,request.requestDate]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month < request.month"
              [routerLink]="['/transport/reject', request.value,request.requestDate]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link" *ngIf="actuelMonth.year < request.year"
              [routerLink]="['/transport/reject', request.value,request.requestDate]"
              (click)="goUpdateRequest($event,request.description)">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year && actuelMonth.month > request.month "
              [routerLink]="['/transport/view/', request.value,request.requestDate]"
              (click)="goUpdateRequest($event,request.description)">Voir
      </button>
  
  
    </div>
    <div *ngIf="request.type == constants.parkingCard" class="semi-bold position-absolute">
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day >= businessParamDay"
              data-toggle="modal" data-target="#popup-parking-under-construction">Voir
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month == request.month && actuelMonth.day < businessParamDay"
              data-toggle="modal" data-target="#popup-parking-under-construction">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year &&  actuelMonth.month < request.month"
              data-toggle="modal" data-target="#popup-parking-under-construction">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link" *ngIf="actuelMonth.year < request.year"
              data-toggle="modal" data-target="#popup-parking-under-construction">Voir/Modifier
      </button>
  
      <button class="btn btnVoirModifier link"
              *ngIf="actuelMonth.year == request.year && actuelMonth.month > request.month "
              data-toggle="modal" data-target="#popup-parking-under-construction">Voir
      </button>
  
  
    </div>
  
  
    <div class="modal fade" id="popup-parking-under-construction" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Votre carte parking</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Vous êtes actuellement titulaire d’une Carte Parking délivrée par votre bureau de rattachement. </p>
            <p>Le présent portail vous permettra prochainement de gérer cette carte en liaison directe avec le service
              concerné ; en attendant, si vous souhaitez restituer votre carte afin de pouvoir émettre une demande pour
              une autre formule de financement, contactez directement le service en charge des Cartes Parking pour votre
              bureau (cf Intranet : <a target="_blank" href="https://sites.ey.com/sites/newshubwem/fr/hub/aq/127/150/153">Intranet WEM - Info pratique : Réservation de places de parking (ey.com)</a> ).
            </p>
  
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  