import { Injectable } from '@angular/core';
import User from '../models/user';
import { HttpClientService } from './http-client.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  infoConnectedUser: any = {};
  constructor(
    private httpService: HttpClientService<any>,
    private route: Router
  ) {}

  async loadCurrentUser() {
    let userHubApi = this.httpService.create('Users');
    this.infoConnectedUser = await firstValueFrom(userHubApi).catch((err) => {
      if (err.status == 403) {
        localStorage.setItem('errorMessage', err.error.detail);
        this.route.navigate(['/no-access']);
      }
      if (err.status == 401) {
        localStorage.setItem(
          'errorMessage',
          "vous n'êtes pas en mesure d'accéder à cette page !"
        );
        this.route.navigate(['/no-access']);
      }
    });
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      localStorage.removeItem('MobilitesFR-currentUser');
      localStorage.setItem(
        'MobilitesFR-currentUser',
        JSON.stringify(this.infoConnectedUser)
      );
    } else {
      localStorage.setItem(
        'MobilitesFR-currentUser',
        JSON.stringify(this.infoConnectedUser)
      );
    }
    // this.httpService.create('Users').subscribe((connectedUser) => {
    //   this.infoConnectedUser = connectedUser;

    //   if (localStorage.getItem('MobilitesFR-currentUser')) {
    //     localStorage.removeItem('MobilitesFR-currentUser');
    //     localStorage.setItem(
    //       'MobilitesFR-currentUser',
    //       JSON.stringify(this.infoConnectedUser)
    //     );
    //   } else {
    //     localStorage.setItem(
    //       'MobilitesFR-currentUser',
    //       JSON.stringify(this.infoConnectedUser)
    //     );
    //   }

    // });
  }

  async getConnectedUser(): Promise<User> {
    if (!localStorage.getItem('MobilitesFR-currentUser')) {
      await this.loadCurrentUser();
    }
    const obj: string = localStorage.getItem(
      'MobilitesFR-currentUser'
    ) as string;
    return JSON.parse(obj) ? JSON.parse(obj) : null;
  }
}
