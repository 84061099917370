<div class="row">
  <div class="col-md-12">
    <div class="BannerContainer">
      <img
        alt=""
        class="imageBanner"
        [src]="environement.baseUrl + 'assets/images/velo.jpg'"
      />
    </div>
    <div class="BannerGradient2 padding-left">
      <div class="col-md-12 margin-top">
        <span class="ArrowVelo"
          ><a routerLink="/mobility-request/list"
            ><img
              alt=""
              [src]="
                environement.baseUrl + 'assets/images/Arrow.svg'
              " /></a></span
        ><span class="bold TitleVelo">Ma demande détaillée </span>
        <div>
          <img
            alt=""
            class="DividerVelo"
            [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
          />
        </div>
      </div>
      <div class="divider2"></div>
      <div class="col-md-12 margin-top">
        <span class="bold Title2Velo semi-bold"
          >Subvention<span class="bold">Vélo</span>
        </span>
      </div>
      <div class="divider3"></div>
      <div class="col-md-12 bannerDescription">
        <p class="see-condition">
          <a
            href="#"
            class="text-secondary"
            data-toggle="modal"
            data-target="#view-bike-condition"
            >Voir les conditions</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
<div class="SpacerBox margin-top">
  <div class="row">
    <div class="divider"></div>
    <div class="mb-3 col-md-6 bold yellowColor titleFormule">
      <div class="squarePuce"></div>
      Prise d’effet
    </div>
    <div class="col-md-6 bold yellowColor titleFormule flex-box">
      <div class="squarePuce"></div>
      Pièces justificatives
    </div>
  </div>
  <div class="row mt-2">
    <div class="mb-3 col-md-6 bold">
      <div class="row">
        <div class="col-md-4 pt-2">
          Mois ciblé pour le début des remboursements
        </div>
        <div class="col-md-4">
          <span class="date-box">{{
            requestVelo?.startDate | date : "LLLL yyyy"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 bold flex-box">
      <div class="piecesContainer">
        <div class="piecesBoxGray">
          <img
            alt=""
            class="imgBox"
            [src]="environement.baseUrl + 'assets/images/Group.svg'"
          />
        </div>
        <!-- <img alt="" class="TrashImg" [src]="environement.baseUrl + 'assets/images/Group9828.svg"> -->
        <a
          (click)="openFile(requestVelo?.certificateFMD)"
          class="link"
        >
          <span class="piecesTxt">Attestation de prise en charge FMD</span>
        </a>
      </div>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-md-6 bold flex-box">
      <div class="piecesContainer mt-2">
        <div class="piecesBoxGray">
          <img
            alt=""
            class="imgBox"
            [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
          />
        </div>
        <!-- <img alt="" class="TrashImg" [src]="environement.baseUrl + 'assets/images/Group9828.svg"> -->
        <a
          (click)="openFile(requestVelo?.proofOfPurchase)"
          target="_blank"
          class="link"
        >
          <span class="piecesTxt">Justificatif d'achat obligatoire</span>
        </a>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="mb-3 col-md-6 bold yellowColor titleFormule mt-2">
      <div class="squarePuce"></div>
      Informations sur l'achat
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6 flex-box">
      <div class="col-md-4 pt-2">
        Montant total des achats éligibles au forfait
      </div>
      <div class="col-md-7 form-group">
        <span class="date-box text-flex-right">{{
          requestVelo?.purchaseAmount?.toString() | moneyFormat
        }}</span>
        <span class="euro">€</span>
      </div>
    </div>
    <div class="col-md-6 flex-box">
      <div class="col-md-3 pt-2">Date de l'achat/facture</div>
      <div class="col-md-7">
        <span class="date-box text-center">{{
          requestVelo?.purchaseDate | date : "dd LLLL yyyy"
        }}</span>
      </div>
    </div>
  </div>
  <div class="row velo-electrique mt-2">
    <div class="mr-4">Vélo à assistance électrique (VAE)</div>
    <div class="">
      <input
        disabled
        [checked]="requestVelo?.vae"
        type="checkbox"
        class="form-control"
      />
    </div>
  </div>

  <hr class="hr mt-1" />
  <div class="row mb-3 float-right flex-column">
    <div class="velo-request">
      <div class="velo-request">
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Submitted"
          >Statut: Envoyée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Assigned"
          >Statut: En cours d'examen</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Returned"
          >Statut: A compléter par l’émetteur</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Canceled"
          >Statut: Annulée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Rejected"
          >Statut: Rejetée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestVelo?.status == mobilityRequestStatus.Validated"
          >Statut: Validée</span
        >
      </div>
    </div>
    <div
      class="descriptionFormule mt-3"
      *ngIf="requestVelo?.status == mobilityRequestStatus.Assigned"
    >
      <!-- <div class="">En charge de votre demande:</div>
      <div class="">
        <a
          class="piece-justif-box"
          [href]="'mailto:' + requestVelo?.validator?.email"
          >{{ requestVelo?.validator?.displayName }}</a
        >
      </div> -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 row">
      <div class="bold yellowColor titleFormule mb-2 col-md-12 row">
        <div class="squarePuce"></div>
        Prise en charge EY
      </div>
      <div class="row">
        <div class="col-md-7 pt-2">Montant total subventionné par EY</div>
        <div class="col-md-4 form-group">
          <span class="date-box text-flex-right">{{
            requestVelo?.subsidizedAmount?.toString() | moneyFormat
          }}</span>
          <span class="euro">€</span>
        </div>
      </div>
      <hr class="hr2 mt-3 mb-3" />
      <div class="row">
        <div class="col-md-1 pt-2">Soit</div>
        <div class="col-md-2 form-group">
          <span class="date-box text-center">{{
            requestVelo?.numberFullMonths
          }}</span>
        </div>
        <div class="col-md-2 pt-2">Mensualité(s) de</div>
        <div class="col-md-2 form-group">
          <span class="date-box text-flex-right">{{
            requestVelo?.applicableMonthlyPayment?.toString() | moneyFormat
          }}</span>
          <span class="euro">€</span>
        </div>
        <div class="col-md-2 pt-2">et 1 dernière de</div>
        <div class="col-md-2 form-group">
          <span class="date-box text-flex-right">{{
            requestVelo?.lastMonthAmount?.toString() | moneyFormat
          }}</span>
          <span class="euro">€</span>
        </div>
      </div>
      <hr class="hr2 mt-3 mb-3" />
      <div class="row">
        <div class="col-md-4 pt-2">versées sur solde entre</div>
        <div class="col-md-3 form-group">
          <span class="date-box text-center">{{
            requestVelo?.startDate | date : "LLLL yyyy"
          }}</span>
        </div>
        <div class="col-md-2 pt-2">et</div>
        <div class="col-md-3 form-group">
          <span class="date-box text-center">{{
            requestVelo?.endDate | date : "LLLL yyyy"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="requester-box mb-3">
        <span class="mr-2">Commentaire demandeur</span>
        <span class="comment-box" *ngIf="requestVelo?.requesterComment">{{
          requestVelo?.requesterComment
        }}</span>
      </div>
      <div class="requester-box mb-3" *ngIf="requestVelo?.controlerComment">
        <span class="mr-2">Commentaire valideur</span>
        <span class="comment-box">{{ requestVelo?.controlerComment }}</span>
      </div>
    </div>
  </div>
  <div class="float-right">
    <button
      type="button"
      class="btn btn-primary"
      [routerLink]="['/bike/update', requestVelo?.id]"
      *ngIf="
        requestVelo?.status == mobilityRequestStatus.Submitted ||
        requestVelo?.status == mobilityRequestStatus.Returned
      "
    >
      Modifier ma demande
    </button>
    <br />
    <button
      type="button"
      class="btn btn-close-request mt-3 mb-3"
      routerLink="/mobility-request/list"
    >
      Fermer
    </button>
  </div>
  <app-bike-condition-popup></app-bike-condition-popup>
</div>
