<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/transport.jpg'"
        />
      </div>
      <div class="BannerGradient1 padding-left">
        <div class="col-md-12 margin-top mb-3">
          <span class="ArrowVelo"
            ><a href="#" data-toggle="modal" data-target="#close-form"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleVelo">Ma demande détaillée </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="col-md-12 margin-top mt-4">
          <span class="bold Title2Velo semi-bold"
            >Indemnité <span class="bold">Transports</span></span
          >
        </div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-transport-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="SpacerBox margin-top">
    <form class="form" [formGroup]="transportRequestForm" autocomplete="off">
      <div class="bold yellowColor titleFormule">
        <div class="squarePuce"></div>
        Période de remboursement
      </div>
      <div class="row form-inline align-items-end mt-3">
        <div class="form-inline col-md-3">
          <!-- Date début -->
          <label>A partir du mois suivant</label>
          <select
            [attr.disabled]="dateDisabled ? '' : null"
            class="form-control ml-2 text-center"
            formControlName="startMonth"
            (change)="changeStartDate($event)"
          >
            <option>Choisir la Date de début</option>
            <option
              [value]="item.numberValue"
              *ngFor="let item of listStartMonth"
            >
              {{ item.value }}
            </option>
          </select>
          <span
            class="text-danger pl-2 mb-3"
            *ngIf="submitted && form.startMonth.errors?.required"
            >Mois de début obligatoire</span
          >
        </div>
        <div class="form-inline col-md-3">
          <!-- Date fin -->
          <label class="ml-2">Jusqu’au mois suivant</label>
          <select
            [attr.disabled]="dateDisabled ? '' : null"
            class="form-control ml-2 text-center"
            (change)="changeEndDate()"
            formControlName="endMonth"
          >
            <option>Choisir la Date de fin</option>
            <option
              [value]="item.numberValue"
              *ngFor="let item of listEndMonth"
            >
              {{ item.value }}
            </option>
          </select>
          <span
            class="text-danger ml-2"
            *ngIf="submitted && form.endMonth.errors?.required"
            >Mois de fin obligatoire</span
          >
        </div>
        <div class="form-inline col-md-3">
          <button
            class="btn btn-primary ml-2 mt-2"
            *ngIf="
              form.startMonth.value &&
              form.endMonth.value &&
              form.startMonth.value === form.endMonth.value &&
              !dateDisabled &&
              !resetForm
            "
            type="button"
            (click)="validateDates()"
          >
            Valider la période
          </button>
          <button
            class="btn btn-primary ml-2 mt-2"
            *ngIf="dateDisabled && !resetForm"
            type="button"
            data-toggle="modal"
            data-target="#confirm-update-dates"
          >
            Modifier la période
          </button>
        </div>
        <div class="form-inline col-md-3">
          <div
            class="piecesContainer"
            *ngIf="
              form.startMonth.value &&
              form.endMonth.value &&
              dateValid &&
              form.startMonth.value !== form.endMonth.value
            "
          >
            <div
              [class]="!!attestationFile ? 'piecesBoxGray' : 'piecesBoxYello'"
              data-toggle="modal"
              data-target="#generate-attestation"
            >
              <img
                alt=""
                class="imgBox"
                [src]="environement.baseUrl + 'assets/images/Group.svg'"
              />
            </div>
            <input type="hidden" formControlName="attestationFile" />
            <span class="txt-attachment ml-2"
              >Attestation pour reconduction</span
            >
          </div>
          <div class="piecesContainer" *ngIf="requestTransport?.certificateFmd">
            <span
              class="textJustif text-danger"
              *ngIf="submitted && form.attestationFile.errors?.required"
            >
              Attestation obligatoire
            </span>
            <div
              class="TrashBox"
              data-toggle="modal"
              data-target="#confirm-delete-attestation"
              *ngIf="attestationFile"
            >
              <img
                alt=""
                class="TrashImg mr-2"
                [src]="environement.baseUrl + 'assets/images/poubelle.svg'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="dateDisabled">
        <div class="col-md-6 bold yellowColor titleFormule mt-2">
          <div class="squarePuce"></div>
          Formule d'abonnement
        </div>
      </div>
      <!-- debut subcription formula  -->
      <div *ngIf="dateDisabled">
        <ng-container formArrayName="subscriptionFormulaForm">
          <ng-container
            *ngFor="
              let sbForm of subscriptionFormulaForm.controls;
              let i = index
            "
          >
            <div [formGroupName]="i">
              <div class="row mt-2 d-flex align-items-center">
                <div class="col-md-4">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-4 form-group">
                      <div
                        class="mr-1"
                        data-toggle="modal"
                        data-target="#confirm-delete-formula"
                        *ngIf="i > 0"
                        (click)="deletedIndex.value = i.toString()"
                      >
                        <img
                          alt=""
                          class="TrashImg"
                          [src]="
                            environement.baseUrl + 'assets/images/poubelle.svg'
                          "
                        />
                      </div>
                      Sélection de la formule
                    </div>
                    <div class="col-md-8">
                      <select
                        [id]="i"
                        (change)="selectTypeAbonnement($event, i)"
                        class="form-control"
                      >
                        <!-- formControlName="typeAbo" -->
                        <option></option>
                        <option
                          *ngFor="let subscription of subscriptions"
                          [value]="subscription.id"
                          [style]="
                            !subscription.siteId
                              ? 'font-weight: bold;font-style: italic;'
                              : ''
                          "
                        >
                          {{ subscription.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-5" *ngIf="selectedTypoAbos[i]">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-4">
                      {{ selectedTypoAbos[i].tarifName }}
                    </div>
                    <div
                      class="col-md-8 form-group inputDisabled"
                      *ngIf="
                        !selectedTypoAbos[i].isMisc &&
                        selectedTypoAbos[i].tarifName
                      "
                    >
                      <input
                        matInput
                        class="form-control text-right"
                        appFormatMoney
                        mask="separator.2"
                        decimalMarker=","
                        [attr.disabled]="true"
                        readonly
                        formControlName="Price"
                        type="text"
                      />
                      <span class="euro">€</span>
                    </div>
                    <div
                      class="col-md-8 form-group"
                      *ngIf="selectedTypoAbos[i].isMisc"
                    >
                      <input
                        matInput
                        class="form-control text-right"
                        appFormatMoney
                        (change)="testFormat($event, i)"
                        mask="separator.2"
                        decimalMarker=","
                        type="text"
                        formControlName="Price"
                      />
                      <span class="euro">€</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mt-3" *ngIf="selectedTypoAbos[i]">
                  <div class="row" *ngIf="selectedTypoAbos[i].tarifName">
                    <div
                      *ngIf="!selectedTypoAbos[i].File"
                      class="col-md-12 piecesContainer"
                    >
                      <div
                        [class]="
                          selectedTypoAbos[i].File
                            ? 'piecesBoxGray'
                            : 'piecesBoxYello'
                        "
                      >
                        <img
                          alt=""
                          class="imgBox"
                          [src]="
                            environement.baseUrl + 'assets/images/Line.svg'
                          "
                        />
                        <img
                          alt=""
                          class="imgBox"
                          [src]="
                            environement.baseUrl + 'assets/images/Arrow6.svg'
                          "
                        />
                      </div>
                      <input
                        (change)="getJustifFile($event, i)"
                        #file
                        accept="image/*,.pdf"
                        type="file"
                        [class]="
                          selectedTypoAbos[i].File
                            ? 'FileInputDisabled'
                            : 'FileInput'
                        "
                      />
                      <input type="hidden" />
                      <!-- formControlName="attachment"  -->
                      <span
                        class="text-danger"
                        *ngIf="
                          submitted &&
                          form['subscriptionFormulaForm']?.controls[i]?.controls
                            ?.File?.errors?.required
                        "
                        >Attachement obligatoire</span
                      >
                      <div class="mytooltip tooltip-effect-1 mr-2">
                        <div class="tooltip-item infoBulle">
                          <i
                            class="fa fa-info-circle info-bulle"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <span class="tooltip-content clearfix">
                          <span class="tooltip-text-info"
                            >Si vous avez plusieurs PJ à l’appui de cette
                            formule, merci de les insérer dans le même .pdf
                            <br />
                            Pour en savoir plus sur les PJ exigées à l’appui de
                            vos demandes : Voir les conditions
                          </span>
                        </span>
                      </div>
                      <span class="justif-txt"
                        >Mon justificatif d'abonnement</span
                      >
                    </div>
                    <div
                      *ngIf="selectedTypoAbos[i].File"
                      class="col-md-12 piecesContainer mb-2"
                    >
                      <div
                        [class]="
                          selectedTypoAbos[i].File
                            ? 'piecesBoxGray'
                            : 'piecesBoxYello'
                        "
                      >
                        <img
                          alt=""
                          class="imgBox"
                          [src]="
                            environement.baseUrl + 'assets/images/Line.svg'
                          "
                        />
                        <img
                          alt=""
                          class="imgBox"
                          [src]="
                            environement.baseUrl + 'assets/images/Arrow6.svg'
                          "
                        />
                      </div>
                      <span
                        class="textJustif ml-2"
                        (click)="openAttachment(i)"
                        *ngIf="selectedTypoAbos[i].File"
                      >
                        Mon justificatif d'abonnement {{ i + 1 }}</span
                      >
                      <div
                        class="TrashBox"
                        (click)="removeJustifFile(i)"
                        *ngIf="selectedTypoAbos[i].File"
                      >
                        <img
                          alt=""
                          class="TrashImg"
                          [src]="
                            environement.baseUrl + 'assets/images/poubelle.svg'
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row justify-content-center"
                    *ngIf="
                      !Files[i].fileExists && selectedTypoAbos[i].tarifName
                    "
                  >
                    <br />
                    <span class="file-size-gray"
                      >(Saisir un fichier qui ne dépasse pas 1 Mo)</span
                    >
                    <!-- *ngIf="!selectedTypoAbos[i].File" -->
                  </div>
                  <div
                    class="row justify-content-center"
                    *ngIf="
                      Files[i].fileExists &&
                      Files[i].fileGreater &&
                      selectedTypoAbos[i].tarifName
                    "
                  >
                    <br />
                    <span class="file-size-red"
                      >(La taille du fichier ne doit pas dépasser 1 Mo)</span
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <div
                    class="row d-flex align-items-center"
                    *ngIf="selectedTypoAbos[i].showQuantity"
                  >
                    <div class="col-md-4">
                      Quantité
                      <br />
                      <span class="quantite-desc"
                        >(Saisir une quantité entre 1 et 5)</span
                      >
                    </div>
                    <div class="col-md-8 form-group">
                      <input
                        type="number"
                        matInput
                        min="1"
                        max="5"
                        oninput="validity.valid||(value='');"
                        class="form-control"
                        formControlName="Quantity"
                      />
                    </div>
                  </div>
                  <div
                    class="row d-flex align-items-center"
                    *ngIf="selectedTypoAbos[i].isMisc"
                  >
                    <div class="col-md-4">
                      Désignation de votre formule « Autre »
                    </div>
                    <div class="col-md-8 form-group">
                      <input
                        type="text"
                        matInput
                        class="form-control"
                        formControlName="EntitledMisc"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- fin subcription formula -->
        <div
          class="addFormule mt-3"
          (click)="addRowFormula(subscriptionFormulaForm.length)"
          *ngIf="
            subscriptionFormulaForm.length >= 1 &&
            subscriptionFormulaForm.length < 3
          "
        >
          + Ajouter une formule
        </div>
      </div>
      <hr class="hr" />
      <div class="row">
        <div class="col-md-4">
          <div class="divider2"></div>
        </div>
        <div class="col-md-6">
          <div class="FormuleComment">
            <div class="descriptionxt">Commentaire demandeur</div>
            <div class="form-group">
              <textarea
                maxlength="250"
                matInput
                id="requester-comment"
                class="form-control"
                formControlName="requesterComment"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-2 mt-2 flex-column">
          <button
            type="button"
            *ngIf="subscriptionFormulaForm.controls.length == 0"
            [disabled]="
              (subscriptionFormulaForm.valid &&
                !(
                  form.endMonth.value &&
                  form.startMonth.value &&
                  subscriptionFormulaForm.controls.length > 0
                )) ||
              submitted
            "
            class="btn btn-primary mb-2"
            data-toggle="modal"
            data-target="#valider-form"
          >
            <span class="bold">Valider ma demande</span>
          </button>
          <button
            type="button"
            *ngIf="subscriptionFormulaForm.controls.length > 0"
            [disabled]="!subscriptionFormulaForm.valid || !filesValid()"
            class="btn btn-primary mb-2"
            data-toggle="modal"
            data-target="#valider-form"
          >
            <span class="bold">Valider ma demande</span>
          </button>
          <button
            type="button"
            class="btn btn-secondary float-right"
            data-toggle="modal"
            data-target="#close-form"
          >
            Annuler ma demande
          </button>
        </div>
      </div>
    </form>
    <!-- Modal generate attestation -->
    <div
      class="modal fade"
      id="generate-attestation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalPdfForm"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Attestation sur l’honneur</h5>
            <h5 class="modal-title">
              Pour prise en charge récurrente des frais d’abonnement couvrant
              les transports domicile-travail
            </h5>
          </div>
          <div class="modal-body">
            <div class="popUpCard" id="AttestationToExport">
              <div class="logo-container">
                <img
                  alt=""
                  class="logo-attestation"
                  id="logo-attestation"
                  [src]="
                    environement.baseUrl + 'assets/images/logoAttestation.jpg'
                  "
                />
              </div>
              <div class="core-attestation">
                <div class="entete-container">
                  <div class="entete-title soussigne">
                    Je soussigné(e)
                    <span class="bold">{{ connectedUser?.displayName }}</span>
                  </div>
                  <div class="entete-title pl-5">
                    Entité juridique EY:
                    <span class="bold">
                      {{ connectedUser?.myCompanyDesc }}</span
                    >
                  </div>
                  <div class="entete-title pl-5">
                    Bureau de rattachement:
                    <span class="bold">{{ connectedUser?.locationDesc }}</span>
                  </div>
                  <div class="entete-title pl-5">
                    N° employé:
                    <span class="bold">{{ connectedUser?.gpn }}</span>
                  </div>
                  <div class="pt-3">
                    <span class="pr-2">Demeurant:</span>
                    <input
                      [(ngModel)]="demeurantIn"
                      matInput
                      #demeurant
                      class="form-control popup-input"
                      type="text"
                    />
                  </div>
                  <div class="divider2"></div>
                  <div class="popUpDescription">
                    Atteste les points suivants, à l'appui de la demande de
                    prise en charge partielle de mes frais de transports, à
                    l’abonnement éligible auquel j’ai souscrit).
                  </div>
                  <div class="divider2"></div>

                  <div class="checkBoxDesc">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        [(ngModel)]="condition1"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        class="custom-control-label checkbox-label checkbox-text pl-4"
                        for="customCheck1"
                      >
                        La formule de transports pour laquelle je demande une
                        prise en charge est destinée à mes déplacements
                        domicile-lieu de travail.
                      </label>
                    </div>
                    <div class="divider3"></div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        [(ngModel)]="condition2"
                        class="custom-control-input"
                        id="customCheck2"
                      />
                      <label
                        class="custom-control-label checkbox-label checkbox-text pl-4"
                        for="customCheck2"
                      >
                        J’ai souscrit un abonnement annuel pour lequel je
                        m’engage à régler l’entièreté de son coût auprès de
                        l’organisme de transport
                      </label>
                    </div>
                    <div class="divider3"></div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        [(ngModel)]="condition3"
                        class="custom-control-input"
                        id="customCheck3"
                      />
                      <label
                        class="custom-control-label checkbox-label checkbox-text pl-4"
                        for="customCheck3"
                      >
                        En cas de non-paiement de l'un/plusieurs des mois prévus
                        ou de changement de situation de mon
                        abonnement(modification, arrêt ou suspension), je
                        m'engage à avertir sans délai mon employeur, par
                        l'intermédiaire de ce Portail Mobilités-Transports
                        (modification de ma formule et/ou nouvelle demande).
                      </label>
                    </div>
                  </div>

                  <div class="divider2"></div>

                  <div class="popUpDescription">
                    A défaut de pouvoir justifier de mes versements mensuels
                    effectifs en cas de demande de mon employeur, je m'expose à
                    la suspension de mon indemnité transport et à la reprise des
                    remboursements non justifiés.
                  </div>
                  <div class="divider2"></div>
                  <div class="d-flex">
                    <label class="mr-4">Fait à</label>
                    <input
                      #faita
                      matInput
                      [(ngModel)]="doIn"
                      class="form-control popup-input-md"
                      type="text"
                    />
                    <span class="DateNow pl-5"
                      >Le {{ dateNow | date : "dd/MM/yyyy" }}</span
                    >
                  </div>
                </div>
                <div class="divider2"></div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="
                  !(
                    demeurant.value &&
                    demeurant.value.length > 2 &&
                    faita.value &&
                    faita.value.length > 2 &&
                    condition1 &&
                    condition2 &&
                    condition3
                  )
                "
                data-dismiss="modal"
                (click)="generatePDF()"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal valider demande  -->
    <div
      class="modal fade"
      id="valider-form"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalValiderForm"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Attention !</h5>
          </div>
          <div class="modal-body">
            Pour un mois donné vous ne pourrez déposer qu'une seule demande de
            remboursement : assurez-vous donc bien que la présente demande
            contient toutes les formules et pièces jointes que vous souhaitez
            vous faire rembourser pour la période considérée
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              (click)="addTransportRequest()"
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal confirm update dates -->
    <div
      class="modal fade"
      id="confirm-update-dates"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifier la période</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <strong>
              Attention, si vous confirmez cette modification, les éléments déjà
              renseignés dans votre demande (formule d’abonnement, pièces
              justificatives, etc.) seront automatiquement effacés, vous devrez
              les ressaisir</strong
            >
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              (click)="resetAllForm()"
            >
              Je Confirme
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal confirm delete attestation -->
    <div
      class="modal fade"
      id="confirm-delete-attestation"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Supprimer l’Attestation</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <strong>
              Attention, cette attestation est liée aux infos et pièces que vous
              pourriez avoir déjà renseignées par ailleurs dans votre demande :
              si vous confirmez sa suppression vous devrez donc ressaisir ces
              éléments</strong
            >
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              (click)="deleteAttestation()"
            >
              Je Confirme
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal attachement  -->
    <span
      #openModalAttachement
      data-target="#open-attachement"
      data-toggle="modal"
    ></span>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="open-attachement"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">
              Mon justificatif d'abonnement
            </h5>
          </div>
          <div class="modal-body">
            Les justificatifs sont obligatoires en cas de contrôle URSSAF. EY se
            réserve le droit d’un contrôle ponctuel des justificatifs annuels en
            cours d’abonnement.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal negative number -->
    <span
      #openModalNegativeNumber
      data-target="#open-negative-number"
      data-toggle="modal"
    ></span>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="open-negative-number"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">
              Valeur incorrecte
            </h5>
          </div>
          <div class="modal-body">
            Dans "tarif réglé pour le mois", vous ne pouvez saisir qu'un nombre
            positive. Pour ajouter des décimales, vous devez utiliser une ",".
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal formula already exist-->

    <span
      #openForumlaAlreadyExist
      data-target="#formula-already-exist"
      data-toggle="modal"
    ></span>
    <div
      class="modal fade"
      id="formula-already-exist"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Formule déjà sélectionnée</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <strong>
              Attention vous avez déjà sélectionné cette formule pour la
              présente demande .</strong
            >
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal confirm annuler demande -->
  <div
    class="modal fade"
    id="close-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Fermer la demande</h5>
        </div>
        <div class="modal-body">
          Attention, si vous confirmez cette action vous allez perdre toutes les
          saisies en cours
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Revenir sur la demande
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            routerLink="/mobility-request/list"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="confirm-delete-formula"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression formule</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <strong>
            Attention, en supprimant cette ligne vous effacez tous les
            enregistrements et attachements correspondants .</strong
          >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <input type="hidden" #deletedIndex />
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="deleteRow(deletedIndex.value)"
          >
            Je Confirme
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-transport-condition-popup></app-transport-condition-popup>
  <div class="has-no-access" *ngIf="!checkAccessTransport?.access">
    <div
      class=""
      id="exampleModalAcces"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Accèss non autorisé
            </h5>
          </div>
          <div class="modal-body">
            {{ checkAccessTransport?.message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              routerLink="/mobility-request/list"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="has-no-access" *ngIf="popUpHasNoAccessToAdd">
    <div
      class=""
      id="exampleModalAcces"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Submission non autorisé pour ce mois-ci
            </h5>
          </div>
          <div class="modal-body">
            Vous ne pouvez plus émettre de nouvelle demande d’indemnisation pour
            ce mois-ci
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              routerLink="/mobility-request/list"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
