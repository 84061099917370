import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-admin.routing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewTransportArchiveComponent } from './view/view.component';
// import { ViewComponent } from './view/view.component';



@NgModule({
  declarations: [
    ListComponent,
    ViewTransportArchiveComponent
  ],
  exports: [ ListComponent,ViewTransportArchiveComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(routes),
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class TransportArchiveModule { }
