<div class="row">
    <div class="col-md-12">
        <div class="banner-admin">
            <div class="col-md-12 padding-left">
                <span class="ArrowVelo"><a routerLink="/"><img alt=""
                [src]="environement.baseUrl + 'assets/images/Arrow.svg'"></a></span><span class="bold TitleVelo">Espace Admin -
            Validation
          </span>
                <div>
                    <img alt="" class="DividerVelo" [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row spacer-box ">
    <div class="col-md-12">
        <mat-tab-group [selectedIndex]="selectedIndex" class="admin-tabs">
            <mat-tab  *ngIf="ShowTRS">
                <ng-template  mat-tab-label>
                    <span (click)="getTransportData()">Indemnité Transports</span>
                </ng-template>
                <br>
                <app-list-transport [transportData]="isTransportData"></app-list-transport>
            </mat-tab>
            <mat-tab *ngIf="ShowVelo">
              <ng-template   mat-tab-label>
                <span (click)="getBikeData()">Subvention Vélo</span>
              </ng-template>
              <br>
              <app-list-bike *ngIf="isBikeData"  [bikeData]="isBikeData"></app-list-bike>
            </mat-tab>

            <mat-tab  *ngIf="ShowParking">

                <ng-template mat-tab-label>
                    <span (click)="getParkingData()" >Carte Parking</span>
                </ng-template>
                <br>
                <app-list-parking *ngIf="isParkingData"  [parkingData]="isParkingData"></app-list-parking>
            </mat-tab>

            <mat-tab *ngIf="isSuperAdmin">
              <ng-template   mat-tab-label>
                  <span (click)="getTranportArchiveData()"  >Archive Indemnité Transports</span>
              </ng-template>
              <br>
              <app-list-transport-archive *ngIf="isTransportArchiveData"  [transportArchiveData]="isTransportArchiveData"></app-list-transport-archive>
            </mat-tab>

            <mat-tab *ngIf="isSuperAdmin">
              <ng-template mat-tab-label>
                  <span (click)="getBikeArchiveData()" >Archive Subvention Vélo</span>
              </ng-template>
              <br>
              <app-list-bike-archive *ngIf="isBikeArchiveData"  [bikeArchiveData]="isBikeArchiveData"></app-list-bike-archive>
          </mat-tab>

        </mat-tab-group>


    </div>

</div>
