<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/parking.jpg'"
        />
      </div>
      <div class="BannerGradient3 padding-left">
        <div class="col-md-12 margin-top mb-3">
          <span class="ArrowVelo"
            ><a href="#" data-toggle="modal" data-target="#close-form"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleParking">Ajouter une carte parking </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="col-md-12 margin-top mt-4">
          <span class="bold Title2Velo semi-bold"
            >Cartes <span class="bold">Parking</span></span
          >
        </div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-parking-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- body page -->
  <div class="SpacerBox margin-top">
      <div class="bold yellowColor titleFormule">
        <div class="squarePuce"></div>
        Personne concernée
      </div>
      <div class="row form-inline align-items-end mt-3">
        <div class="form-inline col-md-3">
          <!-- Date début -->
          <label class="ml-3">Saisissez le prénom et le nom </label>
          <app-people-picker
          (resetUser)="resetUser()"
          [user]="user"
          (selectUser)="selectUser($event)"
          class="ml-2"
        >
        </app-people-picker>
        </div>
      </div>

      <div class="bold yellowColor titleFormule" *ngIf="filterOptions.email">
        <div class="squarePuce"></div>
        Période de remboursement
      </div>
      <div class="row form-inline align-items-end mt-3" *ngIf="filterOptions.email">
        <div class="form-inline col-md-3">
          <!-- Date début -->
          <label class="mr-2">A partir du mois suivant </label>
          <select
            [attr.disabled]="dateDisabled ? '' : null"
            class="form-control ml-2 text-center"
            [(ngModel)]="filterOptions.startDate"
          >
            <option>Choisir la Date de début</option>
            <option
              [value]="item.numberValue"
              *ngFor="let item of listStartMonth"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form-inline col-md-3" *ngIf="filterOptions.email && filterOptions.startDate">
            <!-- Date fin -->
            <label class="mr-4">Numéro de la carte</label>
            <input type="text" class="form-control" matInput [(ngModel)]="filterOptions.cardNumber">
          </div>
      </div>

      <hr class="hr" />
      <div class="row float-right">
        <div class="col-md-2 mb-2 flex-column">
          <button
            type="button"
            [disabled]="!filterOptions.email && !filterOptions.startDate"
            class="btn btn-primary mb-2"
            (click)="addParkingRequest()"
          >
            <span class="bold">Valider la demande</span>
          </button>
          <button
            type="button"
            class="btn btn-secondary float-right"
            data-toggle="modal"
            data-target="#close-form"
          >
            Annuler ma demande
          </button>
        </div>
      </div>
    <app-parking-condition-popup
      data-target="#view-parking-condition"
    ></app-parking-condition-popup>
    <!-- modal confirm close form -->
    <div
      class="modal fade"
      id="close-form"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCloseForm"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Fermer la demande</h5>
          </div>
          <div class="modal-body">
            Attention, si vous confirmez cette action vous allez perdre toutes
            les saisies en cours
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Revenir sur la demande
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              routerLink="/mobility-request-admin/list"
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- pop up haw no acces -->
  <div class="has-no-access" *ngIf="!checkAccessTransport?.access">
    <div
      class=""
      id="exampleModalAcces"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Accèss non autorisé
            </h5>
          </div>
          <div class="modal-body">
            {{ checkAccessTransport?.message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              routerLink="/mobility-request-admin/list"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
