import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { DateService } from 'src/core/services/date.service';
import { environment } from 'src/environments/environment';
import { ExcelService } from 'src/core/services/excel.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { HttpClientService } from 'src/core/services/http-client.service';
import { ExtractType } from 'src/core/enum/extractTypes.enum';
import { Canstants } from 'src/core/canstants/canstants';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
})
export class ListComponent implements OnInit {
  @ViewChild('openExtractPopup') openExtractPopup!: ElementRef;
  listRequests!: FormGroup;
  listMonth: Array<any> = [];
  allrequests: Array<any> = [];
  ExtractRequest: Array<any> = [];
  displayedColumns: string[] = [];
  selectedMonth: any = {};
  selectedTypeRequest: any = {};
  subscriptions: any;
  subscriptionFormula: any;
  sameCurrentMonth!: boolean;
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  retroTRS: any;
  constant = Canstants;
  AllRequestTransports: any;
  businessParam!: any;
  sortOption: any = { active: 'Created', direction: 'desc' };
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort | undefined;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  constructor(
    private fb: FormBuilder,
    private dateService: DateService,
    private httpService: HttpClientService<any>,
    private excelService: ExcelService,
    private dialog: MatDialog
  ) {
    this.initForm();
  }
  get mobilityRequestType(): typeof ExtractType {
    return ExtractType;
  }

  async ngOnInit(): Promise<void> {
    this.listMonth = this.dateService.getEighteenMonth();
    this.getBussinesParam();
  }
  private initForm(): void {
    this.listRequests = this.fb.group({
      requestType: [''],
      date: [''],
    });
  }
  get environement(): typeof environment {
    return environment;
  }
  getBussinesParam(): void {
    this.httpService.findAll('BusinessParams').subscribe((bp) => {
      let formattedMonth = moment().format('MM');
      let title =
        Canstants.limitValidTransportM +
        moment().format('yyyy') +
        formattedMonth;
      this.businessParam = bp.filter((param: any) => param.title == title);
    });
  }
  getExcelFileSameMonth(): void {
    const res = this.ExtractRequest.map(function (value: any) {
      return Object.values(value);
    });
    let header: any = [];

    header = [
      'LPN',
      'Nom',
      'Mois imputation paie',
      'Sens',
      'Montant imputable paie',
      'Mois de référence',
      'Ref dossier Mobilité',
      'Date extraction',
      'Formule mobilité',
      'Type opération',
    ];

    const selectedMonth = this.listMonth.filter(
      (x) => x.numberValue == this.listRequests.controls['date'].value
    );
    this.excelService.generateExcel(
      res,
      selectedMonth[0].month,
      this.selectedTypeRequest,
      header
    );
  }
  getExcelFile(): void {
    const dateNowDay = new Date().getDate();
    if (
      this.sameCurrentMonth &&
      dateNowDay <= Number(this.businessParam[0].descriptionBP)
    ) {
      this.openExtractPopup.nativeElement.click();
    } else {
      const res = this.ExtractRequest.map(function (value: any) {
        return Object.values(value);
      });
      let header: any = [];

      header = [
        'LPN',
        'Nom',
        'Mois imputation paie',
        'Sens',
        'Montant imputable paie',
        'Mois de référence',
        'Ref dossier Mobilité',
        'Date extraction',
        'Formule mobilité',
        'Type opération',
      ];

      const selectedMonth = this.listMonth.filter(
        (x) => x.numberValue == this.listRequests.controls['date'].value
      );
      this.excelService.generateExcel(
        res,
        selectedMonth[0].month,
        this.selectedTypeRequest,
        header
      );
    }
  }

  async getSelectedMonth(FormMonth: any): Promise<void> {
    const currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    var currentDateFormated = '01/' + month + '/' + currentDate.getFullYear();
    const selectedMonth = this.listMonth.filter(
      (x) => x.numberValue == FormMonth.date
    );
    let mois = selectedMonth[0].month;
    let moisFormatte = mois.toString().padStart(2, '0');
    var date = '01/' + moisFormatte + '/' + selectedMonth[0].year;
    this.selectedMonth = date;
    if (this.selectedMonth == currentDateFormated) {
      this.sameCurrentMonth = true;
    } else {
      this.sameCurrentMonth = false;
    }
  }
  async getListRequests(): Promise<void> {
    this.selectedTypeRequest = this.listRequests.controls['requestType'].value;

    this.displayedColumns = [
      'LPN',
      'Nom',
      'MoisImputation',
      'sens',
      'MontantImputable',
      'MoisReference',
      'RefDossier',
      'DateExtraction',
      'FormuleMobilite',
    ];

    this.httpService
      .findAll('ExtractPayments', {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        SelectedMonth: this.selectedMonth,
        RequestType: this.mobilityRequestType[this.selectedTypeRequest],
      })
      .subscribe((extractPayment: any) => {
        this.ExtractRequest = extractPayment;
        this.dataSource = new MatTableDataSource<any>(this.ExtractRequest);
        const initialPageIndex = 0;
        const initialPageSize = 10;
        setTimeout(() => {
          this.paginator.pageIndex = initialPageIndex;
          this.paginator.pageSize = initialPageSize;
          this.paginator.page.emit({
            pageIndex: initialPageIndex,
            pageSize: initialPageSize,
            length: this.ExtractRequest.length,
          });
        });
       
      },
      (error) => {
        if (error.status === 500) {
          this.dialog.open(ErrorDialogComponent, {
            data: { message: 'Oups, problème système' }
          });
        } 
      });
  }

  onPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.dataSource.data = this.ExtractRequest.slice(startIndex, endIndex);
  }
}
