import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateParkingComponent implements OnInit {
  parkingRequest!: any;
  listStartMonth: Array<any> = [];
  parkingRequestForm!: FormGroup ;

  constructor(
    private httpService: HttpClientService<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
   this.httpService
      .findOneById('ParkingRequests', this.activatedRoute.snapshot.params['id'])
      .subscribe((result: any) => {
        this.parkingRequest = result;
        this.parkingRequestForm.controls['startMonth'].setValue(this.parkingRequest.startDate)
        this.parkingRequestForm.controls['endMonth'].setValue(this.parkingRequest.endDate)
         this.checkEligibility();
      });
  }
  private initializeForm(): void {
    this.parkingRequestForm = this.formBuilder.group({
      startMonth: [''],
      endMonth:['']
    });
  }
  get form(): any {
    return this.parkingRequestForm.controls;
  }

  async checkEligibility(): Promise<any> {
    const mapping: any = [];
    this.httpService.findAll('ParkingRequests/CheckEligibility/'+this.parkingRequest.requester.email).subscribe({
      next: (months) => {
        let index = 0;
        months.availableMonths.forEach((element: any) => {
          mapping.push({
            value: moment(element).format('MMMM YYYY'),
            index,
            numberValue: moment(element).format('YYYY-MM'),
          });
          index++;
        });
        this.listStartMonth = mapping;
        // this.ineligibleDates = months.dates;
      },
      error: (err: HttpErrorResponse) => {
        // if (err.status == 403) {
        //   this.checkAccessTransport.access = false;
        //   this.checkAccessTransport.message = err.error.detail;
        // }
      },
    });
  }
  updateParkingRequest() {
    const selectedStartDate = new Date(this.parkingRequestForm.value.startMonth);
    const selectedEndDate = new Date(this.parkingRequestForm.value.endMonth);
    selectedEndDate.setUTCHours(0, 0, 0, 0);
    selectedStartDate.setUTCHours(0, 0, 0, 0);
    selectedStartDate.setUTCDate(selectedStartDate.getUTCDate() + 1);
    selectedEndDate.setUTCDate(selectedEndDate.getUTCDate() + 1);
    this.httpService
    .patchFormData('ParkingRequests',this.activatedRoute.snapshot.params['id'], {
      Id: this.activatedRoute.snapshot.params['id'],
      IsAdmin:true,
      StartDate:selectedStartDate.toISOString(),
      EndDate:selectedEndDate.toDateString()
    })
    .subscribe((data) => {
      this.router.navigate(['/mobility-request-admin/list']);
    },
    (error) => {
      if (error.status === 500) {
        this.dialog.open(ErrorDialogComponent, {
          data: { message: 'Oups, problème système' }
        });
      } 
    });
  }
  get environement(): typeof environment {
    return environment;
  }
}
