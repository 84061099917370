import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionPermissionsComponent } from './gestion-permissions/gestion-permissions.component';
import { GestionCalendrierComponent } from './gestion-calendrier/gestion-calendrier.component';
import { RouterModule } from '@angular/router';
import routes from './super-admin.routing';
import { SharedModule } from "../shared/shared.module"
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { ButtonModule } from 'primeng/button';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { GestionArchiveComponent } from './gestion-archive/gestion-archive.component';
import { TransportModule } from '../mobility-request-admin/transport/transport.module';
import { BikeModule } from '../mobility-request-admin/bike/bike.module';
import { GestionFormulesComponent } from './gestion-formules/gestion-formules.component';
import { GestionRemboursementsComponent } from './gestion-remboursements/gestion-remboursements.component';


@NgModule({
  declarations: [
    GestionPermissionsComponent,
    GestionCalendrierComponent,
    GestionArchiveComponent,
    GestionFormulesComponent,
    GestionRemboursementsComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    SharedModule,
    AngularMaterialModule,
    ButtonModule,
    ReactiveFormsModule,
    TransportModule,
    BikeModule,
    FormsModule,
    CommonModule
  ],
  exports:[
    GestionPermissionsComponent,
    GestionCalendrierComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SuperAdminModule { }
