import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { Canstants } from 'src/core/canstants/canstants';
import { MobilityRequestStatusArchive } from 'src/core/enum/mobility-request-status-archive.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { FilterOptions } from 'src/core/models/filter-options';
import { RequestBike } from 'src/core/models/request-bike';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { ExcelService } from 'src/core/services/excel.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault("Europe/Paris");

@Component({
  selector: 'app-list-bike-archive',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  @Input() bikeArchiveData!: boolean;
  statusForm!: FormGroup;
  constant = Canstants;
  filterOptions = new FilterOptions();
  sortOption: any = { active: 'Created', direction: 'desc' };
  mobilityRequestStatusArchive!: MobilityRequestStatusArchive;
  subscriptions: any;
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  displayedColumnsVelo: string[] = [];
  requestVelo: RequestBike[] = [];
  allrequestVelo: RequestBike[] = [];
  toggleFilter=true;
  listSites: any;
  listStatus: any;
  currentDate = new Date();
  requestor: any;
  validator: any;
  lastMonth: Date | undefined;
  @ViewChild('paginatorRequestVelo') paginatorRequestVelo!: MatPaginator;
  @ViewChild('allSelectedBike') allSelectedBike!: MatOption;
  @ViewChild(MatSort) sort: MatSort | undefined;
  user: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: Router,
    private fb: FormBuilder,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private excelService: ExcelService
  ) {}
  get mobilityRequestStatusArchives(): typeof MobilityRequestStatusArchive {
    return MobilityRequestStatusArchive;
  }
  async ngOnInit(): Promise<void> {
    this.statusForm = this.fb.group({
      statusFieldBike: new FormControl(''),
    });
    await this.initializeFilter();
    this.user = await this.authService.getConnectedUser();
    this.initializeHeader();
    await this.getSites();
    this.getStatus();
    if (
      this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
      this.user.roles.includes(this.constant.ROLE_VALIDATOR_VELO)
      || this.bikeArchiveData
    ) {
      await this.loadBikeRequest();
    }
  }
  dateChanged(event:any){
    this.toggleFilter=false;
  }

  async initializeFilter(): Promise<void> {
    const obj: string = localStorage.getItem('Mob_FilterBike_Archive') as string;
    const userFilter = JSON.parse(obj) ? JSON.parse(obj) : null;
    if (userFilter) {
      this.filterOptions.Created = new Date(userFilter.Created).toISOString();
      this.filterOptions.Status = userFilter.Status;
      this.statusForm.controls['statusFieldBike'].setValue(userFilter.Status);
      if (userFilter.Site) {
        this.filterOptions.Site = userFilter.Site;
      }
      if (userFilter.searchType) {
        this.filterOptions.searchType = userFilter.searchType;
      }
      if (userFilter.SL) {
        this.filterOptions.SL = userFilter.SL;
      }
      if (userFilter.validator) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.validator);
        // this.selectValidateur(user);
      }
      if (userFilter.requestor) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.requestor);
        // this.selectRequester(user);
      }
    this.toggleFilter=true;


    } else {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      this.filterOptions.Created = currentDate.toISOString();
      this.toggleFilter=true;

      this.filterOptions.Status = [
        MobilityRequestStatusArchive[MobilityRequestStatusArchive.Validated].toString(),
      ];
      this.statusForm.controls['statusFieldBike'].setValue([
        MobilityRequestStatusArchive[MobilityRequestStatusArchive.Validated].toString(),
      ]);

    }
  }

  async loadBikeRequest(fromFilter?:boolean): Promise<void> {
    if (this.filterOptions.Created  ) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    localStorage.setItem('Mob_FilterBike_Archive', JSON.stringify(this.filterOptions));

    if (fromFilter) {
      this.currentPage = 1;
    }
    this.httpService
      .findAll('BikeRequests/GetAllArchive', {
        ...this.filterOptions,
        Created:this.filterOptions.Created,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        sortName: this.sortOption.active,
        sortDirection : this.sortOption.direction
      })
      .subscribe((bikes) => {
        this.requestVelo = bikes.items;
        setTimeout(() => {
          this.paginatorRequestVelo.pageIndex = this.currentPage - 1;
          this.paginatorRequestVelo.length = bikes.totalCount;
        });
        // this.route.navigateByUrl('/', { skipLocationChange: true })
        //     .then(() => {
        //       this.route.navigate(['/mobility-request-admin/list']);
        //     });
      });
    this.toggleFilter=true;

  }

  pageChanged(event: PageEvent) {

    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadBikeRequest();
  }

  toggleAllSelectionBike(): void {
    if (this.allSelectedBike.selected) {
      this.statusForm.controls['statusFieldBike'].setValue([
        ...this.listStatus.map((item: any) => item),
        0,
      ]);
      this.filterOptions.Status = [...this.listStatus.map((item: any) => item)];
    } else {
      this.statusForm.controls['statusFieldBike'].setValue([]);
      this.filterOptions.Status = [];
    }
  }
  selectPerOneVelo(all: any) {
    this.filterOptions.Status =
      this.statusForm.controls['statusFieldBike'].value;
    if (this.allSelectedBike.selected) {
      this.allSelectedBike.deselect();
      return false;
    }
    if (
      this.statusForm.controls['statusFieldBike'].value.length ==
      this.listStatus.length
    )
      this.allSelectedBike.select();
    return true;
  }
  goPageVelo() {
    const pageIndex = this.paginatorRequestVelo.pageIndex + 1;
    this.requestVelo = this.allrequestVelo.slice(
      (pageIndex - 1) * this.paginatorRequestVelo.pageSize,
      pageIndex * this.paginatorRequestVelo.pageSize
    );
  }

  getStatus(): void {
    this.listStatus = Object.getOwnPropertyNames(MobilityRequestStatusArchive)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => MobilityRequestStatusArchive[key]);
  }

  async getSites(): Promise<void> {
    this.httpService.findAll('Sites',{
      pageSize: 100,
      SortName:"name",
      SortDirection:"Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items;
    });
  }



  async sortVeloRequest(event: any): Promise<void> {
    this.sortOption = event;
    this.paginatorRequestVelo.pageIndex = 0;
    await this.loadBikeRequest();
  }

  initializeHeader(): void {
    this.displayedColumnsVelo = [
      'Created',
      'Requester',
      'SiteRattachement',
      'ServiceLine',
      'EntiteJuridique',
      'Grade',
      'StartDate',
      'duration',
      'Status',
      'NomValideur',
      'Actions',
    ];
  }

  async resetSearchVelo(): Promise<void> {
    this.filterOptions = new FilterOptions();
    localStorage.removeItem('Mob_FilterBike_Archive');
    this.resetRequester();
    this.resetValidateur();
    await this.initializeFilter();
    this.paginatorRequestVelo.pageIndex = 0;
    this.loadBikeRequest();
  }

  selectRequester(user: any): void {
    this.requestor = user;
    this.filterOptions.Requester = user.email;
  }
  resetRequester() {
    this.requestor = null;
    this.filterOptions.Requester = '';
  }
  selectValidateur(user: any): void {
    this.validator = user;
    this.filterOptions.Validator = user.email;
  }
  resetValidateur() {
    this.validator = null;
    this.filterOptions.Validator = '';
  }
}
