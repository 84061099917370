import { Injectable } from '@angular/core';
import jspdf from 'jspdf';
import * as moment from 'moment-timezone';
import { environment } from '../../environments/environment';
import { MobilityRequestType } from '../enum/mobility-request-type.enum';
import User from '../models/user';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  pdf: jspdf = new jspdf({
    unit: 'pt',
    encryption: {
      ownerPassword: 'OwnerAttestationDeplacement1986#',
      userPermissions: ['print'],
    },
  });

  constructor(private authService: AuthenticationService) {}
 get environement(): typeof environment {
    return environment;
  }
  async generatePDF(
    duration: number = 0,
    doIn: string,
    demeurantIn: string,
    typePDF: MobilityRequestType
  ): Promise<void> {
    this.pdf = new jspdf({
      unit: 'pt',
      encryption: {
        ownerPassword: 'OwnerAttestationDeplacement1986#',
        userPermissions: ['print'],
      },
    });
    switch (typePDF) {
      case MobilityRequestType.cycling:
        await this.generatePDFWithLogoVelo(
          this.environement.baseUrl +'assets/images/logoAttestation.jpg',
          doIn,
          demeurantIn
        );
        break;
      case MobilityRequestType.transport:
        await this.generatePDFWithLogoTransport(
          this.environement.baseUrl +'assets/images/logoAttestation.jpg',
          duration,
          doIn,
          demeurantIn
        );
        break;
    }
  }

  async generatePDFWithLogoVelo(
    logoBase64: any,
    doIn: string,
    demeurantIn: string
  ): Promise<void> {
    const connectedUser = await this.authService.getConnectedUser();
    this.pdf.addImage(logoBase64, 'JPEG', 10, 10, 40, 40);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(22);
    this.pdf.setFont('bold');
    this.pdf.text(" Attestation sur l'honneur", 200, 60);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(16);
    this.pdf.setFont('bold');
    this.pdf.text(
      'pour utilisation d’un mode de transport ouvrant droit à la prise en charge',
      90,
      90
    );

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(16);
    this.pdf.setFont('bold');
    this.pdf.text('dans le cadre du « forfait mobilités durables »', 200, 110);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'Je soussigné(e) :' + connectedUser.displayName + ',',
      70,
      160
    );

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'Entité juridique EY :' + connectedUser.myCompanyDesc,
      100,
      180
    );

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'Bureau de rattachement :' + connectedUser.locationDesc,
      100,
      200
    );

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text('N° employé :' + connectedUser.gpn, 100, 220);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text('demeurant ' + demeurantIn + ',', 70, 260);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'demandant à mon employeur une subvention pour l’achat d’un vélo dans le cadre ',
      70,
      280
    );
    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text('forfait mobilités durables,', 70, 300);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'atteste sur l’honneur utiliser ce mode de transport pour mes trajets domicile – lieu de travail.',
      70,
      320
    );

    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text('Fait pour servir et valoir ce que de droit.', 70, 370);

    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text(
      'Fait a ' + doIn + ', le ' + moment().format('DD/MM/yyyy'),
      70,
      400
    );
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');
    this.pdf.text('Signé ' + connectedUser.displayName, 70, 440);
  }
  async generatePDFWithLogoTransport(
    logoBase64: any,
    duration: number,
    doIn: string,
    demeurantIn: string
  ): Promise<void> {
    const connectedUser: User = await this.authService.getConnectedUser();
    this.pdf.addImage(logoBase64, 'JPEG', 10, 10, 40, 40);

    this.pdf.setFont('Interstate-Bold');
    this.pdf.setFontSize(22);
    this.pdf.setFont('bold');
    this.pdf.text(" Attestation sur l'honneur", 200, 60);

    this.pdf.setFontSize(14);
    this.pdf.setFont('bold');
    this.pdf.text(
      'Pour prise en charge récurrente des frais d’abonnement couvrant les transports domicile-travail',
      40,
      90
    );
    this.pdf.setFontSize(13);
    this.pdf.setFont('bold');

    this.pdf.text(
      'Je soussigné(e) :' + connectedUser.displayName + ',',
      70,
      160
    );
    this.pdf.text(
      'Entité juridique EY :' + connectedUser.myCompanyDesc,
      100,
      180
    );
    this.pdf.text(
      'Bureau de rattachement :' + connectedUser.locationDesc,
      100,
      200
    );
    this.pdf.text('N° employé :' + connectedUser.gpn, 100, 220);
    this.pdf.text('demeurant ' + demeurantIn + ',', 70, 260);
    this.pdf.text(
      "atteste les points suivants, à l'appui de la demande de prise en charge partielle de mes frais",
      70,
      280
    );
    this.pdf.text(
      'de transports, déposée ce jour via le Portail Mobilités Transport pour une durée de ' +
        duration +
        ' mois',
      70,
      300
    );
    this.pdf.text(
      '(correspondant à l’abonnement éligible auquel j’ai souscrit).',
      70,
      320
    );
    this.pdf.rect(70, 360, 10, 10, 'F');
    this.pdf.text(
      'La formule de transports pour laquelle je demande une prise en charge est destinée',
      90,
      370
    );
    this.pdf.text('à mes déplacements domicile-lieu de travail.', 90, 390);
    this.pdf.rect(70, 410, 10, 10, 'F');
    this.pdf.text(
      'J’ai souscrit un abonnement annuel pour lequel je m’engage à régler l’entièreté',
      90,
      420
    );
    this.pdf.text('de son coût auprès de l’organisme de transport', 90, 440);
    // this.pdf.setFont('Interstate-Bold');
    // this.pdf.setFontSize(13);
    // this.pdf.setFont('bold');
    // this.pdf.text('versements effectués,je me suis bien acquitté-e du premier mois-au moins', 90, 430);
    // this.pdf.setFont('Interstate-Bold');
    // this.pdf.setFontSize(13);
    // this.pdf.setFont('bold');
    // this.pdf.text('pour lequel je demnde cette prise en charge.', 90, 450);

    this.pdf.rect(70, 460, 10, 10, 'F');
    this.pdf.text(
      "En cas de non-paiement de l'un/plusieurs des mois prévus ou de changement de",
      90,
      470
    );
    this.pdf.text(
      "situation de mon abonnement(modification, arrêt ou suspension), je m'engage à avertir",
      90,
      490
    );
    this.pdf.text(
      "sans délai mon employeur, par l'intermédiaire de ce Portail Mobilités-Transports",
      90,
      510
    );
    this.pdf.text(
      '(modification de ma formule et/ou nouvelle demande).',
      90,
      530
    );

    this.pdf.text(
      'A défaut de pouvoir justifier de mes versements mensuels effectifs en cas de demande',
      70,
      570
    );
    this.pdf.text(
      "de mon employeur, je m'expose à la suspension de mon indemnité transport et à la reprise",
      70,
      590
    );
    this.pdf.text('des remboursements non justifiés.', 70, 610);
    this.pdf.text('Fait pour servir et valoir ce que de droit.', 70, 650);
    this.pdf.text(
      'Fait a ' + doIn + ', le ' + moment().format('DD/MM/yyyy'),
      70,
      690
    );
    this.pdf.text('Signé ' + connectedUser.displayName, 70, 730);
  }
  resetPDF(): void {
    this.pdf = new jspdf({
      unit: 'pt',
      encryption: {
        ownerPassword: 'OwnerAttestationDeplacement1986#',
        userPermissions: ['print'],
      },
    });
  }
  blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return theBlob as File;
  }
  openPdf(): void {
    window.open(URL.createObjectURL(this.pdf?.output('blob')));
  }
}
