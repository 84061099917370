import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateParkingComponent } from './create/create.component';
import { RejectParkingComponent } from './reject/reject.component';
import { UpdateParkingComponent } from './update/update.component';
import { ViewParkingComponent } from './view/view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-user.routing';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    CreateParkingComponent,
    UpdateParkingComponent,
    ViewParkingComponent,
    RejectParkingComponent
  ],
  exports:[
    CreateParkingComponent,
    UpdateParkingComponent,
    ViewParkingComponent,
    RejectParkingComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ParkingModule { }
