import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Canstants } from '../canstants/canstants';
import { MobilityRequestType } from '../enum/mobility-request-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DateService {
constants: any = Canstants;

  constructor() {}
  get mobilityRequestType(): typeof MobilityRequestType {
    return MobilityRequestType;
  }
  getThreeMonthFromNow(
    peoplePlan: any,
    descriptionBusinessParam: number
  ): Array<any> {
    const actualDay = new Date().getDate();
    const mapping: any = [];
    if (actualDay <= descriptionBusinessParam) {
      const startingDate = moment().subtract(5, 'month');
      for (let i = 0; i < 6; i++) {
        const d = startingDate.add(1, 'month');
        const obj = {
          value: d.format('MMMM YYYY'),
          index: i,
          numberValue: d.format('YYYY-MM'),
        };
        if (!peoplePlan['Mob_' + d.format('MMYY')]) {
          mapping.push(obj);
        }
      }
    } else {
      const startingDate = moment().subtract(2, 'month');
      for (let i = 0; i < 3; i++) {
        const d = startingDate.add(1, 'month');
        const obj = {
          value: d.format('MMMM YYYY'),
          index: i,
          numberValue: d.format('YYYY-MM'),
        };
        if (!peoplePlan['Mob_' + d.format('MMYY')]) {
          mapping.push(obj);
        }
      }
    }
    return mapping;
  }
  getFourMonthFromNow(
    peoplePlan: any,
    requestTransports: any,
    requestVelos: any,
    requestParkings: any,
    descriptionBusinessParam: number,
    requestTransport: any
  ): Array<any> {
    let mapping: any = [];
    let monthPeoplePlan: any = [];
    const listMonths = [];
    const jourNow = new Date().getDate();
    let startingDate: any;
    if (jourNow <= descriptionBusinessParam) {
      startingDate = moment().subtract(3, 'month');
    } else {
      startingDate = moment().subtract(2, 'month');
    }
    let d = startingDate;
    const monthPlusUn = moment().add(1, 'month').format('MMMM YYYY');

    let i = 0;
    do {
      d = startingDate.add(1, 'month');
      const obj = {
        value: d.format('MMMM YYYY'),
        index: i,
        numberValue: d.format('YYYY-MM'),
      };
      if (obj.numberValue != '2021-04') {
        listMonths.push(obj);
      }
      i++;
    } while (d.format('MMMM YYYY') != monthPlusUn);

    listMonths.forEach((res) => {
      const month = 'Mob_' + moment(res.numberValue).format('MMYY');
      if (!peoplePlan[month]) {
        // object.splice(index, 1)
        const obj = {
          value: res.value,
          index: res.index,
          numberValue: res.numberValue,
        };
        monthPeoplePlan.push(obj);
      }
    });
    monthPeoplePlan = monthPeoplePlan.filter(
      (res: any) =>
        !requestTransports.some(
          (request: any) =>
            new Date(res.numberValue) >= new Date(request.Mob_MoisDebut) &&
            new Date(res.numberValue) <= new Date(request.Mob_MoisFin)
        ) ||
        (requestTransport &&
          new Date(res.numberValue).getMonth() ==
            new Date(requestTransport.Mob_MoisDebut).getMonth())
    );

    monthPeoplePlan = monthPeoplePlan.filter(
      (res: any) =>
        !requestVelos.some(
          (request: any) =>
            new Date(res.numberValue) >= new Date(request.Mob_MoisDebut) &&
            new Date(res.numberValue) <= new Date(request.Mob_MoisFin)
        )
    );

    monthPeoplePlan = monthPeoplePlan.filter(
      (res: any) =>
        !requestParkings.some(
          (request: any) =>
            new Date(res.numberValue) >= new Date(request.Mob_MoisDebut) &&
            new Date(res.numberValue) <= new Date(request.Mob_MoisFin)
        )
    );

    if (!monthPeoplePlan) {
      let startingDate: any;
      if (jourNow <= descriptionBusinessParam) {
        startingDate = moment().subtract(3, 'month');
        for (let i = 0; i < 3; i++) {
          const d = startingDate.add(1, 'month');
          const obj = {
            value: d.format('MMMM YYYY'),
            index: i,
            numberValue: d.format('YYYY-MM'),
          };
          mapping.push(obj);
        }
      } else {
        startingDate = moment().subtract(2, 'month');
        for (let i = 0; i < 2; i++) {
          const d = startingDate.add(1, 'month');
          const obj = {
            value: d.format('MMMM YYYY'),
            index: i,
            numberValue: d.format('YYYY-MM'),
          };
          mapping.push(obj);
        }
      }
    } else {
      mapping = monthPeoplePlan;
    }
    return mapping;
  }
  getElevenMonthFromNow(
    event: any,
    peoplePlan: any,
    requestTransports: any,
    requestVelos: any,
    requestParkings: any,
    requestTransport: any
  ): Array<any> {
    const actuelDate = moment(moment().format('YYYY-MM'));
    let startingDate = moment(event, 'YYYY-MM');
    const mapping: any = [];
    if (startingDate >= actuelDate) {
      for (let i = 0; i < 12; i++) {
        const obj = {
          value: startingDate.format('MMMM YYYY'),
          index: i,
          numberValue: startingDate.format('YYYY-MM'),
        };
        const requestTransport = requestTransports.some(
          (request: any) =>
            startingDate.format('YYYY-MM') ==
            moment(request.Mob_MoisDebut).format('YYYY-MM')
        );
        const requestVelo = requestVelos.some(
          (request: any) =>
            startingDate.format('YYYY-MM') ==
            moment(request.Mob_MoisDebut).format('YYYY-MM')
        );
        const requestParking = requestParkings.some(
          (request: any) =>
            startingDate.format('YYYY-MM') ==
            moment(request.Mob_MoisDebut).format('YYYY-MM')
        );

        if (
          !peoplePlan['Mob_' + startingDate.format('MMYY')] &&
          !requestTransport &&
          !requestVelo &&
          !requestParking
        ) {
          mapping.push(obj);
        } else {
          break;
        }
        startingDate = startingDate.add(1, 'month');
      }
    } else {
      const obj = {
        value: startingDate.format('MMMM YYYY'),
        numberValue: startingDate.format('YYYY-MM'),
      };
      mapping.push(obj);
    }
    return mapping;
  }
  
  getEighteenMonthFromNow(mobilityRequestValidated: any[]): Array<any> {
    const startingDate = moment().subtract(5, 'month');
    const mapping: any = [];
    for (let i = 0; i < 19; i++) {
      const d = startingDate.add(1, 'month');
      let infos = this.returnInformations(mobilityRequestValidated, d);
      const obj = {
        key: 'Mob_' + d.format('MMYY'),
        description: d.format('MMMM YYYY'),
        month: Number(d.format('MM')),
        year: Number(d.format('YYYY')),
        index: i,
        value: infos.value ? infos.value : '',
        type: infos.type ? infos.type : '',
        forfait: infos.forfait ? infos.forfait : '',
        requestDate: Number(d.format('YYYY')) +"-"+Number(d.format('MM'))+"-1"
      };
      mapping.push(obj);
    }
    return mapping;
  }
  getEighteenMonth(): Array<any> {
    const startingDate = moment().subtract(5, 'month');
    const mapping: any = [];
    for (let i = 0; i < 19; i++) {
      const d = startingDate.add(1, 'month');
      const obj = {
        value: 'Mob_' + d.format('MMYY'),
        description: d.format('MMMM YYYY'),
        month: Number(d.format('MM')),
        year: Number(d.format('YYYY')),
        numberValue: d.format('YYYY-MM'),
        index: i,
      };
      mapping.push(obj);
    }
    return mapping;
  }

  private returnInformations(data: Array<any>, daate: any): any {
    let informations = {
      type : '',
      forfait : '',
      value:''
    };
   var date = new Date(daate)
    var dateCompare =  (date.getMonth()+1)+"/01/" +date.getFullYear();
    var compareTo = new Date(dateCompare)
    if (data) {
      data.forEach((elem: any) => {
        var startDate = new Date(elem.startDate);
        var endDate = new Date(elem.endDate);
        if(compareTo >= startDate && compareTo <= endDate){
          informations.type = elem.requestType
          informations.value = elem.id
          if(elem.requestType == this.constants.transportAllowance){
            informations.forfait = elem.subscriptionFormulas[0].unitPrice
          }
          else {
            informations.forfait = ''
          }

        }
      });
    }

    return informations;
  }

  getMonthBike(peoplePlan: any, descriptionBusinessParam: number): Array<any> {
    const actualDay = new Date().getDate();
    const mapping: any = [];
    const nextMonth = moment().add(1, 'month');
    let index = 0;
    if (actualDay <= descriptionBusinessParam) {
      mapping.push({
        value: moment().format('MMMM YYYY'),
        index,
        numberValue: moment().format('YYYY-MM'),
      });
      index++;
    }
    if (!peoplePlan['Mob_' + nextMonth.format('MMYY')]) {
      mapping.push({
        value: nextMonth.format('MMMM YYYY'),
        index,
        numberValue: nextMonth.format('YYYY-MM'),
      });
    }
    return mapping;
  }

  calculateDuration(startDate: Date, endDate: Date): number {
    const startMonth = new Date(startDate);
    const endMonth = new Date(endDate);
    let months;
    months = (startMonth.getFullYear() - endMonth.getFullYear()) * 12;
    months -= endMonth.getMonth();
    months += startMonth.getMonth();
    return months == 0 ? 1 : months + 1;
  }

  convertDate(inputDate: string): Date {
    let outputDate = '';
    if (inputDate) {
      const dateSplitted = inputDate.split(' ');
      const month = dateSplitted[0];
      const year = dateSplitted[1];
      switch (month.toLowerCase()) {
        case 'janvier':
          outputDate = year + '-01';
          break;
        case 'février':
          outputDate = year + '-02';
          break;
        case 'mars':
          outputDate = year + '-03';
          break;
        case 'avril':
          outputDate = year + '-04';
          break;
        case 'mai':
          outputDate = year + '-05';
          break;
        case 'juin':
          outputDate = year + '-06';
          break;
        case 'juillet':
          outputDate = year + '-07';
          break;
        case 'août':
          outputDate = year + '-08';
          break;
        case 'septembre':
          outputDate = year + '-09';
          break;
        case 'octobre':
          outputDate = year + '-10';
          break;
        case 'novembre':
          outputDate = year + '-11';
          break;
        case 'décembre':
          outputDate = year + '-12';
          break;
      }
    }
    return new Date(outputDate);
  }
}
