import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import User from '../models/user';
@Injectable({
  providedIn: 'root',
})
export class bikeGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (!(user.myCompanyCode == "FR73" || user.myCompanyDesc.toLowerCase() == "fabernovel")){
      return true; 
    } else {
      this.router.navigate(['/no-access']); 
      return false;
    }
  }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //     const user: User = JSON.parse(
  //       localStorage.getItem('MobilitesFR-currentUser') || '{}'
  //     );
  //     if (user.myCompanyCode == "FR19" || user.myCompanyDesc == "EY SERVICES FRANCE")
  //       return true;
  //   return false;
  // }
}
