import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { List } from 'linqts';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatusArchive } from 'src/core/enum/mobility-request-status-archive.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { FilterOptions } from 'src/core/models/filter-options';
import { QueryOption } from 'src/core/models/query-option';
import { RequestTransport } from 'src/core/models/request-transport';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { ExcelService } from 'src/core/services/excel.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-list-transport-archive',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],

})
export class ListComponent implements OnInit {
  @Input() transportArchiveData!: boolean;

  statusForm!: FormGroup;
  constant = Canstants;
  filterOptions = new FilterOptions();
  displayedColumnsTransport: string[] = [];
  requestTransports: RequestTransport[] = [];
  toggleFilter=true;
  subscriptions: any;
  sortOption: any = { active: 'Created', direction: 'desc' };
  listSites: any;
  listStatus: any;
  // currentDate = new Date();
  pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  user: any;
  requestor: any;
  validator: any;
  lastMonth!: Date;
  @ViewChild('paginatorRequestTransport')
  paginatorRequestTransport!: MatPaginator;
  @ViewChild('allSelectedTransport')
  private allSelectedTransport!: MatOption;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private excelService: ExcelService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}
  get mobilityRequestStatuss(): typeof MobilityRequestStatusArchive {
    return MobilityRequestStatusArchive;
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  async ngOnInit(): Promise<void> {
    this.statusForm = this.fb.group({
      statusFieldTransport: new FormControl(''),
    });
    await this.initializeFilter();
    this.user = await this.authService.getConnectedUser();
    await this.getListAbonnements();
    this.initializeHeader();
    await this.getSites();
    this.getStatus();
    if (
      this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
      this.user.roles.includes(this.constant.ROLE_VALIDATOR_TRANSPORT)
      || this.transportArchiveData
    ) {
      await this.loadRequestTransports();

    }
  }
  async initializeFilter(): Promise<void> {
    const obj: string = localStorage.getItem('Mob_FilterTransport_Archive') as string;
    const userFilter = JSON.parse(obj) ? JSON.parse(obj) : null;
    if (userFilter) {
      this.filterOptions.Created = new Date(userFilter.Created).toISOString();
      this.filterOptions.Status = userFilter.Status;
      this.statusForm.controls['statusFieldTransport'].setValue(
        userFilter.Status
      );
      if (userFilter.Site) {
        this.filterOptions.Site = userFilter.Site;
      }
      if (userFilter.searchType) {
        this.filterOptions.searchType = userFilter.searchType;
      }
      if (userFilter.SL) {
        this.filterOptions.SL = userFilter.SL;
      }
      if (userFilter.validator) {
        // let user = await this.userService.getUserProfileByEmail(
        //   userFilter.validator
        // );
        // this.selectValidateur(user);
      }
      if (userFilter.requestor) {
        // let user = await this.userService.getUserProfileByEmail(
        //   userFilter.requestor
        // );
        // this.selectRequester(user);
      }
      this.toggleFilter=true;

    } else {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      this.filterOptions.Created = currentDate.toISOString();
      this.toggleFilter=true;


      this.filterOptions.Status = [
        MobilityRequestStatusArchive[MobilityRequestStatusArchive.Validated].toString(),
      ];
      this.statusForm.controls['statusFieldTransport'].setValue([
        MobilityRequestStatusArchive[MobilityRequestStatusArchive.Validated].toString(),
      ]);
    }
  }
  async getListAbonnements(): Promise<void> {
    this.subscriptions = [];

    let result: any = [];
    await this.httpService.findAll('SubscriptionTypes').subscribe((abos) => {
      result = abos;
      const grouped: any = new List(result).GroupBy((x: any) => x.formulaCode);
      const codes = Object.getOwnPropertyNames(grouped);
      for (const code of codes) {
        this.subscriptions.push(grouped[code][0]);
      }
      this.subscriptions.sort(function (a: any, b: any) {
        return a.order - b.order;
      });
    });
  }
  dateChanged(event:any){
    this.toggleFilter=false;
  }
  async loadRequestTransports(fromFilter?: boolean): Promise<void> {
    if (this.filterOptions.Created  ) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    localStorage.setItem(
      'Mob_FilterTransport_Archive',
      JSON.stringify(this.filterOptions)
    );
    if (fromFilter) {
      this.currentPage = 1;
    }
    this.httpService
      .findAll('TransportRequests/GetAllArchive', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        sortName: this.sortOption.active,
        sortDirection: this.sortOption.direction,
      })
      .subscribe((trs) => {
        this.requestTransports = trs.items;
        setTimeout(() => {
          this.paginatorRequestTransport.pageIndex = this.currentPage - 1;
          this.paginatorRequestTransport.length = trs.totalCount;
        });
        this.changeDetectorRefs.detectChanges();
      });

    this.toggleFilter=true;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadRequestTransports();
  }

  toggleAllSelectionTransport(): void {
    if (this.allSelectedTransport.selected) {
      this.statusForm.controls['statusFieldTransport'].setValue([
        ...this.listStatus.map((item: any) => item),
        0,
      ]);

      this.filterOptions.Status = [...this.listStatus.map((item: any) => item)];
    } else {
      this.statusForm.controls['statusFieldTransport'].setValue([]);
      this.filterOptions.Status = [];
    }

  }
  selectPerOneTransport(all: any) {
    this.filterOptions.Status =
      this.statusForm.controls['statusFieldTransport'].value;
    if (this.allSelectedTransport.selected) {
      this.allSelectedTransport.deselect();

      return false;
    }
    if (
      this.statusForm.controls['statusFieldTransport'].value.length ==
      this.listStatus.length
    )
      this.allSelectedTransport.select();
    return true;
  }
  getStatus(): void {
    this.listStatus = Object.getOwnPropertyNames(MobilityRequestStatusArchive)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => MobilityRequestStatusArchive[key]);
  }

  async getSites(): Promise<void> {
    await this.httpService.findAll('Sites',{
      pageSize: 100,
      SortName:"name",
      SortDirection:"Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items;
    });
  }


  getFormuleType(type: any) {
    switch (type) {
      case 0:
        return FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
      case 1:
        return FormuleTypeEnum[FormuleTypeEnum.InfraMensuel].toString();
      case 2:
        return FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
      case 3:
        return FormuleTypeEnum[FormuleTypeEnum.Misc].toString();
    }
    return null;
  }
  async sortTransportRequest(event: any): Promise<void> {
    this.sortOption = event;
    this.paginatorRequestTransport.pageIndex = 0;
    await this.loadRequestTransports();
  }
  initializeHeader(): void {
    this.displayedColumnsTransport = [
      'Created',
      'Requester',
      'SiteRattachement',
      'ServiceLine',
      'EntiteJuridique',
      'Grade',
      'type',
      'StartDate',
      'duration',
      'Status',
      'NomValideur',
      'Actions',
    ];
  }

  async resetSearchTransport(): Promise<void> {
    //
    this.filterOptions = new FilterOptions();
    localStorage.removeItem('Mob_FilterTransport_Archive');
    this.resetRequester();
    this.resetValidateur();
    await this.initializeFilter();
    this.paginatorRequestTransport.pageIndex = 0;
    this.loadRequestTransports();
    //
  }

  selectRequester(user: any): void {
    this.requestor = user;
    this.filterOptions.Requester = user.email;
  }
  resetRequester() {
    this.requestor = null;
    this.filterOptions.Requester = '';
  }
  selectValidateur(user: any): void {
    this.validator = user;
    this.filterOptions.Validator = user.email;
  }
  resetValidateur() {
    this.validator = null;
    this.filterOptions.Validator = '';
  }
}
