export enum Roles {
    USER,
    VALIDATOR,
    PAIE,
    ACL,
    VALIDATOR_TRANSPORT,
    VALIDATOR_VELO,
    VALIDATOR_PARKING,
    ADMIN,
    SUPER_ADMIN,
    CANCEL_VALID_REQUEST,
    MANAGE_FORMULAS,
    MANAGE_CALENDAR,
    MANAGE_ARCHIVE,
    MANAGE_PERMISSIONS
  }