import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryBuilder } from 'odata-query-builder';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService<Model> {
  constructor(private http: HttpClient) {}

  create(endpoint: string): Observable<any> {
    return this.http.post(environment.apiurl + endpoint, {});
  }
  postFromBody(endpoint: string,payload:any): Observable<any> {
    return this.http.post(environment.apiurl + endpoint, payload);
  }
  postFormData(endpoint: string, model: any): Observable<any> {
    var formdata: FormData = new FormData();

      for (var key in model) {
        if (Array.isArray(model[key])) {
          for (var i = 0; i < model[key].length; i++) {
            //Append as object if it is an object
            if (typeof model[key][i] === 'object') {
              for (var objKey in model[key][i]) {
                formdata.append(
                  `${key}[${i}].${objKey}`,
                  model[key][i][objKey]
                );
              }
            } else formdata.append(`${key}`, model[key][i]);
          }
        } else formdata.append(key, model[key]);
      }
    return this.http.post(environment.apiurl + endpoint, formdata);
  }

  patchFormData(
    endpoint: string,
    id: number,
    model: any,
    params?: any
  ): Observable<any> {
    var formdata: FormData = new FormData();

    for (var key in model) {
      if (Array.isArray(model[key])) {
        for (var i = 0; i < model[key].length; i++) {
          //Append as object if it is an object
          if (typeof model[key][i] === 'object') {
            for (var objKey in model[key][i]) {
              formdata.append(
                `${key}[${i}].${objKey}`,
                model[key][i][objKey]
              );
            }
          } else formdata.append(`${key}`, model[key][i]);
        }
      } else formdata.append(key, model[key]);
    }
    
    let httpParams = new HttpParams();
    for (var key in params) {
      httpParams = httpParams.append(key, params[key]);
    }
    return this.http.patch(`${environment.apiurl}${endpoint}/${id}`, formdata, {
      params: httpParams,
    });
  }

  createUrlEncode(endpoint: string, params: string) {
    return this.http.post(environment.apiurl + endpoint + params, null);
  }

  createFile(endpoint: string, params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'blob',
    });
    return this.http.post(environment.apiurl + endpoint, params, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  downloadFile(endpoint: string, params: any) {
    return this.http.get(environment.apiurl + endpoint, {
      responseType: 'blob',
      observe: 'response',
      params,
    });
  }

  update(endpoint: string, id: any, model: Model) {
    return this.http.put(environment.apiurl + endpoint + '/' + id, model);
  }

  findOneById(endpoint: string, id: any): Observable<any> {
    return this.http.get<any>(
      environment.apiurl + endpoint + '/' + id.toString()
    );
  }

  findOneByIdAndDate(endpoint: string, id: any,date:any): Observable<any> {
    return this.http.get<any>(
      environment.apiurl + endpoint + '/' + id.toString()+ '/' + date
    );
  }

  findOneByQuery(endpoint: string, query: QueryBuilder): Observable<any> {
    if (query.toQuery()) {
      return this.http.get<any>(
        environment.apiurl + endpoint + '/' + query.toQuery()
      );
    } else {
      return this.http.get<any>(environment.apiurl + endpoint);
    }
  }
  patchWithoutId(endpoint: string, value: any) {
    return this.http.patch(environment.apiurl + endpoint, value);
  }
  patch(endpoint: string, id: any, value: any) {
    return this.http.patch(environment.apiurl + endpoint + '/' + id, value);
  }

  patchUrlEncode(endpoint: string, params: string) {
    return this.http.patch(environment.apiurl + endpoint + '?' + params, null);
  }

  findAll(endpoint: string, queryParams?: any): Observable<any> {
    return this.http.get<any>(environment.apiurl + endpoint, {
      params: queryParams,
    });
  }

  findUrlEncode(endpoint: string, params: string) {
    return this.http.get<any>(environment.apiurl + endpoint + params);
  }

  findFile(endpoint: string, params: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      responseType: 'blob',
    });
    return this.http.get(environment.apiurl + endpoint + params, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  delete(endpoint: string, id: any): Observable<any> {
    return this.http.delete(environment.apiurl + endpoint + '/' + id);
  }

  findAllExcept(endpoint: string, model: Array<string>): Observable<any> {
    return this.http.post(environment.apiurl + endpoint, model);
  }
}
