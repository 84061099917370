<div class="modal fade" id="view-transport-condition" tabindex="-1" role="dialog" aria-labelledby="modalCloseForm" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                <h4 class="modal-title bold">Indemnité Transports</h4>
                <h5 class="modal-title semi-bold">Modalités et conditions</h5>
            </div>
            <div class="modal-body">
                <div class="conditionTitle">
                    <span class="semi-bold yellowColor">A compter du mois d’Août 2021, toute demande d’indemnisation des frais de
            transports doit s’effectuer via ce portail.</span>
                </div>
                <div class="mt-3">EY indemnise vos frais de transport en commun récurrents (abonnements transports hebdomadaires, mensuels, plurimensuels, et annuels,...) ou abonnements de moyenne durée de vélos publics (ex : Véligo, Vélov’, V’Lille, Monbicloo, Vélhop,
                    … ) à hauteur de 75% de leur coût, à condition qu’ils concernent vos trajets entre votre domicile et votre lieu de travail fixe (bureau EY de rattachement). Ce remboursement est directement intégré à votre virement mensuel de paie
                    et mentionné dans votre bulletin de paie, à condition que la demande en soit dûment déposée – et renouvelée le cas échéant – sur le présent portail.
                </div>
                <div class="yellowColor bold mt-3">Comment ? </div>
                <div class="mt-1">Pour qu'EY puisse procéder au versement de cette quote-part Transports, je dépose mes demandes
                    <span class="yellowColor">régulièrement</span> sur ce Portail, en produisant les justificatifs requis.
                </div>
                <div>
                    <table class="mt-2 mb-2">
                        <tbody>
                            <tr>
                                <th class="cell-backgound">Durée de l’abonnement</th>
                                <th>Périodicité de dépôt de la demande</th>
                                <th colspan="2">Justificatifs à produire dans la demande</th>
                                <th>Commentaires</th>
                            </tr>
                            <tr>
                                <td class="cell-backgound">Infra-mensuel (coupon hebdo, etc.) *</td>
                                <td>Chaque mois</td>
                                <td colspan="2">Coupon d’achat</td>
                                <td rowspan="2">Lorsqu’aucune nouvelle demande n’est transmise, l’indemnité n’est pas automatiquement reconduite le mois suivant. Une demande ne peut être déposée que dans un délai maximum de 1 mois après le dernier jour de validité du
                                    coupon en question.</td>
                            </tr>
                            <tr>
                                <td class="cell-backgound">Mensuel</td>
                                <td>Chaque mois</td>
                                <td colspan="2">Coupon Mensuel ou Attestation de la régie de transports **</td>
                            </tr>
                            <tr>
                                <td class="cell-backgound">Pluri-mensuel</td>
                                <td>A chaque renouvellement de cet abonnement</td>
                                <td>Attestation de la régie de transports **</td>
                                <td>Attestation sur l’honneur (EY)</td>
                                <td rowspan="2">En cas de changement de situation et/ou de suspension des paiements prévus dans mon abonnement, je m’engage à avertir sans délai mon employeur, en modifiant la formule validée sur ce Portail. A défaut, je m’expose à la
                                    suspension de mon indemnité Transports et à la reprise des remboursements non justifiés sur ma Paie.</td>
                            </tr>
                            <tr>
                                <td class="cell-backgound">Annuel</td>
                                <td>Chaque année lors du renouvellement</td>
                                <td>Attestation de la régie de transports **</td>
                                <td>Attestation sur l’honneur (EY)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>(*) Les tickets unitaires ne sont pas acceptés</div>
                <div>(**) Document nominatif édité par la régie de transports, certifiant la durée de l’abonnement et l’échéancier
                </div>
                <div>des paiements à effectuer</div>
                <div class="yellowColor bold mt-3">Précisions importantes : </div>
                <ul class="squareList mt1">
                    <!-- <li>En cas de contrôle (URSSAF ou autre) EY peut être amené à contrôler les titres de transport qui vous ont
            été remboursés en paie. Dans ce cas, vous devez être en mesure de fournir tous les justificatifs concernés
          </li> -->
                    <li>Si vous devez prendre des titres de transport en complément pour vous rendre sur le lieu d'une mission professionnelle, il convient de vous les faire rembourser en notes de frais.
                    </li>
                    <li>Les personnes qui bénéficient d'une place de parking ne peuvent pas se faire rembourser leur titre de transport en paie.
                    </li>
                    <li>Si vous souhaitez bénéficier d’un autre mode de financement ‘Mobilités’ (abonnement transports en commun ou abonnement vélo public), alors que vous bénéficiez déjà d’une subvention vélo en cours, vous pouvez à tout moment mettre fin
                        à ces remboursements ; dans ce cas vous renoncerez définitivement aux mensualités non encore versées de votre subvention.
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>