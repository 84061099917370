import { MobilityRequestStatus } from "../enum/mobility-request-status.enum";
import { SubscriptionFormula } from "./subscription-formula";
import User from "./user";

export class RequestTransport {
  id!: number ;
  title!: string ;
  certificateFmd !: File ;
  controlerComment !: string ;
  requesterComment !: string ;
  statusDate !: Date ;
  validatedDate !: Date ;
  startDate !: Date ;
  endDate !: Date ;
  initialEndDate !: Date ;
  status !: MobilityRequestStatus ;
  requesterId !: string ;
  validatorId !: string ;
  validator !: User ;
  requester !: User ;
  requesterAddress!:string;
  subscriptionFormulas!: Array<SubscriptionFormula> ;
  created!:Date;
}
