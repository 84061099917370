import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BikeConditionPopupComponent } from './bike-condition-popup/bike-condition-popup.component';
import { ParkingConditionPopupComponent } from './parking-condition-popup/parking-condition-popup.component';
import { TransportConditionPopupComponent } from './transport-condition-popup/transport-condition-popup.component';
import { CalculateDurationPipe } from 'src/core/pipes/calculate-duration.pipe';
import { FormatMoneyDirective } from 'src/core/directives/format-money.directive';
import { MoneyFormatPipe } from 'src/core/pipes/money-format.pipe';
import { TypoAboTarifUnitairePipe } from 'src/core/pipes/typo-abo-tarif-unitaire.pipe';
import { PeoplePickerComponent } from './people-picker/people-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';


@NgModule({
  declarations: [
    PeoplePickerComponent,
    BikeConditionPopupComponent,
    ParkingConditionPopupComponent,
    TransportConditionPopupComponent,
    MoneyFormatPipe,
    FormatMoneyDirective,
    TypoAboTarifUnitairePipe,
    CalculateDurationPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule
  ],
  exports: [
    PeoplePickerComponent,
    BikeConditionPopupComponent,
    TransportConditionPopupComponent,
    ParkingConditionPopupComponent,
    MoneyFormatPipe,
    FormatMoneyDirective,
    TypoAboTarifUnitairePipe,
    CalculateDurationPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
