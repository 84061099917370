export enum AttachementType {
  Attachment,
  Attestation
}

export enum AttachementMode {
  Deleted,
  New
}

export class AttachementRequest {
  id: number | undefined;
  requestId: number | undefined;
  type: AttachementType | undefined | string;
  title: string | undefined = '';
  linkFilename: string | undefined = '';
  encodedAbsUrl: string | undefined;
  file: File | undefined;
  mode: AttachementMode | undefined | string;
  moisDebut: any | undefined;
}
