import {Routes} from '@angular/router';
import { UpdateBikeComponent } from './bike/update/update.component';
import { ViewBikeComponent } from './bike/view/view.component';
import {ListComponent} from './list/list.component';
import { UpdateParkingComponent } from './parking/update/update.component';
import { ViewParkingComponent } from './parking/view/view.component';
import { UpdateTransportComponent } from './transport/update/update.component';
import { ViewTransportAdminComponent } from './transport/view/view.component';
import { ViewComponent } from './bike-archive/view/view.component';
import { ViewTransportArchiveComponent } from './transport-archive/view/view.component';


const routes: Routes = [

  {
    path: 'mobility-request-admin/list',
    component: ListComponent,
  },
  {
    path: 'transport-admin/update/:id',
    component: UpdateTransportComponent,
  },
  {
    path: 'transport-admin/view/:id',
    component: ViewTransportAdminComponent,
  },
  {
    path: 'transport-admin-archive/view/:id',
    component: ViewTransportArchiveComponent,
  },
  {
    path: 'bike-admin/update/:id',
    component: UpdateBikeComponent,
  },
  {
    path: 'bike-admin/view/:id',
    component: ViewBikeComponent,
  },
  {
    path: 'bike-admin-archive/view/:id',
    component: ViewComponent,
  },
  {
    path: 'parking-admin/update/:id',
    component: UpdateParkingComponent,
  },
  {
    path: 'parking-admin/view/:id',
    component: ViewParkingComponent,
  },
];
export default routes;
