import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Providers } from '@microsoft/mgt-element';
import { Client } from '@microsoft/microsoft-graph-client';
@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.css'],
})
export class PeoplePickerComponent implements OnInit {
  graphClient!: Client;
  minLength: number = 3;
  @Input('class') class!: string;
  @Input('user') user!: any;
  @Input('disabled') disabled = false;
  @Output() selectUser: EventEmitter<any> = new EventEmitter();
  @Output() resetUser: EventEmitter<any> = new EventEmitter();
  users: any[] = [];
  constructor() {this.graphClient = Client.initWithMiddleware({
      authProvider: Providers.globalProvider,
    });
  }
  ngOnInit(): void {
    this.disabled = this.user != null;
  }
  filterUserSingle(event: any) {
    this.searchUser(event.target.value);
  }
  async searchUser(event: any) {
    if (event.length > this.minLength) {
      const resMail = await this.graphClient
        .api("/users?$filter=startswith(mail,'" + event + "')")
        .get();

      const resName = await this.graphClient
        .api('/users?$search="displayName:' + event + '"')
        .header('ConsistencyLevel', 'eventual')
        .count(true)
        .get();
    

      let finalRes: Array<any> = [];
      let m = resMail.value as Array<any>;
      let n = resName.value as Array<any>;
      
      for (let i = 0; i < m.length; i++) {
        let user: any = {};
        user.email = m[i].mail;
        user.displayName = m[i].displayName;
        user.rankDesc = m[i]?.jobTitle;
        user.id = m[i].id;
        if(m[i].jobTitle){
          finalRes.push(user);
        }
      }
      for (let i = 0; i < n.length; i++) {
        let user: any = {};
        user.email = n[i].mail;
        user.displayName = n[i].displayName;
        user.rankDesc = n[i]?.jobTitle;
        user.id = n[i].id;
        if(n[i].jobTitle){
          finalRes.push(user);
        }
      }
      finalRes = finalRes.filter(
        (elem, index, self) =>
          self.findIndex((t) => {

            return (
              t.email === elem.email &&
              t.displayName === elem.displayName &&
              t.rankDesc === elem.rankDesc
            );
          }) === index
      );
      this.users = finalRes;
    }
  }
  resetPeoplePicker() {
    this.user = null;
    this.disabled = false;
    this.resetUser.emit();
  }
  setUser(user: any) {
    this.user = user;
    this.disabled = true;
    this.selectUser.emit(user);
  }
  
}
