import { Component, OnInit } from '@angular/core';
import { QueryBuilder } from 'odata-query-builder';
import { Canstants } from 'src/core/canstants/canstants';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  usefulLinks: Array<any> = [];
  LastInfos: Array<any> = [];
  isScroll = false;
  canAddRequestParking = false;
  siteAssets: string | undefined;
  user: any;
  ShowEspacesAdmin!:boolean;
  constant = Canstants;
  constructor(
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getConnectedUser();
    this.checkPermission();
    this.loadUsefulLinks();
    this.loadLastInformation();
  }
  get environement(): typeof environment {
    return environment;
  }
  checkPermission():void{
    const rolesToCheck = [
      Canstants.ROLE_VALIDATOR,
      Canstants.ROLE_VALIDATOR_TRANSPORT,
      Canstants.ROLE_VALIDATOR_VELO,
      Canstants.ROLE_VALIDATOR_PARKING,
      Canstants.ROLE_SUPER_ADMIN
  ];
    this.ShowEspacesAdmin = this.user?.roles?.some((role:any) => rolesToCheck.includes(role));
  }
  loadUsefulLinks(): void {
    this.httpService.findAll('UsefulLinks').subscribe((usefulLinks) => {
      let usefulLinkss = usefulLinks.items.sort((a: any, b: any) => {
        if (a.ordre < b.ordre) {
          return -1;
        }
        if (a.ordre > b.ordre) {
          return 1;
        }
        return 0;
      });
      this.usefulLinks = usefulLinkss;
    });
  }
  loadLastInformation(): void {
    this.httpService.findAll('LastInformations').subscribe((lastInfos) => {
      this.LastInfos = lastInfos.items;
    });
  }
}
