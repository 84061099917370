<div class="row">
  <div class="col-md-12">
    <div class="banner-admin">
      <div class="col-md-12 padding-left">
        <span class="ArrowVelo"
          ><a routerLink="/"
            ><img
              alt=""
              [src]="
                environement.baseUrl + 'assets/images/Arrow.svg'
              " /></a></span
        ><span class="bold TitleVelo">Espace Admin - Archivage </span>
        <div>
          <img
            alt=""
            class="DividerVelo"
            [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
          />
        </div>
      </div>
    </div>
  </div>
</div>


<div class="spacer-box">
  <div class="pt-1">
    <form
      [formGroup]="archiveForm"
      class="form-inline mt-4"
    >
      <div class="mr-3 mb-3">
        <label class="justify-content-start"
          >Date du dernier mois remboursé</label
        >
        <input
        matInput
        class="form-control date-input"
        [matDatepicker]="picker"
        [max]="maxDate"
        formControlName="dateArchivage"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      </div>
      <div class="ml-4 mr-3">
        <label class="justify-content-start">Type de demande</label>

        <mat-form-field class="date-input">
          <mat-select
          formControlName="typeArchivage"
          >
            <mat-option [value]="item.id" *ngFor="let item of ArchiveTypeEnum">
              {{ item.name }}
            </mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mt-2 ml-4 col-md-2 contianer-search">
        <button
        type="button"
        class="btn btn-filter mb-2 mr-3 ml-2"
        [disabled]="!archiveForm.valid"
        (click)="launchArchive()"
        >
        Lancer l'archivage
      </button>

      </div>
    </form>
  </div>

  <hr class="hr"  *ngIf="isBikeDataAvailable||isTransportDataAvailable" />

  <div *ngIf="isBikeDataAvailable||isTransportDataAvailable">
    <button
    type="button"
    class="btn btn-primary float-right ml-2"
    (click)="confirmArchive()"
    >
    Confirmer L'archivage
  </button>
  <button
    type="button"
    class="btn btn-secondary float-right"
    (click)="cancelArchive()"
  >
    Annuler l'archivage
  </button>
  </div>

  <br>

  <div [ngStyle]="{'display': isBikeDataAvailable || isTransportDataAvailable ? 'block' : 'none'}">
    <mat-tab-group class="admin-tabs">
        <mat-tab *ngIf="isTransportDataAvailable">
            <ng-template mat-tab-label >
                <span (click)="getTransportData()">Indemnité Transports</span>
            </ng-template>
            <br >
            <app-list-transport [transportData]="isTransportData"
            [isArchive]="true"
            (dataAvailable)="handleTransportDataAvailability($event)"
          ></app-list-transport>
        </mat-tab>

        <mat-tab *ngIf="isBikeDataAvailable">
          <ng-template mat-tab-label >
            <span (click)="getBikeData()">Subvention Vélo</span>
          </ng-template>
          <br >
          <app-list-bike  [bikeData]="isBikeData"
          [isArchive]="true"
          (dataAvailable)="handleBikeDataAvailability($event)"
          ></app-list-bike>
        </mat-tab>
    </mat-tab-group>
</div>

  <!-- modal confirmation -->
<span
#openModalValidation
data-target="#valider-form"
data-toggle="modal"
></span>

  <div
  class="modal fade"
  id="valider-form"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalValiderForm"
  aria-hidden="true"
  >
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title">Archivage lancé !</h5>
      </div>
      <div class="modal-body">
        Votre demande d'archivage est lancée , veuiller confirmer votre archivage !
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reloadPage()">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
<!-- modal confirmation -->

<!-- modal done -->
<span
#openModalConfirmation
data-target="#open-confirmation"
data-toggle="modal"
></span>
<div
class="modal fade"
tabindex="-1"
role="dialog"
id="open-confirmation"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title" id="exampleModalLabel">Archivage effectué </h5>
    </div>
    <div class="modal-body">
      Vos demandes ont été archivées avec succès
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reloadPage()">
        Ok
      </button>
    </div>
  </div>
</div>
</div>
<!-- modal done -->


<!-- modal CANCEL -->
<span
#openModalCancellation
data-target="#open-cancellation"
data-toggle="modal"
></span>
<div
class="modal fade"
tabindex="-1"
role="dialog"
id="open-cancellation"
aria-hidden="true"
>
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="modal-title" id="exampleModalLabel">Archivage Annulé </h5>
    </div>
    <div class="modal-body">
      Annulation d'archivage effectuée avec succès
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reloadPage()">
        Ok
      </button>
    </div>
  </div>
</div>
</div>
<!-- modal done -->

</div>
