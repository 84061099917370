import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateDuration'
})
export class CalculateDurationPipe implements PipeTransform {
  transform(startDate: Date, endDate: Date): number {
    const startMonth = new Date(startDate);
    const endMonth = new Date(endDate);
    let months;
    months = (endMonth.getFullYear() - startMonth.getFullYear()) * 12;
    months -= startMonth.getMonth();
    months += endMonth.getMonth();
    return months === 0 ? 1 : months + 1;
  }
}
