<div class="row">
  <div class="col-md-12">
    <div class="BannerContainer">
      <img
        alt=""
        class="imageBanner"
        [src]="environement.baseUrl + 'assets/images/velo.jpg'"
      />
    </div>
    <div class="row BannerGradient2 padding-left">
      <div class="col-md-6">
        <div class="col-md-12 margin-top">
          <span class="ArrowVelo"
            ><a routerLink="/mobility-request-admin/list"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleVelo">Demande détaillée </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="divider2"></div>
        <div class="col-md-12 margin-top">
          <span class="bold Title2Velo semi-bold"
            >Subvention<span class="bold">Vélo</span>
          </span>
        </div>
        <div class="divider3"></div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-bike-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-right float-right box-requester-admin">
          <span class="bold yellowColor requester-name">
            {{ requestBike?.requester?.displayName }}
          </span>
          <span class="bold yellowColor requester-name">
            {{ requestBike?.requester?.gpn }}
          </span>
          <div *ngIf="requestBike?.status === mobilityRequestStatus.Assigned">
            <span class="text-secondary"
              >Adresse domicile saisie dans l'attestation
            </span>
            <span class="bold yellowColor">{{
              requestBike.requesterAddress
            }}</span>
          </div>
          <div
            *ngIf="
              requestBike?.status === mobilityRequestStatus.Assigned &&
              requestBike?.validator?.email == connectedUser?.email &&
              personalAddress
            "
          >
            <span class="text-secondary"
              >Adresse domicile issue des systèmes RH
            </span>
            <span class="bold yellowColor">{{ personalAddress }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="SpacerBox margin-top">
  <div class="row">
    <div class="divider"></div>
    <div class="mb-3 col-md-6 bold yellowColor titleFormule">
      <div class="squarePuce"></div>
      Prise d'effet
    </div>
    <div class="col-md-6 bold yellowColor titleFormule flex-box">
      <div class="squarePuce"></div>
      Pièces justificatives
    </div>
  </div>
  <div class="row mt-2">
    <div class="mb-3 col-md-6 bold">
      <div class="row">
        <div class="col-md-4 pt-2">
          Mois ciblé pour le début des remboursements
        </div>
        <div class="col-md-4">
          <span class="date-box">{{
            requestBike?.startDate | date : "LLLL yyyy"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6 bold flex-box">
      <div class="piecesContainer">
        <div class="piecesBoxGray">
          <img
            alt=""
            class="imgBox"
            [src]="environement.baseUrl + 'assets/images/Group.svg'"
          />
        </div>
        <!-- <img alt="" class="TrashImg" [src]="siteAssets+'assets/images/Group9828.svg'"> -->
        <a (click)="openFile(requestBike?.certificateFMD)" class="link">
          <span class="piecesTxt">Attestation de prise en charge FMD</span>
        </a>
      </div>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-md-6 bold flex-box">
      <div class="piecesContainer mt-2">
        <div class="piecesBoxGray">
          <img
            alt=""
            class="imgBox"
            [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
          />
        </div>

        <a
          (click)="openFile(requestBike?.proofOfPurchase)"
          target="_blank"
          class="link"
        >
          <span class="piecesTxt">Justificatif d'achat obligatoire</span>
        </a>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="mb-3 col-md-6 bold yellowColor titleFormule mt-2">
      <div class="squarePuce"></div>
      Informations sur l'achat
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-6 flex-box">
      <div class="col-md-4 pt-2">
        Montant total des achats éligibles au forfait
      </div>
      <div class="col-md-7 form-group">
        <span class="date-box text-flex-right">{{
          requestBike?.purchaseAmount?.toString() | moneyFormat
        }}</span>
        <span class="euro">€</span>
      </div>
    </div>
    <div class="col-md-6 flex-box">
      <div class="col-md-3 pt-2">Date de l'achat/facture</div>
      <div class="col-md-7">
        <span class="date-box">{{
          requestBike?.purchaseDate | date : "LLLL yyyy"
        }}</span>
      </div>
    </div>
  </div>
  <form class="form" [formGroup]="adminViewForm">
    <div class="row velo-electrique mt-2">
      <div class="mr-4">Vélo à assistance électrique (VAE)</div>
      <div class="">
        <input
          [disabled]="requestBike?.status == mobilityRequestStatus.Submitted"
          [checked]="requestBike?.vae"
          formControlName="vae"
          type="checkbox"
          class="form-control"
          (change)="changeVae($event)"
          
        />
      </div>
    </div>
    <hr class="hr mt-1" />
    <div class="row mb-3 float-right flex-column">
      <div class="velo-request">
        <div class="velo-request">
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Submitted"
            >Statut: Envoyée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Assigned"
            >Statut: En cours d'examen</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Returned"
            >Statut: A compléter par l’émetteur</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Canceled"
            >Statut: Annulée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Rejected"
            >Statut: Rejetée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestBike?.status == mobilityRequestStatus.Validated"
            >Statut: Validée</span
          >
        </div>
      </div>
      <div
        class="descriptionFormule mt-3"
        *ngIf="requestBike?.status == mobilityRequestStatus.Assigned"
      >
        <!-- <div class="">En charge de votre demande:</div>
      <div class="">
        <a
          class="piece-justif-box"
          [href]="'mailto:' + requestBike?.validator?.email"
          >{{ requestBike?.validator?.displayName }}</a
        >
      </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 row">
        <div class="bold yellowColor titleFormule mb-2 col-md-12 row">
          <div class="squarePuce"></div>
          Prise en charge EY
        </div>
        <div class="row">
          <div class="col-md-7 pt-2">Montant total subventionné par EY</div>
          <div class="col-md-4 form-group">
            <span class="date-box text-flex-right">{{
              requestBike?.subsidizedAmount?.toString() | moneyFormat
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
        <hr class="hr2 mt-3 mb-3" />
        <div class="row">
          <div class="col-md-1 pt-2">Soit</div>
          <div class="col-md-2 form-group">
            <span class="date-box text-center">{{
              requestBike?.numberFullMonths
            }}</span>
          </div>
          <div class="col-md-2 pt-2">Mensualité(s) de</div>
          <div class="col-md-2 form-group">
            <span class="date-box text-flex-right">{{
              requestBike?.applicableMonthlyPayment?.toString() | moneyFormat
            }}</span>
            <span class="euro">€</span>
          </div>
          <div class="col-md-2 pt-2">et 1 dernière de</div>
          <div class="col-md-2 form-group">
            <span class="date-box text-flex-right">{{
              requestBike?.lastMonthAmount?.toString() | moneyFormat
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
        <hr class="hr2 mt-3 mb-3" />
        <div class="row">
          <div class="col-md-4 pt-2">versées sur solde entre</div>
          <div class="col-md-3 form-group">
            <span class="date-box text-center">{{
              requestBike?.startDate | date : "LLLL yyyy"
            }}</span>
          </div>
          <div class="col-md-2 pt-2">et</div>
          <div class="col-md-3 form-group">
            <span class="date-box text-center">{{
              requestBike?.endDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="requester-box mb-3">
          <span class="mr-2">Commentaire demandeur</span>
          <span class="comment-box" *ngIf="requestBike?.requesterComment">{{
            requestBike?.requesterComment
          }}</span>
        </div>
        <div class="">
          <div class="">
            <div class="mb-2">Commentaire valideur</div>
            <div class="col-md-12">
              <textarea
                rows="5"
                cols="50"
                matInput
                id="requester-comment"
                class="form-control controle-comment"
                formControlName="controleComment"
                [readonly]="
                  requestBike?.status == mobilityRequestStatus.Assigned
                    ? null
                    : ''
                "
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="box-action-admin">
    <button
      *ngIf="
        requestBike?.status == mobilityRequestStatus.Submitted ||
        (requestBike?.status == mobilityRequestStatus.Assigned &&
          requestBike?.validator?.email != connectedUser?.email)
      "
      type="button"
      class="btn btn-take-request mt-3"
      (click)="takeRequest()"
    >
      Prendre en charge
    </button>
    <button
      *ngIf="
        requestBike?.status == mobilityRequestStatus.Submitted ||
        (requestBike?.status == mobilityRequestStatus.Assigned &&
          requestBike?.validator?.email != connectedUser?.email)
      "
      type="button"
      class="btn btn-close-request mt-3"
      [routerLink]="['/mobility-request-admin/list']"
    >
      Fermer
    </button>
    <button
      [routerLink]="['/mobility-request-admin/list']"
      *ngIf="
        requestBike?.status == mobilityRequestStatus.Rejected ||
        requestBike?.status == mobilityRequestStatus.Canceled ||
        requestBike?.status == mobilityRequestStatus.Validated ||
        (requestBike?.status == mobilityRequestStatus.Returned &&
          requestBike?.validator?.email == connectedUser?.email)
      "
      type="button"
      class="btn btn-close-request mt-3"
    >
      Fermer
    </button>
    <button
      *ngIf="
        requestBike?.status == mobilityRequestStatus.Validated && toBeCancled
      "
      type="button"
      class="btn btn-take-request mt-3 ml-3"
      data-toggle="modal"
      data-target="#annuler-request"
    >
      Annuler validation
    </button>
    <div
      *ngIf="
        requestBike?.status == mobilityRequestStatus.Assigned &&
        requestBike?.validator?.email == connectedUser?.email
      "
    >
      <button
        type="button"
        class="btn btn-validate mt-3"
        data-toggle="modal"
        data-target="#valider-form"
      >
        Valider
      </button>
      <button type="button" class="btn btn-update mt-3" (click)="update()">
        Modifier
      </button>
      <span
        #openUpdatePopup
        data-toggle="modal"
        data-target="#open-update-popup"
      ></span>
      <button type="button" class="btn btn-return mt-3" (click)="returned()">
        Retourner
      </button>
      <button type="button" class="btn btn-reject mt-3" (click)="reject()">
        Rejeter
      </button>
      <button type="button" class="btn btn-cancel mt-3" (click)="annuler()">
        Annuler
      </button>
      <button
        type="button"
        class="btn btn-close-request mt-3"
        (click)="fermer()"
      >
        Fermer
      </button>
    </div>
  </div>
  <div
    class="modal fade"
    id="open-update-popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Date limite de validation dépassée
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Attention : la date limite de validation est dépassée pour une
          subvention démarrant au mois ciblé par le demandeur, vous devez
          d'abord modifier le mois de prise d'effet pour donner suite à cette
          demande.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            [routerLink]="['/bike-admin/update', requestBike?.id]"
            data-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal annuler demande valider  -->
  <div
    class="modal fade"
    id="annuler-request"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalValiderForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Annuler la demande</h5>
        </div>
        <div class="modal-body">
          Êtes-vous sûr de vouloir annuler la demande ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="cancelRequest()"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="valider-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalValiderForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Valider la demande</h5>
        </div>
        <div class="modal-body">
          Êtes-vous sûr de vouloir valider la demande ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="validate()"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-bike-condition-popup></app-bike-condition-popup>
</div>
