import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Msal2Provider, Providers } from '@microsoft/mgt';
import { HttpInterceptorService } from 'src/core/interceptors/http-interceptor.service';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BikeModule } from './mobility-request-admin/bike/bike.module';
import { MobilityRequestAdminModule } from './mobility-request-admin/mobility-request-admin.module';
import { ParkingModule } from './mobility-request-admin/parking/parking.module';
import { TransportModule } from './mobility-request-admin/transport/transport.module';
import { MobilityRequestPaieModule } from './mobility-request-paie/mobility-request-paie.module';
import { MobilityRequestUserModule } from './mobility-request-user/mobility-request-user.module';
import { NoAccessComponent } from './no-access/no-access.component';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './template/template.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LOCALE_ID } from '@angular/core';
import {registerLocaleData} from "@angular/common"
import localeFr from "@angular/common/locales/fr";
import { SuperAdminModule } from './super-admin/super-admin.module';
registerLocaleData(localeFr);
import { NgxMaskModule,IConfig } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';

const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: ' ',
  decimalMarker: ',',
};

@NgModule({
  declarations: [AppComponent, HomeComponent, NoAccessComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TemplateModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    MobilityRequestAdminModule,
    MobilityRequestPaieModule,
    MobilityRequestUserModule,
    SuperAdminModule,
    TransportModule,
    ParkingModule,
    BikeModule,
    NgxMaskModule.forRoot(maskConfig),
    MatDialogModule

  ],
  providers: [
    Location,
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthenticationService],
      useFactory: signinIfNotSignedFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
export function signinIfNotSignedFactory(authService: AuthenticationService) {
  Providers.globalProvider = new Msal2Provider(environment.OAuthSettings);
  return () =>
    new Promise<any>(async (reslove: any) => {
      await authService.loadCurrentUser();
      reslove();
    });
}
