<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img alt="" class="imageBanner" [src]="environement.baseUrl + 'assets/images/transport.jpg'" />
      </div>
      <div class="row BannerGradient1 padding-left">
        <div class="col-md-6">
          <div class="col-md-12 margin-top mb-3">
            <span class="ArrowVelo"
              ><a routerLink="/mobility-request/list"
                ><img alt="" [src]="environement.baseUrl + 'assets/images/Arrow.svg'" /></a></span
            ><span class="bold TitleVelo"> Demande détaillée </span>
            <div>
              <img
                alt=""
                class="DividerVelo"
                [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
              />
            </div>
          </div>
          <div class="col-md-12 margin-top mt-4">
            <span class="bold Title2Velo semi-bold"
              >Indemnité <span class="bold">Transports</span></span
            >
          </div>

          <div class="col-md-12 bannerDescription">
            <p class="see-condition">
              <a
                href="#"
                class="text-secondary"
                data-toggle="modal"
                data-target="#view-transport-condition"
                >Voir les conditions</a
              >
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right float-right box-requester-admin">
            <span class="bold yellowColor requester-name">
              {{ pivotMonth }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="SpacerBox margin-top">
  <div class="bold yellowColor titleFormule">
    <div class="squarePuce"></div>
    Période de remboursement
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="transport-box mt-3">
        <div class="">A partir du mois suivant</div>
        <div class="form-group">
          <!-- start month -->
          <span class="ml-4 date-box text-center">{{
            requestTransport?.startDate | date : "LLLL yyyy"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="transport-box mt-3">
        <div class="">Jusqu’au mois suivant</div>
        <div class="form-group">
          <span class="ml-4 date-box text-center">{{
            requestTransport?.endDate | date : "LLLL yyyy"
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div *ngIf="attestationFile?.encodedAbsUrl" class="piecesContainer mt-3">
        <div class="piecesBoxGray">
          <img alt="" class="imgBox" [src]="environement.baseUrl + 'assets/images/Group.svg'" />
        </div>
        <!-- <img alt="" class="TrashImg" [src]="environement.baseUrl + 'assets/images/Group9828.svg"> -->
        <a (click)="openFile(requestTransport?.certificateFmd?.toString())" class="link piecesTxt"
          >Attestation pour reconduction</a
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 bold yellowColor titleFormule mt-2">
      <div class="squarePuce"></div>
      Formule d'abonnement
    </div>
  </div>
  <!-- debut subcription formula  -->
  <div
    *ngFor="
      let SubscriptionFormula of requestTransport?.subscriptionFormulas;
      let i = index
    "
  >
    <div class="row flex-center">
      <div class="col-md-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">Formule d'abonnement</div>
          <div class="form-group col-md-8">
            <span class="intitule-box">{{
              SubscriptionFormula.subscription.title
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">
            <span
              class="descriptionxt"
              *ngIf="
                SubscriptionFormula.subscription?.formulaType ==
                enumSubscriptionType.Annuel
              "
              >Tarif annuel</span
            >
            <span
              class="descriptionxt"
              *ngIf="
                SubscriptionFormula.subscription?.formulaType ==
                enumSubscriptionType.Mensuel
              "
              >Tarif mensuel</span
            >
            <span
              class="descriptionxt"
              *ngIf="
                SubscriptionFormula.subscription?.formulaType ==
                enumSubscriptionType.InfraMensuel
              "
              >Tarif unitaire</span
            >
            <span
              class="descriptionxt"
              *ngIf="
                SubscriptionFormula.subscription?.formulaType ==
                enumSubscriptionType.Misc
              "
              >Tarif réglé pour le mois</span
            >
          </div>
          <div class="form-group col-md-8">
            <span class="date-box text-flex-right">{{
              getForumlaValue(SubscriptionFormula)?.fees
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="piecesContainer mt-2 mb-2">
          <div class="piecesBoxGray">
            <img alt="" class="imgBox" [src]="environement.baseUrl + 'assets/images/Line.svg'" />
            <img alt="" class="imgBox" [src]="environement.baseUrl + 'assets/images/Arrow6.svg'" />
          </div>
          <a (click)="openFile(SubscriptionFormula?.attachement?.toString())" class="link">
            <span class="piecesTxt"
              >Mon justificatif d'abonnement {{ i + 1 }}</span
            >
          </a>
          <!-- <a class="link" [href]="SubscriptionFormula?.attachement" target="_blank">
              <span class="piecesTxt">Mon justificatif d'abonnement {{i+1}}</span>
            </a> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div
          class="row d-flex align-items-center"
          *ngIf="SubscriptionFormula.quantity"
        >
          <div class="col-md-4">Quantité</div>
          <div class="col-md-8 form-group">
            <span class="intitule-box">{{ SubscriptionFormula.quantity }}</span>
          </div>
        </div>
        <div
          class="row d-flex align-items-center"
          *ngIf="SubscriptionFormula.entitledMisc"
        >
          <div class="col-md-4">Désignation de votre formule « Autre »</div>
          <div class="col-md-8 form-group">
            <span class="intitule-box">{{
              SubscriptionFormula.entitledMisc
            }}</span>
          </div>
        </div>
        <div
          class="col-md-4"
          *ngIf="
            !SubscriptionFormula.quantity &&
            !SubscriptionFormula.entitledMisc &&
            requestTransport?.status == mobilityRequestStatus.Validated
          "
        ></div>
      </div>
      <div
        class="col-md-4"
        *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
      >
        <div class="row d-flex align-items-center">
          <div class="col-md-4">Indemnité mensuelle applicable</div>
          <div class="col-md-8 form-group">
            <span class="date-box text-flex-right">{{
              getForumlaValue(SubscriptionFormula)?.refundRate
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 hr2" />
  </div>
  <div
    class="row mt-2"
    *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
  >
    <div class="col-md-4 bold yellowColor titleFormule mt-2">
      <div class="squarePuce"></div>
      Remboursement applicable
    </div>
    <div class="col-md-4">
      <div class="row d-flex align-items-center">
        <div class="col-md-4">Total versé mensuellement</div>
        <div class="col-md-8 form-group">
          <span class="date-box text-flex-right">{{
            calculateIndemTotal()
          }}</span>
          <span class="euro">€</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-row-reverse mt-2">
    <div>
      <!-- <div class="transport-request">
        Statut: {{ requestTransport?.status }}
      </div> -->
      <div class="velo-request">
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Submitted"
          >Statut: Envoyée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
          >Statut: En cours d'examen</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Returned"
          >Statut: A compléter par l’émetteur</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Canceled"
          >Statut: Annulée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Rejected"
          >Statut: Rejetée</span
        >
        <span
          class="en-cours bold"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
          >Statut: Validée</span
        >
      </div>
      <div
        class="descriptionFormule mt-2"
        *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
      >
        <!-- <div class="">En charge de votre demande:</div>
        <div class="">
          <a
            class="piece-justif-box"
            [href]="'mailto:' + requestTransport?.validator?.email"
            >{{ requestTransport?.validator?.displayName }}</a
          >
        </div> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="FormuleComment">
        <div class="descriptionxt">Commentaire demandeur</div>
        <div class="form-group">
          <span
            class="comment-box"
            *ngIf="requestTransport?.requesterComment"
            >{{ requestTransport?.requesterComment }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="FormuleComment">
        <div class="descriptionxt">Commentaire valideur</div>
        <div class="form-group">
          <span
            class="comment-box"
            *ngIf="requestTransport?.controlerComment"
            >{{ requestTransport?.controlerComment }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-md-4 mb-2 flex-column mt-3">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#annuler-demande"
        >
          Mettre fin à cette formule
        </button>
        <br />
        <button
          type="button"
          class="btn btn-close-request mt-3"
          routerLink="/mobility-request/list"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="annuler-demande"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Mettre fin à cette formule
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Attention :
          <br />
          Vous vous apprêtez à mettre fin au remboursement de l’Indemnité
          Transports dont vous bénéficiez actuellement, et cela à compter de
          {{ pivotMonth }}.
          <br />
          Si vous confirmez cette action, l’ensemble des remboursements
          ultérieurs qui seraient également planifiés au titre de cette même
          indemnité seront également annulés.
          <br />
          En revanche vous pourrez ainsi prétendre à d’autres formules de
          soutien Mobilités à compter de
          {{ pivotMonth }}.
          <br />
          Confirmez-vous mettre fin à cette Indemnité Transports à compter du
          mois de septembre
          {{ pivotMonth }} ?
          <br />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="annulerRequest()"
            data-dismiss="modal"
          >
            Oui
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            routerLink="/mobility-request/list"
          >
            Non
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-transport-condition-popup></app-transport-condition-popup>
</div>
