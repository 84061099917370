<div class="row">
  <div class="col-md-12">
    <div class="banner-admin">
      <div class="col-md-12 padding-left">
        <span class="ArrowVelo"
          ><a routerLink="/"
            ><img
              alt=""
              [src]="
                environement.baseUrl + 'assets/images/Arrow.svg'
              " /></a></span
        ><span class="bold TitleVelo">Espace Admin - Extraction </span>
        <div>
          <img
            alt=""
            class="DividerVelo"
            [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spacer-box">
  <div>
    <form
      [formGroup]="listRequests"
      (ngSubmit)="getListRequests()"
      class="form-inline"
    >
      <div class="mr-3 mb-3">
        <label class="justify-content-start"
          >Sélectionner un Mois de paie</label
        >
        <select
          class="mt-2 select-engagement form-control"
          (change)="getSelectedMonth(listRequests.value)"
          formControlName="date"
        >
          <option value="GPN">Choisir une date</option>
          <option [value]="item.numberValue" *ngFor="let item of listMonth">
            {{ item.description }}
          </option>
        </select>
      </div>
      <div class="ml-4 mr-3 mb-3">
        <label class="justify-content-start">Type d'extraction Paie</label>
        <select
          class="mt-2 select-type form-control"
          formControlName="requestType"
        >
          <option
            [value]="
              mobilityRequestType[mobilityRequestType.trsSelected].toString()
            "
          >
            Transports mois courant
          </option>
          <option
            [value]="
              mobilityRequestType[mobilityRequestType.trsAnterieur].toString()
            "
          >
            Transports mois antérieurs
          </option>
          <option
            [value]="
              mobilityRequestType[mobilityRequestType.cycling].toString()
            "
          >
            Subvention Vélo
          </option>
        </select>
      </div>

      <div class="mt-3 ml-4 col-md-2 contianer-search">
        <button type="submit" class="btn btn-primary btn-search">
          Rechercher
        </button>
      </div>
    </form>
  </div>

  <hr class="hr" />
  <div *ngIf="ExtractRequest.length > 0">
    <button
      type="button"
      class="btn btn-primary float-right"
      (click)="getExcelFile()"
    >
      Export Excel
    </button>
  </div>
  <span
        #openExtractPopup
        data-toggle="modal"
        data-target="#open-extract-popup"
      ></span>
  <table
    matSort
    mat-table
    [dataSource]="dataSource"
    *ngIf="ExtractRequest.length > 0"
  >
    <ng-container matColumnDef="LPN">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>LPN</th>
      <td mat-cell *matCellDef="let element">{{ element.lpn }}</td>
    </ng-container>

    <ng-container matColumnDef="Nom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element">{{ element.nom }}</td>
    </ng-container>

    <ng-container matColumnDef="MoisImputation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Mois imputation paie
      </th>
      <td mat-cell *matCellDef="let element">{{ element.moisImputation }}</td>
    </ng-container>

    <ng-container matColumnDef="sens">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sens</th>
      <td mat-cell *matCellDef="let element">{{ element.sens }}</td>
    </ng-container>

    <ng-container matColumnDef="MontantImputable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Montant imputable paie
      </th>
      <td mat-cell *matCellDef="let element">{{ element.montantImputable.toString() | moneyFormat }}</td>
    </ng-container>

    <ng-container matColumnDef="MoisReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Mois de référence
      </th>
      <td mat-cell *matCellDef="let element">{{ element.moisReference }}</td>
    </ng-container>

    <ng-container matColumnDef="RefDossier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Ref dossier Mobilité
      </th>
      <td mat-cell *matCellDef="let element">{{ element.refDossier }}</td>
    </ng-container>

    <ng-container matColumnDef="DateExtraction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date extraction</th>
      <td mat-cell *matCellDef="let element">{{ element.dateExtraction }}</td>
    </ng-container>

    <ng-container matColumnDef="FormuleMobilite">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Formule mobilité
      </th>
      <td mat-cell *matCellDef="let element">{{ element.formuleMobilite }}</td>
    </ng-container>

    <ng-container matColumnDef="TypeOperation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type opération</th>
      <td mat-cell *matCellDef="let element">{{ element.typeOperation }}</td>
    </ng-container>

   

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    class="request-paginator"
    #paginator
    (page)="onPageChange($event)"
    *ngIf="ExtractRequest.length > 0"
    [length]="ExtractRequest.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 30]"
    >
  </mat-paginator>
  <!-- [pageIndex]="currentPage-1" -->

  <div
    class="modal fade"
    id="open-extract-popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Attention - Validation des demandes encore possible
          </h5>
          
        </div>
        <div class="modal-body">
          <p>La date limite de validation n'a pas encore été atteinte. Les administrateurs peuvent toujours valider les demandes de remboursement pour le mois en cours.</p>
          <p>Êtes-vous sûr de vouloir exporter la liste des remboursements validés avant la date limite de validation ?</p>
        </div>
        <div class="modal-footer">
          <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="getExcelFileSameMonth()"
        >
          Confirmer
        </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
