import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { FilterPermission } from 'src/core/models/filter-permission';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-parking',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateParkingComponent implements OnInit {
  dateDisabled = false;
  connectedUser: User | undefined;
  user: any;
  filterOptions = { email: '' , 
  startDate :'',
  cardNumber : ''
};
  listStartMonth: Array<any> = [];
  checkAccessTransport = {
    access: true,
    message: '',
  };
  constructor(
    private router: Router,
    private dateService: DateService,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
  }

  
  get environement(): typeof environment {
    return environment;
  }
 
  addParkingRequest(){
   
    let payload: any = {
      CardNumber: this.filterOptions.cardNumber,
      StartDate: this.filterOptions.startDate,
      Email: this.filterOptions.email
    };
    this.httpService.postFormData('ParkingRequests', payload).subscribe({
      next: (data) => {
        this.router.navigate(['/mobility-request-admin/list']);
      },
        error: (error) => {
          if (error.status === 500) {
            this.dialog.open(ErrorDialogComponent, {
              data: { message: 'Oups, problème système' }
            });
          } 
        },
    
    });
  }
  async checkEligibility(): Promise<any> {
    const mapping: any = [];

    this.httpService.findAll('ParkingRequests/CheckEligibility/'+this.filterOptions.email).subscribe({
      next: (months) => {
        let index = 0;
        months.availableMonths.forEach((element: any) => {
          mapping.push({
            value: moment(element).format('MMMM YYYY'),
            index,
            numberValue: moment(element).format('YYYY-MM'),
          });
          index++;
        });
        this.listStartMonth = mapping;
        // this.ineligibleDates = months.dates;
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.checkAccessTransport.access = false;
          this.checkAccessTransport.message = err.error.detail;
        }
      },
    });
  }

  resetUser() {
    this.user = null;
    this.filterOptions.email = '';
  }
  async selectUser(user: any): Promise<void> {
    this.user = user;
    this.filterOptions.email = user.email;
    await this.checkEligibility();
  }
}
