import { FormuleTypeEnum } from "../enum/formule-type-enum";
import { SubscriptionType } from "./subscription-type";

export class SubscriptionFormula {
  id!: number ;
  title!: string ;
  attachement!: string ;
  formulaCode!: string ;
  formulaDesignation!: string ;
  entitledMisc!: string ;
  startDate!: Date ;
  quantity!: number ;
  monthlyPayment!: number ;
  refundTotalApplicable!: number ;
  refundUnitApplicable!: number ;
  unitPrice!: number ;
  formulaType!: FormuleTypeEnum ;
  transportRequesId!: number ;
  subscriptionId!: number ;
  miscRefundRate?: number ;
  subscription !: SubscriptionType ;

}
