import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/shared/file.service';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatusArchive } from 'src/core/enum/mobility-request-status-archive.enum';
import { RequestTransport } from 'src/core/models/request-transport';
import { TRSDto } from 'src/core/models/TRSDto';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})

export class ViewTransportArchiveComponent implements OnInit {
  @ViewChild('openUpdatePopup')
  openUpdatePopup!: ElementRef;
  requestTransport!: any;
  connectedUser: User | undefined;
  constant = Canstants;
  adminViewForm!: FormGroup;
  attestationFile: any;
  peoplePlan: any;
  businessParam: any;
  personalAddress!: string;
  limiteValidTrs: any;
  totalVerseMensuellement!: string;
  transportRequestDto: TRSDto = new TRSDto();
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  changedFormula: Array<any> = [];
  indemInit: any = 0;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private route: Router,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private moneyService: MoneyService,
    private fileService: FileService
  ) {
    this.initializeForm();
  }
  get form(): any {
    return this.adminViewForm.controls;
  }
  get environement(): typeof environment {
    return environment;
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatusArchive {
    return MobilityRequestStatusArchive;
  }
  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    await this.loadTransportRequests();
    this.httpService.findAll('BusinessParams').subscribe((bp) => {
      let formattedMonth = moment().format('MM');
      let title = Canstants.limitValidTransportM +
        moment().format("yyyy") +
        formattedMonth;
      this.limiteValidTrs = bp.filter(
        (param: any) => param.title == title
      );
    });
  }
  async getRequesterAddress(): Promise<void> {
    this.httpService
      .findOneById('Users', this.requestTransport.requester.gpn)
      .subscribe((result: any) => {
        this.personalAddress = result.address;
      });
  }
  initializeForm(): void {
    this.adminViewForm = this.formBuilder.group({
      Formulas: this.formBuilder.array([]),
      controleComment: [''],
      requesterComment: [''],
    });
  }

  private pushFormLineSubscriptionFormula(): void {
    const Formulas = this.formBuilder.group({
      id: [''],
      FormulaId: [''],
      // FormulaType: [''],
      Price: [''],
      EntitledMisc: [''],
      Quantity: [''],
      File: [''],
      FileType: [''],
      MiscRefundRate: [''],
    });
    this.Formulas.push(Formulas);
  }

  private async loadTransportForm(result: RequestTransport): Promise<void> {
    this.adminViewForm.controls['requesterComment'].setValue(
      result.requesterComment
    );
    this.adminViewForm.controls['controleComment'].setValue(
      result.controlerComment
    );
    let index = 0;
    for (const formula of result.subscriptionFormulas) {
      this.changedFormula.push({
        id: formula.id,
        FormulaId: formula.subscriptionId,
        IdAttachment: formula.attachement,
        EntitledMisc: formula.entitledMisc,
      });
      // var isMisc=false;
      // if (formula.formulaType==3){
      //   isMisc=true;
      // }
      this.pushFormLineSubscriptionFormula();

      // isMisc=false;
      const subscriptionFormulaLine = this.Formulas.controls[
        index
      ] as FormGroup;

      subscriptionFormulaLine.controls['FormulaId'].setValue(
        formula.subscriptionId
      );
      // subscriptionFormulaLine.controls['FormulaType'].setValue(
      //   formula.formulaType
      // );

      subscriptionFormulaLine.controls['id'].setValue(formula.id);

      subscriptionFormulaLine.controls['Quantity'].setValue(formula.quantity);
      subscriptionFormulaLine.controls['EntitledMisc'].setValue(
        formula.entitledMisc
      );
      subscriptionFormulaLine.controls['File'].setValue(formula.attachement);

      let tarifName = '';
      let showQuantity = false;
      switch (formula.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            formula.subscription.subscriptionTypeRefund?.otherFees
          );
          tarifName = 'Tarif unitaire';
          showQuantity = true;
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(formula.monthlyPayment.toString(), '.')
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValue(
            formula.entitledMisc
          );
          subscriptionFormulaLine.controls['MiscRefundRate'].setValue(
            formula.miscRefundRate
          );
          subscriptionFormulaLine.controls['MiscRefundRate'].setValidators(
            Validators.required
          );

          tarifName = 'Tarif réglé pour le mois';
          break;
      }

      index++;
    }
  }

  get Formulas(): FormArray {
    return this.adminViewForm.controls['Formulas'] as FormArray;
  }

  set Formulas(formArray: FormArray) {
    this.adminViewForm.controls['Formulas'] = formArray;
  }

  async loadTransportRequests(): Promise<void> {
    const id =   this.activatedRoute.snapshot.params['id'];
    const startDate = new Date(this.activatedRoute.snapshot.params['date']);
    startDate.setUTCDate(startDate.getUTCDate() + 1);
    this.httpService
      .findOneByIdAndDate(
        'TransportRequests',id,new Date(startDate).toISOString()
      )
      .subscribe((result: any) => {
        this.requestTransport = result;
        this.getRequesterAddress();
        this.loadTransportForm(this.requestTransport);
        this.adminViewForm.controls['controleComment'].setValue(
          this.requestTransport.controlerComment
        );
      });
  }

  getForumlaValue(formula: any) {
    if (formula != undefined)
      switch (formula.formulaType) {
        case FormuleTypeEnum.Annuel:
          const refundA = formula?.subscription.subscriptionTypeRefund?.percent
            ? ((formula?.subscription.subscriptionTypeRefund?.annualFees / 12) *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
          return {
            fees: this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            ),
            refundRate: this.moneyService.format(refundA.toString(), '.'),
          };
        case FormuleTypeEnum.Mensuel:
          const refundM = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
          return {
            fees: this.moneyService.format(
              formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            ),
            refundRate: this.moneyService.format(refundM.toString(), '.'),
          };
        case FormuleTypeEnum.InfraMensuel:
          const refundI = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula.quantity *
                formula?.subscription.subscriptionTypeRefund?.otherFees *
                formula?.subscription.subscriptionTypeRefund?.unitRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.unitRefundRate;
          return {
            fees: this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.otherFees?.toString(),
              '.'
            ),
            refundRate: this.moneyService.format(refundI.toString(), '.'),
          };
        case FormuleTypeEnum.Misc:
          const miscRefund =
            formula.miscRefundRate != null
              ? this.moneyService.format(formula.miscRefundRate.toString(), '.')
              : this.moneyService.format(
                  (
                    (formula.monthlyPayment *
                      formula.subscription.subscriptionTypeRefund
                        .monthlyRefundRate) /
                    100
                  ).toString(),
                  '.'
                );
          return {
            fees: this.moneyService.format(
              formula.monthlyPayment.toString(),
              '.'
            ),
            refundRate: miscRefund,
          };
        default:
          return {
            fees: 0,
            refundRate: 0,
          };
      }
    else
      return {
        fees: 0,
        refundRate: 0,
      };
  }



  changeIndemniteMensuelleMisc(event: any, rowIndex: number): void {
    this.requestTransport.subscriptionFormulas[rowIndex].refundTotalApplicable =
      Number(event.target.value.replace(',', '.'));
    this.requestTransport.subscriptionFormulas[rowIndex].refundUnitApplicable =
      Number(event.target.value.replace(',', '.'));
    this.testFormat(event, rowIndex);
    this.indemInit = this.calculateIndemTotal();
  }

  testFormat(value: any, index: any) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.openModalNegativeNumber.nativeElement.click();
      subscriptionFormulaLine.controls['MiscRefundRate'].setValue('');
    }
  }
  calculateNewIndem() {
    let total: any = this.calculateIndemTotal();
    if (this.requestTransport.status != 4) {
      return this.moneyService.format(total,'.');
    }
    const myArray = this.adminViewForm.get('Formulas') as FormArray;
    for (let i = 0; i < myArray.length; i++) {
      const value = myArray.get(i.toString())?.value;
      if (value.MiscRefundRate != null && value.MiscRefundRate) {
        total = total + parseFloat(value.MiscRefundRate);
      }
    }
    this.indemInit = this.moneyService.format(total,'.');
    return this.moneyService.format(total,'.');
  }

  calculateIndemTotal(): string {
    let total = 0;
    if (this.requestTransport)
      for (const subscriptionFormula of this.requestTransport
        ?.subscriptionFormulas) {
        let formula: any = subscriptionFormula;

        switch (subscriptionFormula.formulaType) {
          case FormuleTypeEnum.Annuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(((formula?.subscription.subscriptionTypeRefund?.annualFees /
                  12) *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;
          case FormuleTypeEnum.Mensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;

          case FormuleTypeEnum.InfraMensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula.quantity *
                  formula?.subscription.subscriptionTypeRefund?.otherFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate || 0,'.');

            break;

          case FormuleTypeEnum.Misc:
            total =
              formula?.miscRefundRate == null
                ? total +
                  this.moneyService.formatNumber((formula.monthlyPayment *
                    formula.subscription.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,'.')
                : total + this.moneyService.formatNumber(formula.miscRefundRate || 0,'.');
            break;

          default:
            break;
        }
      }

    this.indemInit = this.moneyService.format(total,'.');
    // var totalString = total.toFixed(2);
    // totalString = totalString.replace('.', ',');
    return this.moneyService.format(total,'.');
  }











}
