import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appFormatMoney]'
})
export class FormatMoneyDirective {

  constructor(private el: ElementRef) {
  }


  @HostListener('change') onInputChange(): void {
    this.format(this.el.nativeElement.value);
  }

  private format(value: string): void {
    if (value) {
      const values = value.split(',');
      if (!values[1]) {
        this.el.nativeElement.value = value + ',00';
      } else {
        if (values[1].length === 1) { // exp : 120,1
          this.el.nativeElement.value = value + '0';
        }
        if (values[1].length === 2) {
          this.el.nativeElement.value = value;
        }
      }
    }
  }
}
