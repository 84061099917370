import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Providers } from '@microsoft/mgt';
import User from 'src/core/models/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  SignedIn!: boolean;
  isAutorised!: boolean;
  errorMsg !: string;
  isSmallScreen!: boolean;
  constructor(private route: Router) {}
  get environement(): typeof environment {
    return environment;
  }

  async ngOnInit(): Promise<void> {
    this.isSmallScreen = window.innerWidth <= 844;
    this.errorMsg = localStorage.getItem('errorMessage') || "";
    let connectedUser: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (connectedUser && connectedUser.roles.length != 0) {
      this.isAutorised = true;
    } else {
      this.isAutorised = false;
      this.errorMsg = "Vous n'avez pas le droit de vous connecter."
    }
    // end sign in
    if (Providers.globalProvider.state == 2) {
      this.SignedIn = true;
    } else {
      this.SignedIn = false;
    }
  }
  logoutCompleted() {
    this.SignedIn = false;
  }
  async loginCompleted() {
    this.SignedIn = true;
    // this.route.navigate(['/home']);
  }
}
