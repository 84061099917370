<mat-form-field [class]="class">
  <div class="row">
    <div class="col-1" *ngIf="user && user.picture">
      <input type="image" class="img-people-picker-selected"
             [matAutocomplete]="auto"
             [src]="user.picture">
    </div>
    <div class="col  ml-2">


      <input type="text"
             aria-label="Number"
             matInput
             [readonly]="disabled"
             [value]="user ? user.displayName : ''"
             [matAutocomplete]="auto"
             (keydown)="filterUserSingle($event)">

    </div>
    <div class="col-1 clear-btn">
      <button class="mat-btn-clear btn-clear-people-picker" mat-button *ngIf="user" matSuffix mat-icon-button
              aria-label="Clear" (click)="resetPeoplePicker()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>


  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let user of users"
                [value]="user.displayName"
                (onSelectionChange)="setUser(user)">
      <div class="row">
        <div class="col-12">
          <span class="display-name-people-picker">{{user.displayName}}</span>
          <br/>
          <span class="email-people-picker"> {{user.email}}</span>
          <br/>
          <span class="rank-desc-people-picker"> {{user.rankDesc}}</span>
        </div>
      </div>

    </mat-option>
  </mat-autocomplete>

</mat-form-field>


<!-- <p-autoComplete
  [(ngModel)]="selectedUser"
  [suggestions]="suggestUsers"
  (completeMethod)="searchUser($event)"
  (onSelect)="onSelect($event)"
  (onClear)="onClear()"
  field="displayName"
  [size]="50"
  placeholder="Trouver un utilisateur"
  [minLength]="minLength"
  [emptyMessage]="emptyMessage"
  [showTransitionOptions]="'300ms'"
  [hideTransitionOptions]="'100ms'"
>
  <ng-template let-selectedUser pTemplate="item">
    <div class="listPeoplePick">
      <div class="peopleProperties">
        <b>{{ selectedUser.displayName }}</b>
        <br />
        <div class="email-people-picker">{{ selectedUser.email }}</div>
        <div class="rank-desc-people-picker">{{ selectedUser.rankDesc }}</div>
      </div>
      <div class="clear-btn">
        <button
          class="mat-btn-clear"
          mat-button
          *ngIf="user"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="resetPeoplePicker()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </ng-template>
</p-autoComplete>
<div *ngIf="setPermission == true && choosenYet == true">
  <button (click)="submit()" class="btn btn-success peopleBtnAdd">
    <span class="pi pi-plus"></span> Ajouter
  </button>
</div> -->
