import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClientService } from 'src/core/services/http-client.service';
import { RequestTransport } from 'src/core/models/request-transport';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestion-archive',
  templateUrl: './gestion-archive.component.html',
  styleUrls: ['./gestion-archive.component.css'],
})
export class GestionArchiveComponent implements OnInit {
  ArchiveTypeEnum: any;
  maxDate!: Date;
  archiveForm!: FormGroup;
  isTransportData: boolean = true;
  isBikeData: boolean = false;
  archiveLaunchCommand: any = {
    StartDate: '',
    archiveType: '',
  };
  archiveRequestCommand: any = {
    archiveType: '',
  };
  total:number=0;
  archiveType="";
  isBikeDataAvailable = false;
  isTransportDataAvailable = false;



  @ViewChild('openModalCancellation')
  openModalCancellation!: ElementRef;
  @ViewChild('openModalConfirmation')
  openModalConfirmation!: ElementRef;
  @ViewChild('openModalValidation')
  openModalValidation!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpService: HttpClientService<any>
  ) {
    this.initializeForm();
  }

  get environement(): typeof environment {
    return environment;
  }

  get form(): any {
    return this.archiveForm.controls;
  }

  async ngOnInit() {
    this.loadTrasportData();
    this.loadBikeData();
    this.isTransportData=true;
    let maxDate = new Date();

    maxDate.setFullYear(maxDate.getFullYear() - 1);
    maxDate.setMonth(11); 
    maxDate.setDate(31); 
    this.maxDate = maxDate;

    this.ArchiveTypeEnum = [
      {
        id: '0',
        name: 'Demandes de subvention vélo',
      },
      {
        id: '1',
        name: "Demandes d'indemnité de transport",
      },
    ];
  }

  getTransportData(){
    this.isTransportData=true;

  }
  getBikeData(){
    this.isBikeData=true;
  }

  private initializeForm(): void {
    this.archiveForm = this.formBuilder.group({
      dateArchivage: ['', Validators.required],
      typeArchivage: ['', Validators.required],
    });
  }

  async launchArchive() {
    const startDate = new Date(this.archiveForm.value.dateArchivage);
    startDate.setUTCHours(0, 0, 0, 0);
    startDate.setUTCDate(startDate.getUTCDate() + 1);
    this.archiveLaunchCommand.StartDate = startDate.toISOString();
    this.archiveLaunchCommand.archiveType =this.archiveForm.value.typeArchivage;
    this.archiveType=this.archiveForm.value.typeArchivage;
    await this.httpService
      .postFormData('Archive/LaunchArchive', this.archiveLaunchCommand)
      .subscribe((data) => {
        this.initializeForm();
        this.total=data;
        this.openModalValidation.nativeElement.click();
      });
  }



  confirmArchive(){
    this.archiveRequestCommand.archiveType =3;
    this.httpService
    .postFormData('Archive/ArchiveRequests', this.archiveRequestCommand)
    .subscribe((data) => {
      this.archiveType="";
      this.archiveLaunchCommand = {
        StartDate: '',
        archiveType: '',
      };
      this.archiveRequestCommand = {
        archiveType: '',
      };
      this.total=0;
      this.initializeForm();
      this.openModalConfirmation.nativeElement.click();
    });
  }

  async cancelArchive(){
    this.archiveRequestCommand.archiveType =2;
    await this.httpService
    .postFormData('Archive/ArchiveRequests', this.archiveRequestCommand)
    .subscribe((data) => {
      this.archiveType="";
      this.archiveLaunchCommand = {
        StartDate: '',
        archiveType: '',
      };
      this.archiveRequestCommand = {
        archiveType: '',
      };
      this.total=0;
      this.initializeForm();
      this.openModalCancellation.nativeElement.click();
    });
  }

  reloadPage(): void {
    window.location.reload();
  }
  loadTrasportData(){
    this.httpService
      .findAll('TransportRequests', {
        IsDeleted: true,
      })
      .subscribe((trs) => {
        if (trs.items.length > 0) {
          this.isTransportDataAvailable = true;
        }
        setTimeout(() => {
        });
      });
  }
  loadBikeData(){
    this.httpService
      .findAll('BikeRequests', {
        IsDeleted: true,
      })
      .subscribe((bikes) => {
        if (bikes.items.length > 0) {
          this.isBikeDataAvailable = true;
        }
        setTimeout(() => {
        });
      });
  }

  handleBikeDataAvailability(isAvailable: boolean) {
    this.isBikeDataAvailable = isAvailable;
  }

  handleTransportDataAvailability(isAvailable: boolean) {
    this.isTransportDataAvailable = isAvailable;
  }
}
