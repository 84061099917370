import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { Canstants } from 'src/core/canstants/canstants';
import { NgxSpinnerService } from 'ngx-spinner';
import User from 'src/core/models/user';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { DateService } from 'src/core/services/date.service';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-mobility-request-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit {
  sortOption: any = { active: 'Created', direction: 'desc' };
  constant = Canstants;
  ListMonth: any;
  canAccessBike!: boolean;
  indexOfSlide = 1;
  indexOfSlide2 = 4;
  sildes: Array<any> = [];
  sildes2: Array<any> = [];
  cantAddRequest = false;
  hasRequestParking = false;
  canAddRequestParking = false;
  disableNext = false;
  disablePrev = false;
  allMobilityRequests: Array<any> = [];
  mobilityRequests: Array<any> = [];
  mobilityRequestValidated: Array<any> = [];
  isScroll = false;
  peoplePlan: any;
  connectedUser: User | undefined;
  businessParamDay: any;
  isSmallScreen!: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private httpService: HttpClientService<any>,
    private dateService: DateService,
    private authService: AuthenticationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSmallScreen = window.innerWidth <= 844;
    this.connectedUser = await this.authService.getConnectedUser();
    if (
      !(
        this.connectedUser.myCompanyCode == 'FR73' ||
        this.connectedUser.myCompanyDesc.toLowerCase() == 'fabernovel'
      )
    ) {
      this.canAccessBike = true;
    } else {
      this.canAccessBike = false;
    }
    this.onAccessRequest();
    this.loadBusinessParam();
    await this.loadUserRequests();
  }
  get environement(): typeof environment {
    return environment;
  }
  async loadBusinessParam(): Promise<any> {
    await this.httpService.findAll('BusinessParams').subscribe((bp) => {
      let formattedMonth = moment().format('MM');
      let title =
        Canstants.limitValidTransportM +
        moment().format('yyyy') +
        formattedMonth;
      let bpDay = bp.filter((param: any) => param.title == title);
      this.businessParamDay = bpDay[0].descriptionBP;
    });
  }
  async loadUserRequests(): Promise<void> {
    this.httpService.findAll('Requests').subscribe(async (requests) => {
      requests.transportRequests.forEach((elem: any) => {
        elem.requestType = this.constant.transportAllowance;
        if (elem.status === MobilityRequestStatus.Validated) {
          this.mobilityRequestValidated.push(elem);
        } else {
          this.mobilityRequests.push(elem);
        }
      });
      requests.bikeRequests.forEach((elem: any) => {
        elem.requestType = this.constant.bicycleSubsidy;
        if (elem.status === MobilityRequestStatus.Validated) {
          this.mobilityRequestValidated.push(elem);
        } else {
          this.mobilityRequests.push(elem);
        }
      });
      requests.parkingRequests.forEach((elem: any) => {
        elem.requestType = this.constant.parkingCard;
        if (elem.status === MobilityRequestStatus.Validated) {
          this.mobilityRequestValidated.push(elem);
        } else {
          this.mobilityRequests.push(elem);
        }
      });
      this.mobilityRequests = this.mobilityRequests.sort(function (a, b) {
        return (
          new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
        );
      });
      await this.getListMonth();
    });
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event: any): void {
  //   this.isScroll = window.pageYOffset >= 12;
  // }
  async onAccessRequest(): Promise<void> {}
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  get mobilityRequestType(): typeof MobilityRequestType {
    return MobilityRequestType;
  }

  goPrev(): void {
    this.disableNext = false;
    if (this.indexOfSlide > 0) {
      this.indexOfSlide--;
      this.disablePrev = this.indexOfSlide === 0;
    }
    if (this.indexOfSlide2 > 0) {
      this.indexOfSlide2--;
    }
  }

  goNext(): void {
    this.disablePrev = false;
    if (this.indexOfSlide < 6) {
      this.indexOfSlide++;
      this.disableNext = this.indexOfSlide === 6;
    }
    if (this.indexOfSlide2 < 18) {
      this.indexOfSlide2++;
    }
  }

  async getListMonth(): Promise<void> {
    const mapping = this.dateService.getEighteenMonthFromNow(
      this.mobilityRequestValidated
    );
    console.log('mapping',mapping)
    this.sildes = mapping.sort();
    this.sildes2 = mapping.sort();
    this.sildes = this.chunk(mapping, 3);
    console.log('chunk',this.sildes)
    this.sildes2 = this.chunk(mapping, 1);
    console.log('chunk2',this.sildes2)
  }

  chunk(arr: any, chunkSize: number): Array<any> {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  viewRequest(value: string): void {}
  updateRequest(value: string): void {}
}
