export enum RoleTypeEnum {
  VALIDATOR = "VALIDATOR",
  PAIE = "PAIE",
  USER = "USER",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  MANAGE_FORMULAS = "MANAGE_FORMULAS",
  MANAGE_CALENDAR = "MANAGE_CALENDAR",
  MANAGE_ARCHIVE = "MANAGE_ARCHIVE",
  MANAGE_PERMISSIONS = "MANAGE_PERMISSIONS",
  CANCEL_VALID_REQUEST = "CANCEL_VALID_REQUEST"
}

export default class Role {
  id!: number;
  number!: string;
  type!: RoleTypeEnum;
  groupName!: string;
}
