  <span class="text-secondary">Filtrage des demandes</span>
  <hr class="hr" />
  <div class="filter-container row">
    <div class="col-md-3">
      <div class="filter-txt">Créer à partir de</div>
      <div class="d-flex">
        <input
          matInput
          class="form-control date-input ml-4"
          [matDatepicker]="picker"
          (ngModelChange)="dateChanged($event)"
          [(ngModel)]="filterOptions.Created"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="filter-creteria col-md-3">
      <span class="filter-txt">Nom demandeur</span>
      <app-people-picker
        (resetUser)="resetRequester()"
        [user]="requestor"
        (selectUser)="selectRequester($event)"
        class="ml-2"
      >
      </app-people-picker>
    </div>
    <div class="filter-creteria col-md-3">
      <span class="filter-txt">Bureau</span>
      <mat-form-field class="ml-4">
        <mat-select [(ngModel)]="filterOptions.Site">
          <mat-option value="">Choisir un site</mat-option>
          <mat-option [value]="item.code" *ngFor="let item of listSites">
            {{ item.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-creteria col-md-3">
      <span class="filter-txt">SL</span>
      <mat-form-field class="ml-2">
        <mat-select [(ngModel)]="filterOptions.SL">
          <mat-option value="">Choisir une SL</mat-option>
          <mat-option value="ASU">ASU</mat-option>
          <mat-option value="TAX">TAX</mat-option>
          <mat-option value="EYC">EYC</mat-option>
          <mat-option value="SAT">SAT</mat-option>
          <mat-option value="CBS">CBS</mat-option>
          <mat-option value="FSO">FSO</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-creteria col-md-3">
      <span class="filter-txt">Statut</span>
      <mat-form-field class="ml-4">
        <form
          [formGroup]="statusForm"
          fxFlex
          fxLayout="column"
          autocomplete="off"
        >
          <mat-select formControlName="statusFieldParking" multiple>
            <mat-option
              #allSelectedParking
              (click)="toggleAllSelectionParking()"
              [value]="0"
              >tout sélectionner</mat-option
            >
            <mat-option
              *ngFor="let item of listStatus"
              [value]="item"
              (click)="selectPerOneParking(allSelectedParking.viewValue)"
            >
              {{ item }}
            </mat-option>
          </mat-select>
        </form>
      </mat-form-field>
    </div>
    <div class="filter-creteria col-md-3">
      <span class="filter-txt">Traitée par</span>
      <app-people-picker
        (resetUser)="resetValidateur()"
        [user]="validator"
        (selectUser)="selectValidateur($event)"
        class="ml-2"
      >
      </app-people-picker>
    </div>
    <div class="float-right ml-4 col-md-5">
      <button
        type="button"
        class="btn btn-filter mb-2 mr-3 ml-2"
        (click)="this.loadParkingRequest()"
      >
        Filtrer avec ces critères
      </button>
      <button
        type="button"
        class="btn mb-2 ml-2 btn-reset-filter"
        data-toggle="modal"
        (click)="resetSearchParking()"
      >
        Annuler tous les critères
      </button>
    </div>
  </div>
  <hr class="hr" />
  <div class="row justify-content-end">
    <div class="col-md-2"></div>
  </div>
  <div>
    <button
      *ngIf="requestParking.length > 0"
      type="button"
      class="btn btn-primary float-right"
      (click)="getExcelFileParking()"
    >
      Export Excel
    </button>
    <div class="btn-add-parking" routerLink="/parking/create">
      +
    </div>
  </div>

  <table
    matSort
    (matSortChange)="sortParkingRequest($event)"
    mat-table
    [dataSource]="requestParking"
    *ngIf="requestParking.length > 0"
  >
    <ng-container matColumnDef="Created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Submission</th>
      <td mat-cell *matCellDef="let element">
        {{ element.created | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_RequesterName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Demandeur</th>
      <td mat-cell *matCellDef="let element">
        {{ element.requester.displayName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_SiteRattachement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bureau</th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let site of listSites">
          <div *ngIf="element.requester.locationCode == site.code">
            {{ site.name }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_ServiceLine">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SL</th>
      <td mat-cell *matCellDef="let element">{{ element.requester.slTgm }}</td>
    </ng-container>

    <ng-container matColumnDef="Mob_EntiteJuridique">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>BU</th>
      <td mat-cell *matCellDef="let element">
        {{ element.requester.myCompanyDesc }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_Grade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rank</th>
      <td mat-cell *matCellDef="let element">
        {{ element.requester.rankDesc }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_MoisDebut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mois début</th>
      <td mat-cell *matCellDef="let element">
        {{ element.startDate | date : "MMMM yyyy" }}
      </td>
    </ng-container>

    

    <ng-container matColumnDef="Mob_Status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
      <td mat-cell *matCellDef="let element">
        <span
          class="en-cours bold"
          *ngIf="element.status === mobilityRequestStatuss.Submitted"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Submitted].toString()
          }}</span
        >
        <span
          class="en-cours bold"
          *ngIf="element.status === mobilityRequestStatuss.Assigned"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Assigned].toString()
          }}</span
        >
        <span
          class="returned bold"
          *ngIf="element.status === mobilityRequestStatuss.Returned"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Returned].toString()
          }}</span
        >
        <span
          class="canceled bold"
          *ngIf="element.status === mobilityRequestStatuss.Canceled"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Canceled].toString()
          }}</span
        >
        <span
          class="rejected bold"
          *ngIf="element.status === mobilityRequestStatuss.Rejected"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Rejected].toString()
          }}</span
        >
        <span
          class="en-cours bold"
          *ngIf="element.status === mobilityRequestStatuss.Validated"
          >{{
            mobilityRequestStatuss[mobilityRequestStatuss.Validated].toString()
          }}</span
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="Mob_NomValideur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Traitée par</th>
      <td mat-cell *matCellDef="let element">
        {{ element.validator?.displayName }} 
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="semi-bold">
          <button
            [routerLink]="['/parking-admin/update', element.id]"
            class="btn btnVoirModifier link"
          >
            Modifier 
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsParking"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsParking"></tr>
  </table>
  <mat-paginator
    class="request-paginator"
    #paginatorRequestParking
    (page)="pageChanged($event)"
    [length]="totalRows"
    [pageIndex]="currentPage - 1"
    [pageSize]="filterOptions.pageSize"
    [pageSizeOptions]="[5, 10, 20, 30]"
  >
  </mat-paginator>
