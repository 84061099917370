import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';


  @Component({
    selector: 'app-error-dialog',
    templateUrl: './ErrorDialog.component.html',
    styleUrls: ['./ErrorDialog.component.css'],
  })
export class ErrorDialogComponent { 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    private router: Router
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
    this.router.navigate(['/']); 
  }
}
