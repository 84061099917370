<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/velo.jpg'"
        />
      </div>
      <div class="BannerGradient2 padding-left">
        <div class="col-md-12 margin-top mb-3">
          <span class="ArrowVelo"
            ><a href="#" data-toggle="modal" data-target="#close-form"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleVelo">Ma demande détaillée </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="col-md-12 margin-top mt-4">
          <span class="bold Title2Velo semi-bold"
            >Subvention <span class="bold">Vélo</span></span
          >
        </div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-bike-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="SpacerBox margin-top">
    <form class="form" [formGroup]="bikeRequestForm" autocomplete="off">
      <div class="bold yellowColor titleFormule mb-2">
        <div class="squarePuce"></div>
        Prise d'effet
      </div>
      <div class="row">
        <div class="col-md-6 row">
          <div class="col-md-4 pt-2">
            Mois ciblé pour le début des remboursements
          </div>
          <div class="col-md-4 form-group">
            <select
              class="form-control text-center"
              (change)="changeStartMonth()"
              formControlName="startMonth"
            >
              <option
                [value]="item.numberValue"
                *ngFor="let item of listStartMonth"
              >
                {{ item.value }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="bold yellowColor titleFormule mb-2">
        <div class="squarePuce"></div>
        Informations sur l'achat
      </div>
      <div class="row">
        <div class="col-md-4 row">
          <div class="col-md-4 pt-2">
            Montant total des achats éligibles au forfait
          </div>
          <div class="col-md-7 form-group">
            <input
              appFormatMoney
              mask="separator.2"
              [dropSpecialCharacters]="false"
              thousandSeparator=" "
              decimalMarker=","
              type="text"
              (change)="changePurchaseAmount($event)"
              matInput
              class="form-control text-right"
              formControlName="purchaseAmount"
            />

            <span class="euro">€</span>
          </div>
        </div>

        <div class="col-md-4 row">
          <div class="col-md-4 pt-2">Date de l'achat/facture</div>
          <div class="col-md-7 form-group">
            <input
              matInput
              class="form-control text-center"
              [matDatepicker]="picker"
              [min]="minDate"
              [max]="maxDate"
              formControlName="purchaseDate"
              readonly
              (dateChange)="changePurchaseDate($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <span
            class="text-danger pl-2"
            *ngIf="submitted && form.purchaseDate.errors?.required"
          >
            Date obligatoire
          </span>
        </div>
        <div class="col-md-4 row">
          <div class="piecesContainer" *ngIf="form.startMonth.value">
            <div
              [class]="
                bikeRequestDto.certificateFMD
                  ? 'piecesBoxGray'
                  : 'piecesBoxYello'
              "
              data-toggle="modal"
              data-target="#generate-attestation"
            >
              <img
                alt=""
                class="imgBox"
                [src]="environement.baseUrl + 'assets/images/Group.svg'"
              />
            </div>
            <input type="hidden" formControlName="attestationFile" />
            <span class="txt-attachment ml-2 mr-2"
              >Attestation de prise en charge FMD</span
            >
          </div>

          <div class="piecesContainer">
            <span
              class="textJustif text-danger"
              *ngIf="submitted && form.attestationFile.errors?.required"
            >
              Attestation obligatoire
            </span>
            <div
              class="TrashBox"
              (click)="removeAttestation()"
              data-toggle="modal"
              data-target="#confirm-delete-attestation"
              *ngIf="bikeRequestDto.certificateFMD"
            >
              <img
                alt=""
                class="TrashImg mr-2"
                [src]="environement.baseUrl + 'assets/images/poubelle.svg'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row velo-electrique mt-2">
        <div class="mr-4">
          Cela concerne-t-il un vélo à assistance électrique (VAE)
        </div>
        <div class="">
          <input type="checkbox" class="form-control" formControlName="vae" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 row"></div>
        <div class="col-md-4 row"></div>
        <div class="col-md-4 row">
          <div class="col-md-6 piecesContainer">
              <input type="hidden" formControlName="attachmentFile" />
              <div
                [class]="
                  bikeRequestDto.proofOfPurchase
                    ? 'piecesBoxGray'
                    : 'piecesBoxYello'
                "
              >
                <img
                  alt=""
                  class="imgBox"
                  [src]="environement.baseUrl + 'assets/images/Line.svg'"
                />
                <img
                  alt=""
                  class="imgBox"
                  [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
                />
              </div>
              <input
                (click)="file.value = ''"
                (change)="uploadAttachment($event)"
                accept="image/*,.pdf"
                #file
                type="file"
                [class]="
                  bikeRequestDto.proofOfPurchase
                    ? 'FileInputDisabled'
                    : 'FileInput'
                "
              />
              <span class="txt-attachment ml-2 mr-2">Justificatif d'achat </span>
              <div class="TrashBox" *ngIf="bikeRequestDto.proofOfPurchase">
                <!-- {{bikeRequestDto.proofOfPurchase}} -->
                <img
                  alt=""
                  class="TrashImg"
                  [src]="environement.baseUrl + 'assets/images/Group9828.svg'"
                />
              </div>
          </div>
          <div class="col-md-6 piecesContainer">
            <span
              class="textJustif text-danger"
              *ngIf="submitted && form.attachmentFile.errors?.required"
            >
              Justificatif d'achat obligatoire
            </span>
            <div
              class="TrashBox"
              (click)="removeAttachment()"
              *ngIf="bikeRequestDto.proofOfPurchase"
            >
              <img
                alt=""
                class="TrashImg"
                [src]="environement.baseUrl + 'assets/images/poubelle.svg'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-3">
        <div class="col-md-4 row"></div>
        <div class="col-md-4 row"></div>
        <div class="col-md-4 row">
            <div class="row justify-content-center" *ngIf="
                        !fileExists 
                      ">
              <span class="file-size-gray ml-5">(Saisir un fichier qui ne dépasse pas 1 Mo)</span>
            </div>
            <div class="row justify-content-center" *ngIf="
            fileExists  && fileGreater
          ">
              <span class="file-size-red ml-5">(La taille du fichier ne doit pas dépasser 1 Mo)</span>
            </div>
        </div>
      
      </div>

      <div class="row justify-content-center">
        <button
          type="button"
          [disabled]="
            !reCalculateEvent ||
            !form.startMonth.value ||
            !form.purchaseAmount.value ||
            !form.purchaseDate.value ||
            fileGreater
          "
          class="btn btn-primary"
          (click)="calculatePayment()"
        >
          (re)Calculer
        </button>
      </div>

      <hr class="hr" />
      <div class="bold yellowColor titleFormule mb-2 col-md-12 row">
        <div class="squarePuce"></div>
        Prise en charge EY
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="row mb-3">
            <div class="col-md-7 pt-2">Montant total subventionné par EY</div>
            <div class="col-md-4 form-group">
              <input
                appFormatMoney
                mask="separator.2"
                decimalMarker=","
                type="text"
                [attr.disabled]="true"
                class="form-control text-right"
                formControlName="subsidizedAmount"
              />
              <span
                class="text-danger pl-2"
                *ngIf="submitted && form.subsidizedAmount.errors?.required"
                >Obligatoire</span
              >
              <span class="euro">€</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-1 pt-2">Soit</div>
            <div class="col-md-2 form-group">
              <input
                type="text"
                [attr.disabled]="true"
                class="form-control text-center"
                formControlName="numberFullMonths"
              />
            </div>

            <div class="col-md-2 pt-2">Mensualité(s) de</div>
            <div class="col-md-2 form-group">
              <input
                appFormatMoney
                type="text"
                mask="separator.2"
                decimalMarker=","
                [attr.disabled]="true"
                class="form-control text-right"
                formControlName="applicableMonthlyPayment"
              />

              <span class="euro">€</span>
            </div>

            <div
              class="col-md-2 pt-2"
              *ngIf="toNumber(form.lastMonthAmount.value) > 0"
            >
              et 1 dernière de
            </div>

            <div
              class="col-md-2 form-group"
              *ngIf="toNumber(form.lastMonthAmount.value) > 0"
            >
              <input
                appFormatMoney
                type="text"
                mask="separator.2"
                decimalMarker=","
                [attr.disabled]="true"
                class="form-control text-right"
                formControlName="lastMonthAmount"
              />
              <span class="euro">€</span>
            </div>
          </div>
          <div class="row mt-2 mb-4">
            <div class="col-md-4 pt-2">versées sur solde entre</div>
            <div class="col-md-3 form-group">
              <input
                type="text"
                [attr.disabled]="true"
                class="form-control text-center"
                formControlName="startDate"
              />
            </div>

            <div class="col-md-2 pt-2">et</div>
            <div class="col-md-3 form-group">
              <input
                type="text"
                [attr.disabled]="true"
                class="form-control text-center"
                formControlName="endDate"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="">Commentaire demandeur</div>
          <div class="">
            <textarea
              maxlength="250"
              matInput
              id="requester-comment"
              class="form-control"
              formControlName="requesterComment"
            ></textarea>
          </div>
        </div>
        <div class="col-md-2">
          <button
            type="button"
            [disabled]="isValid && fileGreater"
            class="btn btn-primary mb-3 mt-2 float-right"
            data-toggle="modal"
            data-target="#valider-form"
          >
            Valider ma demande
          </button>
          <button
            type="button"
            class="btn btn-secondary float-right"
            data-toggle="modal"
            data-target="#close-form"
          >
            Annuler ma demande
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    class="modal fade"
    id="generate-attestation"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalPdfForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Attestation sur l'honneur</h5>
          <h5 class="modal-title">
            pour utilisation d'un mode de transport ouvrant droit à la prise en
            charge dans le cadre du
          </h5>
          <h5 class="modal-title">« forfait mobilités durables »</h5>
        </div>
        <div class="modal-body">
          <div class="popUpCard" id="AttestationToExport">
            <div class="logo-container">
              <img
                alt=""
                class="logo-attestation"
                id="logo-attestation"
                [src]="
                  environement.baseUrl + 'assets/images/logoAttestation.jpg'
                "
              />
            </div>
            <div class="core-attestation">
              <div class="entete-container">
                <div class="entete-title soussigne">
                  Je soussigné(e)
                  <span class="bold">{{ connectedUser?.displayName }}</span>
                </div>
                <div class="entete-title pl-5">
                  Entité juridique EY:
                  <span class="bold"> {{ connectedUser?.myCompanyDesc }}</span>
                </div>
                <div class="entete-title pl-5">
                  Bureau de rattachement:
                  <span class="bold">{{ connectedUser?.locationDesc }}</span>
                </div>
                <div class="entete-title pl-5">
                  N° employé: <span class="bold">{{ connectedUser?.gpn }}</span>
                </div>
                <div class="pt-3">
                  <span class="pr-2">Demeurant:</span>
                  <input
                    [(ngModel)]="demeurantIn"
                    matInput
                    #demeurant
                    class="form-control popup-input"
                    type="text"
                  />
                </div>

                <div class="divider2"></div>

                <div class="popUpDescription">
                  demandant à mon employeur une subvention pour l'achat d'un
                  vélo dans le cadre du forfait mobilités durables
                </div>
                <div class="divider2"></div>
                <div class="checkBoxDesc">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      [(ngModel)]="condition1"
                      class="custom-control-input"
                      id="customCheck1"
                    />
                    <label
                      class="custom-control-label checkbox-label checkbox-text pl-4"
                      for="customCheck1"
                    >
                      atteste sur l'honneur utiliser ce mode de transport pour
                      mes trajets domicile - lieu de travail.
                    </label>
                  </div>
                </div>
                <div class="divider2"></div>
                <div class="popUpDescription">
                  Fait pour servir et valoir ce que de droit.
                </div>
                <div class="divider2"></div>
                <div class="d-flex">
                  <label class="mr-4">Fait à</label>
                  <input
                    #faita
                    matInput
                    [(ngModel)]="doIn"
                    class="form-control popup-input-md"
                    type="text"
                  />
                  <span class="DateNow pl-5"
                    >Le {{ dateNow | date : "dd/MM/yyyy" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-2">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              [disabled]="
                !(
                  demeurant.value &&
                  demeurant.value.length > 2 &&
                  faita.value &&
                  faita.value.length > 2 &&
                  condition1 
                )
              "
              data-dismiss="modal"
              (click)="generatePDF()"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="close-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">Fermer la demande</h5>
        </div>
        <div class="modal-body">
          Attention, si vous confirmez cette action vous allez perdre toutes les
          saisies en cours
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Revenir sur la demande
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            routerLink="/mobility-request/list"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
  <span
    #openModalNegativeNumber
    data-target="#open-negative-number"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-negative-number"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">Valeur incorrecte </h5>
        </div>
        <div class="modal-body">
          Dans "tarif réglé pour le mois", vous ne pouvez saisir qu'un nombre positive. 
Pour ajouter des décimales, vous devez utiliser une ",".
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- pop up valider demande -->
  <div
    class="modal fade"
    id="valider-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalValiderForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Attention !</h5>
        </div>
        <div class="modal-body">
          Sur la période considérée de la présente demande, vous ne pourrez pas
          faire de nouvelle demande de remboursement
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="createBikeRequest()"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- pop info justificatif -->
  <span
    #openModalAttachement
    data-target="#open-attachement"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-attachement"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Mon justificatif d'abonnement
          </h5>
        </div>
        <div class="modal-body">
          Les justificatifs sont susceptibles d'être contrôlés auprès des
          commerçants ou prestataires concernés
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-bike-condition-popup></app-bike-condition-popup>
  <div class="has-no-access" *ngIf="!checkAccess?.access">
    <div
      class=""
      id="exampleModalAcces"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Accèss non autorisé
            </h5>
          </div>
          <div class="modal-body">
            {{ checkAccess?.message }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              routerLink="/mobility-request/list"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
