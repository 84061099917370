import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from 'src/app/shared/file.service';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { AttachementType } from 'src/core/models/attachement-request';
import { RequestBike, bikeFile } from 'src/core/models/request-bike';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { PdfService } from 'src/core/services/pdf.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-view-bike',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ViewBikeComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('type') type: MobilityRequestType | undefined;
  requestVelo: RequestBike | undefined;
  connectedUser: User | undefined;
  constant = Canstants;
  bikeViewForm!: FormGroup;
  idSubcriptionFormulaMisc: any;
  attachmentFile: any;
  attestationFile: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private pdfService: PdfService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private fileService:FileService
  ) {}

  get form(): any {
    return this.bikeViewForm.controls;
  }
  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    this.httpService
      .findOneById('BikeRequests', this.activatedRoute.snapshot.params['id'])
      .subscribe((result: any) => {
        this.requestVelo = result;
      });
  }
  get attachementType(): typeof AttachementType {
    return AttachementType;
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  get environement(): typeof environment {
    return environment;
  }

  openPdf(): void {
    this.pdfService.generatePDF(0, '', '', MobilityRequestType.cycling);
    this.pdfService.openPdf();
  }

  openFile(bikeFile?: any): void {
    if (bikeFile?.fileBytes) this.fileService.openFile(bikeFile.fileBytes);
  }
}
