import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileService } from 'src/app/shared/file.service';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { AttachementRequest } from 'src/core/models/attachement-request';
import { QueryOption } from 'src/core/models/query-option';
import { RequestTransport } from 'src/core/models/request-transport';
import { SubscriptionFormula } from 'src/core/models/subscription-formula';
import { SubscriptionType } from 'src/core/models/subscription-type';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-transport',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ViewTransportComponent implements OnInit {
  requestTransport: RequestTransport | undefined;
  attestationFile: any;
  subscriptions: Array<SubscriptionType> = [];
  totalVerseMensuellement!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private moneyService: MoneyService,
    private fileService: FileService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadTransportRequests();
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }

  get environement(): typeof environment {
    return environment;
  }

  async loadTransportRequests(): Promise<void> {
    const id =   this.activatedRoute.snapshot.params['id'];
    this.httpService
      .findOneById(
        'TransportRequests',id)
      .subscribe((result: any) => {
        this.requestTransport = result;
      });
  }

  openFile(base64?: string): void {
    if (base64) this.fileService.openFile(base64);
  }
  calculateIndemTotal(): string {
    let total = 0;
    if (this.requestTransport)
      for (const subscriptionFormula of this.requestTransport
        ?.subscriptionFormulas) {
        let formula: any = subscriptionFormula;
        switch (subscriptionFormula.formulaType) {
          case FormuleTypeEnum.Annuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(((formula?.subscription.subscriptionTypeRefund?.annualFees /
                  12) *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;
          case FormuleTypeEnum.Mensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;

          case FormuleTypeEnum.InfraMensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula.quantity *
                  formula?.subscription.subscriptionTypeRefund?.otherFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate || 0,'.');
            break;

          case FormuleTypeEnum.Misc:
            total =
              formula.miscRefundRate == null
                ? total +
                  this.moneyService.formatNumber((formula.monthlyPayment *
                    formula.subscription.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,'.')
                : total + this.moneyService.formatNumber(formula.miscRefundRate || 0,'.');
            break;
        }
      }
    // var totalString = total.toFixed(2);
    // totalString = totalString.replace('.', ',');
    // const valueToAdd = parseFloat(newValue);
    return this.moneyService.format(total,'.');
  }

  getForumlaValue(formula: any) {
    switch (formula.formulaType) {
      case FormuleTypeEnum.Annuel:
        const refundA = formula?.subscription.subscriptionTypeRefund?.percent
            ? ((formula?.subscription.subscriptionTypeRefund?.annualFees / 12) *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
        return {
          fees: this.moneyService.format(
            formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundA.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.Mensuel:
        const refundM = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
        return {
          fees: this.moneyService.format(
            formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundM.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.InfraMensuel:
        const refundI = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula.quantity *
                formula?.subscription.subscriptionTypeRefund?.otherFees *
                formula?.subscription.subscriptionTypeRefund?.unitRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.unitRefundRate;
        return {
          fees: this.moneyService.format(
            formula?.subscription.subscriptionTypeRefund?.otherFees?.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundI.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.Misc:
        let refund =
          formula.miscRefundRate != null
            ? this.moneyService.format(formula.miscRefundRate.toString(), '.')
            : this.moneyService.format(
                (
                  (formula.monthlyPayment *
                    formula.subscription.subscriptionTypeRefund
                      .monthlyRefundRate) /
                  100
                ).toString(),
                '.'
              );
        return {
          fees: this.moneyService.format(
            formula.monthlyPayment.toString(),
            '.'
          ),
          refundRate:refund,
        };
      default:
        return {
          fees: 0,
          refundRate: 0,
        };
    }
  }
}
