import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-parking',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateParkingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  get environement(): typeof environment {
    return environment;
  }
}
