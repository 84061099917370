import { BusinessParam } from './../core/models/business-param';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/core/services/http-client.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessParamsService {
  constructor(private httpService: HttpClientService<any>) {}

  getBusinessParams = (): Observable<BusinessParam[]> => {
    return this.httpService.findAll('BusinessParams');
  };
}
