<div class="row">
    <div class="col-md-12">
        <div class="banner-admin">
            <div class="col-md-12 padding-left">
                <span class="ArrowVelo"><a routerLink="/"><img alt="" [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span><span class="bold TitleVelo">Espace Admin - Formules </span>
                <div>
                    <img alt="" class="DividerVelo" [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="spacer-box">
    <br />
    <span class="text-secondary">Filtrage des Formules</span>
    <hr class="hr" />
    <div class="filter-container row ">
        <div class="filter-creteria col-md-3">
            <span class="filter-txt ml-4 ">Titre de la formule </span>
                <select [(ngModel)]="SubscriptionFilter" class="form-control ml-4 mt-2">
                    <option value="">Choisir une formule</option>
                    <option [value]="item.title" *ngFor="let item of SubscriptionTypesListAll">
                        {{ item.title }}</option>
                </select>
        </div>
        <div class="col-md-2 button-filter mt-4">
            <button type="button" class="btn btn-filter mt-1 mr-3 ml-2" (click)="loadSubsriptions(true)">

                Filtrer avec ces critères
            </button>
        </div>
        <div class="col-md-2 button-filter mt-4">
            <button type="button" class="btn mt-1 ml-2 btn-reset-filter" data-toggle="modal"
                (click)="resetSearchPermission()">

                Annuler le filtre
            </button>
        </div>
    </div>
    <br>
    <hr class="hr" />


    <div class="row justify-content-end">
        <div class="col-md-2">
            <button class="btn btn-ajouter blod mt-4 px-1 mr-3" (click)="openAddModal()">
                Ajouter une formule
            </button>
        </div>
    </div>

    <table mat-table [dataSource]="SubscriptionTypesList" *ngIf="SubscriptionTypesList.length > 0">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="10">Title</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="10">
                {{ element.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="formulaType">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">formulaType</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                <span class="cancelled bold" *ngIf="element.formulaType === formuleType.Annuel">{{
                    formuleType[formuleType.Annuel].toString()
                    }}</span>
                <span class="cancelled bold" *ngIf="element.formulaType === formuleType.Mensuel">{{
                    formuleType[formuleType.Mensuel].toString()
                    }}</span>
                <span class="cancelled bold" *ngIf="element.formulaType === formuleType.InfraMensuel">{{
                    formuleType[formuleType.InfraMensuel].toString()
                    }}</span>
                <span class="cancelled bold" *ngIf="element.formulaType === formuleType.Misc">{{
                    formuleType[formuleType.Misc].toString()
                    }}</span>
            </td>
        </ng-container>


        <ng-container matColumnDef="formulaCode">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Code de Formule</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                {{ element.formulaCode }}
            </td>
        </ng-container>

        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Ordre</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">{{ element.order }}</td>
        </ng-container>

        <ng-container matColumnDef="siteId">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Bureau</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                <div *ngFor="let site of listSites">
                    <div *ngIf="element.siteId == site.id">
                        {{ site.name }}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="5">Actions</th>
            <td mat-cell *matCellDef="let element" [style.width.%]="5">
                <div class="semi-bold d-flex">
                    <button [routerLink]="[
                '/super-admin/formulas',
                element.id,element.formulaType
              ]" class="btn btnVoirModifier link mr-1">
                        Voir
                    </button>

                    <button (click)="OpenUpdateFormulaPopup(element.id)" class="btn btnVoirModifier link">
                        Modifier
                    </button>
                </div>
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator *ngIf="SubscriptionTypesList.length>0 " class="request-paginator" #paginatorRequestTransport
        (page)="pageChanged($event)" [length]="totalRows" [pageIndex]="currentPage-1" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20, 30]">
    </mat-paginator>

</div>

<span #openAddFormula data-target="#open-add-formula" data-toggle="modal"></span>
<div class="modal fade" tabindex="-1" role="dialog" id="open-add-formula" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title bold" id="exampleModalLabel">
                    Ajouter une formule
                </h5>
            </div>
            <div class="modal-body">
                <div class="row form-container">
                    <form [formGroup]="formulaForm">
                        <div class="form-item">
                            <label>
                                Title:
                            </label>
                            <input type="text" class="form-control title-input" matInput formControlName="title">

                        </div>
                        <div class="form-item">

                            <label>
                                Formula Type:
                            </label>
                            <select class="form-control" formControlName="formulaType">
                                <option>Sélectionner un type</option>
                                <option *ngFor="let type of formuleTypes" [value]="type.index"
                                    [hidden]="type.index === 4">
                                    {{type.name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-item">

                            <label>
                                Formula Code:
                            </label>
                            <input type="text" class="form-control" matInput formControlName="formulaCode">
                        </div>
                        <div class="form-item">
                            <label>
                                Order:
                            </label>
                            <input type="text" class="form-control" matInput formControlName="order">
                        </div>

                        <div class="form-item">
                            <label>
                                Bureau:
                            </label>
                            <select class="form-control" formControlName="siteId">
                                <option>Sélectionner un bureau</option>
                                <option *ngFor="let site of listSites" [value]="site.id">
                                    {{site.name}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-primary" [disabled]="!formulaForm.valid"
                        (click)="addFormula()">
                        Ajouter
                    </button>
                    <button type="button" (click)="cancelAddFormula()" #closeAddButton class="btn btn-secondary"
                        data-dismiss="modal">
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<span #openUpdateFormula data-target="#open-update-formula" data-toggle="modal"></span>
<div class="modal fade" tabindex="-1" role="dialog" id="open-update-formula" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title bold" id="exampleModalLabel">
                    Modifier une formule
                </h5>
            </div>
            <div class="modal-body">
                <div class="row form-container">
                    <form [formGroup]="updateFormulaForm">
                        <div class="form-item">
                            <label>
                                Title:
                            </label>
                            <input type="text" class="form-control title-input" matInput formControlName="title">

                        </div>
                        <div class="form-item">

                            <label>
                                Formula Type:
                            </label>
                            <select class="form-control" formControlName="formulaType">
                                <option>Sélectionner un type</option>
                                <option *ngFor="let type of formuleTypes" [value]="type.index"
                                    [hidden]="type.index === 4">
                                    {{type.name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-item">

                            <label>
                                Formula Code:
                            </label>
                            <input type="text" class="form-control" matInput formControlName="formulaCode">
                        </div>
                        <div class="form-item">
                            <label>
                                Order:
                            </label>
                            <input type="text" class="form-control" matInput formControlName="order">
                        </div>

                        <div class="form-item">
                            <label>
                                Bureau:
                            </label>
                            <select class="form-control" formControlName="siteId">
                                <option>Sélectionner un bureau</option>
                                <option *ngFor="let site of listSites" [value]="site.id">
                                    {{site.name}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-primary" [disabled]="!updateFormulaForm.valid"
                        (click)="UpdateFormula()">
                        Mettre à jour
                    </button>
                    <button type="button"  #closeUpdateButton class="btn btn-secondary"
                        data-dismiss="modal">
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>