import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileType } from 'src/core/enum/FileTypeEnum';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import {
  AttachementRequest,
  AttachementType,
} from 'src/core/models/attachement-request';
import { RequestTransport } from 'src/core/models/request-transport';
import { SubscriptionFormula } from 'src/core/models/subscription-formula';
import { SubscriptionType } from 'src/core/models/subscription-type';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { PdfService } from 'src/core/services/pdf.service';
import { environment } from 'src/environments/environment';
import { FileService } from './../../../shared/file.service';
import { List } from 'linqts';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
moment.locale('fr');
moment.tz.setDefault("Europe/Paris");
@Component({
  selector: 'app-update-transport',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateTransportComponent implements OnInit {
  connectedUser: User | undefined;
  peoplePlan: any;
  requestVelos: any;
  requestTransports: any;
  requestParkings: any;
  requestTransport: RequestTransport = new RequestTransport();
  transportRequestForm!: FormGroup;
  subscriptions: Array<SubscriptionType> = [];
  subscriptionFormula: SubscriptionFormula = new SubscriptionFormula();
  attachmentToBlob: Array<any> = [];
  changedFormula: Array<any> = [];
  submitted = false;
  attestationFile: any;
  quantity: any = null;
  selectedTypoAbos: Array<any> = [];
  tarifMisc: any = null;
  tarifAutreInfra: any = null;
  forfaitRembUnitaire: any = null;
  @ViewChild('openForumlaAlreadyExist')
  openForumlaAlreadyExist!: ElementRef;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  changedAttachment: Array<boolean> = [];
  Files: Array<any> = [];


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pdfService: PdfService,
    private moneyService: MoneyService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  get form(): any {
    return this.transportRequestForm?.controls;
  }
  get environement(): typeof environment {
    return environment;
  }

  async ngOnInit(): Promise<void> {

    const id = this.activatedRoute.snapshot.params['id'];

    await this.loadTransportRequest(id);
    this.connectedUser = await this.authService.getConnectedUser();

    this.initializeRequest();
  }
  private initializeForm(): void {
    this.transportRequestForm = this.formBuilder.group({
      requesterComment: [''],
      Formulas: this.formBuilder.array([]),
    });
  }

  get Formulas(): FormArray {
    return this.transportRequestForm?.controls?.['Formulas'] as FormArray;
  }

  set Formulas(formArray: FormArray) {
    // this.transportRequestForm?.controls?.['Formulas'] = formArray;
  }
  testFormat(value: any, index: any) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    const subscriptionFormulaLine = this.Formulas.controls[
      index
    ] as FormGroup;
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.openModalNegativeNumber.nativeElement.click();
      subscriptionFormulaLine.controls['Price'].setValue('');
    }
  }
  async getListAbonnements(): Promise<void> {
    this.httpService
    .findAll('subscriptionTypes?StartDate='+
    new Date(this.requestTransport.startDate).toISOString())
      .subscribe((subscriptions: Array<SubscriptionType>) => {
       
        const grouped: any = new List(subscriptions).GroupBy(
          (x: any) => x.formulaCode
        );
        const codes = Object.getOwnPropertyNames(grouped);
        // this.subscriptions = subscriptions;
        for (const code of codes) {
          if (
            this.requestTransport.startDate ===
            this.requestTransport.endDate
          ) {
            this.subscriptions.push(grouped[code][0]);
          } else {
            if (
              grouped[code][0].formulaType == FormuleTypeEnum.Annuel ||
              grouped[code][0].formulaType == FormuleTypeEnum.Misc
            ) {
              this.subscriptions.push(grouped[code][0]);
            }
          }
        }
        this.subscriptions.sort(function (a: any, b: any) {
          return a.order - b.order;
        });
      });
      // });
  }

  openAttachment(index: number): void {
    window.open(URL.createObjectURL(this.attachmentToBlob[index]));
  }

  removeAttachement(index: number): void {
    this.selectedTypoAbos[index].File = null;
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(null);
    this.Files[index].fileExists = false;
    this.Files[index].fileGreater = false;
  }

  selectTypeAbonnement(event: any, rowIndex: number): void {
    let subscriptionType = this.subscriptions.find(
      (s) => s.id == event.target.value
    );
    if (subscriptionType) {
      this.selectedTypoAbos[rowIndex].number = subscriptionType.id
      const subscriptionFormulaLine = this.Formulas.controls[
        rowIndex
      ] as FormGroup;
      subscriptionFormulaLine.controls['FormulaId'].setValue(
        subscriptionType.id
      );
      switch (subscriptionType.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType?.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );

          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.otherFees.toString(),
              '.'
            )
          );
          this.tarifAutreInfra =
            subscriptionType.subscriptionTypeRefund?.otherFees;
          this.forfaitRembUnitaire =
            subscriptionType.subscriptionTypeRefund?.unitRefundRate;
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.required
          );
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = true;
          this.selectedTypoAbos[rowIndex].tarifName = 'Tarif unitaire';
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(this.tarifMisc);
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.required
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].isMisc = true;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif réglé pour le mois';
          break;
      }
      this.removeAttachement(rowIndex);
    }
  }

  getQuantity(event: any, index: number): void {
    // this.requestTransport.Mob_SubscriptionFormula[index].Mob_Quantite = event.target.value;
    this.calculateTarif();
  }

  calculateTarif(): void {
    // if (this.subscriptionFormula.Mob_Quantite) {
    //   this.subscriptionFormula.Mob_RegleMensuellement =
    //     this.subscriptionFormula.Mob_Quantite * this.tarifAutreInfra;
    //   this.subscriptionFormula.Mob_RembTotalApplicable =
    //     this.subscriptionFormula.Mob_Quantite * this.forfaitRembUnitaire;
    // }
  }

  getMiscName(event: any, index: number): void {
    //this.requestTransport.Mob_SubscriptionFormula[index].Mob_IntituleMisc = event.target.value;
  }

  private pushFormLineSubscriptionFormula(): void {
    const Formulas = this.formBuilder.group({
      id: [''],
      FormulaId: ['', Validators.required],
      Price: ['', Validators.required],
      EntitledMisc: [''],
      Quantity: [''],
      File: ['', Validators.required],
      FileType: [''],
      RefundRate: ['']
    });
    this.Formulas.push(Formulas);
    this.Files.push({});

  }

  async updateTransportRequest(): Promise<void> {
    this.submitted = true;
    if (!this.transportRequestForm?.valid) {
      return;
    }
    const formulasArray = this.transportRequestForm.get("Formulas") as FormArray;
    for (let i = 0; i < formulasArray.length; i++) {
      let formdeI=formulasArray.at(i).get("EntitledMisc")?.value
      if(formdeI!= null){
        const priceControl = formulasArray.at(i).get("Price");
        let value =priceControl?.value;
        if (value.includes(".")|| !value.includes(",")){
          value=parseFloat(value);
        }
        else{
          const newValue=value.replace(",",".");
          value=parseFloat(newValue);
        }
        value = value.toFixed(2);
        priceControl?.setValue(value);

      }
    }

    this.httpService
      .patchFormData(
        'TransportRequests',
        this.activatedRoute.snapshot.params['id'],
        this.transportRequestForm.value
      )
      .subscribe((res) => {
        this.router.navigate(['/mobility-request/list']);
      },
      (error) => {
        if (error.status === 500) {
          this.dialog.open(ErrorDialogComponent, {
            data: { message: 'Oups, problème système' }
          });
        } 
      }
    );
  }




  async deleteOldSubscriptionFormula(payload: any): Promise<void> {
    // await this.spService.delete('SubscriptionFormulas', payload).catch();
    // this.requestTransport.Mob_SubscriptionFormulaIds = new List(
    //   this.requestTransport?.Mob_SubscriptionFormulaIds
    // )
    //   .RemoveAll((x) => x === payload)
    //   .ToArray();
  }
  async deleteOldAttachmentOfSubFormula(payload: any): Promise<void> {
    // await this.spService.delete('AttachementTransports', payload).catch();
    // this.requestTransport.Mob_AttachmentTransportId = new List(
    //   this.requestTransport?.Mob_AttachmentTransportId
    // )
    //   .RemoveAll((x) => x === payload)
    //   .ToArray();
  }
  async createSubscriptionFormula(payload: SubscriptionFormula): Promise<void> {
    // const result = await this.spService
    //   .create('SubscriptionFormulas', payload)
    //   .catch();
    // await this.globalService.fixPermissionForItems(
    //   result.data.Id,
    //   this.requestTransport.Mob_RequesterEmail,
    //   'SubscriptionFormulas',
    //   'TRS'
    // );
    // this.requestTransport?.Mob_SubscriptionFormulaIds?.push(result.data.Id);
  }

  async createAttachment(attachementRequest: AttachementRequest): Promise<any> {
    // attachementRequest.Title =
    //   this.requestTransport.Title + '-' + attachementRequest.File.name;
    // attachementRequest.Title = attachementRequest.Title.replace(
    //   /[^a-zA-Z0-9_.-]/g,
    //   ''
    // );
    // attachementRequest.LinkFilename =
    //   this.requestTransport.Title + '-' + attachementRequest.File.name;
    // const attachment = await this.spService
    //   .addFile(
    //     'AttachementTransports',
    //     attachementRequest?.Title,
    //     attachementRequest.File
    //   )
    //   .catch();
    // const file = await this.spService.getFile(attachment.data.UniqueId).catch();
    // delete attachementRequest.File;
    // delete attachementRequest.Mode;
    // await this.spService.update(
    //   'AttachementTransports',
    //   file.ListItemAllFields.ID,
    //   attachementRequest
    // ); // update Mob_Type
    // await this.globalService.fixPermissionForItems(
    //   file.ListItemAllFields.ID,
    //   this.requestTransport.Mob_RequesterEmail,
    //   'AttachementTransports',
    //   'TRS'
    // );
    // attachementRequest.Id = file.ListItemAllFields.ID;
    // return attachementRequest;
  }

  async getAllSubscriptionFormula(): Promise<void> {
    // this.requestTransport.Mob_SubscriptionFormula = [];
    // this.Formulas.value.forEach(
    //   (element: any, index: number) => {
    //     const subscriptionFormula = new SubscriptionFormula();
    //     const subscription = new List(this.subscriptions).FirstOrDefault(
    //       (x) => x?.Id === Number(element.typeAbo)
    //     );
    //     const attachmentTransport = new AttachementRequest();
    //     attachmentTransport.Mob_Type =
    //       AttachementType[AttachementType.Attachment];
    //     attachmentTransport.LinkFilename = element.attachment;
    //     attachmentTransport.File = this.selectedTypoAbos[index].justifFile;
    //     subscriptionFormula.Mob_AttachmentTransport.push(attachmentTransport);
    //     subscriptionFormula.Id = element.subscriptionFormulaId;
    //     subscriptionFormula.Mob_DesignationFormule = subscription.Title;
    //     if (subscription) {
    //       switch (subscription.Mob_TypeFormule?.toString()) {
    //         case EnumSubscriptionType[EnumSubscriptionType.Annuel].toString():
    //           subscriptionFormula.Title = this.requestTransport.Title;
    //           attachmentTransport.Title = element.attachment;
    //           subscriptionFormula.Mob_CodeFormule = subscription.Mob_CodeFormule
    //             ? subscription.Mob_CodeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TypeFormule = subscription.Mob_TypeFormule
    //             ? subscription.Mob_TypeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TarifUnitaire =
    //             subscription.Mob_TarifAnnuel;
    //           subscriptionFormula.Mob_RegleMensuellement =
    //             subscription.Mob_TarifMensuel;
    //           subscriptionFormula.Mob_RembUnitApplicable =
    //             subscription.Mob_ForfaitRembMensuel;
    //           subscriptionFormula.Mob_RembTotalApplicable =
    //             subscription.Mob_ForfaitRembMensuel;
    //           subscriptionFormula.Mob_Quantite = undefined;
    //           subscriptionFormula.Mob_IntituleMisc = '';
    //           this.requestTransport.Mob_SubscriptionFormula.push(
    //             subscriptionFormula
    //           );
    //           break;
    //         case EnumSubscriptionType[EnumSubscriptionType.Mensuel].toString():
    //           subscriptionFormula.Title = this.requestTransport.Title;
    //           attachmentTransport.Title = element.attachment;
    //           subscriptionFormula.Mob_CodeFormule = subscription.Mob_CodeFormule
    //             ? subscription.Mob_CodeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TypeFormule = subscription.Mob_TypeFormule
    //             ? subscription.Mob_TypeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_RegleMensuellement =
    //             subscription.Mob_TarifMensuel;
    //           subscriptionFormula.Mob_RembUnitApplicable =
    //             subscription.Mob_ForfaitRembMensuel;
    //           subscriptionFormula.Mob_TarifUnitaire =
    //             subscription.Mob_TarifMensuel;
    //           subscriptionFormula.Mob_RembTotalApplicable =
    //             subscription.Mob_ForfaitRembMensuel;
    //           subscriptionFormula.Mob_Quantite = undefined;
    //           subscriptionFormula.Mob_IntituleMisc = '';
    //           this.requestTransport.Mob_SubscriptionFormula.push(
    //             subscriptionFormula
    //           );
    //           break;
    //         case EnumSubscriptionType[
    //           EnumSubscriptionType.InfraMensuel
    //         ].toString():
    //           subscriptionFormula.Title = this.requestTransport.Title;
    //           attachmentTransport.Title = element.attachment;
    //           subscriptionFormula.Mob_CodeFormule = subscription.Mob_CodeFormule
    //             ? subscription.Mob_CodeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TypeFormule = subscription.Mob_TypeFormule
    //             ? subscription.Mob_TypeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_Quantite = element.quantity;
    //           subscriptionFormula.Mob_RembUnitApplicable =
    //             subscription.Mob_ForfaitRembUnitaire;
    //           subscriptionFormula.Mob_TarifUnitaire =
    //             subscription.Mob_TarifAutre;
    //           subscriptionFormula.Mob_RegleMensuellement =
    //             Number(element.quantity) * subscription.Mob_TarifAutre;
    //           subscriptionFormula.Mob_RembTotalApplicable =
    //             Number(element.quantity) * subscription.Mob_ForfaitRembUnitaire;
    //           this.requestTransport.Mob_SubscriptionFormula.push(
    //             subscriptionFormula
    //           );
    //           break;
    //         case EnumSubscriptionType[EnumSubscriptionType.Misc].toString():
    //           subscriptionFormula.Title = this.requestTransport.Title;
    //           attachmentTransport.Title = element.attachment;
    //           subscriptionFormula.Mob_CodeFormule = subscription.Mob_CodeFormule
    //             ? subscription.Mob_CodeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TypeFormule = subscription.Mob_TypeFormule
    //             ? subscription.Mob_TypeFormule
    //             : undefined;
    //           subscriptionFormula.Mob_TarifUnitaire =
    //             element.tarifUnitaire.replace(',', '.');
    //           subscriptionFormula.Mob_IntituleMisc = element.titleMisc;
    //           this.requestTransport.Mob_SubscriptionFormula.push(
    //             subscriptionFormula
    //           );
    //           break;
    //       }
    //     }
    //   }
    // );
  }

  initializeRequest(): void {
    this.requestTransport.statusDate = moment().toDate();
  }

  attachmentType(): typeof AttachementType {
    return AttachementType;
  }

  deleteRow(rowIndex: any): void {
    this.selectedTypoAbos.splice(rowIndex, 1);
    this.Formulas.removeAt(rowIndex);
    this.Files.splice(rowIndex, 1);

  }

  addRowFormula(RowIndex: number): void {
    this.selectedTypoAbos.push({
      rowIndex: RowIndex,
      number: null,
      tarifName: null,
      File: null,
      isMisc: false,
      showQuantity: false,
    });
    if (this.Formulas.length < 3) {
      this.pushFormLineSubscriptionFormula();
    }
  }

  openFile(base64?: string): void {
    if (base64) this.fileService.openFile(base64);
  }


  private async loadTransportRequest(id: string): Promise<void> {
    this.httpService.findOneById('TransportRequests', id).subscribe({
      next: (value) => {
        this.requestTransport = value;
        this.getListAbonnements();
        this.loadTransportForm(this.requestTransport);
        
      },
    });
  }

  private async loadTransportForm(result: RequestTransport): Promise<void> {
    // commentaire
    this.transportRequestForm.controls['requesterComment'].setValue(
      result.requesterComment ? result.requesterComment : ''
    );

    // // attestation
    // if (result.Mob_AttachmentTransportId[0]) {
    //   await this.loadAttestation(result.Mob_AttachmentTransportId[0]);
    // }
    // subscription Formulas
    let index = 0;
    for (const formula of result.subscriptionFormulas) {
      this.changedFormula.push({
        id: formula.id,
        FormulaId: formula.subscriptionId,
        IdAttachment: formula.attachement,
        EntitledMisc: formula.entitledMisc,
      });
      this.pushFormLineSubscriptionFormula();

      const subscriptionFormulaLine = this.Formulas.controls[
        index
      ] as FormGroup;

      subscriptionFormulaLine.controls['FormulaId'].setValue(
        formula.subscriptionId
      );

      subscriptionFormulaLine.controls['id'].setValue(formula.id);

      subscriptionFormulaLine.controls['Quantity'].setValue(formula.quantity);
      subscriptionFormulaLine.controls['EntitledMisc'].setValue(
        formula.entitledMisc
      );
      subscriptionFormulaLine.controls['File'].setValue(formula.attachement);

      let tarifName = '';
      let showQuantity = false;
      switch (formula.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            formula.subscription.subscriptionTypeRefund?.otherFees
          );
          tarifName = 'Tarif unitaire';
          showQuantity = true;
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(formula.monthlyPayment.toString(), '.')
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValue(
            formula.entitledMisc
          );
          tarifName = 'Tarif réglé pour le mois';
          break;
      }
      this.selectedTypoAbos.push({
        rowIndex: index,
        number: formula.id,
        tarifName: tarifName,
        File: formula.attachement,
        isMisc: formula.formulaType === FormuleTypeEnum.Misc,
        showQuantity: showQuantity,
      });
      index++;
    }

  }

  async getJustifFile(event: any, index: number): Promise<void> {
    this.selectedTypoAbos[index].File = event.target.files[0];
    this.selectedTypoAbos[index].FileType =
      FileType[event.target.files[0].type as keyof typeof FileType];
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(event.target.files[0]);
    subscriptionFormulaLine.controls['FileType'].setValue(
      FileType[event.target.files[0].type as keyof typeof FileType]
    );
    const fileSize = event.target.files[0].size;
      const isFileSizeGreaterThan4MB = fileSize >  1024 * 1024; 
      this.Files[index].fileExists = true;
      this.Files[index].fileGreater = false;

      if (isFileSizeGreaterThan4MB && this.selectedTypoAbos[index].File) {
        this.Files[index].fileGreater = true;
      }
  }

  filesValid():boolean{
    let res=true;
    for (let i =0; i< this.Files.length ;i++){
      if( this.Files[i].fileGreater == true) {
        res=false;
      }
    }
    return res;

  }
  async cancelTransportRequest(): Promise<void> {
    this.httpService
      .patchFormData(
        'TransportRequests',
        this.activatedRoute.snapshot.params['id'],
        {
          CancelRequest: true,
        }
      )
      .subscribe((res) => {
        this.router.navigate(['/mobility-request/list']);
      });
  }
}
