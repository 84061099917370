import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MgtPerson } from '@microsoft/mgt';
import { RoleTypeEnum } from 'src/core/models/role.model';
import User from 'src/core/models/user';
import { environment } from 'src/environments/environment';

MgtPerson.config.useContactApis = true;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  currentUser: User = new User();
  isSuperAdmin: boolean = false;
  isManageCalendar: boolean = false;
  isManagePermissions: boolean = false;
  isManageFormulas: boolean = false;
  isManageArchive: boolean = false;
  activeItem!: any;
  activeMenu!: any;
  constructor(private route: Router) {}
  ngOnInit(): void {
    this.isSuperAdmin = this.isSuperAdminn();
    this.isManageArchive = this.isManageArchivee();
    this.isManageCalendar = this.isManageCalendarr();
    this.isManageFormulas = this.isManageFormulass();
    this.isManagePermissions = this.isManagePermissionss();
    this.activeMenu = JSON.parse(
      localStorage.getItem('activeMenu') || '{}'
    ).active;
    this.activeItem = JSON.parse(
      localStorage.getItem('activeItem') || '{}'
    ).active;
  }
  get environement(): typeof environment {
    return environment;
  }
  getListing(type: string) {
    if (type === 'superAdminPermission') {
      this.route.navigate(['/super-admin/permission']);
    }
    if (type === 'superAdminCalendrier') {
      this.route.navigate(['/super-admin/calendrier']);
    }
    if (type === 'superAdminArchive') {
      this.route.navigate(['/super-admin/archive']);
    }
    if (type === 'superAdminFormulas') {
      this.route.navigate(['/super-admin/formulas']);
    }
  }
  setActiveItem(item: string) {
    this.activeItem = item;
    localStorage.setItem(
      'activeItem',
      JSON.stringify({ active: this.activeItem })
    );
  }
  setActiveMenu(item: string) {
    if (item !== 'adminRH' && item !== 'superAdmin') {
      this.activeItem = '';
    }
    this.activeMenu = item;
    localStorage.setItem(
      'activeMenu',
      JSON.stringify({ active: this.activeMenu })
    );
  }
  isManageArchivee(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.MANAGE_ARCHIVE;
      const roleText: string = role.toString(); 
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }
  isManagePermissionss(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.MANAGE_PERMISSIONS;
      const roleText: string = role.toString(); 
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }
  isManageFormulass(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.MANAGE_FORMULAS;
      const roleText: string = role.toString(); 
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }
  isManageCalendarr(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.MANAGE_CALENDAR;
      const roleText: string = role.toString(); 
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }
  isSuperAdminn(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.SUPER_ADMIN;
      const roleText: string = role.toString(); 
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }
}
