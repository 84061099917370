<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/transport.jpg'"
        />
      </div>
      <div class="BannerGradient1 padding-left">
        <div class="col-md-12 margin-top mb-3">
          <span class="ArrowVelo"
            ><a routerLink="/mobility-request/list"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleVelo">Ma demande détaillée </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="col-md-12 margin-top mt-4">
          <span class="bold Title2Velo semi-bold"
            >Indemnité <span class="bold">Transports</span></span
          >
        </div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-transport-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="SpacerBox margin-top">
    <div class="bold yellowColor titleFormule">
      <div class="squarePuce"></div>
      Période de remboursement
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">A partir du mois suivant</div>
          <div class="form-group">
            <!-- start month -->
            <span class="ml-4 date-box text-center">{{
              requestTransport?.startDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">Jusqu'au mois suivant</div>
          <div class="form-group">
            <span class="ml-4 date-box text-center">{{
              requestTransport?.endDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col" *ngIf="requestTransport?.certificateFmd">
        <div class="piecesContainer mt-3">
          <div class="piecesBoxGray">
            <img
              alt=""
              class="imgBox"
              [src]="environement.baseUrl + 'assets/images/Group.svg'"
            />
          </div>
          <a
            (click)="openFile(requestTransport?.certificateFmd?.toString())"
            class="link"
          >
            <span class="piecesTxt">Attestation pour reconduction</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Formule d'abonnement
      </div>
    </div>
    <!-- debut subcription formula  -->
    <div
      *ngFor="
        let SubscriptionFormula of requestTransport?.subscriptionFormulas;
        let i = index
      "
    >
      <div class="row flex-center">
        <div class="col-md-4">
          <div class="row d-flex align-items-center">
            <div class="col-md-4">Formule d'abonnement</div>
            <div class="form-group col-md-8">
              <span class="intitule-box">{{
                SubscriptionFormula.subscription.title
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row d-flex align-items-center">
            <div class="col-md-4">
              <span
                class="descriptionxt"
                *ngIf="
                  SubscriptionFormula.subscription?.formulaType ==
                  enumSubscriptionType.Annuel
                "
                >Tarif annuel</span
              >
              <span
                class="descriptionxt"
                *ngIf="
                  SubscriptionFormula.subscription?.formulaType ==
                  enumSubscriptionType.Mensuel
                "
                >Tarif mensuel</span
              >
              <span
                class="descriptionxt"
                *ngIf="
                  SubscriptionFormula.subscription?.formulaType ==
                  enumSubscriptionType.InfraMensuel
                "
                >Tarif unitaire</span
              >
              <span
                class="descriptionxt"
                *ngIf="
                  SubscriptionFormula.subscription?.formulaType ==
                  enumSubscriptionType.Misc
                "
                >Tarif réglé pour le mois</span
              >
            </div>
            <div class="form-group col-md-8">
              <span class="date-box text-flex-right">{{
                getForumlaValue(SubscriptionFormula).fees
              }}</span>
              <span class="euro">€</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="piecesContainer mt-2 mb-2">
            <div class="piecesBoxGray">
              <img
                alt=""
                class="imgBox"
                [src]="environement.baseUrl + 'assets/images/Line.svg'"
              />
              <img
                alt=""
                class="imgBox"
                [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
              />
            </div>
            <a
              (click)="openFile(SubscriptionFormula?.attachement?.toString())"
              class="link"
            >
            
              <span class="piecesTxt"
                >Mon justificatif d'abonnement {{ i + 1 }}</span
              >
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div
            class="row d-flex align-items-center"
            *ngIf="SubscriptionFormula.quantity"
          >
            <div class="col-md-4">Quantité</div>
            <div class="col-md-8 form-group">
              <span class="intitule-box">{{
                SubscriptionFormula.quantity
              }}</span>
            </div>
          </div>
          <div
            class="row d-flex align-items-center"
            *ngIf="SubscriptionFormula.entitledMisc"
          >
            <div class="col-md-4">Désignation de votre formule « Autre »</div>
            <div class="col-md-8 form-group">
              <span class="intitule-box">{{
                SubscriptionFormula.entitledMisc
              }}</span>
            </div>
          </div>
          <div
            class="col-md-4"
            *ngIf="
              !SubscriptionFormula.quantity &&
              !SubscriptionFormula.entitledMisc &&
              requestTransport?.status == mobilityRequestStatus.Validated
            "
          ></div>
        </div>
        <div
          class="col-md-4"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
        >
          <div class="row d-flex align-items-center">
            <div class="col-md-4">Indemnité mensuelle applicable</div>
            <div class="col-md-8 form-group">
              <span class="date-box text-flex-right">{{
                getForumlaValue(SubscriptionFormula).refundRate
              }}</span>
              <span class="euro">€</span>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-2 hr2" />
    </div>
    <div
      class="row mt-2"
      *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
    >
      <div class="col-md-4 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Remboursement applicable
      </div>
      <div class="col-md-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">Total versé mensuellement</div>
          <div class="col-md-8 form-group">
            <span class="date-box text-flex-right">{{
              calculateIndemTotal()
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-row-reverse mt-2">
      <div>
        <div class="transport-request">
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Submitted"
            >Statut: Envoyée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
            >Statut: En cours d'examen</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Returned"
            >Statut: A compléter par l’émetteur</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Canceled"
            >Statut: Annulée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Rejected"
            >Statut: Rejetée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
            >Statut: Validée</span
          >
        </div>
        <div
          class="descriptionFormule mt-2"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
        >
          <!-- <div class="">En charge de votre demande:</div>
          <div class="">
            <a
              class="piece-justif-box"
              [href]="'mailto:' + requestTransport?.validator?.email"
              >{{ requestTransport?.validator?.displayName }}</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire demandeur</div>
          <div class="form-group">
            <span
              class="comment-box"
              *ngIf="requestTransport?.requesterComment"
              >{{ requestTransport?.requesterComment || '' }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire valideur</div>
          <div class="form-group">
            <span
              class="comment-box"
              *ngIf="requestTransport?.controlerComment"
              >{{ requestTransport?.controlerComment }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-2 flex-column mt-3">
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary"
            [routerLink]="['/transport/update', requestTransport?.id]"
            *ngIf="
              requestTransport?.status == mobilityRequestStatus.Submitted ||
              requestTransport?.status == mobilityRequestStatus.Returned
            "
          >
            Modifier ma demande
          </button>
          <br />
          <button
            type="button"
            class="btn btn-close-request mt-3"
            routerLink="/mobility-request/list"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>

    <app-pdf-viewer-component
      *ngIf="requestTransport?.certificateFmd"
      [pdfData]="requestTransport?.certificateFmd"
    ></app-pdf-viewer-component>
    <app-transport-condition-popup></app-transport-condition-popup>
  </div>
</div>
