<br />
<span class="text-secondary" *ngIf="!isArchive">Filtrage des demandes</span>
<hr class="hr" *ngIf="!isArchive" />
<div *ngIf="!isArchive" class="filter-container row">
  <div class="col-md-3">
    <div class="filter-txt">Créé à partir de
    </div>
    <div class="d-flex">
      <input
        matInput
        class="form-control date-input ml-4"
        [matDatepicker]="picker"
        (ngModelChange)="dateChanged($event)"
        [(ngModel)]="filterOptions.Created"
      />
      <mat-datepicker-toggle
      matSuffix [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker
      #picker
      ></mat-datepicker>
    </div>
  </div>
  <div class="filter-creteria col-md-3">
    <span class="filter-txt">Nom demandeur</span>
    <app-people-picker
      (resetUser)="resetRequester()"
      [user]="requestor"
      (selectUser)="selectRequester($event)"
      class="ml-2"
    >
    </app-people-picker>
  </div>
  <div class="filter-creteria col-md-3">
    <span class="filter-txt">Bureau</span>
    <mat-form-field class="ml-4">
      <mat-select [(ngModel)]="filterOptions.Site">
        <mat-option value="">Choisir un site</mat-option>
        <mat-option [value]="item.code" *ngFor="let item of listSites">
          {{ item.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>

  <div class="filter-creteria col-md-3">
    <span class="filter-txt">SL</span>
    <mat-form-field class="ml-2">
      <mat-select [(ngModel)]="filterOptions.SL">
        <mat-option value="">Choisir une SL</mat-option>
        <mat-option value="ASU">ASU</mat-option>
        <mat-option value="TAX">TAX</mat-option>
        <mat-option value="EYC">EYC</mat-option>
        <mat-option value="SAT">SAT</mat-option>
        <mat-option value="CBS">CBS</mat-option>
        <mat-option value="FSO">FSO</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="filter-creteria col-md-3">
    <span class="filter-txt">Statut</span>
    <mat-form-field class="ml-4">
      <form
        [formGroup]="statusForm"
        fxFlex
        fxLayout="column"
        autocomplete="off"
      >
        <mat-select formControlName="statusFieldTransport" multiple>
          <mat-option
            #allSelectedTransport
            (click)="toggleAllSelectionTransport()"
            [value]="0"
            >tout sélectionner</mat-option
          >
          <mat-option
            *ngFor="let item of listStatus"
            [value]="item"
            (click)="selectPerOneTransport(allSelectedTransport.viewValue)"
          >
            {{ item }}
          </mat-option>
        </mat-select>
      </form>
    </mat-form-field>
  </div>
  <div class="filter-creteria col-md-3">
    <span class="filter-txt">Traitée par</span>
    <app-people-picker
      (resetUser)="resetValidateur()"
      [user]="validator"
      (selectUser)="selectValidateur($event)"
      class="ml-2"
    >
    </app-people-picker>
  </div>
  <div class="filter-creteria col-md-3">
    <span class="filter-txt">Type</span>
    <mat-form-field class="ml-4">
      <mat-select [(ngModel)]="filterOptions.searchType">
        <mat-option value="">Choisir un type</mat-option>
        <mat-option
          [value]="item.id"
          *ngFor="let item of subscriptions"
        >
          {{ item.title }}</mat-option
        >
        <mat-option value="-1">Demande multi-formules</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <button
      type="button"
      class="btn btn-filter mb-2 mr-3 ml-2"
      (click)="loadRequestTransports(true)"
    >
      Filtrer avec ces critères
    </button>
    <button
      type="button"
      class="btn mb-2 ml-2 btn-reset-filter"
      data-toggle="modal"
      (click)="resetSearchTransport()"
    >
      Annuler tous les critères
    </button>
  </div>
</div>
<hr *ngIf="!isArchive" class="hr" />
<div *ngIf="requestTransports.length > 0 && !isArchive">
  <button
    type="button"
    class="btn btn-primary float-right"
    (click)="getExcelFileTransport()"
  >
    Export Excel
  </button>
</div>
<div class="d-flex justify-content-center" *ngIf="isArchive && requestTransports.length==0">
  <p class="no-data">
    Aucune demande d'indemnité de transport
  </p>
</div>
<!-- end filtre -->
<table
  matSort
  (matSortChange)="sortTransportRequest($event)"
  mat-table
  [dataSource]="requestTransports"
  *ngIf="requestTransports.length > 0"
>
  <ng-container matColumnDef="Created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Date Submission</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.created | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Requester">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Demandeur</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.requester.displayName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="SiteRattachement">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Bureau</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      <div *ngFor="let site of listSites">
        <div *ngIf="element.requester.locationCode == site.code">
          {{ site.name }}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="ServiceLine">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">SL</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">{{ element.requester.slTgm }}</td>
  </ng-container>

  <ng-container matColumnDef="EntiteJuridique">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">BU</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.requester.myCompanyDesc }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Grade">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Rank</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">{{ element.requester.rankDesc }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="15">Type</th>
    <td  mat-cell *matCellDef="let element" [style.width.%]="15">
      <span class="txt-tab" *ngIf="element.subscriptionFormulas.length == 1">
      {{ element.subscriptionFormulas[0]?.subscription.title }}</span>
      <span class="txt-tab" *ngIf="element.subscriptionFormulas.length > 1">
        Demande multi-formules</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="StartDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Mois début</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.startDate | date : "MMMM yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef  [style.width.%]="5">Durée</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.startDate | calculateDuration : element.endDate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Statut</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      <span
        class="submitted bold"
        *ngIf="element.status === mobilityRequestStatuss.Submitted"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Submitted].toString()
        }}</span
      >
      <span
        class="assigned bold"
        *ngIf="element.status === mobilityRequestStatuss.Assigned"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Assigned].toString()
        }}</span
      >
      <span
        class="returned bold"
        *ngIf="element.status === mobilityRequestStatuss.Returned"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Returned].toString()
        }}</span
      >
      <span
        class="canceled bold"
        *ngIf="element.status === mobilityRequestStatuss.Canceled"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Canceled].toString()
        }}</span
      >
      <span
        class="rejected bold"
        *ngIf="element.status === mobilityRequestStatuss.Rejected"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Rejected].toString()
        }}</span
      >
      <span
        class="validated bold"
        *ngIf="element.status === mobilityRequestStatuss.Validated"
        >{{
          mobilityRequestStatuss[mobilityRequestStatuss.Validated].toString()
        }}</span
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="NomValideur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="10">Traitée par</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="10">{{ element.validator?.displayName }}</td>
  </ng-container>

  <ng-container matColumnDef="StatusDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header  [style.width.%]="5">Date de mise à jour</th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      {{ element.statusDate | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef  [style.width.%]="5" ></th>
    <td mat-cell *matCellDef="let element"  [style.width.%]="5">
      <div class="semi-bold" *ngIf="!isArchive">
        <button
          [routerLink]="[
            '/transport-admin/view',
            element.id
          ]"
          class="btn btnVoirModifier link"
        >
          Voir
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumnsTransport"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsTransport"></tr>
</table>
<mat-paginator
*ngIf="requestTransports.length>0 && !isArchive"
  class="request-paginator"
  #paginatorRequestTransport
  (page)="pageChanged($event)"
  [length]="totalRows"
  [pageIndex]="currentPage-1"
  [pageSize]="filterOptions.pageSize"
  [pageSizeOptions]="[5, 10, 20, 30]"
>
</mat-paginator>

<mat-paginator
*ngIf="requestTransports.length>0 && isArchive"
  class="request-paginator"
  #paginatorRequestTransport
  (page)="pageChangedBeforeArchive($event)"
  [length]="totalRows"
  [pageIndex]="currentPage-1"
  [pageSize]="filterOptions.pageSize"
  [pageSizeOptions]="[5, 10, 20, 30]"
>
</mat-paginator>
