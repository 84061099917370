<div class="row">
  <div class="col-md-12">
    <div class="banner-admin">
      <div class="col-md-12 padding-left">
        <span class="ArrowVelo"
          ><a routerLink="/"
            ><img
              alt=""
              [src]="
                environement.baseUrl + 'assets/images/Arrow.svg'
              " /></a></span
        ><span class="bold TitleVelo">Espace Admin - Permissions </span>
        <div>
          <img
            alt=""
            class="DividerVelo"
            [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spacer-box">
  <br />
  <span class="text-secondary">Filtrage des Utilisateurs</span>
  <hr class="hr" />
  <div  class="filter-container row">
    <div class="filter-creteria col-md-2">
      <span class="filter-txt">Nom Prénom</span>
      <app-people-picker
        (resetUser)="resetUser()"
        [user]="user"
        (selectUser)="selectUser($event)"
        class="ml-2"
      >
      </app-people-picker>
    </div>

  
    <div class="filter-creteria col-md-3">
      <span class="filter-txt">Role</span>
      <mat-form-field class="ml-4">
        <form
          [formGroup]="rolesForm"
          fxFlex
          fxLayout="column"
          autocomplete="off"
        >
          <mat-select formControlName="rolesFieldTransport" multiple>
            <mat-option
              #allSelectedpermission
              (click)="toggleAllSelectionPermission()"
              [value]="0"
              >tout sélectionner</mat-option
            >
            <mat-option
              *ngFor="let item of listRole"
              [value]="item"
              (click)="selectPerOneTransport(allSelectedpermission.viewValue)"
            >
              {{ item }}
            </mat-option>
          </mat-select>
        </form>
      </mat-form-field>
    </div>
  
    
    

    <div class="col-md-2">
      <button
        type="button"
        class="btn btn-filter mt-1 mr-3 ml-2"
        (click)="loadListPermission(true)"
      >
        Filtrer avec ces critères
      </button>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        class="btn mt-1 ml-2 btn-reset-filter"
        data-toggle="modal"
        (click)="resetSearchPermission()"
      >
        Annuler tous les critères
      </button>
    </div>
  </div>
  <hr class="hr" />

  <div class="row justify-content-end">
    <div class="col-md-2">
      <button class="btn btn-ajouter blod mt-4 px-1 mr-3"
      (click)="openAddModal()"
       >
        Ajouter un utilisateur
      </button>
    </div>
  </div>
  <!-- <div class="row">



  </div> -->
  <table
    matsort
    mat-table
    [dataSource]="userPermission"
    *ngIf="userPermission.length > 0"
  >
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef>Nom Prénom</th>
      <td mat-cell *matCellDef="let element">
        <div class="employee-full-name">{{ element?.displayName }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element" >
        <div class="employee-full-name">{{ element?.email }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="rankDesc">
      <th mat-header-cell *matHeaderCellDef>Rank</th>
      <td mat-cell *matCellDef="let element">{{ element?.rankDesc }}</td>
    </ng-container>
    <ng-container matColumnDef="sslDesc">
      <th mat-header-cell *matHeaderCellDef>SSL</th>
      <td mat-cell *matCellDef="let element">{{ element?.sslDesc }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th class="actions" mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="actions" mat-cell *matCellDef="let element">
        <span
          type="button"
          class="pi pi-user-edit white-button mr-0"
          (click)="updatePermission(element)"
        ></span>
        <span
          type="button"
          class="pi pi-trash white-button mr-0"
          (click)="openPopUpDelete(element)"
        ></span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsUser"></tr>
  </table>
  <mat-paginator
  *ngIf="userPermission.length>0"
    class="request-paginator"
    #paginatorRequestVelo
    (page)="pageChanged($event)"
    [pageIndex]="currentPage - 1"
    [pageSize]="pageSize"
    [length]="totalRows"
    [pageSizeOptions]="[5, 10, 20, 30]"
    >
  </mat-paginator>
</div>
<span
  #openDeleteConfirm
  data-target="#open-delete-confirm"
  data-toggle="modal"
></span>
<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="open-delete-confirm"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="exampleModalLabel">
          Supprimer la permission
        </h5>
      </div>
      <div class="modal-body">Etes vous sur de vouloir supprimer?</div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="deletePermission()"
        >
          Supprimer
        </button>
        <button
          type="button"
          #closeButton
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<span
  #openUpdatePopup
  data-target="#open-update-popup"
  data-toggle="modal"
></span>
<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="open-update-popup"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="exampleModalLabel">
          Ajouter ou enlever des roles
        </h5>
      </div>
      <div class="modal-body" style="height: 120px">
        <!-- <h3>{{ permissionToUpdate.displayName }}</h3> -->
        <form [formGroup]="permissionForm" >
          <div class="col-12">
            <div class="surface-50 card">
              <div class="p-fluid">
                <span class="p-float-label">
                  <div class="select-container">
                    <mat-select class="multiselect-permission" formControlName="selectedRoles" multiple panelClass="box-option"
                    style="padding: 1%;"
                    (selectionChange)="onRolesSelectionChange($event) "
                    >
                      <mat-select-trigger>
                        <div class="selected-values">
                          <span *ngFor="let role of selectedGroupIds" class="selected-box">
                            {{ getRoleNameById(role) }}
                          </span>
                        </div>
                      </mat-select-trigger>

                      <mat-option class="select-super-admin" *ngFor="let role of allRoles" [value]="role.id" >
                        {{ role.roleName }}
                      </mat-option>
                    </mat-select>

                  </div>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="save" class="btn btn-primary" (click)="submitUpdate()">
          Confirmer
        </button>
        <button
          type="button"
          #closeUpdatePopup
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>


<span
  #openAddUser
  data-target="#open-add-user"
  data-toggle="modal"
></span>
<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="open-add-user"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="exampleModalLabel">
          Ajouter un utilisateur
        </h5>
      </div>
      <div class="modal-body">
        <div class="row">
          Saisissez le prénom et le nom de la personne pour laquelle vous souhaitez ajouter des permissions</div>
        </div>
        <div class="row d-flex justify-content-center">
          <app-people-picker
          (resetUser)="resetRequester()"
          [user]="requestor"
          (selectUser)="selectRequester($event)"
          class="ml-2"
        >
        </app-people-picker>
        </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addUser()"
        >
          Ajouter
        </button>
        <button
          type="button"
          #closeAddButton
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>

