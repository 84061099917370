<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/parking.jpg'"
        />
      </div>
      <div class="row BannerGradient3 padding-left">
        <div class="col-md-6">
          <div class="col-md-12 margin-top mb-3">
            <span class="ArrowVelo"
              ><a href="#" data-toggle="modal" data-target="#close-form"
                ><img
                  alt=""
                  [src]="
                    environement.baseUrl + 'assets/images/Arrow.svg'
                  " /></a></span
            ><span class="bold TitleParking">Demande détaillée</span>
            <div>
              <img
                alt=""
                class="DividerVelo"
                [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
              />
            </div>
          </div>
          <div class="col-md-12 margin-top mt-4">
            <span class="bold Title2Velo semi-bold"
              >Cartes <span class="bold">Parking</span></span
            >
          </div>
          <div class="col-md-12 bannerDescription">
            <p class="see-condition">
              <a
                href="#"
                class="text-secondary"
                data-toggle="modal"
                data-target="#view-parking-condition"
                >Voir les conditions</a
              >
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right float-right box-requester-admin">
            <span class="bold yellowColor requester-name">
              {{ parkingRequest?.requester?.displayName }}
            </span>
            <span class="bold yellowColor requester-name">
              {{ parkingRequest?.requester?.gpn }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- body page -->
  <div class="SpacerBox margin-top">
    <form class="form" [formGroup]="parkingRequestForm" autocomplete="off">
      <div class="bold yellowColor titleFormule">
        <div class="squarePuce"></div>
        Période de remboursement
      </div>
      <div class="row form-inline align-items-end mt-3">
        <div class="form-inline col-md-4">
          <!-- Date début -->
          <label class="mr-2">A partir du mois suivant</label>
          <input
          matInput
          class="form-control text-center"
          [matDatepicker]="pickerr"
          formControlName="startMonth"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerr"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerr></mat-datepicker>
        </div>
        <div class="form-inline col-md-4">
          <!-- Date fin -->
          <input
            matInput
            class="form-control text-center"
            [matDatepicker]="picker"
            formControlName="endMonth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <!-- <div class="row form-inline align-items-end mt-3">
      <div class="form-inline col-md-3">
        <label class="mr-4">Numéro de la carte</label>
        <div>{{ parkingRequest.cardNumber }}</div>
      </div>
    </div> -->
    </form>
    <hr class="hr" />
    <div class="row float-right">
      <div class="col-md-2 mb-2 flex-column">
        <button
          type="button"
          class="btn btn-primary mb-2"
          (click)="updateParkingRequest()"
        >
          <span class="bold">Modifier la demande</span>
        </button>
        <button
          type="button"
          class="btn btn-secondary float-right"
          data-toggle="modal"
          data-target="#close-form"
        >
          Annuler la demande
        </button>
      </div>
    </div>
    <app-parking-condition-popup
      data-target="#view-parking-condition"
    ></app-parking-condition-popup>
    <!-- modal confirm close form -->
    <div
      class="modal fade"
      id="close-form"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCloseForm"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">Fermer la demande</h5>
          </div>
          <div class="modal-body">
            Attention, si vous confirmez cette action vous allez perdre toutes
            les saisies en cours
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Revenir sur la demande
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              routerLink="/mobility-request-admin/list"
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
