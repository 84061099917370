import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-admin.routing';
import { UpdateParkingComponent } from './update/update.component';
import { ViewParkingComponent } from './view/view.component';
import { SharedModule } from "../../shared/shared.module";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [UpdateParkingComponent, ViewParkingComponent, ListComponent],
    exports: [UpdateParkingComponent, ViewParkingComponent, ListComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      RouterModule.forRoot(routes),
      AngularMaterialModule,
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
    ]
})
export class ParkingModule {}
