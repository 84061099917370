import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { List } from 'linqts';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { FilterOptions } from 'src/core/models/filter-options';
import { QueryOption } from 'src/core/models/query-option';
import { RequestTransport } from 'src/core/models/request-transport';
import { SubscriptionFormula } from 'src/core/models/subscription-formula';
import { SubscriptionType } from 'src/core/models/subscription-type';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { ExcelService } from 'src/core/services/excel.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');

@Component({
  selector: 'app-list-transport',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
})
export class ListComponent implements OnInit {
  @Input() transportData!: boolean;
  @Input() isArchive!: boolean;
  @Output() dataAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();

  statusForm!: FormGroup;
  constant = Canstants;
  filterOptions = new FilterOptions();
  displayedColumnsTransport: string[] = [];
  requestTransports: any[] = [];
  requestTransportsExcel: RequestTransport[] = [];
  toggleFilter = true;
  subscriptions: any;
  // sortOption: any = { active: 'Created', direction: 'desc' };
  listSites: any;
  listStatus: any;
  typeFormule!: any;
  // currentDate = new Date();
  // pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  user: any;
  requestor: any;
  validator: any;
  lastMonth!: Date;
  @ViewChild('paginatorRequestTransport')
  paginatorRequestTransport!: MatPaginator;
  @ViewChild('allSelectedTransport')
  private allSelectedTransport!: MatOption;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private excelService: ExcelService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}
  get mobilityRequestStatuss(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  async ngOnInit(): Promise<void> {
    this.statusForm = this.fb.group({
      statusFieldTransport: new FormControl(''),
    });
    await this.initializeFilter();
    this.user = await this.authService.getConnectedUser();
    await this.getListAbonnements();
    this.initializeHeader();
    await this.getSites();
    this.getStatus();
    if (
      (this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
        this.user.roles.includes(this.constant.ROLE_VALIDATOR_VELO) ||
        this.transportData) &&
      !this.isArchive
    ) {
      await this.loadRequestTransports();
    }
    if (
      (this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
        this.user.roles.includes(this.constant.ROLE_VALIDATOR_VELO) ||
        this.transportData) &&
      this.isArchive
    ) {
      await this.loadRequestTransportsBeforeArchive();
    }
  }
  async initializeFilter(): Promise<void> {
    const obj: string = localStorage.getItem('Mob_FilterTransport') as string;
    const userFilter = JSON.parse(obj) ? JSON.parse(obj) : null;
    if (userFilter) {
      this.filterOptions.Created = new Date(userFilter.Created).toISOString();
      this.filterOptions.Status = userFilter.Status;
      this.statusForm.controls['statusFieldTransport'].setValue(
        userFilter.Status
      );
      if(userFilter.sortOption){
        this.filterOptions.sortOption.direction = userFilter.sortOption.direction
        this.filterOptions.sortOption.active = userFilter.sortOption.active
      }
      if(userFilter.pageSize){
        this.filterOptions.pageSize = userFilter.pageSize
      }
      if (userFilter.Site) {
        this.filterOptions.Site = userFilter.Site;
      }
      if (userFilter.searchType) {
        this.filterOptions.searchType = userFilter.searchType;
      }
      if (userFilter.SL) {
        this.filterOptions.SL = userFilter.SL;
      }
      if (userFilter.validator) {
        // let user = await this.userService.getUserProfileByEmail(
        //   userFilter.validator
        // );
        // this.selectValidateur(user);
      }
      if (userFilter.requestor) {
        // let user = await this.userService.getUserProfileByEmail(
        //   userFilter.requestor
        // );
        // this.selectRequester(user);
      }
      this.toggleFilter = true;
    } else {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      this.filterOptions.Created = currentDate.toISOString();
      this.filterOptions.pageSize = 10;
      this.filterOptions.sortOption.direction = 'desc'
      this.filterOptions.sortOption.active = 'Created'
      this.toggleFilter = true;

      this.filterOptions.Status = [
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ];
      this.statusForm.controls['statusFieldTransport'].setValue([
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ]);
    }
  }
  async getListAbonnements(): Promise<void> {
    this.subscriptions = [];

    let result: any = [];
    await this.httpService.findAll('SubscriptionTypes').subscribe((abos) => {
      result = abos;
      const grouped: any = new List(result).GroupBy((x: any) => x.formulaCode);
      const codes = Object.getOwnPropertyNames(grouped);
      for (const code of codes) {
        this.subscriptions.push(grouped[code][0]);
      }
      this.subscriptions.sort(function (a: any, b: any) {
        return a.order - b.order;
      });
    });
  }
  dateChanged(event: any) {
    this.toggleFilter = false;
  }
  async loadRequestTransports(fromFilter?: boolean): Promise<void> {
    if (this.filterOptions.Created) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    localStorage.setItem(
      'Mob_FilterTransport',
      JSON.stringify(this.filterOptions)
    );
    if (fromFilter) {
      this.currentPage = 1;
    }
    this.httpService
      .findAll('TransportRequests', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
        pageSize: this.filterOptions.pageSize,
        pageNumber: this.currentPage,
        sortName: this.filterOptions.sortOption.active,
        sortDirection: this.filterOptions.sortOption.direction,
      })
      .subscribe((trs) => {
        this.requestTransports = trs.items;
        setTimeout(() => {
          // this.paginatorRequestTransport.pageIndex = this.currentPage - 1;
          this.paginatorRequestTransport.length = trs.totalCount;
        });
        this.changeDetectorRefs.detectChanges();
      });

    this.toggleFilter = true;
  }

  async loadRequestTransportsBeforeArchive(
    fromFilter?: boolean
  ): Promise<void> {
    this.httpService
      .findAll('TransportRequests', {
        pageSize: this.filterOptions.pageSize,
        pageNumber: this.currentPage,
        sortName: this.filterOptions.sortOption.active,
        sortDirection: this.filterOptions.sortOption.direction,
        IsDeleted: true,
      })
      .subscribe((trs) => {
        this.requestTransports = trs.items;
        let dataExists = false;
        if (trs.items.length > 0) {
          dataExists = true;
          this.dataAvailable.emit(dataExists);
        }
        setTimeout(() => {
          this.paginatorRequestTransport.pageIndex = this.currentPage - 1;
          this.paginatorRequestTransport.length = trs.totalCount;
        });
        this.changeDetectorRefs.detectChanges();
      });
  }

  pageChanged(event: PageEvent) {
    this.filterOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadRequestTransports();
  }

  pageChangedBeforeArchive(event: PageEvent) {
    this.filterOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadRequestTransportsBeforeArchive();
  }

  toggleAllSelectionTransport(): void {
    if (this.allSelectedTransport.selected) {
      this.statusForm.controls['statusFieldTransport'].setValue([
        ...this.listStatus.map((item: any) => item),
        0,
      ]);

      this.filterOptions.Status = [...this.listStatus.map((item: any) => item)];
    } else {
      this.statusForm.controls['statusFieldTransport'].setValue([]);
      this.filterOptions.Status = [];
    }
  }
  selectPerOneTransport(all: any) {
    this.filterOptions.Status =
      this.statusForm.controls['statusFieldTransport'].value;
    if (this.allSelectedTransport.selected) {
      this.allSelectedTransport.deselect();

      return false;
    }
    if (
      this.statusForm.controls['statusFieldTransport'].value.length ==
      this.listStatus.length
    )
      this.allSelectedTransport.select();
    return true;
  }
  getStatus(): void {
    this.listStatus = Object.getOwnPropertyNames(MobilityRequestStatus)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => MobilityRequestStatus[key]);
  }

  async getSites(): Promise<void> {
    this.httpService.findAll('Sites',{
      pageSize: 100,
      SortName:"name",
      SortDirection:"Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items
    });
  }

  async getExcelFileTransport() {
    let RequestTransports: any = [];
    if (this.filterOptions.Created) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    this.httpService
      .findAll('TransportRequests/GetExcelAdminTransport', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
      })
      .subscribe((trs) => {
        this.requestTransportsExcel = trs;
        this.requestTransportsExcel.forEach((elem) => {
          let obj = {
            Created: moment(elem.created).format('DD-MM-YYYY'),
            StartDate: moment(elem.startDate).format('DD-MM-YYYY'),
            EndDate: moment(elem.endDate).format('DD-MM-YYYY'),
            ValidationDate: elem.validatedDate
              ? moment(elem.validatedDate).format('DD-MM-YYYY')
              : '',
            Requester: elem.requester.displayName,
            Email: elem.requester.email,
            Gui: elem.requester.gui,
            Mob_Matricule: elem.requester.gpn,
            Status: MobilityRequestStatus[elem.status],
            EntiteJuridique: elem.requester?.myCompanyDesc,
            Grade: elem.requester?.rankDesc,
            ServiceLine: elem.requester?.slTgm,
            SiteRattachement: elem.requester?.locationCode,
            Bureau: elem.requester?.locationDesc,
            NomValideur: elem.validator?.displayName,
            Mob_SubscriptionFormula: elem.subscriptionFormulas.length > 1 ? "Demande multi-formules" :
              elem.subscriptionFormulas[0].subscription.title,
            SubscriptionType: elem.subscriptionFormulas.length > 1 ? "Demande multi-formules" :
            this.getFormuleType(
              elem.subscriptionFormulas[0].subscription.formulaType
            ),
            StatusDate: moment(elem.statusDate).format('DD-MM-YYYY'),
          };
          RequestTransports.push(obj);
        });
        const res = RequestTransports.map(function (value: any) {
          return Object.values(value);
        });
        let displayedColumnsTransport = [
          'Date submission',
          'Mois début',
          'Mois fin',
          'Date validation',
          'Demandeur',
          'Adresse mail',
          'GUI',
          'Matricule',
          'Statut',
          'BU',
          'Rank',
          'SL',
          'Code Bureau',
          'Nom Bureau',
          'Traitée par',
          'Nom de la formule',
          'Type',
          'Date de mise à jour',
        ];
        this.excelService.generateExcel(
          res,
          null,
          MobilityRequestType[MobilityRequestType.transport].toString(),
          displayedColumnsTransport
        );
      });
  }
  getFormuleType(type: any) {
    switch (type) {
      case 0:
        return FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
      case 1:
        return FormuleTypeEnum[FormuleTypeEnum.InfraMensuel].toString();
      case 2:
        return FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
      case 3:
        return FormuleTypeEnum[FormuleTypeEnum.Misc].toString();
    }
    return null;
  }
  async sortTransportRequest(event: any): Promise<void> {
    this.filterOptions.sortOption = event;
    this.paginatorRequestTransport.pageIndex = 0;
    if (!this.isArchive) {
      await this.loadRequestTransports();
    } else {
      await this.loadRequestTransportsBeforeArchive();
    }
  }
  initializeHeader(): void {
    this.displayedColumnsTransport = [
      'Created',
      'Requester',
      'SiteRattachement',
      'ServiceLine',
      'EntiteJuridique',
      'Grade',
      'type',
      'StartDate',
      'duration',
      'Status',
      'NomValideur',
      'StatusDate',
      'Actions',
    ];
  }

  async resetSearchTransport(): Promise<void> {
    //
    this.filterOptions = new FilterOptions();
    localStorage.removeItem('Mob_FilterTransport');
    this.resetRequester();
    this.resetValidateur();
    await this.initializeFilter();
    this.paginatorRequestTransport.pageIndex = 0;
    this.loadRequestTransports();
    //
  }

  selectRequester(user: any): void {
    this.requestor = user;
    this.filterOptions.Requester = user.email;
  }
  resetRequester() {
    this.requestor = null;
    this.filterOptions.Requester = '';
  }
  selectValidateur(user: any): void {
    this.validator = user;
    this.filterOptions.Validator = user.email;
  }
  resetValidateur() {
    this.validator = null;
    this.filterOptions.Validator = '';
  }
}
