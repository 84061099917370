import Role from "./role.model"

export default class User {
  id!: number
  absenceAppGpn!: string  
  aclBin!: string  
  badgeDec!: string  
  badgeHex!: string  
  buCode!: string  
  buDesc!: string  
  buddyGpn!: string  
  businessTitle!: string  
  cftLeaderGpn!: string  
  contractType!: string  
  contractTypeDesc!: string  
  counselorConnectLeader!: string  
  counselorGpn!: string  
  eaGpn!: string  
  email!: string 
  employCode!: string  
  employCodeDesc!: string  
  firstName!: string  
  fsoBin!: string  
  gpn!: string  
  gui!: string  
  slTgm!:string
  hireDate!: string  
  hrStatusCode!: string 
  lastName!: string  
  locationBgm!: string  
  locationCode!: string  
  locationDesc!: string  
  lockerCode!: string 
  lpn!: string  
  mcCode!: string  
  mobileProNumb!: string  
  muCode!: string  
  serviceLine!:string
  muDesc!: string  
  myAreaDesc!: string  
  myCompanyCode!: string  
  myCompanyDesc!: string  
  myCompetencyCode!: string  
  myCompetencyDesc!: string  
  myCostcenterCode!: string  
  myCostcenterDesc!: string  
  myCountryDesc!: string  
  myDepartmentCode!: string 
  myEcolocationCode!: string  
  myEcolocationDesc!: string  
  myLocalRegionDesc!: string  
  myRegionDesc!: string  
  myVirtualentityCode!: string  
  myVirtualentityDesc!: string 
  ouCode!: string  
  ouDesc!: string  
  permanentBin!: string  
  processingDate!: string  
  rankCode!: string  
  rankDesc!: string 
  seniority!: string  
  slCode!: string  
  slDesc!: string  
  smuCode!: string  
  smuDesc!: string 
  sslCode!: string  
  sourceUser!:number
  sslDesc!: string  
  supervisorGpn!: string 
  workplaceCode!: string 
  roles!: Role[]
  displayName!: string
  timeZone!: string
  avatar!: string
  roleType!: Array<number>
}
