<header class="topbar" [style]="environement.style">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <div class="navbar-header" [style]="environement.style">
            <a class="navbar-brand" routerLink="/">
                <img [src]="environement.baseUrl + 'assets/images/ey-white-logo.png'" alt="EY" class="light-logo" />
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler d-none waves-effect waves-dark" ><i class="icon-menu"></i></a> </li>
            </ul>
            <!-- icon parametre -->
                <button *ngIf="isSuperAdmin || isManageArchive || isManageCalendar || isManageFormulas || isManagePermissions" mat-button [matMenuTriggerFor]="menuSuperAdmin" (click)="setActiveMenu('superAdmin')" class="super-admin">
                    <span class="material-icons">settings</span>
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                      <mat-menu #menuSuperAdmin="matMenu" yPosition="below" class="menu-content-settings">
                          <button *ngIf="isSuperAdmin || isManagePermissions" (click)="setActiveItem('superAdminPermission')" (click)="getListing('superAdminPermission')" mat-menu-item [ngClass]="
                        activeItem === 'superAdminPermission' ? 'active-sub-menu' : ''
                      ">
                      <a class="link-style">Gestion des permissions</a>
                    </button>
                          <button *ngIf="isSuperAdmin || isManageCalendar" (click)="setActiveItem('superAdminSessions')" (click)="getListing('superAdminCalendrier')" mat-menu-item [ngClass]="activeItem === 'superAdminSessions' ? 'active-sub-menu' : ''">
                      <a class="link-style">Gestion Calendrier</a>
                    </button>
                    <button *ngIf="isSuperAdmin || isManageArchive" (click)="setActiveItem('superAdminArchive')" (click)="getListing('superAdminArchive')" mat-menu-item [ngClass]="activeItem === 'superAdminSessions' ? 'active-sub-menu' : ''">
                      <a class="link-style">Gestion Archive</a>
                    </button>
                    <button *ngIf="isSuperAdmin || isManageFormulas" (click)="setActiveItem('superAdminFormulas')" (click)="getListing('superAdminFormulas')" mat-menu-item [ngClass]="activeItem === 'superAdminSessions' ? 'active-sub-menu' : ''">
                        <a class="link-style">Gestion Formules</a>
                    </button>
                      </mat-menu>

            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown u-pro">
                    <mgt-person class="mgt-person" person-query="me" view="twolines"></mgt-person>
                </li>
            </ul>
        </div>
    </nav>
</header>
