export class FilterOptions {
  Created: string | undefined;
  Site: string | undefined;
  Status: Array<string> | undefined;
  searchType: string | undefined;
  Requester: string | undefined;
  Validator: string | undefined;
  SL: string | undefined;
  pageSize : number | undefined;
  sortOption: any = { active: 'Created', direction: 'desc' };
}
