<div class="modal fade" id="view-parking-condition" tabindex="-1" role="dialog" aria-labelledby="modalCloseForm"
     aria-hidden="true">
  <div class="modal-dialog  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title bold">Carte Parking</h4>
        <h5 class="modal-title semi-bold">Modalités et conditions</h5>
      </div>
      <div class="modal-body">
        
        <div class="mt-3">
          Sur les sites EY proposant ce service, et pour les collaborateurs rattachés à ces sites qui remplissent les conditions de grade requises (ou qui y sont autorisés à titre dérogatoire) une Carte Parking peut vous être mise à disposition pour stationner votre véhicule durant la semaine.
        </div>
        <div class="mt-1">
          <span class="yellowColor">Attention : </span> même pour les personnes éligibles, cette attribution se fait toujours dans la limite des stocks de cartes disponibles - eux-mêmes dépendant des capacités des parkings en question.
        </div>
        <div>
          S’il n’y a pas de Carte Parking disponible à vous attribuer, vous pouvez donc toujours bénéficiez de l’indemnité Transports ou de la Subvention Vélo.
        </div>
        <div class="yellowColor bold mt-3">Qui peut en bénéficier ?</div>
        <div class="mt-1">
          Si vous êtes en mesure de lire la présente notice c’est que vous détenez déjà une Carte Parking, et/ou que vous pouvez en formuler la demande.
        </div>
        <div class="mt-1">
          En revanche la mise à disposition d’une Carte Parking ne peut pas être concomitante avec le bénéfice de l’indemnité Transports ou de la subvention Vélo, également octroyées via ce portail :
        </div>
        <ul class="squareList mt1">
          <li>
            si vous souhaitez l’attribution d’une Carte Parking mais que vous bénéficiez déjà d’une des deux autres formules Mobilités, vous devez d’abord mettre fin à cette dernière via le présent portail.
          </li>
          <li>
            réciproquement, si vous souhaitez bénéficier d’une formule Vélo ou Transports et que vous détenez déjà une Carte Parking, vous devez d’abord restituer cette dernière.
          </li>
        </ul>
        <div class="yellowColor bold mt-3">Quels types de stationnement ?</div>
        <div class="mt-1">
          Toujours selon votre site de rattachement et selon votre grade, il peut vous être proposé de choisir entre 2 types de cartes :
        </div>
        <ul class="squareList mt1">
          <li>
            une carte Voiture
          </li>
          <li>
            ou une carte 2-Roues
          </li>
        </ul>
        <div class="mt-1">
          <span class="yellowColor">Attention : </span>il n’est ici question que de titres de stationnement attribués à titre permanent à du personnel EY.
        </div>
        <div class="mt-1">
          Concernant les titres de stationnement temporaires que certains bureaux peuvent aussi occasionnellement accorder à certains collaborateurs, prestataires ou clients, prière de vous référer à la page Intranet dédiée : <a href="https://sites.ey.com/sites/newshubwem/fr/hub/aq/127/150/153"> Intranet WEM - Info pratique : Réservation de places de parking (ey.com)</a>
        </div>
        <div class="yellowColor bold mt-3">Pour quelle durée ?</div>
        <div class="mt-1">
          Une Carte Parking est attribuée pour une durée indéterminée, tant que le collaborateur demeure salarié(e) d’EY et :
        </div>
        <ul class="squareList mt1">
          <li>
            ne change pas de Bureau EY (ou de pays) ;
          </li>
          <li>
            qu’il n’y a pas de changement structurel affectant le contingent de Cartes en circulation chez EY et/ou qu’il n’est pas besoin de réaffecter des Cartes à d’autres personnes prioritaires ;
          </li>
          <li>
            qu’il n’y a pas d’incident majeur relevé avec l’utilisation de la Carte, et que celle-ci fait l’objet d’un usage minimum de la part de son titulaire.
          </li>
        </ul>
        <div class="mt-1">
          Les services Parking de chaque site se réservent le droit de demander la restitution de cartes manifestement sous-utilisées : voir fréquences minimales mensuelles sur votre intranet :<a href="https://sites.ey.com/sites/newshubwem/fr/hub/aq/127/150/153"> Intranet WEM - Info pratique : Réservation de places de parking (ey.com)</a>
        </div>
        <div class="yellowColor bold mt-3">Où vous renseigner, où retirer et restituer votre Carte sur votre site :</div>
        <div class="mt-1">
          Vous trouverez ces informations pratiques sur la page dédiée de votre Intranet :<a href="https://sites.ey.com/sites/newshubwem/fr/hub/aq/127/150/153"> Intranet WEM - Info pratique : Réservation de places de parking (ey.com)</a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>
