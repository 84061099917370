import { FileService } from './../../../shared/file.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from 'linqts';
import * as moment from 'moment-timezone';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { FileType } from 'src/core/enum/FileTypeEnum';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { AttachementType } from 'src/core/models/attachement-request';
import { RequestTransport } from 'src/core/models/request-transport';
import { SubscriptionFormula } from 'src/core/models/subscription-formula';
import { SubscriptionType } from 'src/core/models/subscription-type';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { environment } from 'src/environments/environment';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateTransportComponent implements OnInit {
  connectedUser: User | undefined;
  requesterUser: User | undefined;
  peoplePlan: any;
  requestVelos: any;
  requestTransports: any;
  requestParkings: any;
  indemMiscToChange!: any;
  indexToChangeMisc!: any;
  isEditedMisc: boolean = false;
  limiteValidTrs: any;
  openPopUpValide: boolean = false;
  requestTransport: RequestTransport = new RequestTransport();
  transportRequestForm!: FormGroup;
  subscriptions: Array<SubscriptionType> = [];
  subscriptionFormula: SubscriptionFormula = new SubscriptionFormula();
  subscriptionFormulas: Array<SubscriptionFormula> = [];
  attachmentToBlob: Array<any> = [];
  changedFormula: Array<any> = [];
  submitted = false;
  attestationFile: any;
  quantity: any = null;
  miscValues: any[] = [];
  currIndem: any = '';
  selectedTypoAbos: Array<any> = [];
  tarifMisc: any = null;
  tarifAutreInfra: any = null;
  forfaitRembUnitaire: any = null;
  @ViewChild('openForumlaAlreadyExist') openForumlaAlreadyExist!: ElementRef;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  @ViewChild('openModalNegativeNumberMisc')
  openModalNegativeNumberMisc!: ElementRef;
  @ViewChild('CloseModalNegativeNumber')
  CloseModalNegativeNumber!: ElementRef;
  @ViewChild('modalUpdateRequest')
  modalUpdateRequest!: ElementRef;
  changedAttachment: Array<boolean> = [];
  personalAddress!: string;
  indemInit: number = 0;
  Files: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private moneyService: MoneyService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private fileService: FileService,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  get form(): any {
    return this.transportRequestForm.controls;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  async ngOnInit(): Promise<void> {
    this.indemInit = 0;

    await this.loadTransportRequest();
    this.initializeRequest();
    this.connectedUser = await this.authService.getConnectedUser();
  }
  async getRequesterAddress(): Promise<void> {
    this.httpService
      .findOneById('Users', this.requestTransport.requester.gpn)
      .subscribe((result: any) => {
        this.personalAddress = result.address;
      });
  }

  testFormat(value: any, index: any) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    const formulasArray = this.transportRequestForm.get(
      'Formulas'
    ) as FormArray;
    let miscrefundRate = formulasArray.at(index).get('MiscRefundRate')?.value;
    if (this.selectedTypoAbos[index].formula) {
      if (!isnum || a == b) {
        this.openModalNegativeNumber.nativeElement.click();
        subscriptionFormulaLine.controls['Price'].setValue(
          this.moneyService.format(
            this.selectedTypoAbos[index].formula.monthlyPayment.toString(),
            '.'
          )
        );
      } else {
        if (miscrefundRate == null || miscrefundRate == '') {
          let monthlyRefundRate =
            this.selectedTypoAbos[index]?.formula.subscription
              .subscriptionTypeRefund.monthlyRefundRate;
          let monthlyPayment: any = 0;
          if (value.target.value.includes(',')) {
            monthlyPayment = value.target.value.replace(',', '.');
          }
          monthlyPayment = parseFloat(monthlyPayment);
          let newRefundRateFloat = (monthlyPayment * monthlyRefundRate) / 100;
          let newRefundRate = this.moneyService.format(
            newRefundRateFloat.toString(),
            '.'
          );
          this.miscValues.splice(index, 0, newRefundRate);
          this.selectedTypoAbos[index].formula.miscRefundRate =
            newRefundRateFloat;
          this.selectedTypoAbos[index].formula.monthlyPayment = monthlyPayment;
          // formulasArray.at(index).get("MiscRefundRate")?.setValue(newRefundRateFloat);
          this.calculateIndemTotal();
        } else {
          let monthlyPayment: any = 0;
          if (value.target.value.includes(',')) {
            monthlyPayment = value.target.value.replace(',', '.');
          }
          monthlyPayment = parseFloat(monthlyPayment);
          this.selectedTypoAbos[index].formula.monthlyPayment = monthlyPayment;
        }
      }
    } else {
      if (!isnum || a == b) {
        this.openModalNegativeNumber.nativeElement.click();
        subscriptionFormulaLine.controls['Price'].setValue('0,00');
      } else {
        let monthlyRefundRate =
          this.selectedTypoAbos[index]?.subscriptionFormula
            .subscriptionTypeRefund.monthlyRefundRate;
        let monthlyPayment: any = 0;
        if (value.target.value.includes(',')) {
          monthlyPayment = value.target.value.replace(',', '.');
        }
        monthlyPayment = parseFloat(monthlyPayment);
        let newRefundRateFloat = (monthlyPayment * monthlyRefundRate) / 100;
        let newRefundRate = this.moneyService.format(
          newRefundRateFloat.toString(),
          '.'
        );
        this.miscValues.splice(index, 0, newRefundRate);
        if (!this.selectedTypoAbos[index].formula) {
          this.selectedTypoAbos[index].formula = {}; // Create the formula object
        }

        this.selectedTypoAbos[index].formula.miscRefundRate =
          newRefundRateFloat;
        this.selectedTypoAbos[index].formula.monthlyPayment = monthlyPayment;
        this.selectedTypoAbos[index].formula.subscription =
          this.selectedTypoAbos[index]?.subscriptionFormula;
        this.calculateIndemTotal();
      }
    }
  }

  private initializeForm(): void {
    this.transportRequestForm = this.formBuilder.group({
      Formulas: this.formBuilder.array([]),
      ControlerComment: [''],
      requesterComment: [''],
      totalVerseMensuellement: [''],
    });
  }

  get Formulas(): FormArray {
    return this.transportRequestForm.controls['Formulas'] as FormArray;
  }

  set Formulas(formArray: FormArray) {
    this.transportRequestForm.controls['Formulas'] = formArray;
  }
  get environement(): typeof environment {
    return environment;
  }
  async getListAbonnements(): Promise<void> {
    this.httpService
      .findAll(
        'subscriptionTypes?StartDate=' +
          new Date(this.requestTransport.startDate).toISOString()
      )
      .subscribe((subscriptions: Array<SubscriptionType>) => {
        const grouped: any = new List(subscriptions).GroupBy(
          (x: any) => x.formulaCode
        );
        const codes = Object.getOwnPropertyNames(grouped);
        for (const code of codes) {
          if (
            this.requestTransport.startDate === this.requestTransport.endDate
          ) {
            this.subscriptions.push(grouped[code][0]);
          } else {
            if (
              grouped[code][0].formulaType == FormuleTypeEnum.Annuel ||
              grouped[code][0].formulaType == FormuleTypeEnum.Misc
            ) {
              this.subscriptions.push(grouped[code][0]);
            }
          }
        }
        this.subscriptions.sort(function (a: any, b: any) {
          return a.order - b.order;
        });

        // });
      });
  }
  filesValid(): boolean {
    let res = true;
    for (let i = 0; i < this.Files.length; i++) {
      if (this.Files[i].fileGreater == true) {
        res = false;
      }
    }
    return res;
  }

  async getFile(event: any, index: number): Promise<void> {
    this.selectedTypoAbos[index].File = event.target.files[0];
    this.selectedTypoAbos[index].FileType =
      FileType[event.target.files[0].type as keyof typeof FileType];
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(event.target.files[0]);
    subscriptionFormulaLine.controls['FileType'].setValue(
      FileType[event.target.files[0].type as keyof typeof FileType]
    );
    const fileSize = event.target.files[0].size;
    const isFileSizeGreaterThan4MB = fileSize > 1024 * 1024;
    this.Files[index].fileExists = true;
    this.Files[index].fileGreater = false;

    if (isFileSizeGreaterThan4MB && this.selectedTypoAbos[index].File) {
      this.Files[index].fileGreater = true;
    }
  }

  openFile(base64?: string): void {
    if (base64) this.fileService.openFile(base64);
  }

  selectTypeAbonnement(event: any, rowIndex: number): void {
    let subscriptionType = this.subscriptions.find(
      (s) => s.id == event.target.value
    );
    if (subscriptionType) {
      const subscriptionFormulaLine = this.Formulas.controls[
        rowIndex
      ] as FormGroup;

      this.selectedTypoAbos[rowIndex].subscriptionFormula = subscriptionType;
      this.selectedTypoAbos[rowIndex].formula.subscription = subscriptionType;
      subscriptionFormulaLine.controls['FormulaId'].setValue(
        subscriptionType.id
      );
      switch (subscriptionType.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType?.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          subscriptionFormulaLine.controls['EntitledMisc'].clearValidators();
          subscriptionFormulaLine.controls[
            'EntitledMisc'
          ].updateValueAndValidity();
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['MiscRefundRate'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );
          subscriptionFormulaLine.controls['EntitledMisc'].clearValidators();
          subscriptionFormulaLine.controls[
            'EntitledMisc'
          ].updateValueAndValidity();
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['MiscRefundRate'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.otherFees.toString(),
              '.'
            )
          );
          this.tarifAutreInfra =
            subscriptionType.subscriptionTypeRefund?.otherFees;
          this.forfaitRembUnitaire =
            subscriptionType.subscriptionTypeRefund?.unitRefundRate;
          subscriptionFormulaLine.controls['EntitledMisc'].clearValidators();
          subscriptionFormulaLine.controls[
            'EntitledMisc'
          ].updateValueAndValidity();
          subscriptionFormulaLine.controls['MiscRefundRate'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.required
          );
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = true;
          this.selectedTypoAbos[rowIndex].tarifName = 'Tarif unitaire';
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(this.tarifMisc);
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.required
          );
          subscriptionFormulaLine.controls['MiscRefundRate'].setValidators(
            Validators.required
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].isMisc = true;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          this.selectedTypoAbos[rowIndex].subscriptionFormula =
            subscriptionType;
          this.selectedTypoAbos[rowIndex].monthlyPayment = 0;

          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif réglé pour le mois';
          break;
      }
      // this.getForumlaValue( subscriptionType,rowIndex)
      this.removeAttachement(rowIndex);
    }
    this.calculateIndemTotal();
  }

  removeAttachement(index: number): void {
    this.selectedTypoAbos[index].File = null;
    const subscriptionFormulaLine = this.Formulas.controls[index] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(null);
    this.Files[index].fileExists = false;
    this.Files[index].fileGreater = false;
  }

  private pushFormLineSubscriptionFormula(): void {
    const Formulas = this.formBuilder.group({
      id: [''],
      FormulaId: ['', Validators.required],
      Price: ['', Validators.required],
      EntitledMisc: [''],
      Quantity: [''],
      File: ['', Validators.required],
      FileType: [''],
      MiscRefundRate: [''],
      attachment: [''],
    });
    this.Formulas.push(Formulas);
    this.Files.push({});
  }

  async updateTransportRequest(): Promise<void> {
    this.submitted = true;
    if (!this.transportRequestForm?.valid) {
      return;
    }
    const id = this.activatedRoute.snapshot.params['id'];
    await this.httpService
      .findOneById('TransportRequests', id)
      .subscribe(async (result: any) => {
        this.requestTransport = result;
        if (
          this.requestTransport.validator.email == this.connectedUser?.email
        ) {
          const formulasArray = this.transportRequestForm.get(
            'Formulas'
          ) as FormArray;
          for (let i = 0; i < formulasArray.length; i++) {
            let formdeI = formulasArray.at(i).get('EntitledMisc')?.value;
            if (formdeI != null) {
              let miscrefundRate = formulasArray
                .at(i)
                .get('MiscRefundRate')?.value;
              if (miscrefundRate != '') {
                if (
                  miscrefundRate.includes('.') ||
                  !miscrefundRate.includes(',')
                ) {
                  miscrefundRate = parseFloat(miscrefundRate);
                } else {
                  const newValueMisc = miscrefundRate.replace(',', '.');
                  miscrefundRate = parseFloat(newValueMisc);
                }
                formulasArray
                  .at(i)
                  .get('MiscRefundRate')
                  ?.setValue(miscrefundRate);
              }

              const priceControl = formulasArray.at(i).get('Price');
              let value = priceControl?.value;
              if (value.includes('.') || !value.includes(',')) {
                value = parseFloat(value);
              } else {
                const newValue = value.replace(',', '.');
                value = parseFloat(newValue);
              }
              priceControl?.setValue(value);
            }
          }

          this.httpService
            .patchFormData(
              'TransportRequests',
              this.activatedRoute.snapshot.params['id'],
              {
                Formulas: formulasArray.value,
                ControlerComment:
                  this.transportRequestForm.controls['ControlerComment'].value,
                RequesterComment:
                  this.transportRequestForm.controls['requesterComment'].value,
                IsAdmin: true,
              }
            )
            .subscribe((res) => {
              this.router.navigate(['/mobility-request-admin/list']);
            });
        } else {
          this.modalUpdateRequest.nativeElement.click();
          await this.loadTransportRequest();
        }
      },
      (error) => {
        if (error.status === 500) {
          this.dialog.open(ErrorDialogComponent, {
            data: { message: 'Oups, problème système' }
          });
        } 
      });
  }

  initializeRequest(): void {
    this.requestTransport.statusDate = moment().toDate();
  }

  attachmentType(): typeof AttachementType {
    return AttachementType;
  }

  deleteRow(rowIndex: any): void {
    this.selectedTypoAbos.splice(rowIndex, 1);
    this.Formulas.removeAt(rowIndex);
    this.calculateIndemTotal();
    this.Files.splice(rowIndex, 1);
  }

  addRowFormula(RowIndex: number): void {
    this.selectedTypoAbos.push({
      rowIndex: RowIndex,
      number: null,
      tarifName: null,
      File: null,
      isMisc: false,
      showQuantity: false,
    });
    if (this.Formulas.length < 3) {
      this.pushFormLineSubscriptionFormula();
    }
  }

  async loadTransportRequest(): Promise<void> {
    const id = this.activatedRoute.snapshot.params['id'];
    this.httpService.findOneById('TransportRequests', id).subscribe({
      next: (value) => {
        this.requestTransport = value;
        this.getRequesterAddress();
        this.getListAbonnements();
        this.loadTransportForm(this.requestTransport);
      },
    });
  }
  resetIndemnite(result: any) {
    let index = 0;
    for (const formula of result.subscriptionFormulas) {
      const subscriptionFormulaLine = this.Formulas.controls[
        index
      ] as FormGroup;

      let tarifName = '';
      let showQuantity = false;
      switch (formula.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            formula.subscription.subscriptionTypeRefund?.otherFees
          );
          tarifName = 'Tarif unitaire';
          showQuantity = true;
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(formula.monthlyPayment.toString(), '.')
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValue(
            formula.entitledMisc
          );

          const refundMisc =
            formula?.miscRefundRate != null
              ? formula?.miscRefundRate
              : (formula?.monthlyPayment *
                  formula?.subscription?.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                100;
          this.indemMiscToChange = this.moneyService.format(
            refundMisc.toString(),
            '.'
          );
          tarifName = 'Tarif réglé pour le mois';
          break;
      }
      index++;
    }
  }
  async loadTransportForm(result: any): Promise<void> {
    if (result.requesterComment != 'null') {
      this.transportRequestForm.controls['requesterComment'].setValue(
        result.requesterComment
      );
    } else {
      this.transportRequestForm.controls['requesterComment'].setValue('');
    }

    if (result.controlerComment != 'null') {
      this.transportRequestForm.controls['ControlerComment'].setValue(
        result.controlerComment
      );
    } else {
      this.transportRequestForm.controls['ControlerComment'].setValue('');
    }

    let index = 0;
    for (const formula of result.subscriptionFormulas) {
      this.changedFormula.push({
        id: formula.id,
        FormulaId: formula.subscriptionId,
        IdAttachment: formula.attachement,
        EntitledMisc: formula.entitledMisc,
      });
      this.pushFormLineSubscriptionFormula();

      const subscriptionFormulaLine = this.Formulas.controls[
        index
      ] as FormGroup;

      subscriptionFormulaLine.controls['FormulaId'].setValue(
        formula.subscriptionId
      );

      subscriptionFormulaLine.controls['id'].setValue(formula.id);

      subscriptionFormulaLine.controls['Quantity'].setValue(formula.quantity);
      subscriptionFormulaLine.controls['EntitledMisc'].setValue(
        formula.entitledMisc
      );
      subscriptionFormulaLine.controls['File'].setValue(formula.attachement);

      let tarifName = '';
      let showQuantity = false;
      switch (formula.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );
          tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            formula.subscription.subscriptionTypeRefund?.otherFees
          );
          tarifName = 'Tarif unitaire';
          showQuantity = true;
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(formula.monthlyPayment.toString(), '.')
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValue(
            formula.entitledMisc
          );
          const refundMisc =
            formula?.miscRefundRate != null
              ? formula?.miscRefundRate
              : (formula?.monthlyPayment *
                  formula?.subscription?.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                100;
          this.indemMiscToChange = this.moneyService.format(
            refundMisc.toString(),
            '.'
          );
          tarifName = 'Tarif réglé pour le mois';
          break;
      }
      this.selectedTypoAbos.push({
        rowIndex: index,
        number: formula.id,
        tarifName: tarifName,
        File: formula.attachement,
        isMisc: formula.formulaType === FormuleTypeEnum.Misc,
        showQuantity: showQuantity,
        subscriptionFormula: formula.subscription,
        formula: formula,
        miscRefundRate: formula.miscRefundRate,
      });
      index++;
    }
  }
  getForumlaValue(formula: any, index: any) {
    if (formula != undefined) {
      if (formula.subscription) {
        switch (formula.subscription.formulaType) {
          case FormuleTypeEnum.Annuel:
            const refundA = formula?.subscription.subscriptionTypeRefund
              ?.percent
              ? ((formula?.subscription.subscriptionTypeRefund?.annualFees /
                  12) *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                100
              : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
            return {
              fees: this.moneyService.format(
                formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundA.toString(), '.'),
            };
          case FormuleTypeEnum.Mensuel:
            const refundM = formula?.subscription.subscriptionTypeRefund
              ?.percent
              ? (formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                100
              : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;

            return {
              fees: this.moneyService.format(
                formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundM.toString(), '.'),
            };
          case FormuleTypeEnum.InfraMensuel:
            const refundI = formula?.subscription.subscriptionTypeRefund
              ?.percent
              ? (formula.quantity *
                  formula?.subscription.subscriptionTypeRefund?.otherFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate) /
                100
              : formula?.subscription.subscriptionTypeRefund?.unitRefundRate *
                formula.quantity;

            return {
              fees: this.moneyService.format(
                formula?.subscription.subscriptionTypeRefund?.otherFees?.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundI.toString(), '.'),
            };
          case FormuleTypeEnum.Misc:
            const refundMisc =
              formula.miscRefundRate != null
                ? formula.miscRefundRate.toString().includes(',')
                  ? formula.miscRefundRate
                  : this.moneyService.format(
                      formula.miscRefundRate.toString(),
                      '.'
                    )
                : this.moneyService.format(
                    (
                      (formula.monthlyPayment *
                        formula.subscription.subscriptionTypeRefund
                          .monthlyRefundRate) /
                      100
                    ).toString(),
                    '.'
                  );
            this.indemMiscToChange = refundMisc;
            if (index) {
              this.miscValues.splice(index, 0, refundMisc);
            }
            return {
              fees: formula.monthlyPayment
                ? this.moneyService.format(
                    formula.monthlyPayment?.toString(),
                    '.'
                  )
                : 0,
              refundRate: refundMisc,
            };
          default:
            return {
              fees: 0,
              refundRate: 0,
            };
        }
      } else {
        switch (formula.formulaType) {
          case FormuleTypeEnum.Annuel:
            const refundA = formula?.subscriptionTypeRefund?.percent
              ? ((formula?.subscriptionTypeRefund?.annualFees / 12) *
                  formula?.subscriptionTypeRefund?.monthlyRefundRate) /
                100
              : formula?.subscriptionTypeRefund?.monthlyRefundRate;
            return {
              fees: this.moneyService.format(
                formula?.subscriptionTypeRefund?.annualFees?.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundA.toString(), '.'),
            };
          case FormuleTypeEnum.Mensuel:
            const refundM = formula?.subscriptionTypeRefund?.percent
              ? (formula?.subscriptionTypeRefund?.monthlyFees *
                  formula?.subscriptionTypeRefund?.monthlyRefundRate) /
                100
              : formula?.subscriptionTypeRefund?.monthlyRefundRate;

            return {
              fees: this.moneyService.format(
                formula.subscriptionTypeRefund?.monthlyFees.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundM.toString(), '.'),
            };
          case FormuleTypeEnum.InfraMensuel:
            const refundI = formula?.subscriptionTypeRefund?.percent
              ? (formula.quantity *
                  formula?.subscriptionTypeRefund?.otherFees *
                  formula?.subscriptionTypeRefund?.unitRefundRate) /
                100
              : formula?.subscriptionTypeRefund?.unitRefundRate;

            return {
              fees: this.moneyService.format(
                formula?.subscriptionTypeRefund?.otherFees?.toString(),
                '.'
              ),
              refundRate: this.moneyService.format(refundI.toString(), '.'),
            };
          case FormuleTypeEnum.Misc:
            // const refundMisc =
            //   formula.miscRefundRate != null
            //     ? this.moneyService.format(formula.miscRefundRate.toString(), '.')
            //     : this.moneyService.format(
            //         (
            //           (formula.monthlyPayment *
            //             formula.subscriptionTypeRefund
            //               .monthlyRefundRate) /
            //           100
            //         ).toString(),
            //         '.'
            //       );
            // this.indemMiscToChange = refundMisc;
            // if (index) {
            //   this.miscValues.splice(index, 0, refundMisc);
            // }
            // return {
            //   fees: this.moneyService.format(
            //     formula.monthlyPayment.toString(),
            //     '.'
            //   ),
            //   refundRate: refundMisc,
            // };
            return {
              fees: 0,
              refundRate: 0,
            };
          default:
            return {
              fees: 0,
              refundRate: 0,
            };
        }
      }
    } else
      return {
        fees: 0,
        refundRate: 0,
      };
  }
  getValueIndemMisc(value: any, index: any) {
    this.miscValues.splice(index, 0, value);
    this.currIndem = this.miscValues[index];
    this.indemMiscToChange = value;
    this.indexToChangeMisc = index;
  }
  ChangeValueNewIndem() {
    if (
      this.currIndem != this.miscValues[this.indexToChangeMisc] ||
      this.indexToChangeMisc == 0
    ) {
      let isnum = /^(\d+,)*(\d+)$/.test(this.currIndem);
      let b = parseFloat('0,00');
      let a = parseFloat(this.currIndem);
      if (!isnum || a == b) {
        this.CloseModalNegativeNumber.nativeElement.click();
        this.openModalNegativeNumberMisc.nativeElement.click();
        this.currIndem = '';
        this.miscValues.splice(this.indexToChangeMisc, 0, '');
      } else {
        this.selectedTypoAbos[this.indexToChangeMisc].formula.miscRefundRate =
          this.currIndem;
        const formulasArray = this.transportRequestForm.get(
          'Formulas'
        ) as FormArray;
        formulasArray
          .at(this.indexToChangeMisc)
          .get('MiscRefundRate')
          ?.setValue(this.currIndem);
        this.calculateIndemTotal();
      }
    }
  }
  testFormatMisc(value: any, index: any) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.CloseModalNegativeNumber.nativeElement.click();
      this.openModalNegativeNumberMisc.nativeElement.click();
      this.currIndem = '';
      this.miscValues.splice(index, 0, '');
    } else {
      this.miscValues.splice(this.indexToChangeMisc, 0, value.target.value);
      this.currIndem = this.miscValues[index];
    }
  }

  calculateIndemTotal(event?: any, i?: any): string {
    let total = 0;
    let index = 0;
    const myArray = this.transportRequestForm.get('Formulas') as FormArray;
    for (const subscription of this.selectedTypoAbos) {
      let sub: any = subscription;
      if (sub.tarifName && sub.formula) {
        switch (sub.subscriptionFormula.formulaType) {
          case FormuleTypeEnum.Annuel:
            total = sub?.formula.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  ((sub?.formula.subscription.subscriptionTypeRefund
                    ?.annualFees /
                    12) *
                    sub?.formula.subscription.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.formula.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,
                  '.'
                );
            break;
          case FormuleTypeEnum.Mensuel:
            total = sub?.formula.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  (sub?.formula.subscription.subscriptionTypeRefund
                    ?.monthlyFees *
                    sub?.formula.subscription.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.formula.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,
                  '.'
                );
            break;

          case FormuleTypeEnum.InfraMensuel:
            total = sub?.formula.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  (sub.formula.quantity *
                    sub?.formula.subscription.subscriptionTypeRefund
                      ?.otherFees *
                    sub?.formula.subscription.subscriptionTypeRefund
                      ?.unitRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.formula.subscription.subscriptionTypeRefund
                    ?.unitRefundRate * sub.formula.quantity || 0,
                  '.'
                );
            break;

          case FormuleTypeEnum.Misc:
            const value = myArray.get(index.toString())?.value;
            if (value.MiscRefundRate == null || value.MiscRefundRate == '') {
              total =
                total +
                this.moneyService.formatNumber(
                  (sub.formula.monthlyPayment *
                    sub.formula.subscription.subscriptionTypeRefund
                      .monthlyRefundRate) /
                    100,
                  '.'
                );
            }
            break;
        }
      } else if (sub.tarifName) {
        switch (sub.subscriptionFormula.formulaType) {
          case FormuleTypeEnum.Annuel:
            total = sub?.subscriptionFormula.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  ((sub?.subscriptionFormula.subscriptionTypeRefund
                    ?.annualFees /
                    12) *
                    sub?.subscriptionFormula.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.subscriptionFormula.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,
                  '.'
                );
            break;
          case FormuleTypeEnum.Mensuel:
            total = sub?.subscriptionFormula.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  (sub?.subscriptionFormula.subscriptionTypeRefund
                    ?.monthlyFees *
                    sub?.subscriptionFormula.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.subscriptionFormula.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,
                  '.'
                );
            break;

          case FormuleTypeEnum.InfraMensuel:
            total = sub?.subscriptionFormula.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(
                  (sub.formula.quantity *
                    sub?.subscriptionFormula.subscriptionTypeRefund?.otherFees *
                    sub?.subscriptionFormula.subscriptionTypeRefund
                      ?.unitRefundRate) /
                    100,
                  '.'
                )
              : total +
                this.moneyService.formatNumber(
                  sub?.subscriptionFormula.subscriptionTypeRefund
                    ?.unitRefundRate || 0,
                  '.'
                );
            break;

          case FormuleTypeEnum.Misc:
            // const value = myArray.get(index.toString())?.value;
            // if (value.MiscRefundRate == null || value.MiscRefundRate == '') {
            //   /////////////////STATIIIIICCCCc
            //   total =
            //     total +
            //     (200 *
            //       sub.subscriptionFormula.subscriptionTypeRefund
            //         .monthlyRefundRate) /
            //       100;
            // }
            break;
        }
      }
      index++;
    }

    for (let i = 0; i < myArray.length; i++) {
      const value = myArray.get(i.toString())?.value;
      if (
        value.MiscRefundRate != null &&
        value.MiscRefundRate &&
        typeof value.MiscRefundRate == 'number'
      ) {
        total = total + value.MiscRefundRate;
      }
      if (
        value.MiscRefundRate != null &&
        value.MiscRefundRate &&
        typeof value.MiscRefundRate != 'number'
      ) {
        const newValue = value.MiscRefundRate.replace(',', '.');
        const valueToAdd = parseFloat(newValue);
        total = total + valueToAdd;
      }
    }
    // var totalString = total.toFixed(2);
    // totalString = totalString.replace('.', ',');
    return this.moneyService.format(total, '.');
  }
}
