export const Canstants = {
    limitValidTransportM: 'TRS_Limit_Valid_M_',
    limitValidVeloM: 'VLO_Limit_Valid_M_',

    submited: 'Submited',
    suspended: 'Suspended',
    returned: 'Returned',
    rejected: 'Rejected',
    validated: 'Validated',
    assigned: 'Assigned',
    cancled: 'Canceled',

    cyclingPackage: 'CyclingPackage',
    parking: 'Parking',
    grantTransportation: 'GrantTransportation',

    transportAllowance: 'Indemnité Transports',
    bicycleSubsidy: 'Subvention Vélo',
    parkingCard: 'Carte Parking',


    ROLE_USER: 'USER',
    ROLE_VALIDATOR: 'VALIDATOR',
    Role_PAIE: 'PAIE',
    ROLE_ACL: 'ACL',
    ROLE_VALIDATOR_TRANSPORT: 'VALIDATOR_TRANSPORT',
    ROLE_VALIDATOR_VELO: 'VALIDATOR_VELO',
    ROLE_VALIDATOR_PARKING: 'VALIDATOR_PARKING',
    ROLE_SUPER_ADMIN: 'SUPER_ADMIN',
    ROLE_ADMIN: 'ADMIN',
    MANAGE_FORMULAS:'MANAGE_FORMULAS',
    MANAGE_CALENDAR:'MANAGE_CALENDAR',
    MANAGE_ARCHIVE:'MANAGE_ARCHIVE',
    MANAGE_PERMISSIONS:'MANAGE_PERMISSIONS'

};
