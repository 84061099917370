import {Routes} from '@angular/router';
import {ListComponent} from './list/list.component';


const routes: Routes = [

  {
    path: 'mobility-request-paie/list',
    component: ListComponent,
  }
];
export default routes;
