import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateBikeComponent } from './bike/create/create.component';
import { RejectBikeComponent } from './bike/reject/reject.component';
import { UpdateBikeComponent } from './bike/update/update.component';
import { ViewBikeComponent } from './bike/view/view.component';
import { ListComponent } from './list/list.component';
import { CreateParkingComponent } from './parking/create/create.component';
import { RejectParkingComponent } from './parking/reject/reject.component';
import { UpdateParkingComponent } from './parking/update/update.component';
import { ViewParkingComponent } from './parking/view/view.component';
import { CreateTransportComponent } from './transport/create/create.component';
import { RejectTransportComponent } from './transport/reject/reject.component';
import { UpdateTransportComponent } from './transport/update/update.component';
import { ViewTransportComponent } from './transport/view/view.component';
import { bikeGuard } from 'src/core/guards/bike.guard';

const routes: Routes = [
  {
    path: 'mobility-request/list',
    component: ListComponent,
  },
  {
    path: 'transport/create',
    component: CreateTransportComponent,
  },
  {
    path: 'transport/update/:id',
    component: UpdateTransportComponent,
  },
  {
    path: 'transport/view/:id/:date',
    component: ViewTransportComponent,
  },
  {
    path: 'transport/view/:id',
    component: ViewTransportComponent,
  },
  {
    path: 'transport/reject/:id/:date',
    component: RejectTransportComponent,
  },
  {
    path: 'bike/create',
    component: CreateBikeComponent,
    canActivate:[bikeGuard]
  },
  {
    path: 'bike/update/:id',
    component: UpdateBikeComponent,
  },
  {
    path: 'bike/view/:id',
    component: ViewBikeComponent,
  },
  {
    path: 'bike/reject/:id',
    component: RejectBikeComponent,
  },
  {
    path: 'parking/create',
    component: CreateParkingComponent,
  },
  {
    path: 'parking/update/:id',
    component: UpdateParkingComponent,
  },
  {
    path: 'parking/view/:id',
    component: ViewParkingComponent,
  },
  {
    path: 'parking/reject/:id',
    component: RejectParkingComponent,
  },
 
];
export default routes;
