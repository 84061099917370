import { FileType } from "../enum/FileTypeEnum";
import { MobilityRequestStatus } from "../enum/mobility-request-status.enum";

export class BikeRequestDto {
  purchaseDate: string | undefined;
  purchaseAmount: number | undefined;
  requesterComment: string | undefined;
  controlerComment: string | undefined;
  requesterAddress: string | undefined;
  status !: MobilityRequestStatus;
  startDate: string | undefined;
  // endDate: string | undefined;
  certificateFMD: File | undefined;
  proofOfPurchase: File | undefined;
  ProofOfPurchaseFileType? : FileType
  vae !:boolean;
  FromVae :boolean = false;
}
