import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-admin.routing';
import { SharedModule } from "../../shared/shared.module";
import { UpdateTransportComponent } from './update/update.component';
import { ViewTransportAdminComponent } from './view/view.component';



@NgModule({
    declarations: [
        UpdateTransportComponent,
        ViewTransportAdminComponent,
        ListComponent
    ],
    exports: [
        UpdateTransportComponent,
        ViewTransportAdminComponent,
        ListComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        AngularMaterialModule,
        RouterModule.forRoot(routes),
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class TransportModule { }
