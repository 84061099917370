<div class="row mt-4">
  <div class="col-xl-9 col-md-8">
    <div class="row description-sub-title">
      <div class="col-md-8 padding-left">
        <div class="yellowColor home-sub-title mb-3">
          Bonjour et bienvenue sur votre
        </div>
        <div class="bold home-title mb-2">Portail Mobilités Transports</div>
        <div>
          <img alt="" [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'" />
        </div>
        <div class="home-description mt-4 mb-4 text-justify">
          <span
            >Dans le cadre de « Net zéro pour 2025 », EY s’est engagé dans un
            objectif de neutralité carbone et investit dans vos mobilités
            domicile/travail, pour plus de modularité avec son Plan de Mobilité
            Durable.
          </span>
        </div>
        <div class="btnMesCours mt-4 mb-4">
          <button
            type="button"
            class="btn txtBtnMesCours"
            routerLink="/mobility-request/list"
          >
            Mon espace collaborateur
          </button>
        </div>
        <div *ngIf="ShowEspacesAdmin" 
        class="btnMesCours">
          <button
            type="button"
            class="btn btn-espace-admin"
            routerLink="/mobility-request-admin/list"
          >
            Espace administrateur
          </button>
        </div>
        <div
          *ngIf="user?.roles.includes(constant.Role_PAIE,constant.ROLE_SUPER_ADMIN)"
          class="btnMesCours mt-4"
        >
          <button
            type="button"
            class="btn btn-espace-extract"
            routerLink="/mobility-request-paie/list"
          >
            Extracts Paie
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <img alt="" class="image-home" [src]="environement.baseUrl + 'assets/images/EY_SMART.png'" />
      </div>
    </div>
    <div class="col-12">
      <div class="row mt-4">
        <div class="col-md-12 align-self-center padding-left">
          <h4 class="yellowColor semi-bold">
            Via ce portail vous pouvez choisir votre mode de transport préféré
            et obtenir le cas échéant, un financement correspondant.
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 padding-left text-justify">
          <div class="col-md-12">
            <div class="yellowColor bold mt-3">
              Qu’est-ce que le Plan Mobilité Durable ?
            </div>
          </div>
          <ul>
            <li>
              <div class="timeline-body font-family-description">
                <div>
                  Chaque collaborateur choisit son mode de déplacement préféré.
                </div>
              </div>
            </li>
            <li>
              <div>
                <p>
                  EY propose une subvention, si vous choisissez de vous déplacer
                  avec votre vélo acquis depuis le 1er janvier 2021 sous réserve
                  d’être présent dans les effectifs EY le jour de l’achat.
                </p>
              </div>
            </li>
          </ul>
          <div class="col-md-12 mt-4">
            <div class="yellowColor bold">Quelles dispositions concrètes ?</div>
            <div class="mt-1">
              Vous choisissez le mode de déplacement qui vous convient, et EY
              vous accompagne :
            </div>
          </div>
          <ul>
            <li>
              <div class="font-family-description mt-2">
                <span class="yellowColor bold">Achat d’un vélo :</span
                ><span>
                  EY vous verse une subvention à l’achat d’un vélo mécanique ou
                  électrique à hauteur de 50%. Cette subvention est limitée à
                  400€ et peut-être sollicitée 1 fois sur 3 ans. Un forfait
                  mensuel de 33,33€ (sur 1 an maximum) vous sera versé sur votre
                  paie jusqu’à la fin de votre échéancier personnalisé</span
                >
              </div>
            </li>
            <li>
              <div class="mt-2">
                <span class="yellowColor bold"
                  >Transports en commun / abonnement vélo public :</span
                ><span>
                  EY verse une indemnité sur le coût de l’abonnement à hauteur
                  de 75% pour les trajets domicile/travail.</span
                >
              </div>
            </li>
            <li>
              <div class="mt-2">
                <span class="yellowColor bold">Voiture : </span
                ><span
                  >EY met gracieusement à disposition une carte de parking (sous
                  réserve d’éligibilité).</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-12 mt-4 mb-4 padding-left">
      <div class="row">
        <div class="col-md-6 useful-link-responsive">
          <div class="containerInfos">
            <p class="bold yellowColor">Liens Utiles :</p>
            <p *ngFor="let item of usefulLinks">
              <a
                target="_blank"
                class="usefulLinks"
                [href]="item.link"
                >{{ item.title}}</a
              >
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="containerInfos">
            <p class="bold yellowColor">Dernières informations :</p>
            <p class="link-infos" *ngFor="let item of LastInfos">
           
              <a
              target="_blank"
              class="usefulLinks"
              [href]="item.lastInfoDescription"
              >{{ item.title}}</a
            >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-md-4 mt-5 pt-5">
    <div class="right-box">
      <div class="row mb-1">
        <div class="col-lg-12 mediaScreen">
          <div>
            <img
              alt=""
              class="imagesRightContainer"
              [src]="environement.baseUrl + 'assets/images/velo.jpg'"
            />
            <div class="VeloGradien">
              <div class="bold titleCard">Subvention Vélo</div>
              <div class="bold descCard">
                <a
                  href="#"
                  class="text-dark"
                  data-toggle="modal"
                  data-target="#view-bike-condition"
                  >Voir les conditions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg-12 mediaScreen">
          <div>
            <img
              alt=""
              class="imagesRightContainer"
              [src]="environement.baseUrl + 'assets/images/transport.jpg'"
            />
            <div class="transportGradien">
              <div class="bold titleCard">Indemnité Transports</div>
              <div class="bold descCard">
                <a
                  href="#"
                  class="text-dark"
                  data-toggle="modal"
                  data-target="#view-transport-condition"
                  >Voir les conditions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-1" *ngIf="canAddRequestParking">
        <div class="col-lg-12 mediaScreen">
          <div>
            <img
              alt=""
              class="imagesRightContainer"
              [src]="environement.baseUrl + 'assets/images/parking.jpg'"
            />
            <div class="ParkingGradien">
              <div class="bold titleCard">Carte Parking</div>
              <div class="bold descCard">
                <a
                  href="#"
                  class="text-dark"
                  data-toggle="modal"
                  data-target="#view-parking-condition"
                  >Voir les conditions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg-12 mediaScreen">
          <div class="mail-to mt-2">
            <span class="mail-to-txt">Contact: <a href="mailto:support.mobilites@fr.ey.com">support.mobilites@fr.ey.com</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-bike-condition-popup></app-bike-condition-popup>
  <app-transport-condition-popup></app-transport-condition-popup>
  <app-parking-condition-popup></app-parking-condition-popup>
</div>
