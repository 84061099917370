import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoleTypeEnum } from 'src/core/models/role.model';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { Canstants } from 'src/core/canstants/canstants';



@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],

  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit {
  isSuperAdmin: boolean = false;
  isTransportData: boolean = true;
  isBikeData: boolean = false;
  isParkingData: boolean = false;
  isTransportArchiveData: boolean = false;
  isBikeArchiveData: boolean = false;
  ShowTRS !:boolean;
  ShowVelo !:boolean;
  ShowParking !:boolean;
  user: any;
  constant = Canstants;
  selectedIndex:any="0";
  constructor( private authService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSuperAdmin = this.isSuperAdminn();
    this.user = await this.authService.getConnectedUser();
    this.checkPermission();

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.selectedIndex = 0; // ou l'index de l'onglet que vous souhaitez ouvrir par défaut
      this.cdr.detectChanges();
    });
    }
  get environement(): typeof environment {
    return environment;
  }
  checkPermission():void{
    const rolesShowTRS = [
      Canstants.ROLE_VALIDATOR,
      Canstants.ROLE_VALIDATOR_TRANSPORT,
      Canstants.ROLE_SUPER_ADMIN
  ];
  const rolesShowVelo = [
    Canstants.ROLE_VALIDATOR,
    Canstants.ROLE_VALIDATOR_VELO,
    Canstants.ROLE_SUPER_ADMIN
];
const rolesShowParking = [
  Canstants.ROLE_VALIDATOR,
  Canstants.ROLE_VALIDATOR_PARKING,
  Canstants.ROLE_SUPER_ADMIN
];
    this.ShowTRS = this.user?.roles?.some((role:any) => rolesShowTRS.includes(role));
    this.ShowVelo = this.user?.roles?.some((role:any) => rolesShowVelo.includes(role));
    this.ShowParking= this.user?.roles?.some((role:any) => rolesShowParking.includes(role));
  }
  getBikeData(){
    this.isBikeData=true;
  }
  isSuperAdminn(): boolean {
    const user: User = JSON.parse(
      localStorage.getItem('MobilitesFR-currentUser') || '{}'
    );
    if (localStorage.getItem('MobilitesFR-currentUser')) {
      const role: RoleTypeEnum = RoleTypeEnum.SUPER_ADMIN;
      const roleText: string = role.toString();
      return (
        user.roles.find((role: any) => role == roleText) != null
      );
    } else {
      return false;
    }
  }

  getTransportData(){
    this.isTransportData=true;

  }
  getParkingData(){
    this.isParkingData=true;

  }
  getTranportArchiveData(){
    this.isTransportArchiveData=true;

  }
  getBikeArchiveData(){
    this.isBikeArchiveData=true;

  }
}
