import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { AttachementType } from 'src/core/models/attachement-request';
import { RequestBike } from 'src/core/models/request-bike';
import { FileService } from 'src/app/shared/file.service';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { PdfService } from 'src/core/services/pdf.service';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { MatDialog } from '@angular/material/dialog';
moment.locale('fr');
moment.tz.setDefault("Europe/Paris");
@Component({
  selector: 'app-reject-bike',
  templateUrl: './reject.component.html',
  styleUrls: ['./reject.component.css'],
})
export class RejectBikeComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('type') type: MobilityRequestType | undefined;
  requestVelo!: RequestBike;
  peoplePlan: any;
  connectedUser: User | undefined;
  constant = Canstants;
  bikeViewForm!: FormGroup;
  idSubcriptionFormulaMisc: any;
  attachmentFile: any;
  attestationFile: any;
  pivotMonth: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private pdfService: PdfService,
    private dateService: DateService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private fileService:FileService,
    private dialog: MatDialog
  ) {}

  get form(): any {
    return this.bikeViewForm.controls;
  }
  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    // this.peoplePlan = await this.globalService.loadPeoplePlan(this.connectedUser);
    this.pivotMonth = localStorage.getItem('moisPivot');
    this.httpService
      .findOneById('BikeRequests', this.activatedRoute.snapshot.params['id'])
      .subscribe((result: any) => {
        this.requestVelo = result;
        this.attestationFile = result.certificateFMD.fileBytes;
        this.attachmentFile = result.proofOfPurchase.fileBytes;
      });
  }
  get attachementType(): typeof AttachementType {
    return AttachementType;
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  get environement(): typeof environment {
    return environment;
  }
  downloadFile(
    Certification: boolean = false,
    ProofOfPurchase: boolean = false
  ): void {
    this.httpService
      .downloadFile('BikeRequests/GetBikeRequestFile/' + this.requestVelo.id, {
        Certification,
        ProofOfPurchase,
      })
      .subscribe({
        next: ({ body, headers }) => {
          if (body) {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(body);
            a.download = headers.get('x-file-name') as string;
            a.click();
          }
        },
      });
  }
  openFile(bikeFile?: any): void {
    if (bikeFile?.fileBytes) this.fileService.openFile(bikeFile.fileBytes);
  }

  annulerRequest(): void {
    if (this.requestVelo.id)
      this.httpService
        .patchFormData('BikeRequests', this.requestVelo.id, {
          id: this.requestVelo.id,
          RejectRequest: true,
          RejectionDatetime: moment(this.pivotMonth, 'MMMM YYYY', 'fr').format(
            'YYYY-MM-DD'
          ),
        })
        .subscribe((data) => {
          this.route.navigate(['/mobility-request/list']);
        },
        (error) => {
          if (error.status === 500) {
            this.dialog.open(ErrorDialogComponent, {
              data: { message: 'Oups, problème système' }
            });
          } 
        }
      );
    return;
   
  }
  openPdf(): void {
    this.pdfService.generatePDF(0, '', '', MobilityRequestType.cycling);
    this.pdfService.openPdf();
  }
  private async loadVeloRequests(): Promise<void> {
    // const queryOption = new QueryOption();
    // queryOption.filter = `Title eq '${this.activatedRoute.snapshot.params.title}'`;
    // queryOption.select = `*`;
    // const response: Array<RequestBike> = await this.spService.findAll('RequestVelos', queryOption).catch();
    // this.requestVelo = response[0];
    // this.requestVelo.Mob_AttachmentVelo = new Array<AttachementRequest>();
    // for (const attachmentVeloId of this.requestVelo.Mob_AttachmentVeloId) {
    //   queryOption.filter = `Id eq '${attachmentVeloId}'`;
    //   queryOption.select = `EncodedAbsUrl,Id, Title, LinkFilename, Mob_Type`;
    //   const attachement: Array<AttachementRequest> = await this.spService.findAll('AttachementVelos', queryOption).catch();
    //   attachement[0].Title = attachement[0].LinkFilename;
    //   this.requestVelo?.Mob_AttachmentVelo.push(attachement[0]);
    //   if (attachement[0].Mob_Type === AttachementType[AttachementType.Attachment]) {
    //     this.attachmentFile = attachement[0];
    //   }
    //   if (attachement[0].Mob_Type === AttachementType[AttachementType.Attestation]) {
    //     this.attestationFile = attachement[0];
    //   }
    // }
  }
}
