<div class="row">
    <div class="col-md-12">
      <div class="banner-admin">
        <div class="col-md-12 padding-left">
          <span class="ArrowVelo"
            ><a routerLink="/"
              ><img
                alt=""
                [src]="
                  environement.baseUrl + 'assets/images/Arrow.svg'
                " /></a></span
          ><span class="bold TitleVelo">Espace Admin - Calendrier </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row spacer-box-calendrier">
    <div class="col-md-12 ml-5">
      <span class="bold yellowColor titleFormule">
        <div class="squarePuce"></div>
        Gestion du calendrier des demandes et validations
      </span>
    </div>
    <div class="col-lg-12 mt-5">
      <div
        id="carouselRequestApproval"
        class="carousel slide flex-center"
        data-ride="carousel"
      >
        <div class="carousel-inner txt-center flex-center carousel-cal">
          <div class="row">
            <div
              class="flex-center"
              [ngClass]="
                sildes[indexOfSlide].length > 1 ? 'col-md-4' : 'col-md-12'
              "
              *ngFor="let request of sildes[indexOfSlide]"
            >
              <div class="container-formule" >
                <div class="month-name pb-3 bold">
                  {{ request.description }}
                </div>
                <form class="form" autocomplete="off">
                  <span
                    [class]="request.isCurrentMonth ? 'label-txt-cal mt-1':'label-txt-cal mt-1'"
                    >Date limite de dépôt d'indemnité transport pour le mois
                    antérieur
                  </span>

                  <div class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      oninput="validity.valid||(value='');"
                      [(ngModel)]="request.limitDepotTrs"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                      (change)="changeValues($event, request)"
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates "
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitDepotTrs }}/{{ request.month }}/{{request.year}}</span>
                  </div>

                  <span [class]="request.isCurrentMonth ? 'label-txt-cal pt-1':'label-txt-cal mt-1'"
                    >Date limite de modification des demandes d'indemnité
                    transport
                  </span>
                  <span class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      oninput="validity.valid||(value='');"
                      [(ngModel)]="request.limitModiftTrs"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates"
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitModiftTrs }}/{{ request.month }}/{{
                        request.year
                      }}</span
                    >
                  </span>
                  <span [class]="request.isCurrentMonth ? 'label-txt-cal':'label-txt-cal mt-1'"
                    >Date limite de validation des demandes d'indemnité transport
                  </span>
                  <span class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      [(ngModel)]="request.limitValidTrs"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      oninput="validity.valid||(value='');"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates"
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitValidTrs }}/{{ request.month }}/{{
                        request.year
                      }}</span
                    >
                  </span>

                  <span [class]="request.isCurrentMonth ? 'label-txt-cal':'label-txt-cal mt-1'"
                    >Date limite de dépôt des demandes de subvention vélo
                  </span>
                  <span class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      [(ngModel)]="request.limitDepotVelo"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      oninput="validity.valid||(value='');"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates"
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitDepotVelo }}/{{ request.month }}/{{
                        request.year
                      }}</span
                    >
                  </span>
                  <span [class]="request.isCurrentMonth ? 'label-txt-cal':'label-txt-cal mt-1'"
                    >Date limite de modification des demandes de subvention vélo
                  </span>
                  <span class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      [(ngModel)]="request.limitModifVelo"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      oninput="validity.valid||(value='');"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates"
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitModifVelo }}/{{ request.month }}/{{
                        request.year
                      }}</span
                    >
                  </span>
                  <span [class]="request.isCurrentMonth ? 'label-txt-cal':'label-txt-cal mt-1'"
                    >Date limite de validation des demandes de subvention vélo
                  </span>
                  <span class="form-group mt-1">
                    <input
                      type="number"
                      matInput
                      min="1"
                      max="31"
                      [(ngModel)]="request.limitValidVelo"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeValues($event, request)"
                      oninput="validity.valid||(value='');"
                      [class]="
                        request.isCurrentMonth
                          ? 'display-none'
                          : 'form-control text-right input-day mr-2'
                      "
                    />
                    <span *ngIf="!request.isCurrentMonth" class="disabled-dates"
                      >{{ request.month }}/{{ request.year }}</span
                    >
                    <span
                      *ngIf="request.isCurrentMonth"
                      class="disabled-dates fix-height current-month-date"
                      >{{ request.limitValidVelo }}/{{ request.month }}/{{
                        request.year
                      }}</span
                    >
                  </span>
                  <button
                  *ngIf="!request.isCurrentMonth"
                  type="button"
                  class="btn-valeur-vide link mt-3 mb-2"
                  (click)="remplirParDefaut(request)"
                >
                  Remplir les valeurs vide par défaut
                </button>
                  <button
                    *ngIf="
                      existChanges == request.index && !request.isCurrentMonth
                    "
                    (click)="updateBusinessParam(request)"
                    type="button"
                    class="btn-update-cal link mt-1 mb-2"
                  >
                    Mettre à jour
                  </button>
                  <button
                    *ngIf="
                      existChanges != request.index && !request.isCurrentMonth
                    "
                    type="button"
                    class="btn-update-cal-disabled link mt-1 mb-2"
                  >
                    Mettre à jour
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
        <a
          [class]="
            disablePrev ? 'display-none' : 'carousel-control-prev prev-css'
          "
          href="#carouselRequestApproval"
          role="button"
          data-slide="prev"
          (click)="goPrev()"
          ><img
            alt=""
            class="CarouselArrow"
            [src]="environement.baseUrl + 'assets/images/prev.PNG'"
        /></a>
        <a
          [class]="
            disableNext ? 'display-none' : 'carousel-control-next next-css'
          "
          href="#carouselRequestApproval"
          role="button"
          data-slide="next"
          (click)="goNext()"
        >
          <img
            alt=""
            class="CarouselArrow"
            [src]="environement.baseUrl + 'assets/images/next.PNG'"
          />
        </a>
      </div>
    </div>
  </div>
