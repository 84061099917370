import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTransportComponent } from './create/create.component';
import { RejectTransportComponent } from './reject/reject.component';
import { UpdateTransportComponent } from './update/update.component';
import { ViewTransportComponent } from './view/view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-user.routing';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CreateTransportComponent,
    UpdateTransportComponent,
    ViewTransportComponent,
    RejectTransportComponent,
  ],
  exports: [
    CreateTransportComponent,
    UpdateTransportComponent,
    ViewTransportComponent,
    RejectTransportComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TransportModule {}
