import { MobilityRequestStatus } from '../enum/mobility-request-status.enum';
import User from './user';

export class RequestBike {
  id: number | undefined;
  title: string | undefined;
  subsidizedAmount!: number ;
  purchaseDate!: Date ;
  purchaseAmount!: number;
  numberFullMonths!: number;
  lastMonthAmount!: number;
  applicableMonthlyPayment!: number ;
  controlerComment: string | undefined;
  requesterComment: string | undefined;
  attachmentVelo!: Array<any> ;
  statusDate: Date | undefined;
  validatedDate!: Date ;
  startDate!: Date ;
  endDate!: Date ;
  initialEndDate: Date | undefined;
  status!: MobilityRequestStatus ;
  requesterId: number | undefined;
  requester!: User;
  validatorId: number | undefined;
  validator: User | undefined;
  requesterAddress: string | undefined;
  certificateFMD?: File;
  proofOfPurchase?: File;
  created?:Date;
  vae!:boolean;
}

export interface bikeFile {
  fileBytes?:string;
  fileName?:string
}