import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private dialog: MatDialog,private spinner: NgxSpinnerService) {}
  private requestsNumber: number = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });
    this.spinner.show();
    this.requestsNumber++;
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status === 302) {
          window.location.reload();
        }
        // if (error.status === 500) {
        //   this.dialog.open(ErrorDialogComponent, {
        //     data: { message: 'Oups, problème système' }
        //   });
        // }
        // if (error.status === 400) {
        //   this.dialog.open(ErrorDialogComponent, {
        //     data: { message: 'Oups, problème système' }
        //   });
        // }
        return throwError(() => error);
      }),
      finalize(() => {
        this.requestsNumber--;
        if (this.requestsNumber === 0) {
          this.spinner.hide();
        }
      })
    );
  }
}
