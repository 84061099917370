import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.css']
})
export class NoAccessComponent implements OnInit {
  constructor() { }
errorMsg : string ="";
  ngOnInit(): void {
    this.errorMsg = localStorage.getItem('errorMessage') || "";
  }
  get environement(): typeof environment {
    return environment;
  }
}
