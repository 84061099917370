import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MoneyService {
  constructor() {}
  format(value: string | number | undefined, separator: string): string {
    if (value === undefined) {
      throw new Error('Input is undefined');
    }

    const numericValue: number =
      typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;

    if (isNaN(numericValue)) {
      throw new Error('Invalid input: not a number');
    }

    const roundedValue: number = Math.round(numericValue * 100) / 100;

    // Formater la chaîne avec une virgule comme séparateur décimal
    const formatter = new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

    let formattedValue: string = formatter.format(roundedValue);
    formattedValue = formattedValue.replace(/\u00A0/g, separator);
    return formattedValue;
  }
  formatNumber(value: string | number | undefined, separator: string): number {
    if (value === undefined) {
      throw new Error('Input is undefined');
    }

    const numericValue: number =
      typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue)) {
      throw new Error('Invalid input: not a number');
    }

    const roundedValue: number = Math.round(numericValue * 100) / 100;

    return roundedValue;
  }
}
