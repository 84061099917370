import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParkingModule } from 'src/app/mobility-request-user/parking/parking.module';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { Canstants } from 'src/core/canstants/canstants';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { FilterOptions } from 'src/core/models/filter-options';
import { QueryOption } from 'src/core/models/query-option';
import { RequestParking } from 'src/core/models/request-parking';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { ExcelService } from 'src/core/services/excel.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault("Europe/Paris");

@Component({
  selector: 'app-list-parking',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
})
export class ListComponent implements OnInit {
  @Input() parkingData!: boolean;
  statusForm!: FormGroup;
  constant = Canstants;
  filterOptions = new FilterOptions();
  // sortOption: any = { active: 'Created', direction: 'desc' };
  mobilityRequestStatus!: MobilityRequestStatus;
  displayedColumnsParking: string[] = [];
  allrequestParking: RequestParking[] = [];
  requestParking: RequestParking[] = [];
  listSites: any;
  toggleFilter=true;
  requestParkingExcel: []=[];
  listStatus: any;
  // pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  currentDate = new Date();
  requestor: any;
  validator: any;
  lastMonth!: Date;
  @ViewChild('paginatorRequestParking') paginatorRequestParking!: MatPaginator;
  @ViewChild('allSelectedParking')
  private allSelectedParking!: MatOption;
  @ViewChild(MatSort) sort: MatSort | undefined;
  user: any;

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private excelService: ExcelService
  ) {}

  get mobilityRequestStatuss(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  async ngOnInit(): Promise<void> {
    //
    this.statusForm = this.fb.group({
      statusFieldParking: new FormControl(''),
    });
    await this.initializeFilter();
    this.user = await this.authService.getConnectedUser();
    this.initializeHeader();
    await this.getSites();
    this.getStatus();

    if (
      this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
      this.user.roles.includes(this.constant.ROLE_VALIDATOR_PARKING)
      || this.parkingData
    ) {
      await this.loadParkingRequest();
    }
    //
  }
  async initializeFilter(): Promise<void> {
    const obj: string = localStorage.getItem('Mob_FilterParking') as string;
    const userFilter = JSON.parse(obj) ? JSON.parse(obj) : null;
    if (userFilter) {
      this.filterOptions.Created = new Date(userFilter.Created).toISOString();

      this.filterOptions.Status = userFilter.Status;
      this.statusForm.controls['statusFieldParking'].setValue(
        userFilter.Status
      );
      if(userFilter.sortOption){
        this.filterOptions.sortOption.direction = userFilter.sortOption.direction
        this.filterOptions.sortOption.active = userFilter.sortOption.active
      }
      if(userFilter.pageSize){
        this.filterOptions.pageSize = userFilter.pageSize
      }
      if (userFilter.Site) {
        this.filterOptions.Site = userFilter.Site;
      }
      if (userFilter.searchType) {
        this.filterOptions.searchType = userFilter.searchType;
      }
      if (userFilter.SL) {
        this.filterOptions.SL = userFilter.SL;
      }
      if (userFilter.validator) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.validator);
        // this.selectValidateur(user);
      }
      if (userFilter.requestor) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.requestor);
        // this.selectRequester(user);
      }

      this.toggleFilter=true;

    } else {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      this.filterOptions.Created = currentDate.toISOString();
      this.filterOptions.pageSize = 10;
      this.filterOptions.sortOption.direction = 'desc'
      this.filterOptions.sortOption.active = 'Created'
      this.toggleFilter=true;

      this.filterOptions.Status = [
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ];
      this.statusForm.controls['statusFieldParking'].setValue([
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ]);
    }
  }

  dateChanged(event:any){
    this.toggleFilter=false;
  }

  async loadParkingRequest(fromFilter?: boolean): Promise<void> {
    if (this.filterOptions.Created  ) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    localStorage.setItem(
      'Mob_FilterParking',
      JSON.stringify(this.filterOptions)
    );
    if (fromFilter) {
      this.currentPage = 1;
    }
    this.httpService
      .findAll('ParkingRequests', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
        pageSize: this.filterOptions.pageSize,
        pageNumber: this.currentPage,
        sortName: this.filterOptions.sortOption.active,
        sortDirection: this.filterOptions.sortOption.direction,
      })
      .subscribe((parkings) => {
        this.requestParking = parkings.items;
        setTimeout(() => {
          this.paginatorRequestParking.pageIndex = this.currentPage - 1;
          this.paginatorRequestParking.length = parkings.totalCount;
        });
      });
    this.toggleFilter=true;

  }
  pageChanged(event: PageEvent) {

    this.filterOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadParkingRequest();
  }
  selectPerOneParking(all: any) {
    this.filterOptions.Status =
      this.statusForm.controls['statusFieldParking'].value;
    if (this.allSelectedParking.selected) {
      this.allSelectedParking.deselect();
      return false;
    }
    if (
      this.statusForm.controls['statusFieldParking'].value.length ==
      this.listStatus.length
    )
      this.allSelectedParking.select();
    return true;
  }

  goPageParking() {
    const pageIndex = this.paginatorRequestParking.pageIndex + 1;
    this.requestParking = this.allrequestParking.slice(
      (pageIndex - 1) * this.paginatorRequestParking.pageSize,
      pageIndex * this.paginatorRequestParking.pageSize
    );
  }
  getStatus(): void {
    this.listStatus = Object.getOwnPropertyNames(MobilityRequestStatus)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => MobilityRequestStatus[key]);
  }

  async getSites(): Promise<void> {
    await this.httpService.findAll('Sites',{
      pageSize: 100,
      SortName:"name",
      SortDirection:"Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items;
    });
  }
  
  getExcelFileParking() {
    let RequestParkings: any = [];
    if (this.filterOptions.Created) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    this.httpService
      .findAll('ParkingRequests/GetExcelAdminParking', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
      })
      .subscribe((pr) => {
        this.requestParkingExcel = pr;
    this.requestParkingExcel.forEach((elem:any) => {
      let obj = {
        Created: moment(elem.created).format('YYYY-MM-DD'),
        Mob_MoisDebut: moment(elem.startDate).format('YYYY-MM-DD'),
        Mob_MoisFin: moment(elem.endDate).format('YYYY-MM-DD'),
        Mob_RequesterName: elem.requester?.displayName,
        Mob_Status: MobilityRequestStatus[elem.status],
        Mob_EntiteJuridique: elem.requester?.myCompanyDesc,
        Mob_Grade: elem.requester?.rankDesc,
        Mob_ServiceLine: elem.requester?.slTgm,
        Mob_SiteRattachement: elem.requester?.locationCode,
        Bureau: elem.requester?.locationDesc,
        Mob_NomValideur: elem.validator?.displayName,
      };
      RequestParkings.push(obj);
    });
    const res = RequestParkings.map(function (value: any) {
      return Object.values(value);
    });
    let displayedColumnsParking = [
      'Date Submission',
      'Mois début',
      'Mois fin',
      'Demandeur',
      'Statut',
      'BU',
      'Rank',
      'SL',
      'Code Bureau',
      'Nom Bureau',
      'Traitée par',
    ];

    this.excelService.generateExcel(
      res,
      null,
      MobilityRequestType[MobilityRequestType.parking].toString(),
      displayedColumnsParking
    );
  })

  }

  async sortParkingRequest(event: any): Promise<void> {
    this.filterOptions.sortOption = event;
    this.paginatorRequestParking.pageIndex = 0;
    await this.loadParkingRequest();
  }

  initializeHeader(): void {
    this.displayedColumnsParking = [
      'Created',
      'Mob_RequesterName',
      'Mob_SiteRattachement',
      'Mob_ServiceLine',
      'Mob_EntiteJuridique',
      'Mob_Grade',
      'Mob_MoisDebut',
      'Mob_Status',
      'Mob_NomValideur',
      'Actions',
    ];
  }
  toggleAllSelectionParking(): void {
    if (this.allSelectedParking.selected) {
      this.statusForm.controls['statusFieldParking'].setValue([
        ...this.listStatus.map((item: any) => item),
        0,
      ]);

      this.filterOptions.Status = [...this.listStatus.map((item: any) => item)];
    } else {
      this.statusForm.controls['statusFieldParking'].setValue([]);
      this.filterOptions.Status = [];
    }
  }
  async resetSearchParking(): Promise<void> {
    this.filterOptions = new FilterOptions();
    localStorage.removeItem('Mob_FilterParking');
    this.resetRequester();
    this.resetValidateur();
    await this.initializeFilter();
    this.paginatorRequestParking.pageIndex = 0;
    this.loadParkingRequest();
  }
  selectRequester(user: any): void {
    this.requestor = user;
    this.filterOptions.Requester = user.email;
  }
  resetRequester() {
    this.requestor = null;
    this.filterOptions.Requester = '';
  }
  selectValidateur(user: any): void {
    this.validator = user;
    this.filterOptions.Validator = user.email;
  }
  resetValidateur() {
    this.validator = null;
    this.filterOptions.Validator = '';
  }
}
