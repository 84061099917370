import { FileType } from 'src/core/enum/FileTypeEnum';
import { BikeRequestBusinessParamsEnum } from './../../../shared/BusinessParams/BikeRequestBusinessParams';
import { BusinessParamsService } from './../../../business-params.service';
import { BusinessParam } from './../../../../core/models/business-param';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { PdfService } from 'src/core/services/pdf.service';
import { BikeRequestDto } from 'src/core/models/BikeRequestDto';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-create-bike',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateBikeComponent implements OnInit {
  private attachmentToBlob!: Blob;
  @ViewChild('openModalAttachement')
  openModalAttachement!: ElementRef;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private pdfService: PdfService,
    private moneyService: MoneyService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private businessParamService: BusinessParamsService,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  submitted = false;
  minDate!: Date;
  maxDate!: Date;
  condition1: boolean = false;
  reCalculateEvent = true;
  bikeRequestDto: BikeRequestDto = new BikeRequestDto();
  connectedUser!: User;
  peoplePlan: any;
  businessParam: any;
  openPopUpAttachement: boolean = false;
  listStartMonth: Array<any> = [];
  // @ts-ignore
  bikeRequestForm: FormGroup = null;
  applicableMonthlyPayment!: number;
  maxRefundAmount!:number;
  doIn = '';
  dateNow = new Date();
  demeurantIn = '';
  attachmentFile: any;
  attestationFile: any;
  isValid = true;
  checkAccess = {
    access: true,
    message: '',
  };
  fileExists:Boolean=false;
  fileGreater:Boolean=false;

  get form(): any {
    return this.bikeRequestForm.controls;
  }

  async ngOnInit(): Promise<void> {
    this.calculatePurchaseDate();
    this.connectedUser = await this.authService.getConnectedUser();
    await this.checkEligibility();
    let formattedMonth = moment().format('MM');
    let monthlyPay =
      BikeRequestBusinessParamsEnum.monthlyPayment ;
    let maxRefundableAmount = BikeRequestBusinessParamsEnum.maxAmount ;
    this.businessParamService.getBusinessParams().subscribe({
      next: (data: BusinessParam[]) => {
        data.forEach((item) => {
          if (item.title == monthlyPay && item.descriptionBP)
            this.applicableMonthlyPayment = Number.parseFloat(
              item.descriptionBP
            );
            if(item.title == maxRefundableAmount && item.descriptionBP)
              this.maxRefundAmount = Number.parseFloat(item.descriptionBP)
        });
      },
      error(err) {},
    });
  }
  get environement(): typeof environment {
    return environment;
  }
  async checkEligibility(): Promise<void> {
    const mapping: any = [];

    this.httpService.findAll('BikeRequests/CheckEligibility').subscribe({
      next: (months) => {
        let index = 0;
        months.availableMonths.forEach((element: any) => {
          mapping.push({
            value: moment(element).format('MMMM YYYY'),
            index,
            numberValue: moment(element).format('YYYY-MM'),
          });
          index++;
        });
        this.listStartMonth = mapping;
        if (this.listStartMonth) {
          this.bikeRequestForm.controls.startMonth.setValue(
            this.listStartMonth[0].numberValue
          );
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.checkAccess.access = false;
          this.checkAccess.message = err.error.detail;
        }
      },
    });
  }

  private initializeForm(): void {
    this.bikeRequestForm = this.formBuilder.group({
      startMonth: [''],
      requesterComment: [''],
      purchaseAmount: [''],
      purchaseDate: [''],
      subsidizedAmount: [''],
      numberFullMonths: [''],
      attestationFile: ['', Validators.required],
      attachmentFile: ['', Validators.required],
      applicableMonthlyPayment: [''],
      lastMonthAmount: [''],
      startDate: [''],
      endDate: [''],
      vae: [''],
    });
  }
  calculatePurchaseDate(): any {
    const currentDate = moment();
    this.minDate = moment('2021-01-01').toDate();
    this.maxDate = currentDate.toDate();
  }

  changePurchaseDate(event: any): any {
    this.bikeRequestForm.controls.purchaseDate.setValue(
      moment(event.value).toDate()
    );
    this.reCalculateEvent = true;
    this.isValid = true;
  }

  changeStartMonth(): void {
    this.reCalculateEvent = true;
    this.isValid = true;
  }

  changePurchaseAmount(value: any): void {
    let isnum = /^(\d{1,3}( \d{3})*)(,\d+)?$/.test(value.target.value);
    if (!isnum) {
      this.openModalNegativeNumber.nativeElement.click();
      this.bikeRequestForm.controls['purchaseAmount'].setValue('');
    } else {
      this.reCalculateEvent = true;
      this.isValid = true;
    }
  }
  changeIsVae(): void {}

  calculatePayment(): void {
    this.reCalculateEvent = false;
    this.isValid = false;

    if (this.bikeRequestForm.controls.purchaseAmount) {
      let subsidizedAmount = 0;
      let applicableMonthlyPayment = 0;
      let numberFullMonths = 0;
      let lastMonthAmount = 0;
      const startDate = moment(this.bikeRequestForm.controls.startMonth.value);
      const purchaseAmount = Number(
        this.bikeRequestForm.controls.purchaseAmount.value.replace(',', '.').replace(' ','')
      );
      if (purchaseAmount > 800) {
        subsidizedAmount = 400;
      } else {
        subsidizedAmount = Number((purchaseAmount / 2).toFixed(2));
      }
      
      applicableMonthlyPayment = this.applicableMonthlyPayment;
      if (subsidizedAmount === 400) {
        numberFullMonths = 12;
      } else {
        numberFullMonths = Math.floor(
          subsidizedAmount / applicableMonthlyPayment
        );
        lastMonthAmount = Number(
          (
            subsidizedAmount -
            applicableMonthlyPayment * numberFullMonths
          ).toFixed(2)
        );
      }
      this.bikeRequestForm.controls.subsidizedAmount.setValue(
        this.moneyService.format(subsidizedAmount.toString(), '.')
      );
      this.bikeRequestForm.controls.applicableMonthlyPayment.setValue(
        this.moneyService.format(applicableMonthlyPayment.toString(), '.')
      );
      this.bikeRequestForm.controls.numberFullMonths.setValue(numberFullMonths);
      this.bikeRequestForm.controls.lastMonthAmount.setValue(
        this.moneyService.format(lastMonthAmount.toString(), '.')
      );
      // this.bikeRequestForm.controls.purchaseAmount.setValue(
      //   this.moneyService.format(purchaseAmount.toString(), '.')
      // );
      this.bikeRequestForm.controls.startDate.setValue(
        startDate.format('MMMM YYYY')
      );

      let endDate;
      if (lastMonthAmount === 0) {
        endDate = startDate.add(numberFullMonths - 1, 'months');
      } else {
        endDate = startDate.add(numberFullMonths, 'months');
      }

      this.bikeRequestForm.controls.endDate.setValue(
        endDate.format('MMMM YYYY')
      );
    }
  }

  async generatePDF(): Promise<void> {
    await this.pdfService.generatePDF(
      0,
      this.doIn,
      this.demeurantIn,
      MobilityRequestType.cycling
    );

    this.bikeRequestForm.controls.attestationFile.setValue(
      this.pdfService.pdf.output('blob')
    );
    this.bikeRequestDto.requesterAddress = this.demeurantIn;
    this.bikeRequestDto.certificateFMD = this.pdfService.pdf.output(
      'blob'
    ) as File;
  }

  openPdf(): void {
    this.pdfService.openPdf();
  }
  fixIssueDate(sensorsData: any) {
    return new Date(
      sensorsData.getTime() - sensorsData.getTimezoneOffset() * 60000
    );
  }
  async createBikeRequest(): Promise<void> {
    this.submitted = true;
    if (!this.bikeRequestForm.valid) {
      return;
    }

    this.bikeRequestDto.startDate = new Date(
      this.bikeRequestForm.value.startMonth
    ).toISOString();
    this.bikeRequestDto.purchaseAmount = Number(
      this.bikeRequestForm.value.purchaseAmount.replace(',', '.').replace(' ','')
    );
    let fixedDate = this.fixIssueDate(this.bikeRequestForm.value.purchaseDate);
    this.bikeRequestDto.purchaseDate = fixedDate.toISOString();
    this.bikeRequestDto.requesterComment =
      this.bikeRequestForm.value.requesterComment;
    this.bikeRequestDto.vae = this.bikeRequestForm.value.vae;
    this.httpService
      .postFormData('BikeRequests', this.bikeRequestDto)
      .subscribe((data) => {
        this.router.navigate(['/mobility-request/list']);
      },
      (error) => {
        if (error.status === 500) {
          this.dialog.open(ErrorDialogComponent, {
            data: { message: 'Oups, problème système' }
          });
        } 
      }
    );
  }

  openAttachment(): void {
    window.open(URL.createObjectURL(this.attachmentToBlob));
  }
  async uploadAttachment(event: any): Promise<void> {
    this.bikeRequestForm.controls.attachmentFile.setValue(
      event.target.files[0]
    );
    this.bikeRequestDto.proofOfPurchase = event.target.files[0];
    this.bikeRequestDto.ProofOfPurchaseFileType =
      FileType[event.target.files[0].type as keyof typeof FileType];
      const fileSize = event.target.files[0].size;
      const isFileSizeGreaterThan1MB = fileSize >  1024 * 1024; 
      this.fileExists = true;
      this.fileGreater = false;
      if (isFileSizeGreaterThan1MB) {
        this.fileGreater = true;
      }
    this.openModalAttachement.nativeElement.click();
  }

  removeAttachment(): void {
    this.bikeRequestDto.proofOfPurchase = undefined;
    this.attachmentFile = null;
    this.bikeRequestForm.controls.attachmentFile.setValue(null);
    this.bikeRequestDto.proofOfPurchase = undefined;
    this.bikeRequestDto.ProofOfPurchaseFileType = undefined;
    this.fileExists=false;
    this.fileGreater=false;
  }

  removeAttestation(): void {
    this.attestationFile = null;
    this.demeurantIn = '';
    this.doIn = '';
    this.condition1 = false;
    this.pdfService.resetPDF();
    this.bikeRequestForm.controls.attestationFile.setValue(null);
    this.bikeRequestDto.certificateFMD = undefined;
    this.bikeRequestDto.requesterAddress = undefined;
  }

  toNumber(value: string): number {
    return Number(value.replace(',', '.').replace(' ',''));
  }
}
