import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/shared/file.service';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { BikeRequestDto } from 'src/core/models/BikeRequestDto';
import { RequestBike, bikeFile } from 'src/core/models/request-bike';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewBikeComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('type') type: MobilityRequestType | undefined;
  @ViewChild('openUpdatePopup') openUpdatePopup!: ElementRef;
  requestBike!: RequestBike;
  requestBikeDto: BikeRequestDto = new BikeRequestDto();
  connectedUser: User | undefined;
  constant = Canstants;
  peoplePlan: any
  adminViewForm!: FormGroup ;
  idSubcriptionFormulaMisc: any;
  attachmentFile: any;
  attestationFile: any;
  businessParam: any;
  toBeCancled !: boolean;
  personalAddress!: string;

  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private route: Router,
    private spinner: NgxSpinnerService,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private fileService:FileService,
    private dialog: MatDialog
    ) {
    this.initializeForm();
  }

  get form(): any {
    return this.adminViewForm.controls;
  }
  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    this.httpService
      .findAll('BusinessParams')
      .subscribe((bp) => {
        let formattedMonth = moment().format('MM');
        let title = Canstants.limitValidVeloM +
          moment().format("yyyy") +
          formattedMonth;
        this.businessParam = bp.filter((param: any) => param.title == title);
      });
      this.checkCancelEligibility()
    this.httpService
      .findOneById('BikeRequests', this.activatedRoute.snapshot.params['id'])
      .subscribe((result: any) => {
        this.requestBike = result;
        this.getRequesterAddress();
        this.adminViewForm.controls['controleComment'].setValue(this.requestBike.controlerComment)
        this.adminViewForm.controls['vae'].setValue(this.requestBike.vae)
      });
  }
  checkCancelEligibility():void{
    this.httpService.findOneById('BikeRequests/CheckCancelEligibility',this.activatedRoute.snapshot.params['id']).subscribe((result: any) => {
      this.toBeCancled = result
    });
  }
  cancelRequest():void{
    this.httpService
    .patch('BikeRequests/CancelRequest', this.activatedRoute.snapshot.params['id'], {
      id: this.activatedRoute.snapshot.params['id']
    })
    .subscribe((data) => {
      this.route.navigate(['/mobility-request-admin/list']);
    });
  }
  async getRequesterAddress():Promise<void>{
    this.httpService.findOneById('Users',this.requestBike.requester.gpn)
    .subscribe((result:any) => {
       this.personalAddress = result.address
    })
  }

  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  initializeForm(): void {
    this.adminViewForm = this.formBuilder.group({
      controleComment: ['', Validators.required],
      vae:['']
    });


  }
  get environement(): typeof environment {
    return environment;
  }
  openFile(bikeFile?: any): void {
    if (bikeFile?.fileBytes) this.fileService.openFile(bikeFile.fileBytes);
  }

 
  async takeRequest(): Promise<void> {
    this.requestBikeDto.status = this.mobilityRequestStatus.Assigned
    this.requestBikeDto.vae = this.adminViewForm.value.vae
    if (this.requestBike?.id) {
        this.httpService
          .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
            id: this.requestBike.id,
            ...this.requestBikeDto
          })
          .subscribe((data) => {
            this.route.navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.route.navigate(['/bike-admin/view/',this.requestBike?.id]);
            });
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' }
              });
            } 
          }
        );
        return;
    }

  
  }
  fermer(): void {
    this.route.navigate(['/mobility-request-admin/list']);
  }

  async update(): Promise<void> {
    const startRequestDate = new Date(this.requestBike.startDate);
    const startRequestYear = startRequestDate.getFullYear();
    const startRequestMonth = startRequestDate.getMonth();
    const dateNow = new Date();
    const dateNowYear = dateNow.getFullYear();
    const dateNowMonth = dateNow.getMonth();
    const dateNowDay = dateNow.getDate();
    if (dateNowYear == startRequestYear && dateNowMonth == startRequestMonth
      && Number(this.businessParam.DescriptionBP) < dateNowDay) {
      this.openUpdatePopup.nativeElement.click();
    }
    else {
      await this.route.navigate(['/bike-admin/update', this.requestBike?.id]);
    }
  }
   changeVae(event:any):void{
    this.requestBikeDto.vae = this.adminViewForm.value.vae
    this.requestBikeDto.FromVae = true
    if (this.requestBike?.id) {
      this.httpService
        .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
          id: this.requestBike.id,
          ...this.requestBikeDto
        })
        .subscribe((data) => {
          // this.route.navigate(['/mobility-request-admin/list']);
        });
      return;
  }
  }
  async validate(): Promise<void> {
    const startRequestDate = new Date(this.requestBike.startDate);
    const startRequestYear = startRequestDate.getFullYear();
    const startRequestMonth = startRequestDate.getMonth();
    const dateNow = new Date();
    const dateNowYear = dateNow.getFullYear();
    const dateNowMonth = dateNow.getMonth();
    const dateNowDay = dateNow.getDate();
    if (dateNowYear == startRequestYear && dateNowMonth == startRequestMonth
      && Number(this.businessParam.DescriptionBP) < dateNowDay) {
      this.openUpdatePopup.nativeElement.click();
    }
    else {
    this.requestBikeDto.status = this.mobilityRequestStatus.Validated
    this.requestBikeDto.controlerComment = this.adminViewForm.value.controleComment
    this.requestBikeDto.vae = this.adminViewForm.value.vae
      if (this.requestBike?.id) {
        this.httpService
          .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
            id: this.requestBike.id,
            ...this.requestBikeDto
          })
          .subscribe((data) => {
            this.route.navigate(['/mobility-request-admin/list']);
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' }
              });
            } 
          });
        return;
    }
    }
  }

  async reject(): Promise<void> {
    this.requestBikeDto.status = this.mobilityRequestStatus.Rejected
    this.requestBikeDto.controlerComment = this.adminViewForm.value.controleComment
    this.requestBikeDto.vae = this.adminViewForm.value.vae
    if (this.requestBike?.id) {
        this.httpService
          .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
            id: this.requestBike.id,
            ...this.requestBikeDto
          })
          .subscribe((data) => {
            this.route.navigate(['/mobility-request-admin/list']);
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' }
              });
            } 
          });
        return;
    }
  }

  async returned(): Promise<void> {
    this.requestBikeDto.status = this.mobilityRequestStatus.Returned
    this.requestBikeDto.controlerComment = this.adminViewForm.value.controleComment
    this.requestBikeDto.vae = this.adminViewForm.value.vae
    if (this.requestBike?.id) {
        this.httpService
          .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
            id: this.requestBike.id,
            ...this.requestBikeDto
          })
          .subscribe((data) => {
            this.route.navigate(['/mobility-request-admin/list']);
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' }
              });
            } 
          });
        return;
    }
  }

  async annuler(): Promise<void> {
    this.requestBikeDto.status = this.mobilityRequestStatus.Canceled
    this.requestBikeDto.controlerComment = this.adminViewForm.value.controleComment
    this.requestBikeDto.vae = this.adminViewForm.value.vae
    if (this.requestBike?.id) {
        this.httpService
          .patch('BikeRequests/UpdateStatus', this.requestBike.id, {
            id: this.requestBike.id,
            ...this.requestBikeDto
          })
          .subscribe((data) => {
            this.route.navigate(['/mobility-request-admin/list']);
          },
          (error) => {
            if (error.status === 500) {
              this.dialog.open(ErrorDialogComponent, {
                data: { message: 'Oups, problème système' }
              });
            } 
          });
        return;
    }
  }
}

