import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from 'src/app/shared/file.service';
import * as moment from 'moment-timezone';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { RequestTransport } from 'src/core/models/request-transport';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { environment } from 'src/environments/environment';
moment.locale('fr');
moment.tz.setDefault("Europe/Paris");
@Component({
  selector: 'app-reject-transport',
  templateUrl: './reject.component.html',
  styleUrls: ['./reject.component.css'],
})
export class RejectTransportComponent implements OnInit {
  requestTransport: RequestTransport | undefined;
  attestationFile: any;
  peoplePlan: any;
  connectedUser: User | undefined;
  pivotMonth: any;
  totalVerseMensuellement!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private dateService: DateService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private moneyService: MoneyService,
    private fileService: FileService

  ) {}

  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    this.pivotMonth = localStorage.getItem('moisPivot');
    await this.loadTransportRequests();
  }
  get enumSubscriptionType(): typeof FormuleTypeEnum {
    return FormuleTypeEnum;
  }
  get mobilityRequestStatus(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  get environement(): typeof environment {
    return environment;
  }

  calculateIndemTotal(): string {
    let total = 0;
    if (this.requestTransport)
      for (const subscriptionFormula of this.requestTransport
        ?.subscriptionFormulas) {
        let formula: any = subscriptionFormula;
        switch (subscriptionFormula.formulaType) {
          case FormuleTypeEnum.Annuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber(((formula?.subscription.subscriptionTypeRefund?.annualFees /
                  12) *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;
          case FormuleTypeEnum.Mensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.monthlyRefundRate || 0,'.');
            break;

          case FormuleTypeEnum.InfraMensuel:
            total = formula?.subscription.subscriptionTypeRefund?.percent
              ? total +
                this.moneyService.formatNumber((formula.quantity *
                  formula?.subscription.subscriptionTypeRefund?.otherFees *
                  formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate) /
                  100,'.')
              : total +
                  this.moneyService.formatNumber(formula?.subscription.subscriptionTypeRefund
                    ?.unitRefundRate || 0,'.');
            break;

          case FormuleTypeEnum.Misc:
            total =
              formula.miscRefundRate == null
                ? total +
                  this.moneyService.formatNumber((formula.monthlyPayment *
                    formula.subscription.subscriptionTypeRefund
                      ?.monthlyRefundRate) /
                    100,'.')
                : total + this.moneyService.formatNumber(formula.miscRefundRate || 0,'.');
            break;
        }
      }
    // var totalString = total.toFixed(2);
    // totalString = totalString.replace('.', ',');
    // const valueToAdd = parseFloat(newValue);
    return this.moneyService.format(total,'.');;
  }

  async loadTransportRequests(): Promise<void> {
    const id =   this.activatedRoute.snapshot.params['id'];
    const startDate = new Date(this.activatedRoute.snapshot.params['date']);
    startDate.setUTCDate(startDate.getUTCDate() + 1);

    this.httpService
      .findOneByIdAndDate(
        'TransportRequests',id,new Date(startDate).toISOString()
      )
      .subscribe((result: any) => {
        this.requestTransport = result;
      });
  }

  getForumlaValue(formula: any) {
    switch (formula.formulaType) {
      case FormuleTypeEnum.Annuel:
        const refundA = formula?.subscription.subscriptionTypeRefund?.percent
            ? ((formula?.subscription.subscriptionTypeRefund?.annualFees / 12) *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
        return {
          fees: this.moneyService.format(
            formula?.subscription.subscriptionTypeRefund?.annualFees?.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundA.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.Mensuel:
        const refundM = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula?.subscription.subscriptionTypeRefund?.monthlyFees *
                formula?.subscription.subscriptionTypeRefund
                  ?.monthlyRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.monthlyRefundRate;
        return {
          fees: this.moneyService.format(
            formula.subscription.subscriptionTypeRefund?.monthlyFees.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundM.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.InfraMensuel:
        const refundI = formula?.subscription.subscriptionTypeRefund?.percent
            ? (formula.quantity *
                formula?.subscription.subscriptionTypeRefund?.otherFees *
                formula?.subscription.subscriptionTypeRefund?.unitRefundRate) /
              100
            : formula?.subscription.subscriptionTypeRefund?.unitRefundRate;
        return {
          fees: this.moneyService.format(
            formula?.subscription.subscriptionTypeRefund?.otherFees?.toString(),
            '.'
          ),
          refundRate: this.moneyService.format(
            refundI.toString(),
            '.'
          ),
        };
      case FormuleTypeEnum.Misc:
        let refund =
          formula.miscRefundRate != null
            ? this.moneyService.format(formula.miscRefundRate.toString(), '.')
            : this.moneyService.format(
                (
                  (formula.monthlyPayment *
                    formula.subscription.subscriptionTypeRefund
                      .monthlyRefundRate) /
                  100
                ).toString(),
                '.'
              );
        return {
          fees: this.moneyService.format(
            formula.monthlyPayment.toString(),
            '.'
          ),
          refundRate:refund,
        };
      default:
        return {
          fees: 0,
          refundRate: 0,
        };
    }
  }

  downloadFile(FormulaId?: number): void {
    if (this.requestTransport)
      this.httpService
        .downloadFile(
          'TransportRequests/GetTransportRequestFile/' +
            this.requestTransport.id,
          FormulaId && {
            FormulaId,
          }
        )
        .subscribe({
          next: ({ body, headers }) => {
            if (body) {
              let a = document.createElement('a');
              a.href = window.URL.createObjectURL(body);
              a.download = headers.get('x-file-name') as string;
              a.click();
            }
          },
        });
  }

  annulerRequest(): void {
    if (this.requestTransport?.id)
      this.httpService
        .patchFormData('TransportRequests', this.requestTransport.id, {
          id: this.requestTransport.id,
          RejectRequest: true,
          RejectionDatetime: moment(this.pivotMonth, 'MMMM YYYY', 'fr').format(
            'YYYY-MM-DD'
          ),
        })
        .subscribe((data) => {
          this.route.navigate(['/mobility-request/list']);
        });
    return;

  }
  openFile(base64?: string): void {
    if (base64) this.fileService.openFile(base64);
  }
}
