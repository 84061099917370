import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateBikeComponent } from './create/create.component';
import { RejectBikeComponent } from './reject/reject.component';
import { UpdateBikeComponent } from './update/update.component';
import { ViewBikeComponent } from './view/view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from '../mobility-request-user.routing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { NgxMaskModule,IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
  thousandSeparator: ' ',
  decimalMarker: ',',
};

@NgModule({
  declarations: [
    CreateBikeComponent,
    UpdateBikeComponent,
    ViewBikeComponent,
    RejectBikeComponent,
  ],
  exports: [
    CreateBikeComponent,
    UpdateBikeComponent,
    ViewBikeComponent,
    RejectBikeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(routes),
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig)

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BikeModule {}
