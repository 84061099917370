export const environment = {
  production: false,
  apiurl: 'https://fr-mesmobilites.eyua.net/api/',
  baseUrl: 'https://fr-mesmobilites.eyua.net/Pages/',
  style: 'background-color: #1d4ed8;',
  OAuthSettings: {
    clientId: '94769fc2-dde8-4593-a6c1-ce1d300d173c',
    authority:
      'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    redirectUri: 'https://fr-mesmobilites.eyua.net',
    scopes: [
      'user.read',
      'user.read.All',
      'User.ReadWrite',
      'Mail.ReadWrite',
      'Mail.Send',
      'OnlineMeetings.ReadWrite',
      'People.Read',
      'profile',
      'User.ReadBasic.All',
    ],
  },
};
