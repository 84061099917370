import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { List } from 'linqts';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { every } from 'rxjs';
import { ErrorDialogComponent } from 'src/app/template/ErrorDialog/ErrorDialog/ErrorDialog.component';
import { Canstants } from 'src/core/canstants/canstants';
import { FileType } from 'src/core/enum/FileTypeEnum';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import {
  AttachementRequest,
  AttachementType,
} from 'src/core/models/attachement-request';
import { FormulaDto } from 'src/core/models/FormulaDto';
import { RequestTransport } from 'src/core/models/request-transport';
import { SubscriptionFormula } from 'src/core/models/subscription-formula';
import { SubscriptionType } from 'src/core/models/subscription-type';
import { TransportRequestDto } from 'src/core/models/TransportRequestDto';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { DateService } from 'src/core/services/date.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { PdfService } from 'src/core/services/pdf.service';
import { environment } from 'src/environments/environment';

moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-create-transport',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateTransportComponent implements OnInit {
  connectedUser: User | undefined;
  peoplePlan: any;
  constants: any = Canstants;
  requestVelos: any;
  selectedStartDate:any;
  requestTransports: any;
  requestParkings: any;
  listStartMonth: Array<any> = [];
  // ineligibleDates: any[] = [];
  requestTransport: RequestTransport = new RequestTransport();
  dateNow = new Date();
  transportRequestForm!: FormGroup;
  subscriptions: Array<SubscriptionType> = [];
  subscriptionFormula: SubscriptionFormula = new SubscriptionFormula();

  attachmentToBlob: Array<any> = [];
  duration = 0;
  doIn = '';
  demeurantIn = '';
  submitted = false;
  attestationFile: any;
  condition1 = false;
  condition2 = false;
  condition3 = false;
  resetForm = false;
  popUpHasNoAccessToAdd = false;
  listEndMonth: Array<any> = [];
  dateDisabled = false;
  quantity: any = null;
  selectedTypoAbos: Array<any> = [];
  Files: Array<any> = [];
  tarifMisc: any = null;
  businessParam: any;
  tarifAutreInfra: any = null;
  forfaitRembUnitaire: any = null;
  dateValid = false;
  selectedSubscription: any;
  subscriptionsList: Array<FormulaDto> = [];
  checkAccessTransport = {
    access: true,
    message: '',
  };
  @ViewChild('openForumlaAlreadyExist')
  openForumlaAlreadyExist!: ElementRef;
  @ViewChild('openModalAttachement')
  openModalAttachement!: ElementRef;
  @ViewChild('openModalNegativeNumber')
  openModalNegativeNumber!: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dateService: DateService,
    private pdfService: PdfService,
    private moneyService: MoneyService,
    private authService: AuthenticationService,
    private httpService: HttpClientService<any>,
    private dialog: MatDialog
  ) {
    this.initializeForm();
  }

  get form(): any {
    return this.transportRequestForm?.controls;
  }
  get environement(): typeof environment {
    return environment;
  }
  async ngOnInit(): Promise<void> {
    this.connectedUser = await this.authService.getConnectedUser();
    await this.checkEligibility();
  }
  async checkEligibility(): Promise<any> {
    const mapping: any = [];

    this.httpService.findAll('TransportRequests/CheckEligibility').subscribe({
      next: (months) => {
        let index = 0;

        months.availableMonths.forEach((element: any) => {
          mapping.push({
            value: moment(element).format('MMMM YYYY'),
            index,
            numberValue: moment(element).format('YYYY-MM'),
          });
          index++;
        });
        this.listStartMonth = mapping;
        // this.ineligibleDates = months.dates;
      },
      error: (err: HttpErrorResponse) => {
        if (err.status == 403) {
          this.checkAccessTransport.access = false;
          this.checkAccessTransport.message = err.error.detail;
        }
      },
    });
  }
  private initializeForm(): void {
    this.transportRequestForm = this.formBuilder.group({
      startMonth: ['', Validators.required],
      endMonth: ['', Validators.required],
      requesterComment: [''],
      attestationFile: [''],
      subscriptionFormulaForm: this.formBuilder.array([]),
    });
  }

  get subscriptionFormulaForm(): FormArray {
    return this.transportRequestForm?.controls?.[
      'subscriptionFormulaForm'
    ] as FormArray;
  }

  set subscriptionFormulaForm(formArray: FormArray) {
    this.transportRequestForm.controls['subscriptionFormulaForm'] = formArray;
  }

  async getListAbonnements(): Promise<void> {
    this.httpService
      .findAll('subscriptionTypes?StartDate='+
      new Date(this.selectedStartDate).toISOString())
      .subscribe((subscriptions: Array<SubscriptionType>) => {
        const grouped: any = new List(subscriptions).GroupBy(
          (x: any) => x.formulaCode
        );
        const codes = Object.getOwnPropertyNames(grouped);
        for (const code of codes) {
          if (
            this.transportRequestForm.value.startMonth ===
            this.transportRequestForm.value.endMonth
          ) {
            this.subscriptions.push(grouped[code][0]);
          } else {
            if (
              grouped[code][0].formulaType == FormuleTypeEnum.Annuel ||
              grouped[code][0].formulaType == FormuleTypeEnum.Misc
            ) {
              this.subscriptions.push(grouped[code][0]);
            }
          }
        }
        this.subscriptions.sort(function (a: any, b: any) {
          return a.order - b.order;
        });
      });
  }



  async getJustifFile(event: any, index: number): Promise<void> {
    this.selectedTypoAbos[index].File = event.target.files[0];
    this.selectedTypoAbos[index].FileType =
    FileType[event.target.files[0].type as keyof typeof FileType];
    const subscriptionFormulaLine = this.subscriptionFormulaForm.controls[
      index
    ] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(event.target.files[0]);
    subscriptionFormulaLine.controls['FileType'].setValue(
      FileType[event.target.files[0].type as keyof typeof FileType]
      );
      const fileSize = event.target.files[0].size;
      const isFileSizeGreaterThan4MB = fileSize >  1024 * 1024; 
      this.Files[index].fileExists = true;
      this.Files[index].fileGreater = false;

      if (isFileSizeGreaterThan4MB && this.selectedTypoAbos[index].File) {
        this.Files[index].fileGreater = true;
      }
    this.openModalAttachement.nativeElement.click();
  }

  openAttachment(index: number): void {
    window.open(URL.createObjectURL(this.attachmentToBlob[index]));
  }
  filesValid():boolean{
    let res=true;
    for (let i =0; i< this.Files.length ;i++){
      if( this.Files[i].fileGreater == true) {
        res=false;
      }
    }
    return res;

  }

  removeJustifFile(index: number): void {
    this.selectedTypoAbos[index].File = null;
    this.selectedTypoAbos[index].FileType = null;
    this.Files[index].fileExists = false;
    this.Files[index].fileGreater = false;

    const subscriptionFormulaLine = this.subscriptionFormulaForm.controls[
      index
    ] as FormGroup;
    subscriptionFormulaLine.controls['File'].setValue(null);
  }

  selectTypeAbonnement(event: any, rowIndex: number): void {
    this.Files[rowIndex].fileExists = false;
    this.Files[rowIndex].fileGreater = false;
    //check if sub exist
    if (
      this.selectedTypoAbos.some((x) => x.id === Number(event.target.value))
    ) {
      this.openForumlaAlreadyExist.nativeElement.click();
    }
    let subscriptionType = this.subscriptions.find(
      (s) => s.id == event.target.value
    );

    if (subscriptionType) {
      const subscriptionFormulaLine = this.subscriptionFormulaForm.controls[
        rowIndex
      ] as FormGroup;
      subscriptionFormulaLine.controls['FormulaId'].setValue(
        subscriptionType.id
      );
      this.selectedTypoAbos[rowIndex].id = subscriptionType.id;

      switch (subscriptionType.formulaType) {
        case FormuleTypeEnum.Annuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType?.subscriptionTypeRefund?.annualFees?.toString(),
              '.'
            )
          );
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Annuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.Mensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.monthlyFees.toString(),
              '.'
            )
          );

          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif ' + FormuleTypeEnum[FormuleTypeEnum.Mensuel].toString();
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          break;
        case FormuleTypeEnum.InfraMensuel:
          subscriptionFormulaLine.controls['Price'].setValue(
            this.moneyService.format(
              subscriptionType.subscriptionTypeRefund?.otherFees.toString(),
              '.'
            )
          );
          this.tarifAutreInfra =
            subscriptionType.subscriptionTypeRefund?.otherFees;
          this.forfaitRembUnitaire =
            subscriptionType.subscriptionTypeRefund?.unitRefundRate;
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.nullValidator
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.required
          );
          this.selectedTypoAbos[rowIndex].isMisc = false;
          this.selectedTypoAbos[rowIndex].showQuantity = true;
          this.selectedTypoAbos[rowIndex].tarifName = 'Tarif unitaire';
          break;
        case FormuleTypeEnum.Misc:
          subscriptionFormulaLine.controls['Price'].setValue(this.tarifMisc);
          subscriptionFormulaLine.controls['EntitledMisc'].setValidators(
            Validators.required
          );
          subscriptionFormulaLine.controls['Quantity'].setValidators(
            Validators.nullValidator
          );
          this.selectedTypoAbos[rowIndex].isMisc = true;
          this.selectedTypoAbos[rowIndex].showQuantity = false;
          this.selectedTypoAbos[rowIndex].tarifName =
            'Tarif réglé pour le mois';
          break;
      }
    }
  }
  fixIssueDate(sensorsData: any) {
    return new Date(
      sensorsData.getTime() - sensorsData.getTimezoneOffset() * 60000
    );
  }
  changeStartDate(event: any): void {
    this.dateValid = false;
    const mapping: any = [];
    let date = event.target.value + '-01';
    this.selectedStartDate = date
    this.httpService
      .findAll(
        'TransportRequests/GetEndMonths?selectedStartDate=' +
          new Date(date).toISOString()
      )
      .subscribe({
        next: (months) => {
          let index = 0;
          months.availableMonths.forEach((element: any) => {
            mapping.push({
              value: moment(element).format('MMMM YYYY'),
              index,
              numberValue: moment(element).format('YYYY-MM'),
            });
            index++;
          });
          this.listEndMonth = mapping;
        },
      });
  }

  changeEndDate(): void {
    var dateEnd = moment(this.transportRequestForm?.value.endMonth);
    if (dateEnd.isValid()) {
      this.duration = this.dateService.calculateDuration(
        this.transportRequestForm?.value.endMonth,
        this.transportRequestForm?.value.startMonth
      );
      this.requestTransport.endDate = this.transportRequestForm?.value.endMonth;
      this.dateValid = true;
    } else {
      this.dateValid = false;
    }
  }
  testFormat(value: any, index: any) {
    let isnum = /^(\d+,)*(\d+)$/.test(value.target.value);
    const subscriptionFormulaLine = this.subscriptionFormulaForm.controls[
      index
    ] as FormGroup;
    let b = parseFloat('0,00');
    let a = parseFloat(value.target.value);
    if (!isnum || a == b) {
      this.openModalNegativeNumber.nativeElement.click();
      subscriptionFormulaLine.controls['Price'].setValue('');
    }
  }
  async resetAllForm(): Promise<void> {
    window.location.reload();
  }

  async validateDates(): Promise<void> {
    // this.subscriptionFormulaForm = this.formBuilder.array([]);
    this.pushFormLineSubscriptionFormula();
    this.dateDisabled = true;
    await this.getListAbonnements();
  }

  private pushFormLineSubscriptionFormula(): void {
    const subscriptionFormulaForm = this.formBuilder.group({
      FormulaId: ['', Validators.required],
      Price: ['', Validators.required],
      EntitledMisc: [''],
      Quantity: [''],
      File: ['', Validators.required],
      FileType: [''],
    });
    this.subscriptionFormulaForm.push(subscriptionFormulaForm);
    this.selectedTypoAbos.push({});
    this.Files.push({});
  }

  async addTransportRequest(): Promise<void> {
    this.submitted = true;
    if (!this.transportRequestForm?.valid) {
      return;
    }
    const formulasArray = this.transportRequestForm.get(
      'subscriptionFormulaForm'
    ) as FormArray;
    
    for (let i = 0; i < formulasArray.length; i++) {
      let formdeI = formulasArray.at(i).get('EntitledMisc')?.value;
      if (formdeI != null && formdeI != '') {
        const priceControl = formulasArray.at(i).get('Price');
        let value = priceControl?.value;
        if (value.includes('.') || !value.includes(',')) {
          value = parseFloat(value);
        } else {
          const newValue = value.replace(',', '.');
          value = parseFloat(newValue);
        }
        this.transportRequestForm.value.subscriptionFormulaForm[i].Price = value;
        value = value.toFixed(2);

        priceControl?.setValue(value);
      }
    }
    let payload: any = {
      StartDate: this.transportRequestForm?.value.startMonth,
      EndDate: this.transportRequestForm?.value.endMonth,
      TransportRequestAttachement:
        this.transportRequestForm?.value.attestationFile,
      RequesterComment: this.transportRequestForm?.value.requesterComment,
      RequesterAddress: this.demeurantIn,
      Formulas: this.transportRequestForm?.value.subscriptionFormulaForm,
    };
    this.httpService.postFormData('TransportRequests', payload).subscribe({
      next: (data) => {
        this.router.navigate(['/mobility-request/list']);
      },
      error: (error) => {
        if (error.status === 500) {
          this.dialog.open(ErrorDialogComponent, {
            data: { message: 'Oups, problème système' }
          });
        } 
      }
    });
  }

  attachmentType(): typeof AttachementType {
    return AttachementType;
  }

  deleteAttestation(): void {
    this.resetAllForm();
  }

  deleteRow(rowIndex: any): void {
    this.subscriptionFormulaForm.removeAt(rowIndex);
    this.selectedTypoAbos.splice(rowIndex, 1);
    this.Files.splice(rowIndex, 1);
  }

  async generatePDF(): Promise<void> {
    this.requestTransport.requesterAddress = this.demeurantIn;

    const duration = this.dateService.calculateDuration(
      this.transportRequestForm.value.endMonth,
      this.transportRequestForm.value.startMonth
    );
    await this.pdfService.generatePDF(
      duration,
      this.doIn,
      this.demeurantIn,
      MobilityRequestType.transport
    );

    this.transportRequestForm.controls['attestationFile'].setValue(
      this.pdfService.pdf.output('blob')
    );
    this.attestationFile = this.pdfService.pdf.output('blob');
    this.requestTransport.requesterAddress = this.demeurantIn;
    this.requestTransport.certificateFmd = this.pdfService.pdf.output(
      'blob'
    ) as File;
    this.pushFormLineSubscriptionFormula();
    this.dateDisabled = true;
    await this.getListAbonnements();
  }

  addRowFormula(RowIndex: number): void {
    if (this.selectedTypoAbos.length < 3) {
      this.pushFormLineSubscriptionFormula();
    }
  }

  openPdf(): void {
    this.pdfService.openPdf();
  }
}
