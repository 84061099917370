import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'moneyFormat'
})
export class MoneyFormatPipe implements PipeTransform {

  transform(value: string | undefined, separator: string = ','): string {
    let outputValue = '';
    if (value) {
      const values = value.split(separator);
      if (!values[1]) {
        outputValue = parseFloat(value).toFixed(2).replace('.', ',');
      } else {
        if (values[1].length === 1) { // exp : 120,1
          outputValue = value + '0';
        }
        if (values[1].length >= 2) {
          outputValue = value;
        }
      }

    }
    return outputValue;
  }

}
