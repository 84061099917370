<div class="row mt-4">
    <div class="col-md-12 ml-2">
      <span class=""><a routerLink="/"><img alt="" [src]="environement.baseUrl + 'assets/images/Arrow.svg'"></a></span>
      <span class="bold name-user yellowColor">{{connectedUser?.displayName}}</span>
    </div>
    <div class="col-md-12 mt-3 ml-5 title-responsive">
      <span class="bold Title">Portail Mobilités Transports | </span>&nbsp;<span class="semi-bold Title yellowColor">Mon
        espace
        collaborateur</span>
      <div>
        <img alt="" [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-md-6">
      <div class="col-md-12 ml-5 title-responsive">
        <span class="bold yellowColor titleFormule">
          <div class="squarePuce"></div> Mes formules de financement approuvées
        </span>
      </div>
      <div class="col-lg-12 mt-5">
        <div id="carouselRequestApproval" class="carousel slide flex-center" data-ride="carousel">
          <div class="carousel-inner txt-center flex-center">
            <div class="row" *ngIf="!isSmallScreen">
              <app-request-approved class="flex-center"
                [ngClass]="sildes[indexOfSlide].length > 1 ? 'col-md-4' : 'col-md-12'"
                *ngFor="let request of sildes[indexOfSlide]" (onViewRequest)="viewRequest($event)"
                (onUpdateRequest)="updateRequest($event)" [request]="request" [businessParamDay]="businessParamDay">
              </app-request-approved>
            </div>

            <div class="row" *ngIf="isSmallScreen">
              <app-request-approved class="flex-center"
                [ngClass]="sildes2[indexOfSlide2].length > 1 ? 'col-md-4' : 'col-md-12'"
                *ngFor="let request of sildes2[indexOfSlide2]" (onViewRequest)="viewRequest($event)"
                (onUpdateRequest)="updateRequest($event)" [request]="request" [businessParamDay]="businessParamDay">
              </app-request-approved>
            </div>
          </div>
          <a [class]="disablePrev ? 'display-none':'carousel-control-prev'" href="#carouselRequestApproval"
            role="button" data-slide="prev" (click)="goPrev()"><img alt="" class="CarouselArrow"
            [src]="environement.baseUrl + 'assets/images/prev.PNG'"></a>
          <a [class]="disableNext ? 'display-none':'carousel-control-next'" href="#carouselRequestApproval"
            role="button" data-slide="next" (click)="goNext()">
            <img alt="" class="CarouselArrow" [src]="environement.baseUrl + 'assets/images/next.PNG'">
          </a>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-2">
      <div class="bg-gray right-box2 right-box-responsive">
        <div class="bold yellowColor">
          Lorsqu’une formule s’affiche sur un mois de l’échéancier ci-contre, vous êtes réputé(e) bénéficiaire du
          remboursement correspondant sur votre paie (versé sur le mois courant ou le mois suivant, selon la date de
          validation de la demande), ou d’une Carte Parking le cas échéant.
          <br />
          Lorsque le bouton Voir/Modifier vous est proposé, cela signifie que vous pouvez encore mettre fin au bénéfice de
          cette formule à compter du mois en question (indispensable si vous souhaitez changer de formule).
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-4">
      <div class="right-box">
        <div class="row mb-1">
          <div class="col-lg-12" *ngIf="canAccessBike">
            <div><img alt="" class="imagesRightContainer" [src]="environement.baseUrl + 'assets/images/velo.jpg'">
              <div class="VeloGradien">
                <div class="bold titleCard">Subvention Vélo</div>
                <div class="bold descCard">
                  <a href="#" class="text-dark" data-toggle="modal" data-target="#view-bike-condition">Voir les
                    conditions</a>
                </div>
              </div>
            </div>
            <div class="mytooltip tooltip-effect-1">
              <div class="btn bold bg-color-yellow btnEmettreDemande"><a class="linkDemande"
                  routerLink="/bike/create">Emettre une
                  demande</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-lg-12">
            <div><img alt="" class="imagesRightContainer" [src]="environement.baseUrl + 'assets/images/transport.jpg'">
              <div class="transportGradien">
                <div class="bold titleCard">Indemnité Transports</div>
                <div class="bold descCard"><a href="#" class="text-dark" data-toggle="modal"
                    data-target="#view-transport-condition">Voir les conditions</a></div>
              </div>
            </div>
            <div class="mytooltip tooltip-effect-1">
             
              <div class="btn bold bg-color-yellow btnEmettreDemande"><a class="linkDemande"
                  routerLink="/transport/create">Emettre une
                  demande</a>
              </div>
             
            </div>
          </div>
        </div>
        <div class="row mb-2" *ngIf="canAddRequestParking">
          <div class="col-lg-12">
            <div><img alt="" class="imagesRightContainer" [src]="environement.baseUrl + 'assets/images/parking.jpg'">
              <div class="ParkingGradien">
                <div class="bold titleCard">Carte Parking</div>
                <div class="bold descCard"><a href="#" class="text-dark" data-toggle="modal"
                    data-target="#view-parking-condition">Voir les conditions</a></div>
              </div>
            </div>
            <div class="mytooltip tooltip-effect-1">
              <div class="tooltip-item btn bold bg-color-gray btnEmettreDemande">
                Emettre
                une demande
              </div>
              <span class="tooltip-content clearfix">
                <span class="tooltip-text">Vous pourrez très prochainement déposer ici une demande d’attribution de Carte
                  Parking.
                  Cette fonctionnalité sera accessible dans le courant du mois septembre.
                  En attendant vous pouvez vous adresser directement au service concerné pour votre bureau (cf page
                  Conditions)
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-lg-12 mediaScreen">
            <div class="mail-to mt-2">
              <span class="mail-to-txt">Contact: <a href="mailto:support.mobilites@fr.ey.com">support.mobilites@fr.ey.com</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-md-9 mt-3">
      <div class="col-md-12 ml-5 title-responsive">
        <span class="bold yellowColor titleFormule mb-3">
          <div class="squarePuce"></div> Mes demandes en cours et cloturées
        </span>
      </div>
      <div class="col-md-12 mt-2 ml-5 tab">
        <div class="row">
          <div class="col-md-2">
            <span>Date de la demande</span>
          </div>
          <div class="col-md-2">
            <span>Type de demande</span>
          </div>
          <div class="col-md-2">
            <span>Mois début ciblé</span>
          </div>
          <div class="col-md-2">
            <span>Mois fin ciblé</span>
          </div>
          <div class="col-md-2">
            <span>Statut</span>
          </div>
         
          <div class="col-md-2">
            <span>Action</span>
          </div>
        </div>
        <hr class="hr2">
        <div class="box-demande" *ngIf="mobilityRequests.length !=0">
          <div class="row text-align-left" *ngFor="let item of mobilityRequests ; let i=index">
            <div class="col-md-2">
              <span *ngIf="item.created">{{item.created | date:'dd/MM/yyyy'}}</span>
            </div>
            <div class="col-md-2">
              <span class="label bg-color-transport badgeEcheances semi-bold mb-4"
                *ngIf="item.requestType == constant.transportAllowance">Transports</span>
              <span class="label bg-color-velo badgeEcheances semi-bold mb-4"
                *ngIf="item.requestType == constant.bicycleSubsidy">Vélo</span>
              <span class="label bg-color-parking badgeEcheances semi-bold mb-4"
                *ngIf="item.requestType == constant.parkingCard">Parking</span>
            </div>
            <div class="col-md-2">
              <span>{{item.startDate | date :'MMMM yyyy'}}</span>
            </div>
            <div class="col-md-2">
              <span>{{item.endDate | date :'MMMM yyyy'}}</span>
            </div>
            <div class="col-md-2">
              <span class="en-cours  bold"
                *ngIf="item.status === mobilityRequestStatus.Submitted">Envoyée</span>
              <span class="en-cours  bold"
                *ngIf="item.status === mobilityRequestStatus.Assigned">En cours
                d'examen</span>
              <span class="returned bold"
                *ngIf="item.status === mobilityRequestStatus.Returned">A compléter
                par l’émetteur</span>
              <span class="canceled bold"
                *ngIf="item.status === mobilityRequestStatus.Canceled">Annulée</span>
              <span class="rejected bold"
                *ngIf="item.status === mobilityRequestStatus.Rejected">Rejetée</span>
            </div>

            
            <div class="col-md-2 pb-2 mb-2">
              <div class="semi-bold"
                *ngIf="item.status ===  mobilityRequestStatus.Assigned ||
                 item.status ===  mobilityRequestStatus.Rejected || 
                 item.status ===  mobilityRequestStatus.Canceled">

                <button *ngIf="item.requestType == constant.transportAllowance"
                  [routerLink]="['/transport/view', item.id]" class="btn btnVoirModifier link">Voir</button>

                <button *ngIf="item.requestType == constant.bicycleSubsidy"
                  [routerLink]="['/bike/view', item.id]" class="btn btnVoirModifier link">Voir</button>
              </div>
              <div class="semi-bold"
                *ngIf="item.status === mobilityRequestStatus.Submitted ||
                 item.status === mobilityRequestStatus.Returned">

                <button *ngIf="item.requestType == constant.transportAllowance"
                  class="btn btnVoirModifier link" [routerLink]="['/transport/view',item.id]">Voir/Modifier</button>
                  
                <button *ngIf="item.requestType == constant.bicycleSubsidy"
                  class="btn btnVoirModifier link" [routerLink]="['/bike/view',item.id]">Voir/Modifier</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row pasDemande" *ngIf="mobilityRequests.length ==0">
          <span class="bold">Pas de demande en cours</span>
        </div>
        <hr class="hr mb-2">
      </div>
    </div>
    <div class="col-md-3 mt-5 pr-5 ">
      <div class="bg-gray ml-5 right-box2">
        <div class="bold yellowColor">
          Une fois validées, vos demandes n’apparaissent plus dans le tableau ci-contre mais directement dans l’échéancier
          placé au-dessus « Mes formules de financement approuvées », pour le(s) mois correspondant(s).
        </div>
      </div>
    </div>
  </div>
  <app-transport-condition-popup></app-transport-condition-popup>
  <app-bike-condition-popup></app-bike-condition-popup>
  <app-parking-condition-popup></app-parking-condition-popup>