<app-header *ngIf="SignedIn" class="header-box-app"></app-header>
<div class="body-pages container-fluid global-gary-bg" *ngIf="SignedIn">
  <ngx-spinner
    bdColor="rgb(26, 26, 36)"
    size="medium"
    color="#fee600"
    type="square-jelly-box"
  ></ngx-spinner>
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="SignedIn"></app-footer>

<div *ngIf="!SignedIn" class="box-sign-in container-fluid">
  <div *ngIf="isAutorised && !isSmallScreen" class="row">
    <div class="col">
      <img alt="img" class="img-sign-in" [src]="environement.baseUrl + 'assets/images/EY_SMART.png'" />
    </div>
    <div class="col right-side">
      <br />
      <div class="sign-in">
        <mgt-login
          (logoutCompleted)="logoutCompleted()"
          (loginCompleted)="loginCompleted()"
          class="login-button"
          id="mgtlogin"
        ></mgt-login>
      </div>
    </div>
  </div>
  <div *ngIf="isAutorised && isSmallScreen" class="display-responsive">
    <div class="row">
      <img alt="img" class="img-sign-in-responsive" [src]="environement.baseUrl + 'assets/images/EY_SMART.png'" />
    </div>
    <div class="row right-side">
      <br />
      <div class="sign-in">
        <mgt-login
          (logoutCompleted)="logoutCompleted()"
          (loginCompleted)="loginCompleted()"
          class="login-button"
          id="mgtlogin"
        ></mgt-login>
      </div>
    </div>
  </div>
  <div *ngIf="!isAutorised" class="row no-acces-sign-in">
    <div class="col">
      <img alt="img" class="img-sign-in" [src]="environement.baseUrl + 'assets/images/EY_SMART.png'" />
    </div>
    <div class="col right-side">
      <br />
      <div class="text-no-acces-sign-in">
      {{errorMsg}}
      </div>
    </div>
  </div>
</div>
