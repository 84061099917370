import { Routes } from '@angular/router';
import { GestionPermissionsComponent } from './gestion-permissions/gestion-permissions.component';
import { GestionCalendrierComponent } from './gestion-calendrier/gestion-calendrier.component';
import { GestionArchiveComponent } from './gestion-archive/gestion-archive.component';
import { GestionFormulesComponent } from './gestion-formules/gestion-formules.component';
import { GestionRemboursementsComponent } from './gestion-remboursements/gestion-remboursements.component';

const routes: Routes = [
  {
    path: 'super-admin/permission',
    component: GestionPermissionsComponent,
  },

  {
    path: 'super-admin/calendrier',
    component: GestionCalendrierComponent,
  },
  {
    path: 'super-admin/archive',
    component: GestionArchiveComponent,
  },
  {
    path: 'super-admin/formulas',
    component: GestionFormulesComponent,
  },
  {
    path: 'super-admin/formulas/:id/:type',
    component: GestionRemboursementsComponent,
  },
];
export default routes;
