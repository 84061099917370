<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/transport.jpg'"
        />
      </div>
      <div class="row BannerGradient1 padding-left">
        <div class="col-md-6">
          <div class="col-md-12 margin-top mb-3">
            <span class="ArrowVelo"
              ><a routerLink="/mobility-request-admin/list"
                ><img
                  alt=""
                  [src]="
                    environement.baseUrl + 'assets/images/Arrow.svg'
                  " /></a></span
            ><span class="bold TitleVelo"> Demande détaillée </span>
            <div>
              <img
                alt=""
                class="DividerVelo"
                [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
              />
            </div>
          </div>
          <div class="col-md-12 margin-top mt-4">
            <span class="bold Title2Velo semi-bold"
              >Indemnité <span class="bold">Transports</span></span
            >
          </div>

          <div class="col-md-12 bannerDescription">
            <p class="semi-bold">
              <a
                href="#"
                class="text-secondary"
                data-toggle="modal"
                data-target="#view-transport-condition"
                >Voir les conditions</a
              >
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right float-right box-requester-admin">
            <span class="bold yellowColor requester-name">
              {{ requestTransport?.requester?.displayName }}
            </span>
            <span class="bold yellowColor requester-name">
              {{ requestTransport?.requester?.gpn }}
            </span>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="SpacerBox margin-top">
  <form class="form" [formGroup]="adminViewForm">
    <div class="bold yellowColor titleFormule">
      <div class="squarePuce"></div>
      Période de remboursement
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">A partir du mois suivant</div>
          <div class="form-group">
            <!-- start month -->
            <span class="ml-4 date-box text-center">{{
              requestTransport?.startDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">Jusqu'au mois suivant</div>
          <div class="form-group">
            <span class="ml-4 date-box text-center">{{
              requestTransport?.endDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="requestTransport?.certificateFmd">
        <div class="piecesContainer mt-3">
          <div class="piecesBoxGray">
            <img
              alt=""
              class="imgBox"
              [src]="environement.baseUrl + 'assets/images/Group.svg'"
            />
          </div>
          <a
            class="link"
          >
            <span class="piecesTxt">Attestation pour reconduction</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Formule d'abonnement
      </div>
    </div>
    <!-- debut subcription formula  -->
    <ng-container formArrayName="Formulas">
      <ng-container
        *ngFor="
          let SubscriptionFormula of requestTransport?.subscriptionFormulas;
          let i = index
        "
      >
        <div [formGroupName]="i">
          <div class="row flex-center">
            <div class="col-md-4">
              <div class="row d-flex align-items-center">
                <div class="col-md-4">Formule d'abonnement</div>
                <div class="form-group col-md-8">
                  <span class="intitule-box">{{
                    SubscriptionFormula.subscription.title
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row d-flex align-items-center">
                <div class="col-md-4">
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Annuel
                    "
                    >Tarif annuel</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Mensuel
                    "
                    >Tarif mensuel</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.InfraMensuel
                    "
                    >Tarif unitaire</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Misc
                    "
                    >Tarif réglé pour le mois</span
                  >
                </div>
                <div class="form-group col-md-8">
                  <span class="date-box text-flex-right">{{
                    getForumlaValue(SubscriptionFormula).fees
                  }}</span>
                  <span class="euro">€</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">

            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div
                class="row d-flex align-items-center"
                *ngIf="SubscriptionFormula.quantity"
              >
                <div class="col-md-4">Quantité</div>
                <div class="col-md-8 form-group">
                  <span class="intitule-box">{{
                    SubscriptionFormula.quantity
                  }}</span>
                </div>
              </div>
              <div
                class="row d-flex align-items-center"
                *ngIf="SubscriptionFormula.entitledMisc"
              >
                <div class="col-md-4">
                  Désignation de votre formule « Autre »
                </div>
                <div class="col-md-8 form-group">
                  <span class="intitule-box">{{
                    SubscriptionFormula.entitledMisc
                  }}</span>
                </div>
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  !SubscriptionFormula.quantity &&
                  !SubscriptionFormula.entitledMisc
                "
              ></div>
            </div>
            <div
              class="col-md-4"
              *ngIf="
                requestTransport?.status == mobilityRequestStatus.Validated
              "
            >
              <div class="row d-flex align-items-center">
                <div class="col-md-4">Indemnité mensuelle applicable</div>
                <div
                  class="col-md-8 form-group"

                >
                  <span class="date-box text-flex-right">{{
                    getForumlaValue(SubscriptionFormula).refundRate
                  }}</span>
                  <span class="euro">€</span>
                </div>
              </div>
            </div>
            <hr class="mt-2 hr2" />
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div
      class="row mt-2"
      *ngIf="
        requestTransport?.status == mobilityRequestStatus.Validated
      "
    >
      <div class="col-md-4 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Remboursement applicable
      </div>
      <div class="col-md-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">Total versé mensuellement</div>
          <div class="col-md-8 form-group">
            <span class="date-box text-flex-right">{{
              calculateIndemTotal()
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-row-reverse mt-2">
      <div>
        <div class="transport-request">

          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Canceled"
            >Statut: Annulée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Rejected"
            >Statut: Rejetée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
            >Statut: Validée</span
          >
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire demandeur</div>
          <div class="form-group">
            <span
              class="comment-box"
              *ngIf="requestTransport?.requesterComment"
              >{{ requestTransport?.requesterComment }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire valideur</div>
          <div class="form-group">
            <textarea
              matInput
              id="requester-comment"
              class="form-control"
              formControlName="controleComment"
              ></textarea>
              <!-- [readonly]="
                requestTransport?.status == mobilityRequestStatus.Assigned
                  ? null
                  : ''
              " -->
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row justify-content-center">
    <div class="box-action-admin">

      <button
        [routerLink]="['/mobility-request-admin/list']"
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Rejected ||
          requestTransport?.status == mobilityRequestStatus.Canceled ||
          requestTransport?.status == mobilityRequestStatus.Validated
        "
        type="button"
        class="btn btn-close-request mt-3"
      >
        Fermer
      </button>


    </div>
  </div>








  <app-transport-condition-popup></app-transport-condition-popup>
</div>
