import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomPaginator } from 'src/app/shared/custom-paginator';
import { Canstants } from 'src/core/canstants/canstants';
import { MobilityRequestStatus } from 'src/core/enum/mobility-request-status.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { FilterOptions } from 'src/core/models/filter-options';
import { RequestBike } from 'src/core/models/request-bike';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { ExcelService } from 'src/core/services/excel.service';
import { HttpClientService } from 'src/core/services/http-client.service';
moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-list-bike',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
})
export class ListComponent implements OnInit {
  @Input() bikeData!: boolean;
  @Input() isArchive!: boolean;
  @Output() dataAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();

  statusForm!: FormGroup;
  constant = Canstants;
  filterOptions = new FilterOptions();
  // sortOption: any = { active: 'Created', direction: 'desc' };
  mobilityRequestStatus!: MobilityRequestStatus;
  subscriptions: any;
  // pageSize = 10;
  currentPage = 1;
  totalRows = 0;
  displayedColumnsVelo: string[] = [];
  requestVelo: RequestBike[] = [];
  allrequestVelo: RequestBike[] = [];
  requestVeloExcel: RequestBike[] = [];
  toggleFilter = true;
  listSites: any;
  listStatus: any;
  currentDate = new Date();
  requestor: any;
  validator: any;
  lastMonth: Date | undefined;
  @ViewChild('paginatorRequestVelo') paginatorRequestVelo!: MatPaginator;
  @ViewChild('allSelectedBike') allSelectedBike!: MatOption;
  @ViewChild(MatSort) sort: MatSort | undefined;
  user: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: Router,
    private fb: FormBuilder,
    private httpService: HttpClientService<any>,
    private authService: AuthenticationService,
    private excelService: ExcelService
  ) {}
  get mobilityRequestStatuss(): typeof MobilityRequestStatus {
    return MobilityRequestStatus;
  }
  async ngOnInit(): Promise<void> {
    this.statusForm = this.fb.group({
      statusFieldBike: new FormControl(''),
    });
    await this.initializeFilter();
    this.user = await this.authService.getConnectedUser();
    this.initializeHeader();
    await this.getSites();
    this.getStatus();
    if (
      (this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
        this.user.roles.includes(this.constant.ROLE_VALIDATOR_VELO)) &&
      this.bikeData &&
      !this.isArchive
    ) {
      await this.loadBikeRequest();
    }
    if (
      (this.user.roles.includes(this.constant.ROLE_VALIDATOR) ||
        this.user.roles.includes(this.constant.ROLE_VALIDATOR_VELO)) &&
      this.isArchive
    ) {
      await this.loadBikeRequestBeforeArchive();
    }
  }
  dateChanged(event: any) {
    this.toggleFilter = false;
  }

  async initializeFilter(): Promise<void> {
    const obj: string = localStorage.getItem('Mob_FilterBike') as string;
    const userFilter = JSON.parse(obj) ? JSON.parse(obj) : null;
    if (userFilter) {
      this.filterOptions.Created = new Date(userFilter.Created).toISOString();
      this.filterOptions.Status = userFilter.Status;
      this.statusForm.controls['statusFieldBike'].setValue(userFilter.Status);
      if(userFilter.sortOption){
        this.filterOptions.sortOption.direction = userFilter.sortOption.direction
        this.filterOptions.sortOption.active = userFilter.sortOption.active
      }
      if(userFilter.pageSize){
        this.filterOptions.pageSize = userFilter.pageSize
      }
      if (userFilter.Site) {
        this.filterOptions.Site = userFilter.Site;
      }
      if (userFilter.searchType) {
        this.filterOptions.searchType = userFilter.searchType;
      }
      if (userFilter.SL) {
        this.filterOptions.SL = userFilter.SL;
      }
      if (userFilter.validator) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.validator);
        // this.selectValidateur(user);
      }
      if (userFilter.requestor) {
        // let user = await this.userService.getUserProfileByEmail(userFilter.requestor);
        // this.selectRequester(user);
      }
      this.toggleFilter = true;
    } else {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      this.filterOptions.Created = currentDate.toISOString();
      this.filterOptions.pageSize = 10;
      this.filterOptions.sortOption.direction = 'desc'
      this.filterOptions.sortOption.active = 'Created'
      this.toggleFilter = true;

      this.filterOptions.Status = [
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ];
      this.statusForm.controls['statusFieldBike'].setValue([
        MobilityRequestStatus[MobilityRequestStatus.Submitted].toString(),
        MobilityRequestStatus[MobilityRequestStatus.Assigned].toString(),
      ]);
    }
  }

  async loadBikeRequest(fromFilter?: boolean): Promise<void> {
    if (this.filterOptions.Created) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    localStorage.setItem('Mob_FilterBike', JSON.stringify(this.filterOptions));

    if (fromFilter) {
      this.currentPage = 1;
    }
    this.httpService
      .findAll('BikeRequests', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
        pageSize: this.filterOptions.pageSize,
        pageNumber: this.currentPage,
        sortName: this.filterOptions.sortOption.active,
        sortDirection: this.filterOptions.sortOption.direction,
      })
      .subscribe((bikes) => {
        this.requestVelo = bikes.items;
        setTimeout(() => {
          this.paginatorRequestVelo.pageIndex = this.currentPage - 1;
          this.paginatorRequestVelo.length = bikes.totalCount;
        });
        // this.route.navigateByUrl('/', { skipLocationChange: true })
        //     .then(() => {
        //       this.route.navigate(['/mobility-request-admin/list']);
        //     });
      });
    this.toggleFilter = true;
  }
  async loadBikeRequestBeforeArchive(fromFilter?: boolean): Promise<void> {
    this.httpService
      .findAll('BikeRequests', {
        pageSize: this.filterOptions.pageSize,
        pageNumber: this.currentPage,
        sortName: this.filterOptions.sortOption.active,
        sortDirection: this.filterOptions.sortOption.direction,
        IsDeleted: true,
      })
      .subscribe((bikes) => {
        this.requestVelo = bikes.items;
        var dataExists = false;
        if (bikes.items.length > 0) {
          dataExists = true;
          this.dataAvailable.emit(dataExists);
        }
        setTimeout(() => {
          // this.paginatorRequestVelo.pageIndex = this.currentPage - 1;
          this.paginatorRequestVelo.length = bikes.totalCount;
        });
      });
  }

  pageChanged(event: PageEvent) {
    this.filterOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadBikeRequest();
  }

  pageChangedBeforeArchive(event: PageEvent) {
    this.filterOptions.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadBikeRequestBeforeArchive();
  }

  toggleAllSelectionBike(): void {
    if (this.allSelectedBike.selected) {
      this.statusForm.controls['statusFieldBike'].setValue([
        ...this.listStatus.map((item: any) => item),
        0,
      ]);
      this.filterOptions.Status = [...this.listStatus.map((item: any) => item)];
    } else {
      this.statusForm.controls['statusFieldBike'].setValue([]);
      this.filterOptions.Status = [];
    }
  }
  selectPerOneVelo(all: any) {
    this.filterOptions.Status =
      this.statusForm.controls['statusFieldBike'].value;
    if (this.allSelectedBike.selected) {
      this.allSelectedBike.deselect();
      return false;
    }
    if (
      this.statusForm.controls['statusFieldBike'].value.length ==
      this.listStatus.length
    )
      this.allSelectedBike.select();
    return true;
  }
  goPageVelo() {
    const pageIndex = this.paginatorRequestVelo.pageIndex + 1;
    this.requestVelo = this.allrequestVelo.slice(
      (pageIndex - 1) * this.paginatorRequestVelo.pageSize,
      pageIndex * this.paginatorRequestVelo.pageSize
    );
  }

  getStatus(): void {
    this.listStatus = Object.getOwnPropertyNames(MobilityRequestStatus)
      .filter((x) => isNaN(Number(x)) === false)
      .map((key: any) => MobilityRequestStatus[key]);
  }

  async getSites(): Promise<void> {
    this.httpService.findAll('Sites',{
      pageSize: 100,
      SortName:"name",
      SortDirection:"Asc"
    }).subscribe((sites) => {
      this.listSites = sites.items;
    });
  }

  async getExcelFileVelo() {
    let RequestVelos: any = [];
    if (this.filterOptions.Created) {
      const selectedDate = new Date(this.filterOptions.Created);
      selectedDate.setUTCHours(0, 0, 0, 0);
      if (!this.toggleFilter)
        selectedDate.setUTCDate(selectedDate.getUTCDate() + 1);
      this.filterOptions.Created = selectedDate.toISOString();
    }
    await this.httpService
      .findAll('BikeRequests/GetExcelAdminVelo', {
        ...this.filterOptions,
        Created: this.filterOptions.Created,
      })
      .subscribe((trs) => {
        this.requestVeloExcel = trs;

        this.requestVeloExcel.forEach((elem) => {
          let obj = {
            Created: moment(elem.created).format('DD-MM-YYYY'),
            StartDate: moment(elem.startDate).format('DD-MM-YYYY'),
            EndDate: moment(elem.endDate).format('DD-MM-YYYY'),
            ValidationDate: elem.validatedDate
              ? moment(elem.validatedDate).format('DD-MM-YYYY')
              : '',
            Requester: elem.requester.displayName,
            Email: elem.requester.email,
            Gui: elem.requester.gui,
            Mob_Matricule: elem.requester.gpn,
            Status: MobilityRequestStatus[elem.status],
            EntiteJuridique: elem.requester?.myCompanyDesc,
            Grade: elem.requester?.rankDesc,
            ServiceLine: elem.requester?.slTgm,
            SiteRattachement: elem.requester?.locationCode,
            Bureau: elem.requester?.locationDesc,
            NomValideur: elem.validator?.displayName,
            StatusDate: moment(elem.statusDate).format('DD-MM-YYYY'),
            Vae: elem.vae ? 'Oui' : 'Non',
            SubsidizedAmount: elem.subsidizedAmount,
            PurchaseAmount: elem.purchaseAmount,
          };

          RequestVelos.push(obj);
        });
        const res = RequestVelos.map(function (value: any) {
          return Object.values(value);
        });

        let displayedColumnsVelo = [
          'Date submission',
          'Mois début',
          'Mois fin',
          'Date validation',
          'Demandeur',
          'Adresse mail',
          'GUI',
          'Matricule',
          'Statut',
          'BU',
          'Rank',
          'SL',
          'Code Bureau',
          'Nom Bureau',
          'Traitée par',
          'Date de mise à jour',
          'Vélo Electrique',
          'Montant de la subvention',
          'Montant de la facture',
        ];

        this.excelService.generateExcel(
          res,
          null,
          MobilityRequestType[MobilityRequestType.cycling].toString(),
          displayedColumnsVelo
        );
      });
  }

  async sortVeloRequest(event: any): Promise<void> {
    this.filterOptions.sortOption = event;
    this.paginatorRequestVelo.pageIndex = 0;
    if (!this.isArchive) {
      await this.loadBikeRequest();
    } else {
      await this.loadBikeRequestBeforeArchive();
    }
  }

  initializeHeader(): void {
    this.displayedColumnsVelo = [
      'Created',
      'Requester',
      'SiteRattachement',
      'ServiceLine',
      'EntiteJuridique',
      'Grade',
      'StartDate',
      'duration',
      'Status',
      'NomValideur',
      'StatusDate',
      'Actions',
    ];
  }

  async resetSearchVelo(): Promise<void> {
    this.filterOptions = new FilterOptions();
    localStorage.removeItem('Mob_FilterBike');
    this.resetRequester();
    this.resetValidateur();
    await this.initializeFilter();
    this.paginatorRequestVelo.pageIndex = 0;
    this.loadBikeRequest();
  }

  selectRequester(user: any): void {
    this.requestor = user;
    this.filterOptions.Requester = user.email;
  }
  resetRequester() {
    this.requestor = null;
    this.filterOptions.Requester = '';
  }
  selectValidateur(user: any): void {
    this.validator = user;
    this.filterOptions.Validator = user.email;
  }
  resetValidateur() {
    this.validator = null;
    this.filterOptions.Validator = '';
  }
}
