import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/shared/file.service';
import { Canstants } from 'src/core/canstants/canstants';
import { FormuleTypeEnum } from 'src/core/enum/formule-type-enum';
import { MobilityRequestStatusArchive } from 'src/core/enum/mobility-request-status-archive.enum';
import { MobilityRequestType } from 'src/core/enum/mobility-request-type.enum';
import { BikeRequestDto } from 'src/core/models/BikeRequestDto';
import { RequestBike, bikeFile } from 'src/core/models/request-bike';
import User from 'src/core/models/user';
import { AuthenticationService } from 'src/core/services/authentication.service';
import { HttpClientService } from 'src/core/services/http-client.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
 // tslint:disable-next-line:no-input-rename
 @Input('type') type: MobilityRequestType | undefined;
 @ViewChild('openUpdatePopup') openUpdatePopup!: ElementRef;
 requestBike!: any;
 requestBikeDto: BikeRequestDto = new BikeRequestDto();
 connectedUser: User | undefined;
 constant = Canstants;
 peoplePlan: any
 adminViewForm!: FormGroup ;
 idSubcriptionFormulaMisc: any;
 attachmentFile: any;
 attestationFile: any;
 businessParam: any;
 personalAddress!: string;

 constructor(private activatedRoute: ActivatedRoute,
   private formBuilder: FormBuilder,
   private route: Router,
   private spinner: NgxSpinnerService,
   private httpService: HttpClientService<any>,
   private authService: AuthenticationService,
   private fileService:FileService
   ) {
   this.initializeForm();
 }

 get form(): any {
   return this.adminViewForm.controls;
 }
 async ngOnInit(): Promise<void> {
   this.connectedUser = await this.authService.getConnectedUser();
   this.httpService
     .findAll('BusinessParams')
     .subscribe((bp) => {
       let formattedMonth = moment().format('MM');
       let title = Canstants.limitValidVeloM +
         moment().format("yyyy") +
         formattedMonth;
       this.businessParam = bp.filter((param: any) => param.title == title);
     });

   this.httpService
     .findOneById('BikeRequests/GetBikeRequestArchiveById', this.activatedRoute.snapshot.params['id'])
     .subscribe((result: any) => {
       this.requestBike = result;
       this.getRequesterAddress();
       this.adminViewForm.controls['controleComment'].setValue(this.requestBike.controlerComment)
     });
 }
 async getRequesterAddress():Promise<void>{
   this.httpService.findOneById('Users',this.requestBike.requester.gpn)
   .subscribe((result:any) => {
      this.personalAddress = result.address
   })
 }

 get enumSubscriptionType(): typeof FormuleTypeEnum {
   return FormuleTypeEnum;
 }
 get mobilityRequestStatus(): typeof MobilityRequestStatusArchive {
   return MobilityRequestStatusArchive;
 }
 initializeForm(): void {
   this.adminViewForm = this.formBuilder.group({
     controleComment: ['', Validators.required]
   });


 }
 get environement(): typeof environment {
   return environment;
 }




 fermer(): void {
   this.route.navigate(['/mobility-request-admin/list']);
 }





}
