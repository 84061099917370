import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment-timezone';
import { MobilityRequestType } from '../enum/mobility-request-type.enum';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  generateExcel(data: Array<any>, month: any, type: any, header: Array<string>): any {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Extraction');
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell: any) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE600' },
        bgColor: { argb: 'FFE600' }
      }
      cell.font = {
        name: 'Arial',
        family: 2,
        bold: true,
        size: 10
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    data.forEach(d => {
      worksheet.addRow(d);
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (type == MobilityRequestType[MobilityRequestType.transport].toString()) {
        if(month){
          fs.saveAs(blob, 'Mob-TRS_ExtractPaie_' + month  + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
        else{
          fs.saveAs(blob, 'TRS-Extract_'  + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      }
      if (type == MobilityRequestType[MobilityRequestType.parking].toString()) {
        if(month){
          fs.saveAs(blob, 'Mob-PRK_ExtractPaie_' + month  + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
        else{
          fs.saveAs(blob, 'PRK-Extract_'  + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      }
      if (type == MobilityRequestType[MobilityRequestType.cycling].toString()) {
        if(month){
          fs.saveAs(blob, 'Mob-VLO_ExtractPaie_' + month  + '-x_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
        else{
          fs.saveAs(blob, 'Velo-Extract_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      }
      if (type == MobilityRequestType[MobilityRequestType.trsAnterieur].toString()) {
        if(month){
          fs.saveAs(blob, 'Mob-TRS_ExtractPaie_' + month  + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
        else{
          fs.saveAs(blob, 'TRS-Extract_'  + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      }
      if (type == MobilityRequestType[MobilityRequestType.trsSelected].toString()) {
        if(month){
          fs.saveAs(blob, 'Mob-TRS_ExtractPaie_' + month  + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
        else{
          fs.saveAs(blob, 'TRS-Extract_'  + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      }
    });

  }
}
