<div class="modal fade" id="view-bike-condition" tabindex="-1" role="dialog" aria-labelledby="modalCloseForm" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                <h4 class="modal-title bold">Subvention Vélo</h4>
                <h5 class="modal-title semi-bold">Modalités et conditions</h5>
            </div>
            <div class="modal-body">
                <div class="conditionTitle">
                    <span class="semi-bold yellowColor">Le transport le plus durable ! </span>
                </div>
                <div class="mt-3">EY verse une subvention à l’achat d’un vélo électrique ou mécanique à tout collaborateur EY salarié (CDI, CDD, apprenti) ou stagiaire sans condition d’ancienneté.</div>
                <div class="yellowColor bold mt-3">Périmètre : </div>
                <div class="mt-1">Le montant de la dépense pris en compte ne porte que sur le prix du vélo et son gravage obligatoire. Les accessoires ne sont pas pris en charge.</div>
                <div class="yellowColor bold mt-3">Justificatif : </div>
                <ul class="squareList mt1">
                    <li>La facture nominative devra comporter les mentions légales obligatoires dont le n° SIRET ou SIREN du professionnel.
                    </li>
                    <li>Pour toute demande de subvention transmise, la facture devra dater de moins de 90 jours et le collaborateur devra être présent dans les effectifs d'EY à la date d'achat
                    </li>
                </ul>
                <div class="yellowColor bold mt-3">Montant : </div>
                <ul class="squareList mt1">
                    <li>La subvention couvre 50% du montant total de la facture (TTC), dans la limite de 400€ par demande.
                    </li>
                    <li>La subvention est versée par mensualités de 33,33 € maximum sur votre bulletin de paie, jusqu’à l’atteinte du montant total qui vous sera accordé. Cette subvention est exonérée d’impôt et de cotisations sociales.
                    </li>
                </ul>
                <div class="yellowColor bold mt-3">Renouvellement/coexistence : </div>
                <ul class="squareList mt1">

                    <li>Une demande de subvention est renouvelable tous les 3 ans (date anniversaire des 3 ans de votre première demande de subvention vélo).
                    </li>
                    <li>Si vous souhaitez bénéficier d’un autre mode de financement ‘Mobilités’ (abonnement transports en commun ou abonnement vélo public), alors que vous bénéficiez déjà d’une subvention vélo en cours, vous pouvez à tout moment mettre fin
                        à ces remboursements ; dans ce cas vous renoncerez définitivement aux mensualités non encore versées de votre subvention.
                    </li>
                </ul>
                <div class="yellowColor bold mt-3">Fournisseur : </div>
                <ul class="squareList mt1">

                    <li>Vous pouvez acheter votre vélo où vous le souhaitez auprès d'un professionnel. Chez un vélociste professionnel, dans une grande surface de sport, en supermarché, sur Internet…
                    </li>
                    <li>A noter qu’EY a négocié un partenariat avec Cyclez. Ce site dédié EY vous permet d’accéder à une offre d’achat à tarif préférentiel pour des vélos à assistance électrique (VAE) ou classique. Les vélos peuvent être retirés à l’atelier
                        Cyclez à Boulogne Billancourt ou être livrés dans toute la France (frais de livraison).
                    </li>
                    <li>Attention : pour bénéficier de l’aide locale, certaines villes/régions exigent que le vélo soit acheté chez un vélociste local. Pensez à vérifier cette information avant votre achat.
                    </li>
                </ul>
                <div class="yellowColor bold mt-3">Conseils : </div>
                <ul class="squareList mt1">
                    <li>N’oubliez pas d’assurer votre vélo même s’il n'y a pas d'assurance obligatoire pour circuler avec. Votre assurance ‘Responsabilité Civile’ peut couvrir les dommages que vous pouvez causer aux autres, y compris lors de l'utilisation
                        d'un vélo. Renseignez-vous !
                    </li>
                    <li>Cependant, en cas de vol ou dégradation, même à votre votre domicile, il peut être préférable de souscrire un contrat spécifique.
                    </li>
                    <li>Pour votre sécurité, pensez à vous équiper et vous former : être cycliste ne vous dispense pas des règles du code de la route.
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>