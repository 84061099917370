import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import routes from './mobility-request-paie.routing';
import { AngularMaterialModule } from 'src/core/angular-material/angular-material.module';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    ListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports:[
    ListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MobilityRequestPaieModule { }
