<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img alt="" class="imageBanner" [src]="environement.baseUrl + 'assets/images/transport.jpg'" />
      </div>
      <div class="BannerGradient1 padding-left">
        <div class="col-md-12 margin-top mb-3">
          <span class="ArrowVelo"
            ><a href="#" data-toggle="modal" data-target="#close-form"
              ><img alt="" [src]="environement.baseUrl + 'assets/images/Arrow.svg'" /></a></span
          ><span class="bold TitleVelo">Ma demande détaillée </span>
          <div>
            <img
              alt=""
              class="DividerVelo"
              [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
            />
          </div>
        </div>
        <div class="col-md-12 margin-top mt-4">
          <span class="bold Title2Velo semi-bold"
            >Indemnité <span class="bold">Transports</span></span
          >
        </div>
        <div class="col-md-12 bannerDescription">
          <p class="see-condition">
            <a
              href="#"
              class="text-secondary"
              data-toggle="modal"
              data-target="#view-transport-condition"
              >Voir les conditions</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="SpacerBox margin-top">
    <form
      class="form"
      [formGroup]="transportRequestForm"
      (ngSubmit)="updateTransportRequest()"
      autocomplete="off"
    >
      <div class="bold yellowColor titleFormule">
        <div class="squarePuce"></div>
        Période de remboursement
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="transport-box mt-3">
            <div class="">A partir du mois suivant</div>
            <div class="form-group">
              <!-- start month -->
              <span class="ml-4 date-box text-center">{{
                requestTransport?.startDate | date : "LLLL yyyy"
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="transport-box mt-3">
            <div class="">Jusqu’au mois suivant</div>
            <div class="form-group">
              <span class="ml-4 date-box text-center">{{
                requestTransport?.endDate | date : "LLLL yyyy"
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="requestTransport?.certificateFmd">
          <div class="piecesContainer mt-3">
            <div class="piecesBoxGray">
              <img
                alt=""
                class="imgBox"
                [src]="environement.baseUrl + 'assets/images/Group.svg'"
              />
            </div>
            <a
              (click)="openFile(requestTransport?.certificateFmd?.toString())"
              class="link"
            >
              <span class="piecesTxt">Attestation pour reconduction</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 bold yellowColor titleFormule mt-2">
          <div class="squarePuce"></div>
          Formule d'abonnement
        </div>
      </div>
      <!-- debut subcription formula  -->
      <div>
        <ng-container formArrayName="Formulas">
          <ng-container
            *ngFor="
              let sbForm of Formulas.controls;
              let i = index
            "
          >
            <div [formGroupName]="i">
              <div class="row mt-2 d-flex align-items-center">
                <div class="col-md-4">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-4 form-group">
                      <div
                        class="mr-1"
                        data-toggle="modal"
                        data-target="#confirm-delete-formula"
                        *ngIf="i > 0"
                        (click)="deletedIndex.value = i.toString()"
                      >
                        <img
                          alt=""
                          class="TrashImg"
                          [src]="environement.baseUrl + 'assets/images/poubelle.svg'"
                        />
                      </div>
                      Sélection de la formule
                      <input type="hidden" formControlName="FormulaId" />
                    </div>
                    <div class="col-md-8">

                      <select
                        (change)="selectTypeAbonnement($event, i)"
                        class="form-control"
                        formControlName="FormulaId"
                      >
                        <option></option>
                        <option
                          [value]="subscription.id"
                          *ngFor="let subscription of subscriptions"
                          [style]="
                            !subscription.siteId
                              ? 'font-weight: bold;font-style: italic;'
                              : ''
                          ">
                          {{ subscription.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-5" *ngIf="selectedTypoAbos[i]">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-4">
                      {{ selectedTypoAbos[i].tarifName }}
                    </div>
                    <div
                      class="col-md-8 form-group inputDisabled"
                      *ngIf="
                        !selectedTypoAbos[i].isMisc &&
                        selectedTypoAbos[i].tarifName
                      "
                    >
                      <input
                        matInput
                        class="form-control text-right"
                        appFormatMoney
                        mask="separator.2"
                        decimalMarker=","
                        [attr.disabled]="true"
                        readonly
                        formControlName="Price"
                        type="text"
                      />
                      <span class="euro">€</span>
                    </div>
                    <div
                      class="col-md-8 form-group"
                      *ngIf="selectedTypoAbos[i].isMisc"
                    >
                      <input
                        matInput
                        class="form-control text-right"
                        appFormatMoney
                        mask="separator.2"
                        (change)="testFormat($event,i)"
                        decimalMarker=","
                        type="text"
                        formControlName="Price"
                      />
                      <span class="euro">€</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 mt-3" *ngIf="selectedTypoAbos[i]">
                  <div class="row" *ngIf="selectedTypoAbos[i].tarifName">
                    <div
                      *ngIf="!selectedTypoAbos[i].File"
                      class="col-md-12 piecesContainer"
                    >
                      <div
                        [class]="
                          selectedTypoAbos[i].File
                            ? 'piecesBoxGray'
                            : 'piecesBoxYello'
                        "
                      >
                        <img
                          alt=""
                          class="imgBox"
                          [src]="environement.baseUrl + 'assets/images/Line.svg'"
                        />
                        <img
                          alt=""
                          class="imgBox"
                          [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
                        />
                      </div>
                      <input  (change)="getJustifFile($event , i)" #file type="file" [class]="selectedTypoAbos[i].File ? 'FileInputDisabled':'FileInput'">
                      <input type="hidden" formControlName="File" />
                      <!-- <span class="text-danger" *ngIf="submitted && sbForm.errors?.required">Attachement
                        obligatoire</span> -->
                      <div class="mytooltip tooltip-effect-1 mr-2">
                        <div class="tooltip-item infoBulle">
                          <i
                            class="fa fa-info-circle info-bulle"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <span class="tooltip-content clearfix">
                          <span class="tooltip-text-info"
                            >Si vous avez plusieurs PJ à l’appui de cette
                            formule, merci de les insérer dans le même .pdf
                            <br />
                            Pour en savoir plus sur les PJ exigées à l’appui de
                            vos demandes : Voir les conditions
                          </span>
                        </span>
                      </div>
                      <span class="justif-txt"
                      (click)="openFile(selectedTypoAbos[i].File?.toString())"

                        >Mon justificatif d'abonnement</span
                      >
                      <div
                        class="TrashBox"
                        *ngIf="selectedTypoAbos[i].File"
                      >
                        <img
                          alt=""
                          class="TrashImg"
                          [src]="environement.baseUrl + 'assets/images/Group9828.svg'"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="selectedTypoAbos[i].File"
                      class="col-md-12 piecesContainer mb-2"
                    >
                      <div
                        [class]="
                          selectedTypoAbos[i].File
                            ? 'piecesBoxGray'
                            : 'piecesBoxYello'
                        "
                      >
                        <img
                          alt=""
                          class="imgBox"
                          [src]="environement.baseUrl + 'assets/images/Line.svg'"
                        />
                        <img
                          alt=""
                          class="imgBox"
                          [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
                        />
                      </div>
                      <a
                        target="_blank"
                        class="textJustif text-secondary ml-2"
                        (click)="openFile(selectedTypoAbos[i].File?.toString())"

                        *ngIf="
                          selectedTypoAbos[i].File &&
                          selectedTypoAbos[i].File?.encodedAbsUrl
                        "
                        >Mon justificatif d'abonnement {{ i + 1 }}</a
                      >
                      <span
                        class="textJustif ml-2"
                        (click)="openFile(selectedTypoAbos[i].File?.toString())"

                        *ngIf="
                          selectedTypoAbos[i].File &&
                          !selectedTypoAbos[i].File?.encodedAbsUrl
                        "
                        >Mon justificatif d'abonnement {{ i + 1 }}</span
                      >
                      <div
                        class="TrashBox"
                        (click)="removeAttachement(i)"
                        *ngIf="selectedTypoAbos[i].File"
                      >
                        <img
                          alt=""
                          class="TrashImg"
                          [src]="environement.baseUrl + 'assets/images/poubelle.svg'"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center" *ngIf="Files[i].fileExists==false && selectedTypoAbos[i].tarifName">
                    <br>
                    <span
                    class="file-size-gray"

                  >(Saisir un fichier qui ne dépasse pas 1 Mo)</span>
                </div>
                <div class="row justify-content-center" *ngIf="Files[i].fileExists && Files[i].fileGreater && selectedTypoAbos[i].tarifName ">
                  <br>
                  <span
                  class="file-size-red"

                >(La taille du fichier ne doit pas dépasser 1 Mo)</span>
              </div>
                </div>
              </div>
              <div class="row mt-1" *ngIf="selectedTypoAbos[i]">
                <div class="col-md-4">
                  <div
                    class="row d-flex align-items-center"
                    *ngIf="selectedTypoAbos[i].showQuantity"
                  >
                    <div class="col-md-4">Quantité</div>
                    <div class="col-md-8 form-group">
                      <input
                        type="number"
                        matInput
                        class="form-control"
                        formControlName="Quantity"
                        (change)="getQuantity($event, i)"
                      />
                    </div>
                  </div>
                  <div
                    class="row d-flex align-items-center"
                    *ngIf="selectedTypoAbos[i].isMisc"
                  >
                    <div class="col-md-4">
                      Désignation de votre formule « Autre »
                    </div>
                    <div class="col-md-8 form-group">
                      <input
                        type="text"
                        matInput
                        class="form-control"
                        formControlName="EntitledMisc"
                        (change)="getMiscName($event, i)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- fin subcription formula -->
        <div
          class="addFormule mt-3"
          (click)="addRowFormula(Formulas.length)"
          *ngIf="
            Formulas.length >= 1 &&
            Formulas.length < 3
          "
        >
          + Ajouter une formule
        </div>
      </div>
      <hr class="hr" />
      <div class="row">
        <div class="col-md-4">
          <div class="divider2"></div>
        </div>
        <div class="col-md-6">
          <div class="FormuleComment">
            <div class="descriptionxt">Commentaire demandeur</div>
            <div class="form-group">
              <textarea
                maxlength="250"
                matInput
                id="requester-comment"
                class="form-control"
                formControlName="requesterComment"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-md-2 mb-2 flex-column mt-3">
          <button
            type="submit"
            *ngIf="Formulas.controls.length == 0"
            [disabled]="
              (Formulas.valid &&
              !(Formulas.controls.length > 0) )
              || !filesValid()
            "
            class="btn btn-primary mb-2"
          >
            <span class="bold">Valider ma demande</span>
          </button>
          <button
            type="submit"
            *ngIf="Formulas.controls.length > 0"
            [disabled]="!Formulas.valid || !filesValid()"
            class="btn btn-primary mb-2"
          >
            <span class="bold">Valider ma demande</span>
          </button>
          <button
            type="button"
            class="btn btn-close-request mb-2 float-right"
            data-toggle="modal"
            data-target="#close-form"
          >
            Fermer ma demande
          </button>
          <button
            type="button"
            class="btn btn-secondary float-right"
            data-toggle="modal"
            data-target="#confirm-canceled"
          >
            Annuler ma demande
          </button>
        </div>
      </div>
    </form>
    <!-- modal negative number -->
    <span
    #openModalNegativeNumber
    data-target="#open-negative-number"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-negative-number"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
           Valeur incorrecte
          </h5>
        </div>
        <div class="modal-body">
          Dans "tarif réglé pour le mois", vous ne pouvez saisir qu'un nombre positive. 
          Pour ajouter des décimales, vous devez utiliser une ",".
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
    <!-- Modal formula already exist-->
    <span
      #openForumlaAlreadyExist
      data-target="#formula-already-exist"
      data-toggle="modal"
    ></span>
    <div
      class="modal fade"
      id="formula-already-exist"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Formule déjà sélectionnée</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <strong>
              Attention vous avez déjà sélectionné cette formule pour la
              présente demande .</strong
            >
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal confirm annuler demande -->
  <div
    class="modal fade"
    id="close-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Fermer la demande</h5>

        </div>
        <div class="modal-body">
          Attention, si vous confirmez cette action vous allez perdre toutes les
          saisies en cours
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Revenir sur la demande
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            routerLink="/mobility-request/list"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="confirm-delete-formula"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Suppression formule</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <strong>
            Attention, en supprimant cette ligne vous effacez tous les
            enregistrements et attachements correspondants .</strong
          >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <input type="hidden" #deletedIndex />
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="deleteRow(deletedIndex.value)"
          >
            Je Confirme
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="confirm-canceled"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCloseForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Annuler la demande</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Attention, confirmez vous bien que vous ne souhaitez plus donner suite
          à cette demande ? Le cas échéant toutes vos saisies seront perdues
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Revenir sur la demande
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="cancelTransportRequest()"
          >
            Confirmer l'annulation
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-transport-condition-popup></app-transport-condition-popup>
</div>
