import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { Canstants } from 'src/core/canstants/canstants';
import { HttpClientService } from 'src/core/services/http-client.service';

moment.locale('fr');
moment.tz.setDefault('Europe/Paris');
@Component({
  selector: 'app-request-approved',
  templateUrl: './request-approved.component.html',
  styleUrls: ['./request-approved.component.css'],
})
export class RequestApprovedComponent implements OnInit {
  @Input() request: any;
  @Input() ThisMonth: any;
  @Input() businessParamDay: any;
  @Output() onUpdateRequest = new EventEmitter<string>();
  @Output() onViewRequest = new EventEmitter<string>();
  siteAssets: string | undefined;
  constants: any = Canstants;
  actuelMonth: any = {};
  isViewMode: boolean = false;
  constructor(private httpService: HttpClientService<any>) {}

  async ngOnInit(): Promise<void> {
    // this.loadBusinessParam();
    await this.getAccesMonths();
  }
  // async loadBusinessParam(): Promise<any> {
  //   await this.httpService.findAll('BusinessParams').subscribe((bp) => {
  //     let formattedMonth = moment().format('MM');
  //     let title =
  //       Canstants.limitValidTransportM +
  //       moment().format("yyyy") +
  //       formattedMonth;
  //     let bpDay = bp.filter(
  //       (param: any) => param.title == title
  //     );
  //     this.businessParamDay = bpDay[0].descriptionBP
  //   });
  // }
  async getAccesMonths(): Promise<void> {
    this.actuelMonth.month = new Date().getMonth() + 1;
    this.actuelMonth.year = new Date().getFullYear();
    this.actuelMonth.day = Number(moment().format('DD'));
  }

  goUpdateRequest(event: any, request: any): void {
    const value = 'test of output update';
    this.onUpdateRequest.emit(value);
    localStorage.setItem('moisPivot', request);
  }
  goViewRequest(): void {
    const value = 'test of output view';
    this.onViewRequest.emit(value);
  }
}
