import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClientService } from 'src/core/services/http-client.service';
import { MoneyService } from 'src/core/services/money.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestion-calendrier',
  templateUrl: './gestion-calendrier.component.html',
  styleUrls: ['./gestion-calendrier.component.css'],
})
export class GestionCalendrierComponent implements OnInit {
  disableNext = false;
  disablePrev = true;
  existChanges = -1;
  indexOfSlide = 0;
  sildes: Array<any> = [];
  allBusinessParams: Array<any> = [];
  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private moneyService: MoneyService,
    private httpService: HttpClientService<any>
  ) {}
  get environement(): typeof environment {
    return environment;
  }

  async ngOnInit(): Promise<void> {
    this.getBusinessParam();
  }
  async getBusinessParam() {
    await this.httpService.findAll('BusinessParams').subscribe((bp) => {
      this.allBusinessParams = bp;
      this.getListMonth();
    });
  }
  updateBusinessParam(request: any) {
    this.existChanges = -1;
    let obj = [
      {
        title: 'TRS_Limit_Depot_M-x_' + request.titleDb,
        descriptionBP: request.limitDepotTrs
          ? request.limitDepotTrs.toString()
          : '',
      },
      {
        title: 'TRS_Limit_Modif_M_' + request.titleDb,
        descriptionBP: request.limitModiftTrs
          ? request.limitModiftTrs.toString()
          : '',
      },
      {
        title: 'TRS_Limit_Valid_M_' + request.titleDb,
        descriptionBP: request.limitValidTrs
          ? request.limitValidTrs.toString()
          : '',
      },
      {
        title: 'VLO_Limit_Depot_M_' + request.titleDb,
        descriptionBP: request.limitDepotVelo
          ? request.limitDepotVelo.toString()
          : '',
      },
      {
        title: 'VLO_Limit_Modif_M_' + request.titleDb,
        descriptionBP: request.limitModifVelo
          ? request.limitModifVelo.toString()
          : '',
      },
      {
        title: 'VLO_Limit_Valid_M_' + request.titleDb,
        descriptionBP: request.limitValidVelo
          ? request.limitValidVelo.toString()
          : '',
      },
    ];
    let payload: any = {
      listMonth: obj,
      Month: request.titleDb,
    };
    this.httpService
      .postFromBody('BusinessParams', payload)
      .subscribe((data) => {
        this.getBusinessParam();
      });
  }
  changeValues(value: any, request: any) {
    this.existChanges = request.index;
  }
  goPrev(): void {
    this.disableNext = false;
    if (this.indexOfSlide > 0) {
      this.indexOfSlide--;
      this.disablePrev = this.indexOfSlide === 0;
    }
  }

  goNext(): void {
    this.disablePrev = false;
    if (this.indexOfSlide < 1) {
      this.indexOfSlide++;
      this.disableNext = this.indexOfSlide === 1;
    }
  }
  remplirParDefaut(request: any) {
    let obj = [
      {
        title: 'TRS_Limit_Depot_M-x_' + request.titleDb,
        descriptionBP: request.limitDepotTrs
          ? request.limitDepotTrs.toString()
          : '08',
      },
      {
        title: 'TRS_Limit_Modif_M_' + request.titleDb,
        descriptionBP: request.limitModiftTrs
          ? request.limitModiftTrs.toString()
          : '08',
      },
      {
        title: 'TRS_Limit_Valid_M_' + request.titleDb,
        descriptionBP: request.limitValidTrs
          ? request.limitValidTrs.toString()
          : '10',
      },
      {
        title: 'VLO_Limit_Depot_M_' + request.titleDb,
        descriptionBP: request.limitDepotVelo
          ? request.limitDepotVelo.toString()
          : '08',
      },
      {
        title: 'VLO_Limit_Modif_M_' + request.titleDb,
        descriptionBP: request.limitModifVelo
          ? request.limitModifVelo.toString()
          : '08',
      },
      {
        title: 'VLO_Limit_Valid_M_' + request.titleDb,
        descriptionBP: request.limitValidVelo
          ? request.limitValidVelo.toString()
          : '10',
      },
    ];
    let payload: any = {
      listMonth: obj,
      Month: request.titleDb,
    };

    this.httpService
      .postFromBody('BusinessParams', payload)
      .subscribe((data) => {
        this.getBusinessParam();
      });
  }
  getCalendarMonths(): Array<any> {
    const startingDate = moment().subtract(1, 'month');
    const currentMonth = moment().format('MMYY');
    const mapping: any = [];
    for (let i = 0; i < 6; i++) {
      const d = startingDate.add(1, 'month');
      const obj = {
        key: 'Mob_' + d.format('MMYY'),
        description: d.format('MMMM YYYY'),
        month: d.format('MM'),
        year: Number(d.format('YYYY')),
        index: i,
        isCurrentMonth: d.format('MMYY') == currentMonth ? true : false,
        titleDb: d.format('YYYYMM'),
        limitDepotTrs: this.getValues(
          'TRS_Limit_Depot_M-x_' + d.format('YYYYMM')
        ),
        limitModiftTrs: this.getValues(
          'TRS_Limit_Modif_M_' + d.format('YYYYMM')
        ),
        limitValidTrs: this.getValues(
          'TRS_Limit_Valid_M_' + d.format('YYYYMM')
        ),
        limitDepotVelo: this.getValues(
          'VLO_Limit_Depot_M_' + d.format('YYYYMM')
        ),
        limitModifVelo: this.getValues(
          'VLO_Limit_Modif_M_' + d.format('YYYYMM')
        ),
        limitValidVelo: this.getValues(
          'VLO_Limit_Valid_M_' + d.format('YYYYMM')
        ),
      };
      mapping.push(obj);
    }
    return mapping;
  }
  getValues(month: any) {
    let item = ""
    let findedItem = this.allBusinessParams.find((x) => x.title == month);
    if (findedItem?.descriptionBP && findedItem?.descriptionBP.length == 1 && findedItem?.descriptionBP > 0 && findedItem?.descriptionBP < 10 ) {
      item = '0' + findedItem?.descriptionBP;
    }else {
      item = findedItem?.descriptionBP
    }
   
    return item;
  }
  async getListMonth(): Promise<void> {
    const mapping = this.getCalendarMonths();
    this.sildes = mapping.sort();
    this.sildes = this.chunk(mapping, 3);
  }

  chunk(arr: any, chunkSize: number): Array<any> {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
}
