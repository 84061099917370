<div>
  <div class="row">
    <div class="col-md-12">
      <div class="BannerContainer">
        <img
          alt=""
          class="imageBanner"
          [src]="environement.baseUrl + 'assets/images/transport.jpg'"
        />
      </div>
      <div class="row BannerGradient1 padding-left">
        <div class="col-md-6">
          <div class="col-md-12 margin-top mb-3">
            <span class="ArrowVelo"
              ><a routerLink="/mobility-request-admin/list"
                ><img
                  alt=""
                  [src]="
                    environement.baseUrl + 'assets/images/Arrow.svg'
                  " /></a></span
            ><span class="bold TitleVelo"> Demande détaillée </span>
            <div>
              <img
                alt=""
                class="DividerVelo"
                [src]="environement.baseUrl + 'assets/images/GroupDivider.svg'"
              />
            </div>
          </div>
          <div class="col-md-12 margin-top mt-4">
            <span class="bold Title2Velo semi-bold"
              >Indemnité <span class="bold">Transports</span></span
            >
          </div>

          <div class="col-md-12 bannerDescription">
            <p class="semi-bold">
              <a
                href="#"
                class="text-secondary"
                data-toggle="modal"
                data-target="#view-transport-condition"
                >Voir les conditions</a
              >
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right float-right box-requester-admin">
            <span class="bold yellowColor requester-name">
              {{ requestTransport?.requester?.displayName }}
            </span>
            <span class="bold yellowColor requester-name">
              {{ requestTransport?.requester?.gpn }}
            </span>
            <div
              *ngIf="
                requestTransport?.status === mobilityRequestStatus.Assigned &&
                requestTransport?.validator?.email == connectedUser?.email
              "
            >
              <span class="text-secondary"
                >Adresse domicile saisie dans l'attestation
              </span>
              <span class="bold yellowColor">{{
                requestTransport?.requesterAddress
              }}</span>
            </div>
            <div
              *ngIf="
                requestTransport?.status === mobilityRequestStatus.Assigned &&
                requestTransport?.validator?.email == connectedUser?.email &&
                personalAddress
              "
            >
              <span class="text-secondary"
                >Adresse domicile issue des systèmes RH
              </span>
              <span class="bold yellowColor">{{ personalAddress }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="SpacerBox margin-top">
  <form class="form" [formGroup]="adminViewForm">
    <div class="bold yellowColor titleFormule">
      <div class="squarePuce"></div>
      Période de remboursement
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">A partir du mois suivant</div>
          <div class="form-group">
            <!-- start month -->
            <span class="ml-4 date-box text-center">{{
              requestTransport?.startDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="transport-box mt-3">
          <div class="">Jusqu'au mois suivant</div>
          <div class="form-group">
            <span class="ml-4 date-box text-center">{{
              requestTransport?.endDate | date : "LLLL yyyy"
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="requestTransport?.certificateFmd">
        <div class="piecesContainer mt-3">
          <div class="piecesBoxGray">
            <img
              alt=""
              class="imgBox"
              [src]="environement.baseUrl + 'assets/images/Group.svg'"
            />
          </div>
          <a
            (click)="openFile(requestTransport?.certificateFmd?.toString())"
            class="link"
          >
            <span class="piecesTxt">Attestation pour reconduction</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Formule d'abonnement
      </div>
    </div>
    <!-- debut subcription formula  -->
    <ng-container formArrayName="Formulas">
      <ng-container
        *ngFor="
          let SubscriptionFormula of requestTransport?.subscriptionFormulas;
          let i = index
        "
      >
        <div [formGroupName]="i">
          <div class="row flex-center">
            <div class="col-md-4">
              <div class="row d-flex align-items-center">
                <div class="col-md-4">Formule d'abonnement</div>
                <div class="form-group col-md-8">
                  <span class="intitule-box">{{
                    SubscriptionFormula.subscription.title
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row d-flex align-items-center">
                <div class="col-md-4">
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Annuel
                    "
                    >Tarif annuel</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Mensuel
                    "
                    >Tarif mensuel</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.InfraMensuel
                    "
                    >Tarif unitaire</span
                  >
                  <span
                    class="descriptionxt"
                    *ngIf="
                      SubscriptionFormula.formulaType ==
                      enumSubscriptionType.Misc
                    "
                    >Tarif réglé pour le mois</span
                  >
                </div>
                <div class="form-group col-md-8">
                  <span class="date-box text-flex-right">{{
                    getForumlaValue(SubscriptionFormula).fees
                  }}</span>
                  <span class="euro">€</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="piecesContainer mt-2 mb-2">
                <div class="piecesBoxGray">
                  <img
                    alt=""
                    class="imgBox"
                    [src]="environement.baseUrl + 'assets/images/Line.svg'"
                  />
                  <img
                    alt=""
                    class="imgBox"
                    [src]="environement.baseUrl + 'assets/images/Arrow6.svg'"
                  />
                </div>
                <a
                  (click)="
                    openFile(SubscriptionFormula?.attachement?.toString())
                  "
                  class="link"
                >
                  <span class="piecesTxt"
                    >Mon justificatif d'abonnement {{ i + 1 }}</span
                  >
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div
                class="row d-flex align-items-center"
                *ngIf="SubscriptionFormula.quantity"
              >
                <div class="col-md-4">Quantité</div>
                <div class="col-md-8 form-group">
                  <span class="intitule-box">{{
                    SubscriptionFormula.quantity
                  }}</span>
                </div>
              </div>
              <div
                class="row d-flex align-items-center"
                *ngIf="SubscriptionFormula.entitledMisc"
              >
                <div class="col-md-4">
                  Désignation de votre formule « Autre »
                </div>
                <div class="col-md-8 form-group">
                  <span class="intitule-box">{{
                    SubscriptionFormula.entitledMisc
                  }}</span>
                </div>
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  !SubscriptionFormula.quantity &&
                  !SubscriptionFormula.entitledMisc
                "
              ></div>
            </div>
            <div
              class="col-md-4"
              *ngIf="
                requestTransport?.status == mobilityRequestStatus.Validated ||
                requestTransport?.status == mobilityRequestStatus.Assigned
              "
            >
              <div class="row d-flex align-items-center">
                <div class="col-md-4">Indemnité mensuelle applicable</div>
                <div class="col-md-8 form-group">
                  <span class="date-box text-flex-right">{{
                    getForumlaValue(SubscriptionFormula).refundRate
                  }}</span>
                  <span class="euro">€</span>
                </div>
              </div>
            </div>
            <hr class="mt-2 hr2" />
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div
      class="row mt-2"
      *ngIf="
        requestTransport?.status == mobilityRequestStatus.Validated ||
        requestTransport?.status == mobilityRequestStatus.Assigned
      "
    >
      <div class="col-md-4 bold yellowColor titleFormule mt-2">
        <div class="squarePuce"></div>
        Remboursement applicable
      </div>
      <div class="col-md-4">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">Total versé mensuellement</div>
          <div class="col-md-8 form-group">
            <span class="date-box text-flex-right">{{
              calculateIndemTotal()
            }}</span>
            <span class="euro">€</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-row-reverse mt-2">
      <div>
        <div class="transport-request">
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Submitted"
            >Statut: Envoyée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
            >Statut: En cours d'examen</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Returned"
            >Statut: A compléter par l’émetteur</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Canceled"
            >Statut: Annulée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Rejected"
            >Statut: Rejetée</span
          >
          <span
            class="en-cours bold"
            *ngIf="requestTransport?.status == mobilityRequestStatus.Validated"
            >Statut: Validée</span
          >
        </div>
        <div
          class="descriptionFormule mt-2"
          *ngIf="requestTransport?.status == mobilityRequestStatus.Assigned"
        >
          <!-- <div class="">En charge de votre demande:</div>
          <div class="">
            <a
              class="piece-justif-box"
              [href]="'mailto:' + requestTransport?.validator?.email"
              >{{ requestTransport?.validator?.displayName }}</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire demandeur</div>
          <div class="form-group">
            <span
              class="comment-box"
              *ngIf="requestTransport?.requesterComment != 'null'"
              >{{ requestTransport?.requesterComment }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="FormuleComment">
          <div class="descriptionxt">Commentaire valideur</div>
          <div class="form-group">
            <textarea
              matInput
              id="requester-comment"
              class="form-control"
              formControlName="controleComment"
              [readonly]="
                requestTransport?.status == mobilityRequestStatus.Assigned
                  ? null
                  : ''
              "
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row justify-content-center">
    <div class="box-action-admin">
      <button
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Submitted ||
          (requestTransport?.status == mobilityRequestStatus.Assigned &&
            requestTransport?.validator?.email != connectedUser?.email)
        "
        type="button"
        class="btn btn-take-request mt-3"
        (click)="takeRequest()"
      >
        Prendre en charge
      </button>
      <button
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Submitted ||
          (requestTransport?.status == mobilityRequestStatus.Assigned &&
            requestTransport?.validator?.email != connectedUser?.email)
        "
        type="button"
        class="btn btn-close-request mt-3"
        [routerLink]="['/mobility-request-admin/list']"
      >
        Fermer
      </button>
      <button
        [routerLink]="['/mobility-request-admin/list']"
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Rejected ||
          requestTransport?.status == mobilityRequestStatus.Canceled ||
          requestTransport?.status == mobilityRequestStatus.Validated ||
          (requestTransport?.status == mobilityRequestStatus.Returned &&
            requestTransport?.validator?.email == connectedUser?.email)
        "
        type="button"
        class="btn btn-close-request mt-3"
      >
        Fermer
      </button>
      <button
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Validated &&
          toBeCancled
        "
        type="button"
        class="btn btn-take-request mt-3 ml-3"
        data-toggle="modal"
        data-target="#annuler-request"
      >
        Annuler validation
      </button>

      <div
        *ngIf="
          requestTransport?.status == mobilityRequestStatus.Assigned &&
          requestTransport?.validator?.email == connectedUser?.email
        "
      >
        <button
          type="button"
          class="btn btn-validate mt-3"
          [disabled]="!Formulas.valid"
          data-toggle="modal"
          data-target="#valider-form"
        >
          Valider
        </button>
        <button type="button" class="btn btn-update mt-3" (click)="update()">
          Modifier
        </button>

        <button type="button" class="btn btn-return mt-3" (click)="returned()">
          Retourner
        </button>
        <button type="button" class="btn btn-reject mt-3" (click)="reject()">
          Rejeter
        </button>
        <button type="button" class="btn btn-cancel mt-3" (click)="annuler()">
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-close-request mt-3"
          [routerLink]="['/mobility-request-admin/list']"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="valider-form"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalValiderForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Valider la demande</h5>
        </div>
        <div class="modal-body">
          Êtes-vous sûr de vouloir valider la demande ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="validate()"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal annuler demande valider  -->
  <div
    class="modal fade"
    id="annuler-request"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalValiderForm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title">Annuler la demande</h5>
        </div>
        <div class="modal-body">
          Êtes-vous sûr de vouloir annuler la demande ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="cancelRequest()"
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- modal negative number -->
  <span
    #openModalNegativeNumber
    data-target="#open-negative-number"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-negative-number"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">Valeur négatives</h5>
        </div>
        <div class="modal-body">
          Vous ne pouvez saisir qu'un valeur positives dans la demande.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal take request -->
  <span
    #modalTakeRequest
    data-target="#open-take-request"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-take-request"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Demande déja prise en charge
          </h5>
        </div>
        <div class="modal-body">
          <p>Attention ! Cette demande est déjà prise en charge par
         <strong> {{ requestTransport?.validator?.displayName }}.</strong></p>
          <p>Êtes vous sûr de vouloir prendre en charge cette demande ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="takeRequestPopUp()"
          >
            Prendre en charge
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- can't do update -->
  <span
    #modalUpdateRequest
    data-target="#open-update-request"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-update-request"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Demande déja prise en charge
          </h5>
        </div>
        <div class="modal-body">
          <p>Vous ne pouvez pas réaliser cette action sur cette demande car une autre personne la prend en charge</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal take request -->
  <span
    #modaleAlreadyTaken
    data-target="#open-alreaday-taken"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-alreaday-taken"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Modification rejeter
          </h5>
        </div>
        <div class="modal-body">
          {{errMsgAssignedRequest}}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- modal already validated -->
  <span
    #modaleAlreadyValidated
    data-target="#open-alreaday-validated"
    data-toggle="modal"
  ></span>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    id="open-alreaday-validated"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title" id="exampleModalLabel">
            Demande déjà validée
          </h5>
        </div>
        <div class="modal-body">
          Cette demande a déjà été validée par {{ requestTransport?.validator?.displayName }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>

  
  <app-transport-condition-popup></app-transport-condition-popup>
</div>
